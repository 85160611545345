import React, { useState, useEffect } from 'react';
import {  Button, Row,Col  } from 'antd';
import Modals from '@iso/components/Feedback/Modal';
import confirmemailwrapper from './confirmEmailpopup.style';
import confirmemailicon from "../../assets/images/icon/ch/confirmemailicon.png";
import {useDispatch, useSelector} from "react-redux";
import authenticationAction from '@iso/redux/authentication/action';
import message from '@iso/components/Feedback/Message';
import modalActions from '@iso/redux/modal/actions';
const { resendEmailVerification } =authenticationAction;
const Modal = confirmemailwrapper(Modals);
const { closeModal } = modalActions;
const successMsg = msg => { message.destroy(); message.success(msg) };

const Confirmemailpopup = () => { 
    const { modalVisibility, modalType, modalProps } = useSelector(state => state.modal);
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem('userData'));
    if(!userData){
        window.location.href ="/login"
    }
    const handleCancel = () => {
        dispatch(closeModal());
    };
    const resendConfirmation = (resetEmail) => {
        const userData = JSON.parse(localStorage.getItem('userData'));

        dispatch(resendEmailVerification({email:modalProps.newEmail,userId:userData._id,fullName:userData.fullName,resendType:true,resendChangeEmail:true, resetEmail:resetEmail})).then(({ payload }) => {
            let setUserData = JSON.parse(localStorage.getItem('userData'));
            setUserData.newEmail = '';
            localStorage.setItem("userData", JSON.stringify(setUserData));
            if(payload.success){
                dispatch(closeModal());
                successMsg(payload.data);
            }else{
                message.destroy();
                return message.error(payload.error ? payload.error.error : "Something Went wrong");
            }
        })
    }
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
    }, [modalProps])

    return (
        <Modal        
            visible={modalVisibility && modalType == "confirmEmail"}
            onCancel={handleCancel}
            width="450px"
            footer={null}  
        >
        <div className="confirmemail">
            <Row>
                <Col span={24} className="confirmemailcenter">
                    <img src={confirmemailicon} className="confirmmailicon"/>
                    
                </Col>
                <Col span={24} className="confirmemailcenter">
                    <h2 className="confirmemailtext">Please confirm your email</h2>
                    <div className="emailcolor">Hi there we still need to confirm your email.</div>
                    <div className="emailcolor">Should we resend the confirmation email to</div>
                    <div className="emailtext">{modalProps.newEmail}</div>
                </Col>
                <Col span={24} className="confirmemailcenter">
                    <Button className="resendemailbtn" onClick={() => resendConfirmation(false)}>Resend verification email</Button>
                </Col>
                <Col span={24} className="confirmemailcenter">
                    <Button className="changemailbtn" onClick={() => resendConfirmation(true)}>Don't change my email </Button>
                </Col>
            </Row>
        </div>
        </Modal>
    )
}   

export default Confirmemailpopup