import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }
  .callLogFilterPopup .ant-modal-title{
       text-align: center;
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
  background-color: #efefef!important;
  letter-spacing: .5px;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

/* Login Page tooltip design */
.ant-tooltip.chMagicLinkTooltip{
  max-width: 100%;
  background-color: #222222;
  padding-bottom: 0;
}
/* End Login Page tooltip design */

/* custom styles */

/*Custom message css*/
.ant-message-notice-content{
  padding: unset !important;
  margin-top: -8px;
}
.ant-message-custom-content{
  padding: 16px;
  border-radius: 2px;
}
.ant-message-success{
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.ant-message-info{
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.ant-message-info i{
  color: #bee5eb;
}
.ant-message-error{
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.ant-message-warning, .ant-message-warn{
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
/*End Custom message css*/

.chflex {
  display: flex;
}
.chflexcenter {
  justify-content: center;
  align-items: center;
}
.chjstcenter {
  justify-content: center;
}
.chjstend {
  justify-content: end;
}
.spacebetween {
  justify-content: space-between;
}
.chaligncenter {
  align-items: center;
}
.chalignend {
  align-items: end;
}
.flexcolomch {
  flex-direction: column;
}
.chflexw1 {
  flex: 1;
}
.gap8{
  gap: 8px;
}
.isoLogoWrapper  .chlogot {
  width: 160px;
}
.isoLogoWrapper  .chlogocollapse {
  width: 51px;
}
.bgchtitile {
  font-size: 1.5rem;
}
.fbold {
  font-weight: 600;
}
.height100 {
  height: 100%;
}
.text-center {
  text-align: center;
}
.mgbtm15 {
  margin-bottom: 15px;
}
.mgbtm10 {
  margin-bottom: 10px;
}
.mgtop5 {
  margin-top: 5px;
}
.mgtop10 {
  margin-top: 10px;
}
.mgtop15 {
  margin-top: 15px !important;
}
.mgtop25 {
  margin-top: 25px !important;
}
.mgleft15 {
  margin-left: 15px;
}
.mgbtmzero {
  margin-bottom: 0px !important;
}
.padzero {
  padding: 0px !important;
}
.padbtmzero {
  padding-bottom: 0px !important;
}
.fwidth {
  width: 100%;
}
.fleft {
  float: left;
}
.formpsize {
  font-size: 15px;
  color: #333;
}
.drpdwnshoadow .ant-select-selection {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 0 1px rgba(0,0,0,.08)!important;
  border: 1px solid #f2f2f2!important;
  border-radius: unset;
}
.lnhnormal {
  line-height: normal;
}
.gbtnheight {
  height: 36px !important;
}
.mgbtm20 {
  margin-bottom: 20px;
}
.mgleft12 {
  margin-left: 12px;
}
.mgright5 {
  margin-right: 5px;
}
.mgright10 {
  margin-right: 10px;
}
.mgright15 {
  margin-right: 15px;
}
.plansprice {
  padding: 5px 0;
  border-top: 1px solid #dbdbdb;
  color: #6b6b6b;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .8px;
  text-transform: uppercase;
}
.brdorange {
  border: 1px solid #e27646 !important;
}
.globalinputch {
  border: none !important;
  border-bottom: 1px solid #9e9e9e !important;
  border-radius: 0 !important;
  outline: none !important;
  height: 3rem !important;
}
.globalinputch:hover {
  border-color: #9e9e9e !important;
  outline: none !important;
}
.globalinputch:focus {
border-bottom: 1px solid #e27646 !important;
box-shadow: 0 1px 0 0 #e27646 !important;
outline: none !important;
}
.hideAllocateNumbers{
    display: none;
}
.text-right {
  text-align: right;
}
.chmnsidebar {
    flex: 0 0 212px !important;
    max-width: 240px;
    min-width: 212px !important;
    width: 212px !important;
}  
.siwtchorangerct .ant-switch-checked {
  background-color: #e27646 !important;
}
.clrwhite {
  color: white;
}
.cursorp {
  cursor: pointer;
}
.crpoint {
  cursor: pointer!important;
}
.fnwgt500 {
  font-weight: 500;
}
.glbcolr {
  color: #545454;
  font-size: 15px;
  font-weight: 500;
}
.mgbtm30 {
  margin-bottom: 30px;
}
.brdunset {
  border: unset !important;
}
.numbertable .ant-table-thead > tr > th {
  background: WHITE;
  border-bottom: 1px solid #e8e8e8;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-top: 1px solid #e8e8e8;
  font-size: 15px;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
}
.uploadbtnctm {
    display: flex !important;
    align-items: center !important;
    background: rgba(158,158,158,.2) !important;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12) !important;
    border: unset !important;
}
.uploadbtnctm:hover {
  background-color: #2bbbad !important;
  color: white !important;
}
.uploadbtnctm i {
  margin-right: 8px;
}
/*Module Sidebar*/
.section__wrapper{
  max-height: 88vh;
  overflow: auto;
  background-color: #efefef;
}
.moduleSidebar.ant-anchor-wrapper {
  position: fixed;
  width: 195px;
  background-color: #f5f5f5;
  height: 100%;
  border-right: 1px solid #dddddd;
}
.numbersidebar.ant-anchor-wrapper {
  width: 217px;
}
.moduleSidebar .ant-anchor{
  // padding-top: 20px;
  padding-left: 0;
}
.moduleSidebar .ant-anchor .ant-anchor-ink{
  display: none;
}
.moduleSidebar .ant-anchor-link{
  padding: 0;
}
.moduleSidebar .ant-anchor-link .ant-anchor-link-title{
  padding: 14px 16px;
}
.moduleSidebar .ant-anchor-link:hover{
  background-color: #34495e;
}
.moduleSidebar .ant-anchor-link:hover .moduleSidebarSpan{    
  color: #ffffff;
}
.moduleSidebar .ant-anchor-link.ant-anchor-link-active{
  background-color: #dddddd;
}
.moduleSidebar .ant-anchor-link.ant-anchor-link-active:hover{
  background-color: #34495e;
  color: #ffffff;
}
.moduleSidebarSpan i {
  font-size: 18px;
  width: 25px;
}
.moduleSidebarSpan {
  color: #464646;
  font-weight: 500;
  font-size: 13px !important;
  display: flex;
  align-items: center;
}
/*End Module Sidebar*/

/*Core Modify*/
.ch_radio_color .ant-radio-checked .ant-radio-inner{
  border-color: #e27646;
  background-color: #e27646;
}
.ch_radio_color .ant-radio-checked .ant-radio-inner::after{
  background-color: #e27646;
}
.ch_radio_color.ant-radio-wrapper:hover .ant-radio,
.ch_radio_color .ant-radio:hover .ant-radio-inner,
.ch_radio_color .ant-radio:focus .ant-radio-inner,
.ch_radio_color .ant-radio:active .ant-radio-inner,
.ch_radio_color .ant-radio-input:focus + .ch_radio_color .ant-radio-inner{
  border-color: #e27646;
}
.ch_toggle_switch.ant-switch{
  background-color: #818181;
  height: 15px;
  // left: -2px;
}
.ch_toggle_switch.ant-switch.ant-switch-checked{
  background-color: #84c7c1;
}
.numbersetswitch.ch_toggle_switch.ant-switch::after{
  top: -4px;
  width: 21px;
  height: 21px;
  box-shadow: 0 1px 3px 1px rgba(0,0,0,.4);
  background-color: #f1f1f1;
  margin-left: -4px;
  left: -3px;
}
.ch_toggle_switch.ant-switch.ant-switch-checked::after{
  background-color: #26a69a;
  margin-left: 2px;
}
/*End Core Modify*/

/*Change YES NO sequence*/
.ourApp_btn .ant-modal-footer > div .ant-btn-primary,
.ourApp_btn .ant-modal-footer > div .ant-btn-primary:hover,
.ourApp_btn .ant-modal-footer > div .ant-btn-primary:focus,
.ourApp_btn .ant-modal-footer > div .ant-btn-primary:active {
  background: #e27849 !important;
  border-color: #e27849 !important;
  color: #fff !important;
  margin-right: 10px !important;
}
.ourApp_btn .ant-modal-footer button:nth-child(1):hover,
.ourApp_btn .ant-modal-footer button:nth-child(1):hover,
.ourApp_btn .ant-modal-footer button:nth-child(1):focus,
.ourApp_btn .ant-modal-footer button:nth-child(1):active {
  background: #fff !important;
  border-color: #ddd !important;
  color: #333 !important;
}
.ourApp_btn .ant-modal-footer > div {
  display: flex;
  flex-direction: row-reverse;
}
.ourApp_btn .ant-modal-body p a {
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500;
}
.restrictBtn .ant-modal-footer > div .ant-btn-primary {
  background: #e27849;
  border-color: #e27849;
  color: #fff;
 }
.exportYesNO .ant-modal-footer > div {
  display: flex;
  flex-direction: row-reverse;
}
.exportYesNO .ant-modal-footer button:nth-child(1),.exportYesNOSecond .ant-modal-footer button:last-child{
  margin-left: 5px;
  background: #e27849;
  border-color: #e27849;
  color: #fff;
}
.exportYesNO .ant-modal-footer button:nth-child(1):hover,.exportYesNO .ant-modal-footer button:nth-child(1):active,.exportYesNO .ant-modal-footer button:nth-child(1):focus {
  background-color: #d46332;
  border-color: #e27849;
  color: #fff;
}
.exportYesNO .ant-modal-footer button:nth-child(2) {
  margin-left: 0;
}
.addYesNo .ant-modal-footer button:nth-child(1) {
  border-color: #d9d9d9;
  background: #fff;
  color: #000000d9;
}
.addYesNo .ant-modal-footer button:nth-child(1):hover,.addYesNo .ant-modal-footer button:nth-child(1):active,.addYesNo .ant-modal-footer button:nth-child(1):focus {
  color:#40a9ff;
  border-color: #40a9ff;
  background: #fff;
}
.addYesNo .ant-modal-footer > div > button {
  margin:5px 10px 5px 10px !important;
}
.addYesNo .ant-modal-footer > div {
  text-align:center !important;
  display:flow-root !important;
}
.warningbtn .ant-modal-footer button:nth-child(1){
  display:none;
}
/*End Change YES NO sequence*/

/*number plan select tooltip*/
.number_plan_tooltipWrap .ant-tooltip-inner {
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    margin: 0 auto;
    min-width: 400px;
    max-width: 600px;
}
/*End number plan select tooltip*/
.searchinticon{
  position: relative;
}
.searchinticon i{
  position:absolute;
  right: 8px;
  top: 9px;
}
.searchinticon .searchinttext{
  padding-right: 26px;
  border: unset;
  border-bottom: 1px solid #d9d9d9;
}

/*Parvati Css*/

#fixed-order-team .ant-input
{
  width:50px!important;
  margin-left:-25px!important;
  border-radius:5px !important;
  border:1px solid #9e9e9e !important;
}
.dropdown_mr-5 .ant-form-item-label
{
  margin-right:5px!important;
}
.toCapitalize {
  text-transform: capitalize;
}.
.ant-input:focus {
  border-color:rgb(0 0 0 / 15%) !important;
  box-shadow:none !important;
}
.ant-input:hover {
  border-color:rgb(0 0 0 / 15%) !important; 
}
.free-warning-center .ant-modal-title {
  text-align:center
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .svgremove svg.nav-icon{
  display:none;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .campaignremove {
  display:none;
}
/*hippa compliance*/
.hippaImg {
  width: 70px;
  height: 22px;
  margin-right: 10px;
  align-self: center;
}
.pciImg {
  width: 70px;
  height: 28px;
}
.ant-layout-has-sider > div:first-child  {
  background-color:#fff;
}
.ant-layout-has-sider > div > div  {
 box-shadow:unset;
 border-right: unset;
}
.moduleSidebar.ant-anchor-wrapper {
  margin-left: 0px;
}
.add_credit_ul {
  list-style: disc;
  padding-left: 18px;
}
.add_credit_modal .ant-modal-footer {
  display: none;
}
.app_menu_list_dropdown {
  box-shadow: 0 1px 18px 0 rgb(0 0 0 / 21%);
  border: 1px solid #ddd;
  border-radius: 5px;
 }
 .assignPopup .ant-btn-default {
  display: none !important;
 }
//  disabled btn css
.ant-btn.ant-btn.disabled, 
.ant-btn.ant-btn[disabled], 
.ant-btn.ant-btn.disabled:hover, 
.ant-btn.ant-btn[disabled]:hover, 
.ant-btn.ant-btn.disabled:focus, 
.ant-btn.ant-btn[disabled]:focus, 
.ant-btn.ant-btn.disabled:active, 
.ant-btn.ant-btn[disabled]:active, 
.ant-btn.ant-btn.disabled.active, 
.ant-btn.ant-btn[disabled].active {
  color: #D8D8D8 !important;
  background-color: #f7f7f7 !important;
  border-color: #e9e9e9 !important;
  cursor: not-allowed !important;
}

 } 
 .doctYesNO .ant-modal-footer > div {
  display: flex;
  flex-direction: row-reverse;
}
.doctYesNO .ant-btn-primary,
.doctYesNO .ant-btn-primary:hover,
.doctYesNO .ant-btn-primary:focus,
.doctYesNO .ant-btn-primary:active {
  background-color: #e27849;
  color: #fff;
  border-color: #e27849;
  margin-right: 5px;
}
.hideTopBar .ant-tabs-top-bar{
  display: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .addnumpopup{
    width:754px !important;
  }
  .ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .removeiconcamp i.nav-icon{
    display:none;
  }
  .ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .hidenotification{
    display:none;
  }
  .newintegrationsetting {
    width:766px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1359px) {
  .planbilligsidebar.ant-anchor-wrapper {
    width: 180px;
  }
  .intsidebar.intsidebarres.ant-anchor-wrapper{
    width: 200px;
  }
  .intsidebar.ant-anchor-wrapper {
    width: 172px;
  }
}
.bottom_text a {
  color: rgb(0 0 0 / 30%) !important;
  font-weight: 500;
  font-size: 10px;
  pointer-events: none;
}
.footer_bottom_sticky {
  position: absolute;
  left: 55px;
  bottom: 5px;
  background-color: rgb(255, 255, 255) !important;
  padding: 0 !important;
}
.d__block {
  display: block !important;
}
.grecaptcha-badge{
  display: none !important;
}
.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item:hover {
    background-color:#e2764647 !important;
}
.ant-select-selection:focus, .ant-select-selection:active {
  border:1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
button.fileDownloadBtn{
  border: 1px solid #2685ee;
  background-color: #2685ee;
  margin-left: 4px;
  padding: unset;
  border-radius: 30px;
  height: 34px;
  width: 34px;
}
button.fileDownloadBtn:hover, button.fileDownloadBtn:focus, button.fileDownloadBtn:active {
  border: 1px solid #2685ee;
  background-color: #2685ee;
}
button.fileSendBtn{
  border: 1px solid #5bc0de;
  background-color: #5bc0de;
}
button.fileSendBtn:hover, button.fileSendBtn:focus, button.fileSendBtn:active {
  border: 1px solid #5bc0de;
  background-color: #5bc0de;
}
.dummynumbernote{
  margin: 0 auto;
  float: none;
  text-align: center;
  padding: 8px 35px 8px 14px;
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.purchasenowbtn{
  text-align: center;
  margin: 2%;
  // padding: 2%;
}
.purchasenowbtn .btn-purchase{
  background: #e27849;
  border-color: #e27849;
  color: #fff;
}
.pushAccount-discr{
  margin:5% !important;
  padding:2% !important;
  text-align: left;
}
.disable__Div {
  pointer-events: none;
  opacity:0.5;
}
.searchclosefix .anticon-close-circle {
  position: inherit;
  top: unset;
  right: unset;
}
.searchclosefix .ant-select-selection__clear {
  right: 34px;
  width: 20px;
  height: 20px;
}
.searchclosefix .topbar__Search_Bar {
  padding-right: 37px;
}

// Sidebar Credits Info Tooltip Css
.ant-tooltip:has(.sidebarCreditsTooltip){
  left:4px !important;
}
.ant-tooltip-content:has(.sidebarCreditsTooltip) {
  width:230px !important;
}
.ant-tooltip-content:has(.sidebarCreditsTooltip) .ant-tooltip-arrow {
  display: none !important;
}
.ant-popover-content:has(.planSettingPopoverContent),.ant-popover-content:has(.planBillingPopoverContent){
  max-width:500px !important;
}
.ant-popover-content:has(.needHelpPopover) .ant-popover-arrow{
  top:88% !important;
}
.productTourWrapper:has(.numbersettingarrow) {
  top: 90px;
}
/* HashtagBtn Css Start 11-07-23 */
.cardHashtagLink {
  flex-shrink:0 !important;
}
.cardHashtagLink:has(a + a) {
  gap:12px !important;
}
.cardHashtagLink a:first-child {
  white-space: nowrap;
}
.cardHashtagLink .hashtagBtn{
  border-radius: 50px;
  background: #FFE1E4 !important;
  padding: 8px 18px;
  color: #721C24 !important;
  font-size: 13px !important; 
  display: inline-block;
}
/* HashtagBtn Css End 11-07-23 */
`;

export default GlobalStyles;
