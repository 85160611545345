import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const isoModal = ComponentName => WithDirection(styled(ComponentName)`
.enterprisewrapper .ant-row:after {
    display: block;  
    padding: 0 10px;     
}
.plancard .ant-card-head {
    border-bottom: 0px;
  }
  
  .plancard .ant-card-head-title {
    font-weight: 700;
    font-size: 22px;
    padding: 16px 0px 0;
    padding-bottom: 5px;
    text-align: center;
    text-transform: capitalize;
  }
  .plancard{
    border-radius: 5px;
  }
  .plancard .ant-card-body {
    padding-top: 0px;
  }
  
.plantext {
    color: rgb(0, 0, 0);
    font-size: 30px;
    text-align: center;
    font-weight: 700;
  }
  
  .planmonth {
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .signupbtn{
    border-color: rgb(226, 120, 73);
    padding: 11px 32px;
    font-weight: 500;
    border-radius: 50px;
    width: 183px;
    background: white;
    color: rgb(218, 107, 58);
    height: 40px;
  }
  
  .planbtncenter {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .plancard .ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #e27849;
    border-color: #e27849;
    color: #fff;
  }
  .planlist{
      position:relative;
  }
  .planlist li {
    font-weight: 600;
    font-size: 14px;
    margin: 8px 0px;
    list-style-type: none;
    padding-left: 50px;
    position: relative;    
    padding: 5px 0 5px 50px;
  }
  
  .planlist li:before {
    border-radius: 50%;
    top: 6px;
    content: "✓";
    color: green;
    display: block;
    position: absolute;
    left: 32px;
  }
  .subtitle{
    margin-bottom: 7px;
}
.everthingbox{
    font-style: italic;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}
.morefeaturtext{
    color: rgb(218, 107, 58);
    text-decoration: underline;
    padding-left:50px;
}
.subinfo{
    font-size: 16px;
    margin-left: 5px;
}
.sublist{
    display:flex;
    align-items:center;
}
`);

export default isoModal;