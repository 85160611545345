import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const isoModal = ComponentName => WithDirection(styled(ComponentName)`
.upgradewrapper .ant-row:after {
    display: block;       
}
.purchasenowbtn{
    color:#fff;
    background:#e27646;
    border:1px solid #e27646;
    height:40px;
  }
  .purchasenowbtn.ant-btn:active{
    color:#fff;
    background:#e27646;
    border:1px solid #e27646;
  }
  .purchasenowbtn.ant-btn:hover, .purchasenowbtn.ant-btn:focus{
    color:#fff;
    background:#e27646;
    border:1px solid #e27646;
  }
  .registerrequest{
      font-size:15px;
      color: #979797;
  }
`);

export default isoModal;