//App Public Routes
export const APP_PUBLIC_ROUTE = {
  LANDING: '/',
  LOGIN_IN: '/login',
  SIGN_UP: '/signup',
  CONFIRM_SIGN_UP_ACCOUNT: '/confirmaccount',
  RESET_PASSWORD: '/reset',
  ATTEMPT_FAIL: '/attemptfail',
  SUB_USER_INVITE: '/register',
  MAGIC_LINK: '/magiclinkAuth',
  GOOGLE_SIGNIN: '/googlelogin',
  DIRECT_LOGIN:'/directlogin',
  NORMAL_DIRECT_LOGIN:'/normaldirectlogin',
  CHANGE_EMAIL:'/changeemail',
  AUTH_PROFILE: '/update-profile',
  INSTALL_BITRIX: '/install-bitrix',
};

//App Private Routes
export const APP_PRIVATE_ROUTE = {
  DASHBOARD: '/dashboard',
  MONITER_USER:'/moniteruser',
  NUMBER: '/number',
  ADD_NUMBER: '/addnumber',
  NUMBER_SETTING: '/number_settings',
  NUMBER_BY_ID: '/numbers',
  DID_GROUPS : '/didgroups',
  ADD_DID_GROUP :'/addDidgroup',
  DID_GROUP_BY_ID : '/didGroupDetails',
  NUMBER_CUSTOMER_AVAILABILITY: '/numberCustomAvailability',
  USERS: '/users',
  USER_BY_ID: '/user',
  USER_CUSTOMER_AVIBLITY: '/userCustomAvailability',
  INVITE_USER: '/inviteuser',
  PLAN: '/plan',
  PLAN_BILLING: '/planBilling',
  CALL_LOG_HISTROY: '/userCallLogHistory',
  USER_PROFILE: '/userProfile',
  USER_PROFILE_DOCUMENT: '/uploadDocument',
  CHANGE_PASSWORD: '/changePassword',
  IMPORT_CONTACT: '/importContact',
  CALL_REPORT_DASHBOARD: '/callReportDashboard',
  CALL_REPORT: '/callReport',
  ABANDON_RATE_REPORT: '/abandonRateReport',
  AVAILABILITY_REPORT: '/availabilityReport',
  USER_STATUS_REPORT: '/userStatusReport',
  CALL_STATUS_REPORT: '/callStatusReport',
  OUTGOING_CALL_REPORT: '/outgoingCallReport',
  CALL_PLANNER: '/callreminder',
  TEAMS: '/teams',
  ADD_TEAMS: '/addTeam',
  TEAM_BY_ID: '/teamDetails',
  CAMPAIGNMANAGEMENT: '/campaignManagement',
  ADD_CAMPAIGNMANAGEMENT: '/addcampaignManagement',
  CAMPAIGNMANAGEMENT_BY_ID: '/campaignManagementDetails',
  INTEGRATION: '/integrations',
  POWER_DIALER: '/powerDialer',
  ADD_CAMPAIGNS: '/addCampaigns',
  UPDATE_CAMPAIGNS: '/powerDialer/updateCampaigns' + '/:campaignMasterId',
  EDIT_CAMPAIGNS: '/editCampaigns',
  EDIT_CAMPAIGNS_BROADCAST: '/broadcast/editCampaign/2',
  // DUMMY_ACTIVITY_FEED: '/dummyactivityfeed',
  DUMMY_BROADCAST_ACTIVITY_FEED: '/broadcast/dummyactivityfeed',
  COACH:'/coach',
  CALL_SCRIPT:'/callscript',
  BROADCAST:'/broadcast',  
  CALL_TRACKING:'/calltracking',
  INTEGRATIONCALLTRACK: '/calltracking/integration',
  ACTIVITYFEEDCALLTRACK: '/calltracking/activity',
  CALLDETAIL: '/calltracking/calldetail/:id',
  NUMBERCALLTRACK: '/calltracking/numbercalltrack',
  ADD_NUMBERCALLTRACK: '/calltracking/addnumbercalltrack',
  NUMBER_SETTINGCALLTRACK:'/calltracking/number_settingcalltrack',
  CAMPAIGNCALLTRACK:'/calltracking/campaigncalltrack',
  LEADERBOARD: '/leaderboard',
  INTEGRATION_INSTALL: '/install/:integrationName',
  CALL_SUMMARY:'/coach/callSummary',
  COACHCALLANALYSIS:'/coach/callAnalysis',
  EVALUATIONCOACHING:'/coach/evaluationCoaching',
  MANAGETHEMES:'/coach/manage/themes',
  TARGET_VS_ACHIEVEMENT: '/targetvsachievement',
  // WHATSAPP_BUSINESS_MAIN:'/whatsappbusiness',
  // WHATSAPP_BUSINESS_LIVECHAT:'/whatsappbusiness/livechat',
  // WHATSAPP_BUSINESS_HISTORY:'/whatsappbusiness/history',
  // WHATSAPP_BUSINESS_CONTACTS:'/whatsappbusiness/contacts',
  // WHATSAPP_BUSINESS_CAMPAIGNS:'/whatsappbusiness/campaigns',
  // WHATSAPP_BUSINESS_MANAGE:'/whatsappbusiness/manage',
  CALL_LOG: '/calllog',
  SMS_LOG: '/smslog',
  DUMMY_CALL_LOG: '/dummycalllog',
  DUMMY_SMS_LOG: '/dummysmslog',
  SMS_VERIFICATION: '/sms-verification',
  ADD_TARGET: '/addtarget',
  EDIT_TARGET: '/editTarget',
}

//Modify App Public Routes
export const PUBLIC_ROUTE = {
  ...APP_PUBLIC_ROUTE,
  CONFIRM_SIGN_UP_ACCOUNT: APP_PUBLIC_ROUTE.CONFIRM_SIGN_UP_ACCOUNT + '/:hash/:i/:c',
  CONFIRM_SIGN_UP_ACCOUNT_OLD: APP_PUBLIC_ROUTE.CONFIRM_SIGN_UP_ACCOUNT + '/:hash',
  RESET_PASSWORD: APP_PUBLIC_ROUTE.RESET_PASSWORD + '/:hash/:i/:c',
  ATTEMPT_FAIL: APP_PUBLIC_ROUTE.ATTEMPT_FAIL + '/:hash',
  SUB_USER_INVITE: APP_PUBLIC_ROUTE.SUB_USER_INVITE + '/:hash/:i/:c',
  CHANGE_EMAIL: APP_PUBLIC_ROUTE.CHANGE_EMAIL + '/:hash/:i/:c',
  // CHANGE_EMAIL: APP_PUBLIC_ROUTE.CHANGE_EMAIL + '/:hash' 

};

//Modify App Private Routes
export const PRIVATE_ROUTE = {
  ...APP_PRIVATE_ROUTE,
  NUMBER_BY_ID: APP_PRIVATE_ROUTE.NUMBER_BY_ID + '/:id',
  USER_BY_ID: APP_PRIVATE_ROUTE.USER_BY_ID + '/:id',
  USER_CUSTOMER_AVIBLITY: APP_PRIVATE_ROUTE.USER_CUSTOMER_AVIBLITY + '/:id',
  NUMBER_CUSTOMER_AVAILABILITY: APP_PRIVATE_ROUTE.NUMBER_CUSTOMER_AVAILABILITY + '/:id',
  TEAM_BY_ID: APP_PRIVATE_ROUTE.TEAM_BY_ID + '/:id',
  DID_GROUP_BY_ID :APP_PRIVATE_ROUTE.DID_GROUP_BY_ID + '/:id',
  EDIT_CAMPAIGNS: APP_PRIVATE_ROUTE.EDIT_CAMPAIGNS + '/:id',
  EDIT_CAMPAIGNS_BROADCAST:APP_PRIVATE_ROUTE.EDIT_CAMPAIGNS_BROADCAST+'/:id',
  ADD_CAMPAIGNS_BROADCAST:APP_PRIVATE_ROUTE.BROADCAST+'/addCampaign/2',
  ADD_CALL_SCRIPT: '/addscript',
  EDIT_CALL_SCRIPT: '/editscript/:id',
  BROADCAST_CALL:APP_PRIVATE_ROUTE.BROADCAST + '/voiceBroadcast',
  BROADCAST_ACTIVITY_FEED:APP_PRIVATE_ROUTE.BROADCAST + '/activityFeed',
  CALL_RECORDING_PAGE:'/recording/:callsid',
  EDIT_TARGET:APP_PRIVATE_ROUTE.EDIT_TARGET +'/:id',
};
