import callPlanner from './actions';
import { bindActionCreators } from 'redux';
import _ from "lodash";

const initState = {
  call_reminders: {},
  delete_reminders: {},
  numberMasking: {},
}

export default function callPlannerReducer(state = initState, action) {
  switch (action.type) {
    case callPlanner.CALL_REMINDERS:
      let obj = {...action.payload}
      if(action.activeKey==="1" && action.payload.CallReminders && state.call_reminders.CallReminders){
        // obj.CallReminders.callremindersPending = state.call_reminders.CallReminders.callremindersPending.concat(obj.CallReminders.callremindersPending)
      }else if(action.activeKey==="2" && action.payload.CallReminders && state.call_reminders.CallReminders){
        obj.CallReminders.callremindersCompleted = state.call_reminders.CallReminders.callremindersCompleted.concat(obj.CallReminders.callremindersCompleted)
      }
      return {
        ...state,
        call_reminders:obj,
        numberMasking: action.payload.commonData.numberMasking
      };
      case callPlanner.DELETE_REMINDER:
      let newObj = {...state.call_reminders};
      let newData = [...state.call_reminders.CallReminders.callremindersPending.slice(0,action.payload),...state.call_reminders.CallReminders.callremindersPending.slice(action.payload+1)];
      newObj.CallReminders.callremindersPending = newData;
      return {
        ...state,
        call_reminders:newObj,
        delete_reminders: action.postdata
      };
      case "CLEAR_CALL_PLANNER":
        return {
          call_reminders:[],
          delete_reminders:[]
        }
    default:
      return state;
  }
}

