import axios from 'axios';
import { cryptoEncrypt, cryptoDecrypt } from '@iso/library/helpers/cryptoHelper';

const axiosConfig = (store) => {

  // Add a request interceptor
  axios.interceptors.request.use(config => {
    // Do something before request is sent
    /* if(config.headers['Content-Type'] != "multipart/form-data"){
      config.headers.encryptedch = true;
      config.data && (config.data = { encryptedch: cryptoEncrypt(config.data) })
    } */
    config.headers.castlerequesttoken = window.castlerequesttoken || ''
    return config;
  }, error => {
    // Do something with request error
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //response.data && response.data.encryptedch && (response.data = cryptoDecrypt(response.data.encryptedch));
    return response;
  }, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    /* error.response && 
    error.response.data && 
    error.response.data.encryptedch && (error.response.data = cryptoDecrypt(error.response.data.encryptedch)); */
    return Promise.reject(error);
  });
};
export default axiosConfig;