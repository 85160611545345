export const setMessage = (data) => dispatch => {
               dispatch({
               type:"SET_CUSTOM_MESSAGE",
               payload:data
              })
};

export const clearMessage = () => dispatch => {
               dispatch({
                              type:"CLEAR_CUSTOM_MESSAGE"
               });
}