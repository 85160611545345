import teamsActions from './actions';

const initState = {
  team_list: [],
  add_team: [],
  edit_team: [],
  team_priority: [],
  update_team: [],
  delete_team: [],
  team_name_list : []
}

export default function teamsReducer(state = initState, action) {
  switch (action.type) {
    case teamsActions.TEAM_LIST:
      return {
        ...state,
        team_list: action.payload,
      };
    case teamsActions.ADD_TEAM:
      if (action.payload.success) {
        let teamList = !state.team_list ? [] : state.team_list.success?state.team_list.data:state.team_list;
        teamList.push(action.payload.data);
        return {
          ...state,
          team_list: teamList,
        };
      }
    case teamsActions.UPDATE_TEAM:
      let finalList;
      if (action.payload.success && state.team_list.length !== 0) {
        finalList = state.team_list;
        let data = {
          _id: action.payload.data._id,
          name: action.payload.data.name,
          cascadingOrder: action.payload.data.cascadingOrder,
          teamMembers: action.staticPayload.teamMembersWithName,
        };
        finalList.data.splice(action.staticPayload.updateIndex, 1, data);
      } else {
        finalList = action.payload
      }
      return {
        ...state,
        update_team: finalList,
      };
    case teamsActions.DELETE_TEAM:
      if (action.payload.success) {
        let teamList = state.team_list;
        teamList.data.splice(action.staticPayload, 1);
        return {
          ...state,
          team_list: teamList
        };
      }
    case teamsActions.EDIT_TEAM:
      return {
        ...state,
        edit_team: action.payload,
      };
    case teamsActions.TEAM_PRIORITY:
      return {
        ...state,
        team_priority: action.payload,
      };
      case teamsActions.TEAM_NAME_LIST:
      return {
        ...state,
        team_name_list: action.payload,
      };
    default:
      return state;
  }
}

