import users from './actions';
import { bindActionCreators } from 'redux';
const initState = {
  get_users: [],
  common_data: [],
  number_list: [],
  common_number_list: [],
  common_user_list: [],
  loading:true,
  add_user: [],
  add_user_estiamte: [],
  auto_answer: [],
  user_details: {},
  update_common_settings: [],
  upload_document: [],
  update_acw: [],
  assign_number: [],
  change_password: [],
  get_customer_portal: [],
  mobile_no:[],  
  availability_time:[],
  time_operation:[],
  get_lastlogin_csv:[],
  get_roles:[],

  make_admin_user:[],
  assign_all: []
}
export default function usersReducer(state = initState, action) {
  switch (action.type) {
    case users.GET_USERS:
      return {
        ...state,
        get_users: action.payload.data,
        loading:false
      };
    case users.COMMON_DATA:
      return {
        ...state,
        common_data: action.payload.data,
      };
    case users.DELETE_USER:
      let newUsers = [...state.get_users.slice(0, action.payload),
        ...state.get_users.slice(action.payload + 1)]
      return {
        ...state,
        get_users: newUsers,
        loading:false
      };
    case users.NUMBER_LIST:
      return {
        ...state,
        number_list: action.payload.data,
        loading:false
      };
    case users.COMMON_NUMBER_LIST:
      return {
        ...state,
        common_number_list: action.payload.data,
      };
    case users.COMMON_USER_LIST:
      return {
        ...state,
        common_user_list: action.payload,
      };
    case users.USER_DETAILS:
      return {
        ...state,
        user_details: action.payload.data,
        mobile_no: action.payload.data ? action.payload.data.mobileNo : '', 
        loading:false
      }
    case users.UPDATE_COMMON_SETTINGS:
      return {
        ...state,
        update_common_settings: action.payload,
        loading:false
      }
    case users.UPLOAD_DOCUMENT:
      return {
        ...state,
        upload_document: action.postdata,
        loading:false
      }
    case users.UPDATE_ACW:
      let newUser = {...state.user_details};
      let updatedData = action.payload.data.data;
      if(updatedData){
        Object.keys(updatedData).sort().forEach(function(key) {
          Object.keys(newUser).sort().forEach(function(key1) {
            if(key == key1){
                if(updatedData[key] != newUser[key1]){
                  newUser[key] = updatedData[key]
                }
            }
          });
        });
      }
      return {
        ...state,
        user_details: newUser,
        update_acw: action.postData,
        loading:false
      }
    case users.ASSIGN_NUMBER:
     let newUserDetail = {...state.user_details};
      let {numberIndex,userDataIndex} = action.payload;
      const numberData = JSON.parse(localStorage.getItem('numberData'));
      if(action.payload.isAdd){
        let newAddedNumber = numberData[numberIndex];
        newUserDetail.numbers = [...newUserDetail.numbers,newAddedNumber];
      }else{
        newUserDetail.numbers = [...newUserDetail.numbers.slice(0,userDataIndex),
          ...newUserDetail.numbers.slice(userDataIndex + 1)]
      }
      //return state;
      return {
        ...state,
        user_details: newUserDetail,
        loading:false
      }
    case users.UPDATE_MODULES_RIGHTS:
      let userObj = {...state.user_details};
      let newModules = action.payload.module.split(",")
      let tempArr = [];
      if(action.payload.isAdd){
        // let newModule = state.user_details.allModules.filter((module) => module._id == action.payload.module)[0]._id;
        // userObj.moduleRights = [...userObj.moduleRights,newModule];   
        tempArr = [...userObj.moduleRights,...action.payload.module.split(",")];
      }else{
        //userObj.moduleRights = state.user_details.moduleRights.filter((module) => module != action.payload.module);
        newModules.forEach((element) => {
          tempArr = state.user_details.moduleRights.filter((module) => module != element);
        })
      }
      userObj.moduleRights = tempArr;
      return {
        ...state,
        user_details:userObj,
        loading:false
      }
    case users.UPDATE_MOBILE_PHONE_NUMBERS: 
     return {
       ...state,
       mobile_no:action.payload.mobileNo,
       loading:false
     }
     case "UPDATE_DEFAULT_NUMBER":
      let numbers = [...state.number_list];
      let userDetail = {...state.user_details}
      numbers = numbers.map(function(number){
        if(action.payload.defaultNo && number._id === action.payload.defaultNo._id){
          number.defaultNumber = false;
        }
        if(number._id === action.payload.numberId){
          number.defaultNumber = true;
        }
        return number;
      });
      userDetail.numbers = userDetail.numbers.map(function(usernumber){
        if(action.payload.defaultNo && usernumber._id === action.payload.defaultNo._id){
          usernumber.defaultNumber = false;
        }
        if(usernumber._id === action.payload.numberId){
          usernumber.defaultNumber = true;
        }
        return usernumber;
      });
      return {
        ...state,
        number_list:numbers,
        user_details:userDetail,
        loading:false
      }
    case users.CHANGE_PASSWORD:
      return {
        ...state,
        change_password: action.payload,
        loading:false
      }
    case users.GET_CUSTOMER_PORTAL:
      return {
        ...state,
        get_customer_portal: action.payload,
        loading:false
      }
  case users.ADD_USER:
        return {
          ...state,
          add_user: action.payload,
          loading:false
        }
  case users.ADD_USER_ESTIMATE:
        return {
          ...state,
          add_user_estiamte: action.payload,
          loading:false
        }
    case users.AVAILABILITY_TIME:
      return {
        ...state,
        availability_time: action.payload,
        loading:false
    };
    case users.TIME_OPERATION:
      return {
        ...state,
        time_operation: action.payload,
        loading:false
    };
    case users.GET_LASTLOGIN_CSV:
      return {
        ...state,
        get_lastlogin_csv: action.payload,
        loading: false
    };
    case users.GET_ROLES:
      return {
        ...state,
        get_roles: action.payload,
        loading:false
      };
    case users.MAKE_ADMIN_USER:
      return {
        ...state,
        make_admin_user: action.payload,
        loading: false
    };
    case users.ASSIGN_ALL:
      return{
          ...state,
          assign_all: action.payload
      };
      case users.AUTO_ANSWER:
      return{
          ...state,
          auto_answer: action.payload
      }
    default:
      return state;
  }
}

