import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Icon, Button, Row, Col, Card, Switch} from 'antd';
import Modals from '@iso/components/Feedback/Modal';
import { useHistory } from "react-router-dom";
import PlanUpgradePopupWrapper from './PlanUpgradePopup.styles';
import subscriptionwrapper from '../subscriptionDetailpopup.style';
import modalActions from '@iso/redux/modal/actions';
import planSetting from '@iso/redux/plan/actions';
import message from '@iso/components/Feedback/Message';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import authantication from "@iso/redux/authentication/action";
import users from '@iso/redux/users/actions';
import { tuple } from 'antd/lib/_util/type';

import moment from "moment";

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const pciCompliant = imageUrl+'pciCompliant.png';
const hippaCompliant = imageUrl+'hippaCompliant.png';

const Modal = subscriptionwrapper(Modals);
const { ADD_NUMBER, PLAN_BILLING } = APP_PRIVATE_ROUTE;

const { closeModal,openModal } = modalActions;
const { planUpgrade, getPlan, getPopUpPlanData} = planSetting;
const { changeUserData } = authantication;
const { addUserEstimate} = users;
const successMsg = msg => { message.destroy(); message.success(msg) };
const errorMsg = msg => { message.destroy(); message.error(msg) };

function PricingPlanPopup(props) {
  const [modalPlanVisibility, setModalPlanVisibility] = useState(false);
  const [bronzeDisabled, setBronzeDisabled] = useState(false);
  const [basicDisabled, setBasicDisabled] = useState(false);
  const [silverDisabled, setSilverDisabled] = useState(false);
  const [platinumDisabled, setPlatinumDisabled] = useState(false);
  const [starterDisabled, setStarterDisabled] = useState(false);
  const [professionalDisabled, setProfessionalDisabled] = useState(false);
  const [ultimateDisabled, setUltimateDisabled] = useState(false);
  const [silverLoading, setSilverLoading] = useState(false);
  const [bronzeLoading, setBronzeLoading] = useState(false);
  const [basicLoading, setBasicLoading] = useState(false);
  const [platinumLoading, setPlatinumLoading] = useState(false);
  const [enterpriseLoading, setEnterpriseLoading] = useState(false);
  const [starterLoading, setStarterLoading] = useState(false);
  const [professionalLoading, setProfessionalLoading] = useState(false);
  const [ultimateLoading, setUltimateLoading] = useState(false);
  const [callCenterPlan, setCallCenterPlan] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(false);
  const [currentPlanPeriod, setCurrentPlanPeriod] = useState('');
  const [whichFeatureInSilver, setWhichFeatureInSilver] = useState('');
  const [whichFeatureInPlatinum, setWhichFeatureInPlatinum] = useState('');
  const [whichFeatureInBronze, setWhichFeatureInBronze] = useState('');
  const [whichFeatureInBasic, setWhichFeatureInBasic] = useState('');
  const [whichFeatureProfessional, setWhichFeatureInProfessional] = useState('');
  const [whichFeatureUltimate, setWhichFeatureInUltimate] = useState('');
  const [PlanUpgradePopUp, setPlanUpgradePopUp] = useState(false);
  const [prorateAmount,  setProrateAmount] = useState(0);
  const [planAmount, setPlanAmount] = useState(0);
  const [plan, setPlan] = useState('');
  const chargbeeData = useSelector(state => state.number.chargbee_data);

  const dispatch = useDispatch();
  let history = useHistory();
  const { modalVisibility, modalType, modalProps } = useSelector(state => state.modal);
  const userDataState = useSelector(state => state.authentication.userData.data);
  const userData = useSelector(state => state.authentication.userData.data);
  //const userData = JSON.parse(localStorage.getItem('userData'));
  if(!userData){
    window.location.href ="/login"
  }
  userData.currency = userData.currency ? userData.currency : "USD"
  userData.currencysign = userData.currencySign ? userData.currencySign : "$"
  userData.selectedCurrency = userData.selectedCurrency ? userData.selectedCurrency : "USD"
  let {
    bronze,
    silver,
    platinum,
    enterprise,
    professional,
    ultimate,
    starter,
    basic
  } =  userData && userData.planUpgradeData ?  userData.planUpgradeData :{
    sliver: "",
    bronze: "",
    platinum: "",
    enterprise: "",
    professional: "",
    ultimate: "",
    starter: "",
    basic: "",
  }

  // console.log("bronze",bronze)

  useEffect(() => {   
    if(modalVisibility){
      /* if(window.env.REACT_APP_IS_DIALERHQ){
        message.destroy();
        if(modalType == "planUpgrade"){
          return message.info(`To purchase this feature please contact ${window.env.REACT_APP_SUPPORT_MAIL} .`);
        }else{
          return message.info(`To purchase addon please contact ${window.env.REACT_APP_SUPPORT_MAIL} .`);
        }
      } */
      const userData = JSON.parse(localStorage.getItem('userData'));
      if(
        //when plan not exists
        bronze == undefined || silver == "" || platinum == "" || enterprise=="" || enterprise==undefined || ultimate=="" || ultimate==undefined || 
        
        //when user convert to any plan from basic, if user have other then US/CAD minutes plan
        (starter && userData && starter.planData.freeMinutesCountry !== userData.plan.freeMinutesCountry) || 

        //when some changes happens in plan
        (bronze && (!bronze.planData.planVersion || window.env.REACT_APP_PLAN_VERSION > bronze.planData.planVersion)) || 
        (silver && (!silver.planData.planVersion || window.env.REACT_APP_PLAN_VERSION > silver.planData.planVersion)) || 
        (platinum && (!platinum.planData.planVersion || window.env.REACT_APP_PLAN_VERSION > platinum.planData.planVersion)) || 
        (enterprise && (!enterprise.planData.planVersion || window.env.REACT_APP_PLAN_VERSION > enterprise.planData.planVersion)) ||       
        (starter && (!starter.planData.planVersion || window.env.REACT_APP_PLAN_VERSION > starter.planData.planVersion)) || 
        (professional && (!professional.planData.planVersion || window.env.REACT_APP_PLAN_VERSION > professional.planData.planVersion)) || 
        (ultimate && (!ultimate.planData.planVersion || window.env.REACT_APP_PLAN_VERSION > ultimate.planData.planVersion)) || 
        (basic && (!basic.planData.planVersion || window.env.REACT_APP_PLAN_VERSION > basic.planData.planVersion)) || 

        //when create new plan
        (ultimate && ultimate.planFeatures && ultimate.planFeatures.length == 0) && userData
      ){
        dispatch(getPopUpPlanData({})).then(({ payload }) => {
          if(payload.success){

            dispatch(changeUserData({ data: { planUpgradeData: payload.data.planUpgradeData }, "type": "changeAuth" }))

            /* userData.planUpgradeData = payload.data.planUpgradeData;
            localStorage.setItem("userData", JSON.stringify(userData));
            bronze = userData.planUpgradeData.bronze;
            silver = userData.planUpgradeData.silver;
            platinum = userData.planUpgradeData.platinum;
            enterprise= userData.planUpgradeData.enterprise;
            starter= userData.planUpgradeData.starter;
            professional= userData.planUpgradeData.professional;
            ultimate= userData.planUpgradeData.ultimate; */
            // console.log("bronze",bronze)
          }else{
            errorMsg(payload.error);
          }
        });
      }
      setCurrentPlanPeriod(userData.planPeriod)
      setCurrentPlan(userData.plan.planDisplayName)
      if(userData.plan.isOfficePhoneSystem){ 
          setUltimateDisabled(false)
          setProfessionalDisabled(false)
          setStarterDisabled(false)
        if(userData.plan.planDisplayName === 'ultimate' || (ultimate && ultimate.planData && ultimate.planData[modalProps.planFeatureFlag] === false)){
          setUltimateDisabled(true)
          setProfessionalDisabled(true)
          setStarterDisabled(true)
        }else if(userData.plan.planDisplayName === 'professional' || (professional && professional.planData && professional.planData[modalProps.planFeatureFlag] === false)){
          setStarterDisabled(true)
          setProfessionalDisabled(true)
        } else if (userData.plan.planDisplayName === 'starter' || (starter && starter.planData && starter.planData[modalProps.planFeatureFlag] === false)){
          setStarterDisabled(true)
        }
        if(ultimate && ultimate.planData && ultimate.planData[modalProps.planFeatureFlag] === true){
          setModalPlanVisibility(modalType == "planUpgrade" ? true :false)
        }else if(professional && professional.planData && professional.planData[modalProps.planFeatureFlag] === true){
          setModalPlanVisibility(modalType == "planUpgrade" ? true :false)
        } else{
          setCallCenterPlan(modalType == "planUpgrade" ? true :false)
        }
        if(professional && professional.planData && professional.planData[modalProps.planFeatureFlag] === true){
          setWhichFeatureInProfessional(modalProps.planFeatureName);
        }else if(ultimate && ultimate.planData && ultimate.planData[modalProps.planFeatureFlag] === true){
          setWhichFeatureInUltimate(modalProps.planFeatureName);
        }
      }
      else{
        if(userData.plan.planDisplayName === 'platinum' || (platinum && platinum.planData && platinum.planData[modalProps.planFeatureFlag] === false)){
          setBronzeDisabled(true);
          setBasicDisabled(true);
          setSilverDisabled(true);
          setPlatinumDisabled(true);
        }else if(userData.plan.planDisplayName === 'silver' || (silver && silver.planData && silver.planData[modalProps.planFeatureFlag] === false)){
          setBronzeDisabled(true);
          setBasicDisabled(true);
          setSilverDisabled(true);
        }else if(userData.plan.planDisplayName === 'bronze' || (bronze && bronze.planData[modalProps.planFeatureFlag] === false)){
          setBronzeDisabled(true);
          setBasicDisabled(true);
        }else if(userData.plan.planDisplayName === 'basic' || (basic && basic.planData && basic.planData[modalProps.planFeatureFlag] === false)){
          setBasicDisabled(true);
        }   
        setModalPlanVisibility(modalType == "planUpgrade" ? true :false)
        if( userData.plan.planDisplayName !== 'basic' && userData.plan.planDisplayName !== 'bronze' && userData.plan.planDisplayName !== 'silver' && userData.plan.planDisplayName !== 'platinum' && userData.plan.planDisplayName !== 'enterprise' && basic && basic.planData && basic.planData[modalProps.planFeatureFlag] === true){
          setBasicDisabled(false);
          setWhichFeatureInBasic(modalProps.planFeatureName);
        }
        if(userData.plan.planDisplayName !== 'bronze' && userData.plan.planDisplayName !== 'silver' && userData.plan.planDisplayName !== 'platinum' && userData.plan.planDisplayName !== 'enterprise' && bronze && bronze.planData[modalProps.planFeatureFlag] === true){
          setBronzeDisabled(false);
          setWhichFeatureInBronze(modalProps.planFeatureName);
        }
        
        if(userData.plan.planDisplayName !== 'silver' && userData.plan.planDisplayName !== 'platinum' && userData.plan.planDisplayName !== 'enterprise' && silver && silver.planData[modalProps.planFeatureFlag] === true){
          setSilverDisabled(false);
          setWhichFeatureInSilver(modalProps.planFeatureName);
        }
    
        if(userData.plan.planDisplayName !== 'platinum' && userData.plan.planDisplayName !== 'enterprise' && platinum && platinum.planData[modalProps.planFeatureFlag] === true){
          setPlatinumDisabled(false);
          setWhichFeatureInPlatinum(modalProps.planFeatureName);
        }
        if(userData.plan.planDisplayName === 'silver' && (modalProps.planFeatureFlag ==="HIPAA compliant" || modalProps.feature ==="Machine Detection")){
          console.log('disable platinum')
          setPlatinumDisabled(true);
        }
        if(userData.plan.planDisplayName === 'bronze' && (modalProps.planFeatureFlag ==="HIPAA compliant" || modalProps.feature ==="Machine Detection")){
          setSilverDisabled(true);
          setPlatinumDisabled(true);
        }
        if(userData.plan.planDisplayName === 'basic' && (modalProps.planFeatureFlag ==="HIPAA compliant" || modalProps.feature ==="Machine Detection")){
          setSilverDisabled(true);
          setPlatinumDisabled(true);
        }

      }
    }
  }, [modalProps, modalVisibility])
  


  const onPlanUpgradeConfirmPopup = (planName) => {
    if(chargbeeData.data && chargbeeData.data.success){
      if(chargbeeData.data.data){
          let chargebeeDt =  chargbeeData.data.data.find((dt)=>dt.id == userData.stripeId);
          if(chargebeeDt && chargebeeDt.status == "paused"){
            dispatch(closeModal());
            return dispatch(openModal({modalType: 'resumeAccount', modalProps: { 'resumeReason':"planUpgradePopup"},currentModalProps: { 'resumeReason':"planUpgradePopup"},oldModalProps:modalProps}));
          }
        }
     }
    if(
      userData.roleData &&
      userData.roleData.priority &&
      (userData.roleData.priority === 3 || userData.roleData.priority === 4)
    ){
      return errorMsg('Please ask your admin for this option.');
    }
    let planId;
    setPlan(planName)

    if (planName === 'Silver') {
      planId = silver.planData._id;
      setSilverLoading(true);
    }

    if (planName === 'Platinum') {
      planId = platinum.planData._id;
      setPlatinumLoading(true);
    }

    if (planName === 'Bronze') {
      planId = platinum.planData._id;
      setBronzeLoading(true);
    }

    if (planName === 'Basic') {
      planId = platinum.planData._id;
      setBasicLoading(true);
    }

    if (planName === 'Enterprise') {
      planId = enterprise.planData._id;
      setEnterpriseLoading(true);
    }
    if (planName === 'Starter') {
      planId = starter.planData._id;
      setStarterLoading(true);
    }
    if (planName === 'Professional') {
      planId = professional.planData._id;
      setProfessionalLoading(true);
    }
    if (planName === 'Ultimate') {
      planId = ultimate.planData._id;
      setUltimateLoading(true);
    }

    dispatch(addUserEstimate({
      planPeriod: currentPlanPeriod,
      planId: planId, type:"plan"})).then(({ payload }) => {
      if(payload.success){
        // console.log("payload",payload)
        setModalPlanVisibility(false)
        setPlanUpgradePopUp(true)
        setSilverLoading(false)
        setPlatinumLoading(false)
        setBronzeLoading(false);
        setBasicLoading(false);
        setEnterpriseLoading(false);
        setStarterLoading(false);
        setProfessionalLoading(false);
        setUltimateLoading(false);
        setProrateAmount(payload.data.prorateValue)
        setPlanAmount(payload.data.planAmount)
      }else{
        setSilverLoading(false)
        setPlatinumLoading(false)
        setBronzeLoading(false);
        setBasicLoading(false);
        setEnterpriseLoading(false);
        setStarterLoading(false);
        setProfessionalLoading(false);
        setUltimateLoading(false);
        message.destroy();
        return message.error(payload.error ? payload.error.error : "Something Went wrong");
      }
    })
  }

  const onPlanUpgrade = (planName) => {
    let planId,currentPlanPeriodData,amountCharge;
    currentPlanPeriodData = currentPlanPeriod == 'monthly' ? 4 : userData.planPeriod == 'quarterly' ? 3 : userData.planPeriod == "halfYearly" ? 2 : 1;
    setPlanUpgradePopUp(false)

    if (planName === 'Silver') {
      planId = silver.planData._id;
      amountCharge = silver.planData.userCharge;
      setSilverLoading(true);
    }

    if (planName === 'Platinum') {
      planId = platinum.planData._id;
      amountCharge = platinum.planData.userCharge;
      setPlatinumLoading(true);
    }
    if (planName === 'Bronze') {
      planId = bronze.planData._id;
      amountCharge = bronze.planData.userCharge;
      setBronzeLoading(true);
    }
    if (planName === 'Basic') {
      planId = basic.planData._id;
      amountCharge = basic.planData.userCharge;
      setBasicLoading(true);
    }
    if (planName === 'Enterprise') {
      planId = enterprise.planData._id;
      amountCharge = enterprise.planData.userCharge;
      setEnterpriseLoading(true);
    }
    if (planName === 'Starter') {
      planId = starter.planData._id;
      amountCharge = starter.planData.userCharge;
      setStarterLoading(true);
    }
    if (planName === 'Professional') {
      planId = professional.planData._id;
      amountCharge = professional.planData.userCharge;
      setProfessionalLoading(true);
    }
    if (planName === 'Ultimate') {
      planId = ultimate.planData._id;
      amountCharge = ultimate.planData.userCharge;
      setUltimateLoading(true);
    }

    let obj = {
      amount: amountCharge * 100,
      planPeriod: currentPlanPeriodData,
      userId: userData._id,
      planId: planId,
      newUser: true,
      coupon:'',
      changePlanAsPer:'new'
    }
    dispatch(planUpgrade(obj, userData._id)).then(({ payload }) => {
      setSilverLoading(false);
      setBronzeLoading(false);
      setBasicLoading(false);
      setPlatinumLoading(false);
      setEnterpriseLoading(false);
      setStarterLoading(false);
      setProfessionalLoading(false);
      setUltimateLoading(false);
      if (payload.success) {

        dispatch(getPlan({ uid: userData._id })).then(({ payload }) => {
          if(payload.success){
            userData.plan = planId;
            userData.planPeriod = currentPlanPeriod;
            localStorage.setItem("userData", JSON.stringify(userData));
            // console.log("userData",userData)

            dispatch(changeUserData({plans: payload.data.user.plan}));
            if(modalProps.planUpgradeCallBack){
              modalProps.planUpgradeCallBack(modalProps.planFeatureFlag)
            }
          }else{
            errorMsg(payload.error);
          }
        });

        successMsg(`Your plan has been successfully upgraded to ${planName} plan.`);
        onCloseModal();
      } else {
        if (payload && payload.error && payload.error.error) {
          if (payload.error.NumberAdd) {
            errorMsg(payload.error.error)
            setTimeout(function(){
              window.location.href ="/addnumber"
            }, 200);
            dispatch(closeModal());
          } else if (typeof payload.error == "string") {
            errorMsg(payload.error)
          } else if (typeof payload.error == "object") {
            errorMsg(payload.error.error)
            setTimeout(function(){
              window.location.href ={PLAN_BILLING}
            }, 200);
          } else {
            errorMsg("Something went wrong")
          }
        } else if (payload.error.message) {
          errorMsg(payload.error.message.split("Error message")[0])
        }
      }
    });
  }

  const onCloseModal = () => {
    setModalPlanVisibility(false);
    setCallCenterPlan(false)
    dispatch(closeModal());
  }
  const changePlanPeriod = (e) => {
    // console.log("eee",e, e ? 'annually' :'monthly')
    setCurrentPlanPeriod(e ? 'annually' :'monthly')
  }

  return (
      <Row>
      <Modal
          title="Call Center Solution"
          visible={callCenterPlan}
          onOk={()=>onCloseModal()}
          okText="OK"
          onCancel={()=>onCloseModal()}
          cancelButtonProps={{ style: { display: 'none' } }}
          className="exportYesNO"
          centered
        >
           <p>Please subscribe to any of the Call Center Solution plans to use this feature. To know more, please contact to <a href={`mailto:${window.env.REACT_APP_SUPPORT_MAIL}`} className="morefeaturtext" >{window.env.REACT_APP_SUPPORT_MAIL}</a>.</p>                  
        </Modal>  
      <Modal
          visible={modalPlanVisibility}
          onCancel={() => onCloseModal()}
          width= "1200px"
          footer={null}>
        <div className={`subscriptionwrapper ${silverLoading || bronzeLoading || basicLoading || platinumLoading || enterpriseLoading || starterLoading || professionalLoading || ultimateLoading ? 'modalDisabled' : ''}`}>
          <h2 className="subtitle">Upgrade Plan</h2>     
          <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="monthlyswitch">
            { userData.plan.isOfficePhoneSystem ? 
            <>
             <Col span={8}>
               <div className='padSpace'></div>
              <Card title="Starter" className={`plancard  ${(currentPlan == "starter" || currentPlan == "professional" || currentPlan == "ultimate" ||starterDisabled) ? 'planDisabled' : ''} ${currentPlan == "starter" ?'yourcurrentplan':''}`}>
               {starter && starter.planData && <p className="plantext">
                 {userData.currencySign ? userData.currencySign : "$"}
                  {currentPlanPeriod == 'monthly' ?
                      (!userData.currencySign || userData.currencySign == "$") ?  starter.planData.userCharge : starter.planData.userChargeCurrency[userData.selectedCurrency] :  (!userData.currencySign || userData.currencySign == "$") ?  starter.planData.userChargeYearly : starter.planData.userChargeYearlyCurrency[userData.selectedCurrency]}
                </p>}
                <p className="planmonth">Per user/Month</p>
                <div className="planbtncenter">
                  <Button type="primary" className="upgradeNowBtn" loading={starterLoading} onClick={() => onPlanUpgradeConfirmPopup('Starter')} >Upgrade Now</Button>
                </div>
                <div className='staterText emptyText'></div>
                <ul className="planlist">
                {starter && starter.planFeatures && starter.planFeatures.map((item, key) => item !== modalProps.planFeatureName && <li key={key}>{item}</li>)}
                </ul>
              </Card>
            </Col>
            <Col span={8}>
              <div className='padSpace'></div>
              <Card title="Professional" className={`plancard  ${(currentPlan == "professional" || professionalDisabled) ? 'planDisabled' : ''} ${currentPlan == "professional" ?'yourcurrentplan':''}`}>
               {professional && professional.planData && <p className="plantext">
                 {userData.currencySign ? userData.currencySign : "$"}
                  {currentPlanPeriod == 'monthly' ?
                      (!userData.currencySign || userData.currencySign == "$") ?  professional.planData.userCharge : professional.planData.userChargeCurrency[userData.selectedCurrency] :  (!userData.currencySign || userData.currencySign == "$") ?  professional.planData.userChargeYearly : professional.planData.userChargeYearlyCurrency[userData.selectedCurrency]}
                </p>}
                <p className="planmonth">Per user/Month</p>
                <div className="planbtncenter">
                  <Button type="primary" className="upgradeNowBtn" loading={professionalLoading} onClick={() => onPlanUpgradeConfirmPopup('Professional')} >Upgrade Now</Button>
                </div>
                <div className='staterText'>Everything in Starter +</div>
                <ul className="planlist">
                {whichFeatureProfessional && <li>{whichFeatureProfessional}</li>}
                {professional && professional.planFeatures && professional.planFeatures.map((item, key) => item !== modalProps.planFeatureName && <li key={key}>{item}</li>)}
                </ul>
              </Card>
            </Col>
            <Col span={8}>
            <div className="subswitchwrapper">
              {(currentPlanPeriod == "annually" || currentPlanPeriod == "monthly") &&
              <div>
                <span className="monthlytext">Monthly</span><Switch
                  checked={currentPlanPeriod == "annually"}
                  disabled={userData.planPeriod == "annually"}
                  className="subscriptionswitch" onChange={(e)=>changePlanPeriod(e)}></Switch><span className="annuallytext">Annually</span></div>}
            </div>
              <Card title="Ultimate" className={`plancard  ${(currentPlan == "ultimate" || ultimateDisabled) ? 'planDisabled' : ''} ${currentPlan == "ultimate" ?'yourcurrentplan':''}`}>
               {ultimate && ultimate.planData && <p className="plantext">
                 {userData.currencySign ? userData.currencySign : "$"}
                  {currentPlanPeriod == 'monthly' ?
                      (!userData.currencySign || userData.currencySign == "$") ?  ultimate.planData.userCharge : ultimate.planData.userChargeCurrency[userData.selectedCurrency] :  (!userData.currencySign || userData.currencySign == "$") ?  ultimate.planData.userChargeYearly : ultimate.planData.userChargeYearlyCurrency[userData.selectedCurrency]}
                </p>}
                <p className="planmonth">Per user/Month</p>
                <div className="planbtncenter">
                  <Button type="primary" className="upgradeNowBtn" loading={ultimateLoading} onClick={() => onPlanUpgradeConfirmPopup('Ultimate')} >Upgrade Now</Button>
                </div>
                <div className='staterText'>Everything in Professional +</div>
                <ul className="planlist">
                {whichFeatureUltimate && <li>{whichFeatureUltimate}</li>}
                {ultimate && ultimate.planFeatures && ultimate.planFeatures.map((item, key) => item !== modalProps.planFeatureName && <li key={key}>{item}</li>)}
                </ul>
              </Card>
            </Col>
            </>
            :
            <><Col span={8} offset={16} className="subswitchwrapper">
              {(currentPlanPeriod == "annually" || currentPlanPeriod == "monthly") &&
              <div>
                <span className="monthlytext">Monthly</span><Switch
                  checked={currentPlanPeriod == "annually"}
                  disabled={userData.planPeriod == "annually"}
                  className="subscriptionswitch" onChange={(e)=>changePlanPeriod(e)}></Switch><span className="annuallytext">Annually</span></div>}
            </Col>
            { currentPlan == "basic" && <Col span={currentPlan == "basic" ? 6 : 8}>
              <Card title="Basic" className={`plancard  ${(currentPlan == "basic" || currentPlan == "bronze" || currentPlan == "silver" || basicDisabled) ? 'planDisabled' : ''} ${currentPlan == "basic" ?'yourcurrentplan':''}`}>
               {basic && basic.planData && <p className="plantext">
                 {userData.currencySign ? userData.currencySign : "$"}
                  {currentPlanPeriod == 'monthly' ?
                      (!userData.currencySign || userData.currencySign == "$") ?  basic.planData.userCharge : basic.planData.userChargeCurrency[userData.selectedCurrency] :  (!userData.currencySign || userData.currencySign == "$") ?  basic.planData.userChargeYearly : basic.planData.userChargeYearlyCurrency[userData.selectedCurrency]}
                </p>}
                <p className="planmonth">Per user Month</p>
                <div className="planbtncenter">
                  <Button type="primary" className="upgradeNowBtn" loading={basicLoading} onClick={() => onPlanUpgradeConfirmPopup('Basic')} >Upgrade Now</Button>
                </div>
                <ul className="planlist">
                  {whichFeatureInBasic && <li>{whichFeatureInBasic}</li>}
                  {basic && basic.planFeatures && basic.planFeatures.map((item, key) => item !== modalProps.planFeatureName && <li key={key}>{item}</li>)}
                </ul>
              </Card>
            </Col>}
            <Col span={currentPlan == "basic" ? 6 : 8}>
              <Card title="Bronze" className={`plancard  ${(currentPlan == "bronze" || currentPlan == "silver" || bronzeDisabled) ? 'planDisabled' : ''} ${currentPlan == "bronze" ?'yourcurrentplan':''}`}>
               {bronze && bronze.planData && <p className="plantext">
                 {userData.currencySign ? userData.currencySign : "$"}
                  {currentPlanPeriod == 'monthly' ?
                      (!userData.currencySign || userData.currencySign == "$") ?  bronze.planData.userCharge : bronze.planData.userChargeCurrency[userData.selectedCurrency] :  (!userData.currencySign || userData.currencySign == "$") ?  bronze.planData.userChargeYearly : bronze.planData.userChargeYearlyCurrency[userData.selectedCurrency]}
                </p>}
                <p className="planmonth">Per user Month</p>
                <div className="planbtncenter">
                  <Button type="primary" className="upgradeNowBtn" loading={bronzeLoading} onClick={() => onPlanUpgradeConfirmPopup('Bronze')} >Upgrade Now</Button>
                </div>
                <div className="everythingbox">Everything in Basic +</div>
                <ul className="planlist">
                  {whichFeatureInBronze && <li>{whichFeatureInBronze}</li>}
                  {bronze && bronze.planFeatures && bronze.planFeatures.map((item, key) => item !== modalProps.planFeatureName && !item.toLowerCase().includes("sdap") && <li key={key}>{item}</li>)}
                </ul>
              </Card>
            </Col>
            {<Col span={currentPlan == "basic" ? 6 : 8}>
              <Card title="silver" className={`plancard  ${silverDisabled ? 'planDisabled' : ''} ${currentPlan == "silver" ?'yourcurrentplan':''}`}>
                <p className="plantext">
                  {userData.currencySign ? userData.currencySign : "$"}
                  {currentPlanPeriod == 'monthly' ?
                      (!userData.currencySign || userData.currencySign == "$")?  silver.planData.userCharge : silver.planData.userChargeCurrency[userData.selectedCurrency] :  (!userData.currencySign || userData.currencySign == "$") ?  silver.planData && silver.planData.userChargeYearly?silver.planData.userChargeYearly:0 : silver.planData.userChargeYearlyCurrency[userData.selectedCurrency]}
                </p>
                <p className="planmonth">Per user Month</p>
                <div className="planbtncenter">
                  <Button type="primary" className="upgradeNowBtn" loading={silverLoading} onClick={() => onPlanUpgradeConfirmPopup('Silver')} >Upgrade Now</Button>
                </div>
                <div className="everythingbox">Everything in Bronze +</div>
                <ul className="planlist">
                  {whichFeatureInSilver && <li>{whichFeatureInSilver}</li>}
                  {silver && silver.planFeatures && silver.planFeatures.map((item, key) => item !== modalProps.planFeatureName && <li key={key}>{item}</li>)}
                </ul>
              </Card>
            </Col>}
            <Col span={currentPlan == "basic" ? 6 : 8}>
              <Card title="Platinum" className={`plancard  ${platinumDisabled ? 'planDisabled' : ''} ${currentPlan == "platinum" ?'yourcurrentplan':'planribbon'} ${currentPlan == "platinum" && currentPlan == "basic" ? "planribbon_four_card" : ''}`}>
                <p className="plantext">
                  {userData.currencySign ? userData.currencySign : "$"}

                  {currentPlanPeriod == 'monthly' ?
               (!userData.currencySign || userData.currencySign == "$") ?  platinum.planData.userCharge : platinum.planData.userChargeCurrency[userData.selectedCurrency] :  (!userData.currencySign || userData.currencySign == "$") ? platinum.planData && platinum.planData.userChargeYearly? platinum.planData.userChargeYearly:0 : platinum.planData.userChargeYearlyCurrency[userData.selectedCurrency]}

                </p>
                <p className="planmonth">Per user Month</p>
                <div className="planbtncenter">
                  <Button type="primary" className="upgradeNowBtn" loading={platinumLoading} onClick={() => onPlanUpgradeConfirmPopup('Platinum')} >Upgrade Now</Button>

                </div>
                <div className="everythingbox">Everything in Silver +</div>
                <ul className="planlist">
                  {whichFeatureInPlatinum && <li>{whichFeatureInPlatinum}</li>}
                  {platinum && platinum.planFeatures && platinum.planFeatures.map((item, key) => item !== modalProps.planFeatureName && <li key={key}>{item}</li>)}
                </ul>
              </Card>
            </Col>
            {/* currentPlan != 'basic' && <Col span={6}>
              <Card title="Enterprise" className={`plancard ${currentPlan == "enterprise" ?'planDisabled yourcurrentplan':''}`}>
                <p className="plantext">
                  {userData.currencySign ? userData.currencySign : "$"}

                  {currentPlanPeriod == 'monthly' ?
               (!userData.currencySign || userData.currencySign == "$") ?  enterprise && enterprise.planData && enterprise.planData.userCharge?enterprise.planData.userCharge:0 : enterprise && enterprise.planData &&enterprise.planData.userChargeCurrency && enterprise.planData.userChargeCurrency[userData.selectedCurrency]?enterprise.planData.userChargeCurrency[userData.selectedCurrency]:0 :  (!userData.currencySign || userData.currencySign == "$") ? enterprise && enterprise.planData && enterprise.planData.userChargeYearly? enterprise.planData.userChargeYearly:0 : enterprise && enterprise.planData && enterprise.planData.userChargeYearlyCurrency && enterprise.planData.userChargeYearlyCurrency[userData.selectedCurrency]? enterprise.planData.userChargeYearlyCurrency[userData.selectedCurrency]:0}

                </p>
                <p className="planmonth">Per user Month</p>
                <div className="planbtncenter">
                  <Button type="primary" className="upgradeNowBtn" loading={enterpriseLoading} onClick={() => onPlanUpgradeConfirmPopup('Enterprise')} >Upgrade Now</Button>

                </div>
                <div className="everythingbox">Everything in Platinum +</div>
                <ul className="planlist">
                  {whichFeatureInPlatinum && <li>{whichFeatureInPlatinum}</li>}
                  {enterprise && enterprise.planFeatures && enterprise.planFeatures.map((item, key) => <li key={key}>{item}</li>)}
                </ul>
              </Card>
            </Col> */}</>}

            {/* <Col span={24} className="hippaPciCol">
                <div className="flex-row chflex chjstcenter mgtop25" style={{marginTop:14}}>
                    <label style={{fontWeight:600,fontSize:14,marginRight:7,alignSelf:'center'}}>Our Compliances: </label>
                    <img className="hippaImg" src={hippaCompliant} />
                    <img className="pciImg" src={pciCompliant} />
                </div>
            </Col> */}
          </Row>
        </div>
        </Modal>
  <Modal
      visible={PlanUpgradePopUp}
      title="Upgrade Plan"
      okText="Upgrade"  
      cancelText="Cancel"
      onOk={() => onPlanUpgrade(plan)}
      onCancel={()=>setPlanUpgradePopUp(false)}
      className="exportYesNO"
      centered
  >
    <p>You will be charged  {userData.currencySign}{prorateAmount} as per prorated charges. Please confirm to process the payment.
    </p>
  </Modal>
</Row>
  )
}

export default PricingPlanPopup;