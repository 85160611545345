import React, { memo, useEffect } from 'react';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { BrowserRouter } from 'react-router-dom';
import PusherConnection from './pusher';
import moment from "moment";
import 'react-multi-carousel/lib/styles.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
loadProgressBar({ showSpinner: false });
const App = (props) => {
  useEffect(() => {    
    // if(
    //   props.auth.isAuthenticated &&
    //   props.auth.userData.data &&
    //   props.auth.userData.data.createdDate &&
    //   moment().diff(moment(props.auth.userData.data.createdDate), 'days') > 7
    // ){
    //   const script = document.createElement("script");
    //   // fscript.src = "//fast.appcues.com/106946.js";
    //   document.body.appendChild(script);
    // }
  },[props.auth.isAuthenticated]);
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}>
      <AppProvider>
        <>
          <GlobalStyles />
          {props.auth.isAuthenticated == true && props.auth.userData.data && Object.keys(props.auth.userData.data).length >= 1}
          {/* {props.auth.isAuthenticated == true && <PusherConnection userData ={props.auth.userData.data} />} */}
          <Routes />
        </>
      </AppProvider>
    </GoogleOAuthProvider>
)};

Boot()
  .then(() => App())
  .catch(error => console.error(error));

const mapStateToProps = (state) => {
  return {
    auth: state.authentication
  }
}

export default connect(mapStateToProps, {})(memo(App));
