import React, { Component } from 'react';
import { Row, Col, Form, Button, Radio, message, Tooltip, Select, Tabs,Popover } from 'antd';
import numberAction from '../../redux/number/actions';
import planSetting from '@iso/redux/plan/actions';
import dashboardActions from '@iso/redux/dashboard/actions';
import authantication from "@iso/redux/authentication/action";
import { connect } from 'react-redux';
import lodash from 'lodash';
import jstz from 'jstz';
import Modals from '@iso/components/Feedback/Modal';
import AddNumPopupStyles from './AddNumPopup.styles';
import WithDirection from '@iso/lib/helpers/rtl';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { setMessage } from "../../redux/customMessage/action";
import Currency from "../../constants";

import countries from "../PlanSetting/countries";

const isoModal = AddNumPopupStyles(Modals);
const Modal = WithDirection(isoModal);
const { changeUserSetting } = dashboardActions;
const { changeUserData } = authantication;
const { NUMBER,INVITE_USER,DASHBOARD, USER_PROFILE_DOCUMENT, PLAN_BILLING, ADD_NUMBER } = APP_PRIVATE_ROUTE;
const { Option } = Select;
const { TabPane } = Tabs;

const {
  chargebeeNewUser, getUserProfile,user, getNumbers
} = numberAction;

const {getPlan} = planSetting;

class Addnopopup extends Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef()
    let userData = JSON.parse(localStorage.getItem('userData'))
    this.state = {
      activeKey:"1",
      visible: false,
      showtext: false,
      planselectionshow: false,
      currencyList: [],
      currencyOFC: 'Global',
      cplanName:"professional",
      numberpurchaseshow: false,
      monthlyPrice: '',
      annuallyPrice: '',
      classname: "number-container num-annuactive",
      classnamewithactive: "number-container ",
      classnameforradio: "planprice bgwhite addnumboxborder",
      classnameforradiowithactive: "planprice bgwhite addnumboxborder num-annu-priceactive rdiochrct",
      checkedactive: true,
      checked: false,
      radiovalue: '',
      radiovalueactive: 'a',
      checkrighttickfirst: true,
      checkrightticksecond: false,
      monthlyradiocheckvalue: 'monthly',
      annuallyradiocheckvalue: '',
      planSelectionValue: 'a',
      showplanmsg: false,
      user: [],
      plans: [],
      showMonthlyPlan: false,
      planPriceType: userData.currency ? 'userChargeCurrency': 'userCharge',
      freeNumberPerUser: '1',
      basicfreeNumberPerUser: '0',
      ydata: '',
      mdata: '',
      planId: '',
      checkshow: false,
      countryName: '',
      showcard: false,
      premium: false,
      numberPeriod: 2,
      payPeriod: 2,
      planmonth: 12,
      planPeriod: 2,
      cbInstance: window.Chargebee,
      userData: JSON.parse(localStorage.getItem('userData')),
      userProfile: {},
      disableMonthly:false,
      userMaxSeat: 1,
      freeTrailButtonClick:false,
      regularPlans:[],
      inviteUserStatus : false,
      currency : userData.selectedCurrency ? userData.selectedCurrency : "USD",
      currencySign: userData.selectedCurrency ? Currency[userData.selectedCurrency].sign : "$",
      conversionRate:JSON.parse(window.env.REACT_APP_CONVERSION_RATE),
      ofcDisable:false,
      basicPlanId:'',
      ofcCountries:{'Global':'Global','US':'US/CAD','CA':'US/CAD','GB':'GB','AU':'AUS','IN':'INR'},
      notVisible: false,
      inventoryNumber: false
    };
  }

  componentDidMount() {
    this.props.user({ userId: this.props.userData.data._id, planId: this.props.userData.data.plan._id, authToken: this.props.userData.data.authToken, billingtoken: this.props.userData.data.billingToken });

    if(this.props.userData.data.conversionRate){
      this.setState({conversionRate:this.props.userData.data.conversionRate});
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.visible !== this.props.visible) {
      if(this.props.visible && !this.props.userData.data.stripeId){
        let userDataLocalStorage = JSON.parse(localStorage.getItem('userData'));
        if(userDataLocalStorage.chargebeeWebhookPending == true){
          message.destroy()
          message.info('We are currently updating your plan. Please wait for a moment.',5)
          this.props.history.push(PLAN_BILLING);
          return this.handleCancel()
        }
      }
      this.setState({
        visible: this.props.visible,
        disableMonthly:false,
        notVisible:false
      });
      // if(this.props.visible){
      //   if(this.props.userData.data.isFreeTrialUser){
      //     setTimeout(() => {
      //       message.info('Select a plan of your choice as per the required features and use them for 10 days for free!',7)            
      //     }, 1000);
      //   }
      //   this.setState({loadingUserData:true});
      // }
      if(this.props.newPlan){
        this.setState({
          showtext : true
        });
      }
      if(this.props.visible && this.props.userData && this.props.userData.data){
        this.props.user({ userId: this.props.userData.data._id, planId: this.props.userData.data.plan._id, authToken: this.props.userData.data.authToken, billingtoken: this.props.userData.data.billingToken });
      }
    }
    
    if (prevState.number !== this.props.number) {
      await this.setState({
        number: this.props.number,
        inventoryNumber: this.props.inventoryNumber,
        disableMonthly:false
      });
      if (this.state.number && this.state.number.premium) {
        this.setState({
          premium: this.props.premium,
          numberpurchaseshow: false,
          showtext: true,
          planselectionshow: true
        });
      } else {
        this.setState({
          premium: this.props.premium,
          numberpurchaseshow: false,
          showtext: true,
          planselectionshow: true
        });
      }
    }

    if (prevState.user_details !== this.props.user_details) {
      this.setState({
        user_details: this.props.user_details,
      });
    }

    if (prevState.addressVerifiedNoteHide !== this.props.addressVerifiedNoteHide) {
      this.setState({
        addressVerifiedNoteHide: this.props.addressVerifiedNoteHide,
      });
    }
    if (prevState.premium !== this.props.premium && this.props.visible) {
      if (this.props.premium) {
        this.setState({
          premium: this.props.premium,
          numberpurchaseshow: false,
          showtext: true,
          planselectionshow: true
        });
      } else {
        this.setState({
          premium: this.props.premium,
          numberpurchaseshow: false,
          showtext: true,
          planselectionshow: true
        });
      }
      if(this.props.newPlan){
        this.setState({
          showtext: true
         })
        }
    }
    if (prevProps.get_avail_number !== this.props.get_avail_number && this.props.get_avail_number.data.success) {
      this.setState({
        monthlyPrice: this.props.get_avail_number.data.data.numbers[0].monthlyPrice,
        annuallyPrice: this.props.get_avail_number.data.data.numbers[0].annuallyPrice,
        countryName: this.props.get_avail_number.data.data.country,
        phoneNumber: this.props.get_avail_number.data.data.phoneNumber,
        provider: this.props.get_avail_number.data.data.provider,
        callEnabled: this.props.get_avail_number.data.data.callEnabled,
        smsEnabled: this.props.get_avail_number.data.data.smsEnabled,
        type: this.props.get_avail_number.data.data.type,
        inventoryNumber:this.props.inventoryNumber,
        providerNumberCost: this.props.get_avail_number.monthly_rental_rate,
        providerNumberSetupCost: this.props.get_avail_number.setup_rate,
        setupCost: this.props.get_avail_number.data.data.setupCost
      })
    }
    if (prevProps.chargebee_new_user != this.props.chargebee_new_user) {
      if (this.props.chargebee_new_user.data.success) {
        window.mixpanel.track(1+" people bought number", { distinct_id: this.props.userData.data.email,parent_id:this.props.userData.data && this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email });
        await this.setState({
          chargebee_new_user: this.props.chargebee_new_user.data
        })
        let stripeId = this.getQueryString('stripeId', this.props.chargebee_new_user.data.url);
        
        let setUserData = JSON.parse(localStorage.getItem('userData'));
        if(stripeId){
          this.props.changeUserData({data: { stripeId,currency:setUserData.selectedCurrency? setUserData.selectedCurrency: this.state.currency }, "type":"stripeId"})
        }
        this.props.getUserProfile();
      }else{
        if (!this.props.chargebee_new_user.data.success){
            this.props.history.push(DASHBOARD);
            message.error(`We could not reserve the number you have selected , please contact ${window.env.REACT_APP_SUPPORT_MAIL} to get this number.`) 
        }
      }
    }
    if (prevProps.user_data !== this.props.user_data) {
      if(this.props.user_data && this.props.user_data.data && this.props.user_data.data.data && this.props.user_data.data.data.user && this.props.user_data.data.data.user.isDocVerificationRequired != "approved" && this.props.visible){
        this.setState({loadingUserData:false});
        this.setState({notVisible:true});
        this.props.changeStateFromChild({ visible: false});
        this.props.history.push({  pathname: USER_PROFILE_DOCUMENT, state: {status:428}}); 
      }else{
        this.setState({loadingUserData:false});
        let selectedPlan = this.props.user_data && this.props.user_data.data && this.props.user_data.data.data && this.props.user_data.data.data.plans? this.props.user_data.data.data.plans.find(o => o.planDisplayName === 'silver'):[]
        let basicPlan = this.props.user_data && this.props.user_data.data && this.props.user_data.data.data && this.props.user_data.data.data.plans? this.props.user_data.data.data.plans.find(o => o.planDisplayName === 'basic'):[]
        this.setState({
          user: this.props.user_data.data.data.user,
          plans: this.props.user_data.data.data.plans,
          currencyList: this.props.user_data.data.data.plans.length > 0 ? this.props.user_data.data.data.plans[0].planCurrency: [],
          planId: selectedPlan ? selectedPlan.id : '',
          basicPlanId: basicPlan ? basicPlan.id : '',
          basicfreeNumberPerUser : basicPlan ? basicPlan.freeNumberPerUser : '0',
          regularPlans:this.props.user_data.data.data.regularPlan,
          notVisible:false
        });
        this.props.changeUserData({plans: this.props.user_data.data.data.user.plan,type:"stripeId",data:{stripeId:this.props.user_data.data.data.user.stripeId}, expiredFreeTrailDate: this.props.user_data && this.props.user_data.data && this.props.user_data.data.data && this.props.user_data.data.data.user && this.props.user_data.data.data.user.expiredFreeTrailDate? this.props.user_data.data.data.user.expiredFreeTrailDate: "" })
      }
    }

    if (prevProps.get_users !== this.props.get_users && this.state.chargebee_new_user) {
      if (this.props.get_users.success) {
        this.props.getPlan({ uid: this.state.user._id }).then(({ payload }) => {
          payload.success ? this.setPlanToState(payload.data) :  message.error(payload.error)
        });

        await this.setState({
          userProfile: this.props.get_users.data
        });
        this.props.getNumbers().then((result) => {
          if(result && result.payload && result.payload.success){
            this.props.changeUserData({ data: {numberCount:result.payload.data.length,deletedNumberCount:result.payload.deletedNumberCount,numbersList:result.payload.data,allNumberCount:result.payload.allNumberCount}, "type":"addNumber" });
          }
        });
        let period = this.state.numberPeriod == 2 && this.state.payPeriod == 2 ? 'monthly' : 'annually';
          if (this.getQueryString('numberNotAdded', this.state.chargebee_new_user.url) && this.state.userProfile.userAllNumberCount == 0) {
            let numberNotAvailable = this.getQueryString('numberNotAvailable', this.state.chargebee_new_user.url)
            this.props.changeStateFromChild({ loading: false, fshow: false, show: false, eshow: false});
            this.props.history.push(NUMBER);
            message.error(numberNotAvailable ? `Oops! Just now someone has booked this number before you did. Please select a different number. You will not be charged again if already.` : `We could not reserve the number you have selected , please contact ${window.env.REACT_APP_SUPPORT_MAIL} to get this number.`)
            this.setState({chargebee_new_user: {}});
          } else {
              this.props.changeStateFromChild({ loading: true, fshow: false, show: false, eshow: false });
          }
          //this.props.changeStateFromChild({ loading: true, showAssignNumber: true, fshow: false, show: false, eshow: false });
          if (this.state.userProfile.userNumberCount == 1) {
            if(this.state.userProfile && this.state.userProfile.expiredFreeTrailDate){
              let setUserData = JSON.parse(localStorage.getItem('userData'));   
              setUserData.expiredFreeTrailDate = this.state.userProfile.expiredFreeTrailDate;
              localStorage.setItem("userData", JSON.stringify(setUserData));
            }
              this.props.changeStateFromChild({ loading: false, showAddCreditsModal: true });
          }
          if (this.state.userProfile.userAllNumberCount == 0 && this.state.userProfile.plan.planDisplayName == "free" && this.state.userProfile.paymentType == 'prepaid') {
            this.props.changeStateFromChild({ loading: false, showAddCreditsModal: true });
          }
          if (this.getQueryString('firstNumberPurchase', this.state.chargebee_new_user.url) != "true") {
            // console.log('saveNumber CODE')
          }else {
            this.props.changeStateFromChild({ loading: false ,fshow: false, show: false, eshow: false});
            if (this.state.userProfile.userAllNumberCount == 0 && this.state.userProfile.plan.planDisplayName == "free" && !this.getQueryString('numberNotAdded', this.state.chargebee_new_user.url)) {
              this.props.changeStateFromChild({ loading: false, showAddCreditsModal: true, firstNumberId:this.getQueryString('numberId', this.state.chargebee_new_user.url)});
            } else {
              this.props.changeStateFromChild({firstNumberId:this.getQueryString('numberId', this.state.chargebee_new_user.url)});
            }
          }
          if(this.props.newPlan && this.state.inviteUserStatus && !this.state.newPlan){
            message.success('Plan upgrade successfully.')
            this.setState({newPlan:true});
            this.props.changeStateFromChild({upgradePlan:true});
            this.props.history.push({pathname: DASHBOARD , state:{newPlan:true}});
          }
          else if(!this.state.number && !this.state.number.phoneNumber && this.state.inviteUserStatus && !this.state.newPlan){
              message.success('Plan upgrade successfully.')
              this.setState({newPlan:true});
              this.props.changeStateFromChild({upgradePlan:true});
              this.props.history.push(INVITE_USER);
          }

      } else {
        message.destroy();
        message.error("182 SOMETHING WENT WRONG")
      }
    }
  }

  setPlanToState = planInfo => {
    let totalCredits = Math.abs(parseFloat(planInfo.availableCredits.availableCredits)).toFixed(2) <= 0.00 ? Math.abs(planInfo.availableCredits.availableCredits).toFixed(2) : parseFloat(planInfo.availableCredits.availableCredits - planInfo.availableCredits.usedCredits).toFixed(2);
    if (planInfo.user.plan.planDisplayName != "free" && !planInfo.user.stripeId) {
      planInfo.plans.splice(0, 1);
    }
    //this.props.changeUserData({totalCredits: Number(totalCredits)});
    this.props.changeUserData({totalCredits: Number(totalCredits),plans : planInfo.user.plan})
    this.props.changeUserSetting({ data: { totalCredits: totalCredits }, "type":"credit" });
  }
    getQueryString = (field, url) => {
    var href = url ? url : window.location.href;
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? string[1] : null;
  };

  onMouseOver = e => {
    // console.log("onMouseOver")

  }
  onMouseDown = e => {
    // console.log("onMouseDown")

  }
  handleOk = e => {
    this.setState({
      numberpurchaseshow: false,
      planselectionshow: true,
      showtext: true,
      checkrighttickfirst: true
    });
  };


  handleCancel = e => {
    this.props.close();
    this.props.changeStateFromChild({
      visible: false,
      addressVerifiedNoteHide: false,
      number:''
    })
    this.setState({
      numberpurchaseshow: false,
      showtext: false,
      //planselectionshow: false,
      monthlyradiocheckvalue: '',
      annuallyradiocheckvalue: 'annually',
      planPriceType: this.props.userData.data.currency ? 'userChargeYearlyCurrency': 'userChargeYearly',
      planSelectionValue: '',
    })
  };
  handleCheckout = (e,basicPlan) => {
    if(this.state.userMaxSeat == ""){
      return this.props.setMessage({ message: "You have to choose at least one seat.", type:"error" });
    }
    this.props.changeStateFromChild({ visible: false, addressVerifiedNoteHide: false })
    // this.props.buttonClick({addPopUpVisible:false})
    this.setState({
      visible: false,
      activeKey:"1",
      addPopUpVisible:false,
      showtext: false,
      planselectionshow: false,
      checkrighttickfirst: false,
      checkedactive: true,
      checked: false,
      radiovalue: '',
      radiovalueactive: 'a',
      checkrightticksecond: false,
      monthlyradiocheckvalue: 'monthly',
      annuallyradiocheckvalue: '',
      planSelectionValue: '',
      planPriceType: this.props.userData.data.currency ? 'userChargeCurrency': 'userCharge',
      //planId: this.state.plans[2]._id,
      basicPlanId: this.state.plans[0]._id,
      numberpurchaseshow: false,
      freeTrailButtonClick:e
    })
    this.props.close();
    this.checkout(basicPlan);
    this.trackMixPanelEvent()


  }

  trackMixPanelEvent = ()=>{
    
    window.mixpanel.track("Click on the Buy Now", { distinct_id: this.props.userData.data.email,parent_id:this.props.userData.data && this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email });

    if(this.props.get_number && !this.props.get_number.length){
      window.mixpanel.track("Click on buy now in plan popup",{distinct_id : this.props.userData.data.email,parent_id:this.props.userData.data && this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email});
    } 
  }

  checkout = async (e) => {
    const tempChargebeeNewUser = this.props.chargebeeNewUser;
    const self=this;
    const tempChangeStateFromChild = this.props.changeStateFromChild;
    const { freeNumberPerUser,basicfreeNumberPerUser, plans, payPeriod, numberPeriod, planmonth,regularPlans, freeTrailButtonClick,basicPlanId } = this.state;
    let {planId} = this.state;
    const { changeUserData } = this.props;
    let planAmount = 0, query1, obj, addons = [], productName, plan;
    planId = e ? basicPlanId : planId;
    if (planId) {
      plan = lodash.filter(plans, { _id: planId })[0];
      planAmount = payPeriod == 1 ? (plan.userChargeYearly * 12) : plan.userCharge;
    }
    if(this.state.user_details.isFreeTrialUser && !this.state.freeTrailButtonClick){
      plan = lodash.filter(regularPlans, { planDisplayName: plan.planDisplayName})[0];
      planAmount = payPeriod == 1 ? (plan.userChargeYearly * 12) : plan.userCharge;
    }
    if(plan.isOfficePhoneSystem && this.state.user_details.isFreeTrialUser){
      plan = lodash.filter(regularPlans, { planDisplayName: plan.planDisplayName,freeMinutesCountry : this.state.currencyOFC})[0];
      planAmount = payPeriod == 1 ? (plan.userChargeYearly * 12) : plan.userCharge;
    }
    console.log("plan==========",plan,planId)
    let period, price ;
    if(payPeriod == 1){
      planAmount = 12 * plan.userChargeYearly;
      period = 'annually'
      // price = this.state.number.annuallyPrice
    }else if(payPeriod == 2){
      planAmount = 1 * plan.userCharge;
      period = 'monthly'
      // price = this.state.number.monthlyPrice
    }else if(payPeriod == 3){
      planAmount = 3 * plan.userChargeQuarterly;
      period = 'quarterly'
      // price = this.state.number.quarterlyPrice
    }else if(payPeriod == 4){
      planAmount = 6 * plan.userChargeHalfYearly;
      period = 'halfYearly'
      // price = this.state.number.halfYealryPrice
    }
    
    price = this.state.number.monthlyPrice
    planAmount = planAmount ? planAmount : 0;
    if(plan.flatPrice){
      planAmount = planAmount * 1;
    }else{
      planAmount = planAmount * this.state.userMaxSeat;
    }
//    let period = numberPeriod == 2 && payPeriod == 2 ? 'monthly' : 'annually';
    await this.calculateAmountNewFilter(planAmount, freeNumberPerUser, planmonth);
    if(this.state.number && this.state.number.phoneNumber){
      query1 = 'contactName=' + this.props.contactName + '&countryName=' + this.props.get_avail_number.data.data.country + '&timezone=' + this.state.timezone + '&price=' + price + '&period=' + period + '&phoneNumber=' + this.state.number.phoneNumber + '&callEnabled=' + this.state.number.callEnabled + '&smsEnabled=' + this.state.number.smsEnabled + '&type=' + this.state.number.type + '&providerNumberCost=' + this.state.number.monthly_rental_rate + '&providerNumberSetupCost=' + this.state.number.setup_rate + '&setupCost=' + this.state.number.setupCost + '&couponCode=' + this.state.number.couponCode + '&addressRequired=' + this.state.number.addressRequired;
    }else{
      this.setState({
        inviteUserStatus:true
      });
      query1 = '&price=' + price + '&period=' + period +'&inviteUserStatus=' +true;
    }
    
    //console.log("this.state.planPeriod == 1 ? this.state.number.annuallyPrice", this.state.number)
    //return
    if(this.state.inviteUserStatus || this.props.newPlan){
      obj = {
        userId: this.state.user._id,
        redirectSuccessUrl: window.location.origin + '/addNumber?' + query1,
        redirectFailedUrl: window.location.origin + '/plan/upgradeFail',
        cancelUrl: window.location.origin + '/addNumber',
        description: 'Upgrade Plan And Purchase number',
        paymentGateway: 'chargebee',
        billingToken: this.state.userData.billingToken,
        period: period,
        timezone: this.state.timezone,
        payPeriod1: period,
        payPeriod: planId ? this.state.payPeriod : this.state.numberPeriod,
        planId: plan ? plan._id : this.state.user.plan._id,
        planAmount: Number(planAmount),
        amount: Number(planAmount),
        seatBaseValue: Number(this.state.userMaxSeat),
        inventoryNumber: this.props.inventoryNumber,
        provider: this.state.number.provider,
      };
    }else{
      obj = {
        countryName: this.props.get_avail_number.data.data.country,
        type: this.state.number.type,
        numberPeriod: this.state.numberPeriod,
        userId: this.state.user._id,
        redirectSuccessUrl: window.location.origin + '/addNumber?' + query1,
        redirectFailedUrl: window.location.origin + '/plan/upgradeFail',
        cancelUrl: window.location.origin + '/addNumber',
        description: 'Upgrade Plan And Purchase number',
        paymentGateway: 'chargebee',
        billingToken: this.state.userData.billingToken,
        period: period,
        contactName: this.props.contactName,
        phoneNumber: this.state.number.phoneNumber,
        timezone: this.state.timezone,
        price: Number(price),
        setupCost: this.state.number.setupCost,
        smsEnabled: this.state.number.smsEnabled,
        providerNumberCost: this.state.number.monthly_rental_rate,
        providerNumberSetupCost: this.state.number.setup_rate,
        premium: this.state.number.premium,
        restriction: this.state.number.restriction,
        sub_type: this.state.number.sub_type,
        region: this.state.number.region,
        payPeriod1: period,
        payPeriod: plan ? this.state.payPeriod : this.state.numberPeriod,
        planId: plan ? plan._id : this.state.user.plan._id,
        planAmount: Number(planAmount),
        amount: Number(planAmount),
        provider: this.state.number.provider,
        inventoryNumber: this.props.inventoryNumber,
        seatBaseValue: Number(this.state.userMaxSeat),
        addressRequirements: this.state.number.addressRequirements,
        addressSid : this.props.systemVerifiedDocs && this.props.systemVerifiedDocs.addressSid ? this.props.systemVerifiedDocs.addressSid : '',
        bundleSid : this.props.systemVerifiedDocs && this.props.systemVerifiedDocs.bundleSid ? this.props.systemVerifiedDocs.bundleSid : '',
        ownDocument : this.props.ownDocument ? this.props.ownDocument : false
      };
    }
    obj.currency = this.state.currency;
    console.log("obj.payPeriod>>>>>",obj)
    if(obj.payPeriod == 1){
      productName = this.state.freeTrailButtonClick? plan.stripeIdAnnually_ft: plan.stripeIdAnnually;
    }else if(obj.payPeriod == 2){
      productName = this.state.freeTrailButtonClick? plan.stripeIdMonthly_ft: plan.stripeIdMonthly;
    }else if(obj.payPeriod == 3){
      productName = this.state.freeTrailButtonClick? plan.stripeIdQuarterly_ft: plan.stripeIdQuarterly;
    }else if(obj.payPeriod == 4){
      productName = this.state.freeTrailButtonClick? plan.stripeIdHalfYearly_ft: plan.stripeIdHalfYearly;
    }
    let currencyText = '_'+this.state.currency.toLowerCase()
    console.log("obj.premium>>>",obj.premium)
    if(this.state.currency != "USD"){
      productName = productName + currencyText
    }
    if (obj.premium || (productName.includes("basic") && basicfreeNumberPerUser == 0) || !plan.freeNumberPerUser) {
      addons.push({ id: this.state.currency != "USD" ? "number_" + obj.price+currencyText : "number_" + obj.price , quantity: 1 });
    }
    if (this.state.number.setupCost) {
      let setupCost = this.state.number.setupCost
      addons.push({ id: this.state.currency != "USD" ? "setupcost_" + setupCost + currencyText : "setupcost_" + setupCost, quantity: 1 });
    }
    console.log("productName>>>>>",productName)
    await this.state.cbInstance.init({
      site: window.env.REACT_APP_CHARGEBEE_SITE
    })


    var cbInstance = await this.state.cbInstance.getInstance();
    var customer = { email: this.state.user.email.replace(/[+]/g, ''),"cf_billing_india":"General","cf_mail_send": window.env.REACTAPP_IS_CALLHIPPO ? "YES" : "NO",phone :this.state.user.phoneNumber ? this.state.user.phoneNumber : this.state.user.mobileNo && this.state.user.mobileNo.length ? this.state.user.mobileNo[0] : '' };
    this.setState({
      numberPeriod: 2,
      payPeriod: 2,
      planPeriod: 2
    })
    cbInstance.setCheckoutCallbacks(function (cart) {
      return {
        close: function () {
          cbInstance.logout();
          self.setState({inviteUserStatus:false})
          tempChangeStateFromChild({ number:'' })
        },
        error: function (error) {
           console.log('error in setcheckout', error)
        },
        success: async function (hostedPageId) {
          let userData = await JSON.parse(localStorage.getItem('userData'));
          if(!freeTrailButtonClick && userData.isFreeTrialUser){
            changeUserData({data: { isFreeTrialUser: false }, "type":"freeTrialUser"})
          }
          userData.planPeriod = obj.period;
          delete userData.deleteRequest;
          localStorage.setItem("userData", JSON.stringify(userData));
          cbInstance.logout();
          cbInstance.closeAll();
          obj.hostedPageId = hostedPageId;
          tempChangeStateFromChild({ loading: true })
          console.log("obj",obj)
          const chargebeeNewUserRes = await tempChargebeeNewUser(obj);
          changeUserData({planPeriod:obj.period});
          if(self.props.get_number && !self.props.get_number.length){
          window.mixpanel.track("1st number purchased successfully.",{distinct_id : self.props.userData.data.email,parent_id:this.props.userData.data && this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email});
          }
        },
      };
    });
    var cart = cbInstance.getCart();
    // let planQuantity = plan.planDisplayName == "bronze" ? 2 : 1
    var product = cbInstance.initializeProduct(productName, plan.flatPrice ? 1 : this.state.userMaxSeat);
    for (var i = 0; i < addons.length; i++) {
      product.addAddon(addons[i]);
    }
    cart.replaceProduct(product);
    cart.setCustomer(customer);
    cart.proceedToCheckout();

    // window.mixpanel.track("Click on proceed to checkout in chargebee popup",{distinct_id : this.props.userData.data.email});
    window.mixpanel.identify(this.props.userData.data.email);

    let objData = {
      "$name" : this.props.userData.data.fullName,
      "$email":this.props.userData.data.email,
      "selected plan name":plan.planDisplayName,
      "plan period":obj.payPeriod1,
      "currency":obj.currency,
      "parent_id":this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email
   
    }
    window.mixpanel.people.set(objData);  

  }

  calculateAmountNewFilter = async (planAmount, freeNumberPerUser, planmonth) => {
    var tz = jstz.determine();
    await this.setState({
      timezone: tz.name(),
      number: {
        ...this.state.number,
        price: planAmount
      }
    });
  }

  // when selecting annually on number period selection
  onClickonAnnually = () => {
    this.setState({
      classnamewithactive: "number-container ",
      classname: "number-container num-annuactive",
      classnameforradio: "planprice bgwhite addnumboxborder",
      classnameforradiowithactive: "planprice bgwhite addnumboxborder num-annu-priceactive rdiochrct",
      checkedactive: true,
      checked: false,
      radiovalue: '',
      radiovalueactive: 'a',
      numberPeriod: 1,
      planmonth: 12,
      disableMonthly:true
    })
  }

  // when selecting monthly on number period selection
  onClickonMonthly = () => {
    this.setState({
      classnamewithactive: "number-container num-annuactive",
      classname: "number-container",
      classnameforradio: "planprice bgwhite addnumboxborder num-annu-priceactive rdiochrct",
      classnameforradiowithactive: "planprice bgwhite addnumboxborder",
      checkedactive: false,
      checked: true,
      radiovalue: 'a',
      radiovalueactive: '',
      numberPeriod: 2,
      planmonth: 1,
      disableMonthly:false
    })
  }

  // when selecting annually on plan selection
  onClickOnAnnuallyRadio = e => {
    if (!this.state.premium) {
      this.setState({
        monthlyradiocheckvalue: '',
        annuallyradiocheckvalue: 'annually',
        planPriceType: this.props.userData.data.currency ? 'userChargeYearlyCurrency': 'userChargeYearly',
        planSelectionValue: '',
        showMonthlyPlan: false,
        payPeriod: 1,
        numberPeriod: 1,
        planPeriod: 1,
        planmonth: 12
      })
    } else {
      this.setState({
        monthlyradiocheckvalue: '',
        annuallyradiocheckvalue: 'annually',
        planSelectionValue: '',
        planPriceType: this.props.userData.data.currency ? 'userChargeYearlyCurrency': 'userChargeYearly',
        showMonthlyPlan: false,
        payPeriod: 1,
        planPeriod: 1,
        planmonth: 12
      })
    }
  }

  onRadioNumberPlanSelection = (type,planPriceType,payPeriod) => {
    if (!this.state.premium) {
      this.setState({
        monthlyradiocheckvalue: '',
        annuallyradiocheckvalue: '',
        planSelectionValue: type,
        planPriceType: planPriceType,
        showMonthlyPlan: false,
        payPeriod: payPeriod,
        numberPeriod: 1,
        planPeriod: 6,
        planmonth: 3
      })
    } else {
      this.setState({
        monthlyradiocheckvalue: '',
        annuallyradiocheckvalue: '',
        planSelectionValue: type,
        planPriceType: planPriceType,
        showMonthlyPlan: false,
        payPeriod: payPeriod,
        planPeriod: 6,
        planmonth: 3
      })
    }
  }

  // when selecting monthly on plan selection
  onClickOnMonthlyRadio = e => {
    if (!this.state.premium) {
      this.setState({
        monthlyradiocheckvalue: 'monthly',
        annuallyradiocheckvalue: '',
        planSelectionValue: '',
        showMonthlyPlan: true,
        planPriceType: this.props.userData.data.currency ? 'userChargeCurrency': 'userCharge',
        payPeriod: 2,
        numberPeriod: 2,
        planPeriod: 2,
        planmonth: 1
      })
    } else {
      this.setState({
        monthlyradiocheckvalue: 'monthly',
        annuallyradiocheckvalue: '',
        planSelectionValue: '',
        planPriceType: this.props.userData.data.currency ? 'userChargeCurrency': 'userCharge',
        showMonthlyPlan: true,
        payPeriod: 2,
        planPeriod: 2,
        planmonth: 1
      })
    }
  }
  minSeatChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const re = /^[0-9\b]+$/;
    if (value == "") {
      this.setState({userMaxSeat: ""})
    } else if (re.test(value) && value > 10) {
      return this.props.setMessage({ message: "You Can not choose more than 10 seat.", type:"error" });
    }else if (re.test(value) && value < 1) {
      return this.props.setMessage({ message: "You have to choose at least one seat.", type:"error" });
    }else if (re.test(value)){
      this.setState({userMaxSeat: value})
    }else{
      this.setState({userMaxSeat: this.state.userMaxSeat})
    }
  }

  currencyDropdownListdefault = (val,selectedData) => {
    this.setState({
      currencyOFC:val,
      planId:selectedData.key,
      ofcDisable: this.state.number ? (val == "IN" || val == "Global" || val == this.state.ofcCountries[this.state.number.isoCountry]) ? false : true : false
    })
  }
  onSelectCurrency = (value) => {
    this.setState({
      currency:value,
      currencySign:Currency[value].sign
    })
  
    this.props.changeUserData({data: { selectedCurrency: value }, "type":"changeAuth"})
  }
  minSeatKeyDown = e => { if([69,107,109,187,110,189,190].includes(e.keyCode)){ e.preventDefault() } }
  
  onClickOnSelectPlan = plan => {
    this.setState({
      freeNumberPerUser: plan.freeNumberPerUser,
      ydata: plan.userChargeYearly,
      planId: plan._id,
      mdata: plan.userCharge,
      disableMonthly:plan.planDisplayName == "platinum",
      flatPrice:plan.flatPrice
    })
  }
  onClickOfficeOnSelectPlan = plan => {
    this.setState({
      freeNumberPerUser: plan.freeNumberPerUser,
      ydata: plan.userChargeYearly,
      planId: this.props.user_data.data.data.plans.find(o => (o.freeMinutesCountry === this.state.currencyOFC) && (plan.planDisplayName == o.planDisplayName)) ? this.props.user_data.data.data.plans.find(o => (o.freeMinutesCountry === this.state.currencyOFC) && (plan.planDisplayName == o.planDisplayName)).id : this.props.user_data.data.data.plans.find(o => (o.freeMinutesCountry === 'Global') && (plan.planDisplayName == o.planDisplayName)).id,
      mdata: plan.userCharge,
      disableMonthly:plan.planDisplayName == "platinum",
      cplanName:plan.planDisplayName,
      currencyOFC:this.props.user_data.data.data.plans.find(o => (o.freeMinutesCountry === this.state.currencyOFC) && (plan.planDisplayName == o.planDisplayName)) ? this.state.currencyOFC : 'US/CAD'
    })
  }

  callback = async (key) => {
    
    let tabChangeObj
    if(key === '2'){
      tabChangeObj = {
        planId: this.props.user_data.data.data.plans.find(o => o.planName.includes("professionalglobal"))._id,
        //planId:  this.state.number && this.state.ofcCountries[this.state.number.isoCountry] ? this.props.user_data.data.data.plans.find(o => o.planDisplayName === 'professional' && o.freeMinutesCountry == this.state.ofcCountries[this.state.number.isoCountry]).id : this.props.user_data.data.data.plans.find(o => o.planDisplayName === 'professional').id,
        cplanName:"professional",
        //currencyOFC: this.state.number && this.state.ofcCountries[this.state.number.isoCountry] ? this.state.ofcCountries[this.state.number.isoCountry]: 'US/CAD',
        currencyOFC: 'Global',
        ofcDisable: this.state.number ? (this.state.number.isoCountry == "IN" || this.state.currencyOFC == "Global" || this.state.ofcCountries[this.state.number.isoCountry]) ? false : true : false
      }
    }else
    {
      tabChangeObj = {
        planId: this.props.user_data.data.data.plans.find(o => o.planDisplayName === 'silver').id,
        ofcDisable:false
      }
    }
    console.log("tabChangeObj.currency================",tabChangeObj.currency,this.state.currency)
    if(tabChangeObj.currency != this.state.currency){
      this.props.changeUserData({data: { selectedCurrency: tabChangeObj.currency }, "type":"changeAuth"})
    }

    this.setState({
      ...tabChangeObj,
      activeKey:key
    })
  }

  render() {
    const { annuallyPrice, monthlyPrice,planSelectionValue, monthlyradiocheckvalue, annuallyradiocheckvalue, ydata, mdata, freeNumberPerUser, planId, user_details, currencySign,currency, plans,ofcDisable ,ofcCountries} = this.state;
    let planCallCenter = plans.length ? plans.filter(planObj => !planObj.isOfficePhoneSystem && planObj.planDisplayName != "basic") : [];
    let planOfficePhone = plans.length ? plans.filter(planObj => planObj.isOfficePhoneSystem && planObj.freeMinutesCountry === this.state.currencyOFC) : [];
    return (
      <Modal
        className={`addnumpopup`}
        width={planCallCenter.length >= 3 ? "950px" : "700px"}
        centered
        // visible={true}
        visible={this.state.visible && !this.state.notVisible && !this.state.loadingUserData}
        onCancel={()=>{this.handleCancel()}}
        footer={null}
      >

        {/* <div className="fwidth">
          <div className="stepwizard">
            <div className="stepwizard-row setup-panel stpcheck">
              <div className="stepwizard-row-progress"></div>
              <div className="stepwizard-step">
                <div className="stepwizard-btn-circle-one"><span><i className="material-icons step-check-icon">check</i></span></div>
              </div>
              <div className="stepwizard-step">
                <div className="stepwizard-btn-circle-two"><span>{this.state.checkrighttickfirst ? <i className="material-icons step-check-icon">check</i> : 2}</span></div>
              </div>
              <div className="stepwizard-step stepwizard-step-three">
                <div className="stepwizard-btn-circle-three"><span>{this.state.checkrightticksecond ? <i className="material-icons step-check-icon">check</i> : 3}</span></div>
              </div>
            </div>
          </div> */}
          <div className="fwidth">

            {/* START: popup title design */}
            {/* <div className="text-center">
              {this.state.numberpurchaseshow && <h3 className="num-plan-title numplan_title_offerchange fwidth" style={{ display: 'block' }}>Almost there! Choose your number plan</h3>}
              {this.state.showtext && <h3 className="num-plan-title priceplanttitle fwidth">Select your plan and you are done!<i className="material-icons">mood</i></h3>}
            </div> */}
            {/* END: popup title design */}
     
                {/* START: Tab Structure */}
                {this.state.showtext && !this.state.number.premium && (this.state.user_details && this.state.user_details.isFreeTrialUser)&& <h3 className="num-plan-title priceplanttitle fwidth tab-text-title">Start Your 10 Days Free Trial</h3>}
                <Tabs activeKey={this.state.activeKey} className="fwidth text-center" defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Call Center Solution" key="1">
                <div className="text-center">
                 </div>
                   {/*plan selection design start */}
                    {this.state.planselectionshow &&
                      <form name="addNumberForm">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} id="currencydropdown">
                          <Col className="gutter-row" span={24}>
                            {/* START: selection of plan period(monthly/annually) */}
                            <Col className="gutter-row currencydropbtm padzero" span={24}>
                                <div className="planSelect__duration fwidth chflex spacebetween chaligncenter">
                                {!this.state.flatPrice &&
                                <Col className='padzero' span={6}>
                                  <div className="" id="seatdropdown">
                                    <label className="seatpurchasedlabel">Reserve Seats:<Tooltip overlayClassName="number_plan_tooltipWrap" title="The charges will be calculated based on the total seats."><i className="material-icons seatpurchasedtooltip">info</i></Tooltip></label>
                                    <input type="number" value= {this.state.userMaxSeat} min={0} max={11} onChange={this.minSeatChange} onKeyDown={this.minSeatKeyDown} className="totalseatinput" />
                                  </div>
                                  </Col>
                                }
                                  <Col className='padzero' span={12}>
                                    <div className="switchwrapper">
                                    <Form.Item label="" className='mgleft25'>
                                      <Radio.Group value={monthlyradiocheckvalue}>
                                      <Radio onClick={this.onClickOnMonthlyRadio} value="monthly">Monthly</Radio>
                                        {/* <Radio onClick={this.onClickOnMonthlyRadio} disabled={this.state.disableMonthly} value="monthly">Monthly</Radio> */}
                                      </Radio.Group>
                                    </Form.Item>                        
                                    <Form.Item label="">
                                      <Radio.Group value={annuallyradiocheckvalue}>
                                        <Radio onClick={this.onClickOnAnnuallyRadio} value="annually">Annually</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                    <div className="number_purchse_an_offer_secpage monthcosttab mothcost_dropdown">
                                      <span className="num_purchse_span_sec currecydrop_monthcost">Save 3 Months Cost</span>
                                    </div>
                                  </div>
                                  </Col>
                                  <Col className='padzero' span={6}>
                                  {/* {this.state.currencyList.length > 0 && <Form.Item  className="">
                                    <label className='mgright5 fbold'>Currency:
                                    </label>
                                    <Select defaultValue={this.state.currency} style={{ width: 100 }} className="currencydropdownbtn"
                                     onChange={this.onSelectCurrency} value={this.state.currency}

                                    >{this.state.currencyList.map((planC) => {
                                        return (        <Option value={planC}>{planC}</Option>)
                                      })}
                                    </Select>
                                  </Form.Item>} */}
                                  </Col>
                                </div>
                            </Col>
                            {/* END: selection of plan period(monthly/annually) */}
                            <div className="plancontainer">
                            {planCallCenter.map((plan, index) => {
                              return (  
                                <Col className="planpadding" md={{span: planCallCenter.length >= 3 ? 8 : 12}} lg={{span: planCallCenter.length >= 3 ? 8 : 12}}  key={plan._id}>
                                  <div className="planslbox platinumplan">
                                    <div className="number-container brdorange">
                                      <div className={plan.planDisplayName === "silver" || plan.planDisplayName === "enterprise" ? 'plandigit num-annu num_background plan_title_silver' : 'plandigit num-annu num_background'}>{plan.planDisplayName}</div>
                                        <div className="planstat ng-binding">
                                        <Tooltip overlayClassName="number_plan_tooltipWrap" title={plan.planDescription}><i className="material-icons numberPlanTooltip">info</i></Tooltip>
                                          <span className="plan_price_position"> {plan[this.state.planPriceType][currency]}
                                            <span className="plan_currency_AN">{currencySign}</span>
                                          </span>
                                        </div>
                                        {plan.flatPrice ?
                                          <div class="emptybox_feature"></div> :
                                          <span className="plan_type_label">USER/MONTH</span>
                                        }
                                      <div className="planprice bgwhite addnumboxborder">
                                        {plan._id === this.state.planId ? 
                                          <Button className="slrctplan plan_selected" onClick={() => this.onClickOnSelectPlan(plan)}> 
                                            <i className="material-icons plan_check_icon" >check_circle</i> Selected
                                          </Button> 
                                          : 
                                          <Button className="slrctplan" onClick={() => this.onClickOnSelectPlan(plan)}>Select Plan</Button>
                                        }
                                      </div>
                                      <div className='planprice bgwhite addnumboxborder'>
                                      <>
                                      {index == 0 ? 
                                        <div className='emptybox_feature'></div> :
                                        <div className='scheme-title-box'>Everything in {planCallCenter[index - 1].planDisplayName} +</div>
                                      }
                                      <ul className='scheme-list'>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 mcenter">info</i>
                                        </Popover>}</li>)}
                                      </ul>
                                      </>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              
                              );
                            })
                            }                    
                            </div>
                          </Col>
                        </Row>
                      </form>
                    }
                    {/*plan selection design end */}
                </TabPane>
                <TabPane tab="Office Phone System" key="2">
                <div className="text-center">
                  {/* {this.state.showtext && <h3 className="num-plan-title priceplanttitle fwidth tab-text-title">Idle for business to support their customers</h3>} */}
                 </div>
                  {this.state.planselectionshow &&
                        <form name="addNumberForm">
                            <Row id="currencydropdown" type='flex' className="currencydropbtm flexcolomch">
                              <div className="planSelect__duration fwidth chflex spacebetween chaligncenter">
                              {!this.state.flatPrice &&
                                <Col className='padzero' span={5}>
                                <div className="" id="seatdropdown">
                                  <label className="seatpurchasedlabel">Reserve Seats:<Tooltip overlayClassName="number_plan_tooltipWrap" title="The charges will be calculated based on the total seats. "><i className="material-icons seatpurchasedtooltip">info</i></Tooltip></label>
                                  <input type="number" value= {this.state.userMaxSeat} min={0} max={11} onChange={this.minSeatChange} onKeyDown={this.minSeatKeyDown} className="totalseatinput" />
                                </div>
                                </Col>
                              }
                                <Col className='padzero' span={11}>
                                <div className="switchwrapper">
                                  <Form.Item label="" className='mgleft25'>
                                    <Radio.Group value={monthlyradiocheckvalue}>
                                    <Radio onClick={this.onClickOnMonthlyRadio} value="monthly">Monthly</Radio>
                                      {/* <Radio onClick={this.onClickOnMonthlyRadio} disabled={this.state.disableMonthly} value="monthly">Monthly</Radio> */}
                                    </Radio.Group>
                                  </Form.Item>                        
                                  <Form.Item label="">
                                    <Radio.Group value={annuallyradiocheckvalue}>
                                      <Radio onClick={this.onClickOnAnnuallyRadio} value="annually">Annually</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                  <div className="number_purchse_an_offer_secpage monthcosttab mothcost_dropdown">
                                    <span className="num_purchse_span_sec currecydrop_monthcost">Save 3 Months Cost</span>
                                  </div>
                                </div>
                                </Col>
                                <Col className='padzero' span={8}>
                                {/* {this.state.currencyList.length > 0 && <Form.Item  className="">
                                    <label className='mgright5 fbold'>Currency:
                                    </label>
                                    <Select defaultValue={this.state.currency} style={{ width: 100 }} className="currencydropdownbtn"
                                     onChange={this.onSelectCurrency} value={this.state.currency}

                                    >{this.state.currencyList.map((planC) => {
                                        return (        <Option value={planC}>{planC}</Option>)
                                      })}
                                    </Select>
                                    </Form.Item>*/}
                                </Col>
                              </div>
                              <div className='w-100 unlimited_calling_div'>
                                <Col className='padzero' span={24}>
                                  <Form.Item className='chjustifycenter'>
                                    <label className='mgright5 fbold chflex chaligncenter'>Unlimited Calling:
                                    <Popover
                                    content={'Select country for which you want to get unlimited minutes.'} 
                                    placement="top"><i className="material-icons infopopopen cursorp">info</i>
                                    </Popover>
                                    </label>
                                    <Select 
                                      defaultValue={this.state.currencyOFC} 
                                      style={{ width: 130 }} 
                                      className="currencydropdownbtn"
                                      onChange={this.currencyDropdownListdefault} 
                                      value={this.state.currencyOFC}
                                    >
                                      {plans.map((plan) => {
                                        if(this.state.cplanName == plan.planDisplayName){
                                          return <Option value={plan.freeMinutesCountry} key = {plan._id} label={plan.freeMinutesCountry}>{plan.freeMinutesCountry}</Option>
                                        }
                                        return null
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </div>
                            </Row>
                            {/* END: selection of plan period(monthly/annually) */}
                            <Row type="flex" justify="center" className="plancontainer">
                            {planOfficePhone.map((plan) => 
                                <Col className="planpadding" md={{ span: 8 }} lg={{ span: 8 }}>
                                  <div className="planslbox platinumplan">
                                    <div className="number-container brdorange">
                                      <div className={plan.planDisplayName === "professional" ? 'plandigit num-annu num_background plan_title_silver' : 'plandigit num-annu num_background'}>{plan.planDisplayName}</div>
                                        <div className="planstat ng-binding">
                                        <Tooltip overlayClassName="number_plan_tooltipWrap" title={plan.planDescription}><i className="material-icons numberPlanTooltip">info</i></Tooltip>
                                          <span className="plan_price_position"> {plan[this.state.planPriceType][currency]}
                                            <span className="plan_currency_AN">{currencySign}</span>
                                          </span>
                                        </div>
                                        <span className="plan_type_label">USER/MONTH</span>
                                      <div className="planprice bgwhite addnumboxborder">
                                        {plan.planDisplayName === this.state.cplanName ? 
                                          <Button className="slrctplan plan_selected" onClick={() => this.onClickOfficeOnSelectPlan(plan)}> 
                                            <i className="material-icons plan_check_icon" >check_circle</i> Selected
                                          </Button> 
                                           :
                                          <Button className="slrctplan" onClick={() => this.onClickOfficeOnSelectPlan(plan)}>Select Plan</Button>
                                        }
                                      </div>
                                      <div className='planprice bgwhite addnumboxborder'>
                                      {plan.planDisplayName === "starter" &&
                                      <>
                                      <div className='scheme-title-box emptybox_feature'></div>
                                      <ul className='scheme-list'>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 mcenter">info</i>
                                        </Popover>}</li>)}
                                      </ul> 
                                      </>
                                      }
                                      {plan.planDisplayName === "professional" &&
                                      <>
                                      <div className='scheme-title-box'>Everything in Starter +</div>
                                      <ul className='scheme-list'>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 mcenter">info</i>
                                        </Popover>}</li>)}
                                      </ul>
                                      </>
                                       }
                                       {plan.planDisplayName === "ultimate" &&
                                      <>
                                      <div className='scheme-title-box'>Everything in Professional +</div>
                                      <ul className='scheme-list'>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 mcenter">info</i>
                                        </Popover>}</li>)}
                                      </ul>
                                      </>
                                       }
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )} 
                        </Row>
                       {ofcDisable && this.state.number && <Row type="flex" justify="center" className="plancontainer ofcCountryErr">
                           {Object.keys(ofcCountries).slice(1).map((key)=>ofcCountries[key]).includes(this.state.currencyOFC) ? <Col className="planpadding" md={{ span: 20 }} lg={{ span: 20 }}>
                                To get {countries[Object.keys(ofcCountries).filter((key)=>ofcCountries[key] == this.state.currencyOFC ? key : false)[0]].name}{this.state.currencyOFC == 'US/CAD' ? '/Canada' : ''} unlimited calling, please purchase {this.state.currencyOFC} number
                              </Col>:
                              <Col className="planpadding" md={{ span: 20 }} lg={{ span: 20 }}>
                                 please select US/Canada , UK or AUS number to get unlimited calling benefits of office phone system plans.
                              </Col>}
                        </Row>}
                      </form>
                    }
                </TabPane>
                </Tabs>
                <div className='chflex chaligncenter chjstcenter mgtop10'>
                    {/* <a className='comparePlanLink' href='https://callhippo.com/pricing/' target='_blank' >Compare Plans</a> */}
                </div>
                <div className="purchasebtnend tabBorderTop ">
                  <Row className='mgtop25'>
                  <Col className="gutter-row" span={5}></Col>
                  <Col className="gutter-row" span={14}>
                    {(this.state.user_details && ((this.state.user_details.userAllNumberCount > 0 || this.state.user_details.stripeId) && this.state.user_details.accountStatus != 'cancel' && this.state.user_details.accountStatus != 'hardDelete')) || (this.state.user_details && this.state.user_details.plan.planDisplayName != 'free') ?                    
                        <Button type="primary" className="gbtnheight paybtnrct checkoutBtn mgleft15" key="submit"  onClick={() => this.props.secondNumberPopupOk(this.state.number,this.state.numberPeriod)} disabled={planId === '' || planId === 123 || ofcDisable? true : false}>
                          CHECKOUT({this.state.number ? this.state.number.monthlyPrice : 0})
                        </Button>        
                        :
                        this.state.showtext ?
                         [
                          <>
                        <div className='chflex chaligncenter chjstcenter'>
                        {!this.state.number.premium && (this.state.user_details && this.state.user_details.isFreeTrialUser) &&
                          <Button className={`Trial_selected checkoutBtn`} onClick={() => this.handleCheckout(this.state.freeTrailButtonClick= true)} disabled={(planId == '' || planId == 123 || ofcDisable) ? true : false}>Start 10 Days Trial</Button>
                        }
                        <Button className={`slrctplan plan_selected`} key="submit" type="primary"  onClick={() => this.handleCheckout(this.state.freeTrailButtonClick= false)} disabled={(planId === '' || planId === 123 || ofcDisable) ? true : false}>
                          Buy Now
                        </Button>
                        </div>
                        </>,
                        ] 
                        : [
                        <div className='chflex chaligncenter chjstcenter'>
                            <Button className="gbtnheight paybtnrct addnumberbtn checkoutBtn mgleft15" key="submit" onClick={this.handleOk} disabled={planId === '' || planId === 123 ? true : false}>
                              PAYs {this.state.showtext ? "true":"false"}
                          </Button>
                        </div>
                          ,
                      ]}
                      </Col>
                      <Col className="gutter-row" span={5}>
                         {this.state.basicPlanId && this.state.number && <a className="chflex chaligncenter chjstcenter" style={{height:"36px"}} onClick={() => this.handleCheckout(this.state.freeTrailButtonClick= false,true)} >
                               <span style={{borderBottom: "1px solid",alignSelf: "center"}}>Skip</span> 
                        </a>}
                      </Col>
                  </Row>
                </div>
            {/* END: Tab Struture */}
            {/*plan selection design end */}
          </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  chargebee_new_user: state.number.chargebee_new_user,
  get_users: state.number.get_users,
  user_data: state.number.user_data,
  userData:state.authentication.userData,
  get_number: state.number.get_number

});

export default connect(mapStateToProps, { chargebeeNewUser, getUserProfile, getPlan, changeUserData, changeUserSetting,setMessage,changeUserData, user, getNumbers})(Addnopopup);
