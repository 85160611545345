import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const isoModal = ComponentName => WithDirection(styled(ComponentName)`
.ant-modal-close{
    display:none;
}
.ant-modal-title img.chinfologowidth{
    width:150px;
    margin-bottom:5px;
}   
.ant-modal-title .chinfotitlewrapper{
    text-align:center;
} 
.infopopupwrapper .ant-row:after {
        display: block;       
}
.teamsizewrapper label.ant-radio-button-wrapper{
    margin-right: 30px;
    width: 90px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}
.teamsizewrapper .ant-radio-button-wrapper:not(:first-child)::before{
   content:unset;
}
.teamsizewrapper label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked{
    color: #fff;
    background: #e27849;
    border-color: #e27849;
    box-shadow:unset;
}
.teamsizewrapper label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover{
    color: #000000a6;
    background: #e27849;
    border-color: #e27849;
}
.teamsizewrapper label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:active{
    color: #000000a6;
    background: #e27849;
    border-color: #e27849;
}
.teamsizewrapper .ant-radio-button-wrapper:last-child  {
    margin-right:0px;
}
.teamsizewrapper .ant-radio-button-wrapper:hover{
    color:#000000a6;
}
.infopopupbtn{
    color:#fff;
    background: #e37949;
    border-color: #e37949;
}
.infopopupbtn.ant-btn:hover, .infopopupbtn.ant-btn:focus{
    color:#fff;
    background: #e37949;
    border-color: #e37949;
}
.infopopupwrapper .ant-form-item-label{
    padding: 0 0 2px;
}
.infopopupwrapper .ant-row.ant-form-item{
    margin-bottom:10px;
}
`);

export default isoModal;