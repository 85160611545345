import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const isoModal = ComponentName => WithDirection(styled(ComponentName)`
    .addonwrapper .ant-row:after {
         display: block;       
    }
    .ant-modal-footer{
        text-align:center;
    }
    .addontitle {    
        font-size: 12px;
        display:flex;
        align-items:center;
    }
    .addoncard {
        border-radius: .25rem;
        border: 1px solid #e1e1e1;   
        height: 100%;
        margin-bottom:20px;
    }
    .addoncard:last-child{
        margin-bottom:0;
     }
    .addoncard .ant-card-head {
        border-bottom:0px;
    }
    .addoncard .ant-card-head-title {
        padding-bottom: 0px;
    }
    .addoncard .ant-card-body { 
        padding: 15px 11px;
        display: flex;
    }
    .addonbtn {
        text-align: center;
        margin: 10px 0;
    }
    .addondetail {
        display: flex;    
        align-items: center;
        width: 100%;
        padding-left: 30px;       
    }
    .addoncheckbox .ant-checkbox {
        position: absolute;
        top: 27px;
        left: 15px;
    }
    
    .addoncheckbox .ant-checkbox + span {
        display:flex;
        padding:0;
    }
    .addonprice {
        display: flex;               
        padding-left: 12px;
        padding-right:20px;
        flex-direction:column;
    }
    .pricetext {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        text-align:center;
        
    }
    .addonmaintitle {
        margin-bottom: 15px;
        font-size: 15px;
        margin-top:15px;
    }
    .addontext {
        font-weight: 500;
        font-size: 14px;
        color: #000;
    }
    .addoncheckedborder{
        border:1px solid #e27646;
    }
    .perusertext {    
        color: #b3b3b3;
        font-size: 12px;
        white-space: nowrap;
    }
    .addoncard .ant-checkbox-wrapper {
        width: 100%;
    }
        
    .addoncheckbox .ant-checkbox-inner {
        border: 1px solid #000;
    }
    .addoncheckbox .ant-checkbox-checked::after {
        border:1px solid #000;
    }
    .addoncheckbox .ant-checkbox-checked .ant-checkbox-inner {
        background:#000;
    }
    .addoncheckbox .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color:#000;
    }
    .addonbtn button{
        background-color: #e27646;
        border-color: #e27646;
    }
    .addonbtn button.ant-btn-primary:hover, .ant-btn-primary:focus{
        background-color: #e27646;
        border-color: #e27646;
    }
    .addoncol {
        margin-bottom: 15px;
    }
    .arrow {
        border: solid #909090;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        height:10px;
        width:10px;
    }
    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    .addonrightarrow{
        display:flex;
        align-items:center;
    }
    .addondescbox {           
        background-color: #fff;
        color: #b0b0b0;
        padding: 10px;
        position: relative;       
        border: 1px solid #ddd;   
        box-shadow: 0px 1px 4px 3px rgb(210 207 207 / 30%);
        border-radius: 5px;
       margin-bottom:5px;
      }
      
      .addondesctext{
          font-weight:500;
          color:#000;
          font-size:16px;
      }
      .showaddondesc{
          display:none;
      }
      .addonwrapper{
        overflow-y: auto;
        max-height: 327px;
        overflow-x: hidden;
      }
      .addonwrapper::-webkit-scrollbar {
        width: 6px;
        height:6px;
        background-color: #F5F5F5;
      }
      .addonwrapper::-webkit-scrollbar-thumb{
        border-radius: 10px;
            box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
            background-color:  #F5F5F5;
      }
      .purchasenowbtn{
        color:#fff;
        background:#e27646;
        border:1px solid #e27646;
        height:40px;
      }
      .purchasenowbtn.ant-btn:active{
        color:#fff;
        background:#e27646;
        border:1px solid #e27646;
      }
      .purchasenowbtn.ant-btn:hover, .purchasenowbtn.ant-btn:focus{
        color:#fff;
        background:#e27646;
        border:1px solid #e27646;
      }
      .addondisabledcard{
          cursor:not-allowed;
      }
      .disableaddon{
        color: #dbdbdb;
      }
      .disablePurchaseButton{
        color: #dbdbdb;
        background: white;
       }
      .disablePurchaseButton.ant-btn:hover, .disablePurchaseButton.ant-btn:focus{
        color: #dbdbdb;
        background: white;
       }
      .disableaddonarrow{
          border:solid #dbdbdb;
          border-width: 0 3px 3px 0;
      }
      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
        background: unset !important;
      }

      .hippaImg {
        width: 70px;
        height: 22px;
        margin-right: 10px;
        align-self: center;
    }
    
    .pciImg {
        width: 70px;
        height: 28px;
    }
    .d-flex{
        margin-right: 20px;
      }
     .hippaPciCol {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
`);

export default isoModal;