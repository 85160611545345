import integration from './actions';
const initState = {
  active_integrations: [],
  integration_token :[],
  add_integration : [],
  restapi_integration :[],
  restapi_service :[],
  slack_integration : [],
  remove_integration : [],
  slack_channel : [],
  add_native_integration : [],
  intercom_save : [],
  assign_number : [],
  assign_common_number : [],
  remove_blendr_integration : [],
  remove_blendr_integration : [],
  add_details:[],
  get_details :[],
  delete_details : [],
  update_smswebhook : [],
  integration_users : [],
  set_integration_user: [],
  request_new_integration: [],
  save_ms_selections:[],
  reschedule_activity:[],
  leadsquared_activity:[],
  active_campaign_activity:[],
  departments_zoho_desk:[]
}

export default function integrationReducer(state = initState, action) {
  switch (action.type) {
    case integration.ACTIVE_INTEGRATIONS:
      return {
        ...state,
        active_integrations: action.payload,
      };
      case integration.INTEGRATION_TOKEN:
        return {
          ...state,
          integration_token : action.payload,
        };
      case integration.ADD_INTEGRATION:
        return {
          ...state,
          add_integration : action.payload,
        };
        case integration.RESTAPI_INTEGRATION:
        return {
          ...state,
          restapi_integration : action.payload,
        };
        case integration.RESTAPI_SERVICE:
        return {
          ...state,
          restapi_service : action.payload,
        };
        case integration.SLACK_INTEGRATION:
          return {
            ...state,
            slack_integration : action.payload,
          };
        case integration.REMOVE_INTEGRATION:
          return {
            ...state,
            remove_integration : action.payload
          };
        case integration.SLACK_CHANNEL:
          return {
            ...state,
            slack_channel : action.payload
          }
        case integration.ADD_NATIVE_INTEGRATION:
          return {
            ...state,
            add_native_integration : action.payload
          }
        case integration.INTERCOM_SAVE:
          return{
            ...state,
            intercom_save : action.payload
          }
      case integration.ASSIGN_INTEGRATION_NUMBER:
        return{
          ...state,
          assign_number : action.payload
        }
        case integration.ASSIGN_COMMON_INTEGRATION_NUMBER:
          return{
            ...state,
            assign_common_number : action.payload
          }
        case integration.REMOVE_BLENDR_INTEGRATION:
          return{
            ...state,
            remove_blendr_integration : action.payload
          }
        case integration.ADD_DETAILS:
          return{
            ...state,
            add_details : action.payload
            }
        case integration.GET_DETAILS:
            return{
              ...state,
              get_details : action.payload
              }
        case integration.DELETE_DETAILS:
          return {
            ...state,
            delete_details: action.payload
          }
        case integration.UPDATE_SMS_WEBHOOK:
          return{
            ...state,
            update_smswebhook:action.payload
          };
        case integration.INTEGRATION_USERS:
          return{
            ...state,
            integration_users:action.payload
          };
        case integration.SET_INTEGRATION_USER:
          return{
            ...state,
            set_integration_user:action.payload
          };
        case integration.REQUEST_NEW_INTEGRATION:
          return{
            ...state,
            request_new_integration:action.payload
          };
        case integration.MS_CHANNELS_USERS:
          return{
            ...state,
            ms_channels_users:action.payload
          }
        case integration.SAVE_MS_SELECTIONS:
          return{
            ...state,
            save_ms_selections:action.payload
          }
        case integration.reschedule_activity:
          return{
            ...state,
            reschedule_activity:action.payload
          }
        case integration.LEADSQUARED_ACTIVITY:
          return{
            ...state,
            leadsquared_activity:action.payload
          }
        case integration.ACTIVE_CAMPAIGN_ACTIVITY:
          return{
            ...state,
            active_campaign_activity:action.payload
          }
        case integration.DEPARTMENTS_ZOHO_DESK:
          return{
            ...state,
            departments_zoho_desk:action.payload
          }
    default:
      return state;
  }
}

