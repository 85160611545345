export function checkAccessAllowedForModule (role, moduleName, userData) {
    let accessAllowed = defaultModuleAccess;
    moduleName=(moduleName=="voiceBroadcast")?"powerDialer":moduleName;
    if(userData && userData.parentId){
        if(role && role.modules && role.modules.length > 0){
            role.modules.find((x) => {
                if(x.name === moduleName && x.actions ){
                    accessAllowed = x.actions
                }
            })
        }else if(userData.userModules && userData.userModules.includes(moduleName)){
            accessAllowed = hasModuleAccess
        }
    }else{
        accessAllowed = hasModuleAccess
    }
    return accessAllowed
}

export const ModuleName = {
    USER: 'user',
    Team: 'team',
    NUMBER: 'number',
    INTEGRATIONS: 'integration',
    VOICEBROADCAST: 'voiceBroadcast',
    POWERDIALER: 'powerDialer',
    CALLSCRIPT: 'callScripts',
    BILLING: 'billing',
    SETTING: 'setting',
    CALLPLANNNER: 'callPlanner',
    CALLREPORT: 'callReport',
    CONTACT: 'contact',
    BROADCAST_ACTIVITY_FEED: "broadcastactivityfeed",
    EXPORTCONTACT: "exportcontact",
    CAMPAIGN_MANAGEMENT: 'campaigns',
    CREDIT: 'credit',
};

export const Action = {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    DELETE: 'delete',
};

export const Message = {
    ErrorMessage: 'Ask your Admin for access to this option.',
};

export const defaultModuleAccess = {
    "create" : false,
    "read" : false,
    "update" : false,
    "delete" : false
};


export const hasModuleAccess = {
    "create" : true,
    "read" : true,
    "update" : true,
    "delete" : true
};

