export const FETCH_LIVECALLS_REQUEST = 'FETCH_LIVECALLS_REQUEST'
export const FETCH_LIVECALLS_SUCCESS = 'FETCH_LIVECALLS_SUCCESS'
export const FETCH_LIVECALLS_FAILURE = 'FETCH_LIVECALLS_FAILURE'
export const LIVE_CALL_CREATED = 'LIVE_CALL_CREATED'
export const LIVE_CALL_UPDATED = 'LIVE_CALL_UPDATED'
export const LIVE_CALL_REMOVED = 'LIVE_CALL_REMOVED'
export const AGENT_ADDED = 'AGENT_ADDED'
export const AGENT_REMOVED = 'AGENT_REMOVED'
export const POST_CALL_ACTION_REQUEST = 'POST_CALL_ACTION_REQUEST'
export const POST_CALL_ACTION_SUCCESS = 'POST_CALL_ACTION_SUCCESS'
export const POST_CALL_ACTION_FAILED = 'POST_CALL_ACTION_FAILED'