import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Layout,Tooltip } from 'antd';
import message from '@iso/components/Feedback/Message';
import Scrollbars from '@iso/components/utility/customScrollBar';
import Menu from '@iso/components/uielements/menu';
import appActions from '@iso/redux/app/actions';
import Logo from '@iso/components/utility/logo';
import SidebarWrapper from './Sidebar.styles';
import { Link, withRouter } from 'react-router-dom';
import CHTour from './CHTour';
import { APP_PRIVATE_ROUTE, PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { Helmet } from "react-helmet";
import AddNumberModal from './AddNumberModal.js';
import PlanUpgradePopup from "@iso/components/Popups/PlanUpgrade/PlanUpgradePopup";
import Addonpopup from "../../components/Popups/addOnpopup";
import dashboardAction from "../../redux/dashboard/actions";
import ResumeAccountPopup from "../../components/Popups/resumeAccountPopup";
import {InfoCircleFilled} from '@ant-design/icons';

const { WHATSAPP_BUSINESS_CONTACTS,WHATSAPP_BUSINESS_MAIN,WHATSAPP_BUSINESS_LIVECHAT,WHATSAPP_BUSINESS_HISTORY,WHATSAPP_BUSINESS_CAMPAIGNS, PLAN_BILLING,WHATSAPP_BUSINESS_MANAGE,USER_PROFILE, USER_PROFILE_DOCUMENT, CHANGE_PASSWORD} = APP_PRIVATE_ROUTE;

const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
const {getCredits} = dashboardAction ;

function SidebarCt(props) {
  const dispatch = useDispatch();
  const {
    view,
    openKeys,
    collapsed,
    openDrawer,
    current,
    height,
  } = useSelector(state => state.App);

  const userData = useSelector(state => state.authentication.userData.data);
  const numberData = useSelector(state => state.number.get_number);
  const user_billing = useSelector(state => state.number.user_billing);
  const get_plan = useSelector(state=>state.planReducer.get_plan);
  const isTourOpen = useSelector(state => state.authentication.isTourOpen);
  const pusherData = useSelector(state => state.dashboard.pusher_data);
  const userCredits = useSelector(state => state.dashboard.userCredits);
  var currentCredit = userCredits && userCredits.credits ? userCredits.credits : 0;
  // Pusher connecttion
  const [showCallingCreditSection, setShowCallingCreditSection] = useState(false);
  const [sidebarCredit, setSidebarCredit] = useState(0);
  const [sidebarCreditRedirect, setSidebarCreditRedirect] = useState(false);

  //This useEffect will call's every time when this component loaded
  useEffect(() => { 
    if (!userData.isTourVisited && !userData.isProfileRemaining) props.history.push(WHATSAPP_BUSINESS_MAIN) ;
    sidebarCreditChange();
  }, []);
  
  //Change credit when push event call
  useEffect(() => { if(pusherData && pusherData.type === "credit") sidebarCreditChange();}, [pusherData]);
  useEffect(() =>{
    if(userData.plan.isAccessEditable && userData.isDisableCredit && !(userData.roleData.priority == 1 || userData.roleData.priority == 1.5)){
      setShowCallingCreditSection(!userData.isDisableCredit)
    }else{
      setShowCallingCreditSection(true)
    }
  }, [userData])
  const customizedTheme = useSelector(
    state => state.ThemeSwitcher.sidebarTheme
  );

  const SIDEBAR_ROUTE = {
    WHATSAPP_BUSINESS_MAIN: {
      URL: WHATSAPP_BUSINESS_MAIN,
      KEY: 'whatsappbusiness',
      URL_GROUP: [WHATSAPP_BUSINESS_MAIN]
    },
    WHATSAPP_BUSINESS_LIVECHAT: {
      URL: WHATSAPP_BUSINESS_LIVECHAT,
      KEY: 'livechat',
      URL_GROUP: [WHATSAPP_BUSINESS_LIVECHAT]
    }, 
    WHATSAPP_BUSINESS_HISTORY: {
      URL: WHATSAPP_BUSINESS_HISTORY,
      KEY: 'history',
      URL_GROUP: [WHATSAPP_BUSINESS_HISTORY]
    },
    WHATSAPP_BUSINESS_CONTACTS : {
      URL: WHATSAPP_BUSINESS_CONTACTS,
      KEY: 'contacts',
      URL_GROUP: [WHATSAPP_BUSINESS_CONTACTS]
    },
    WHATSAPP_BUSINESS_CAMPAIGNS : {
      URL: WHATSAPP_BUSINESS_CAMPAIGNS,
      KEY: 'campaigns',
      URL_GROUP: [WHATSAPP_BUSINESS_CAMPAIGNS]
    },
    WHATSAPP_BUSINESS_MANAGE : {
      URL: WHATSAPP_BUSINESS_MANAGE,
      KEY: 'manage',
      URL_GROUP: [WHATSAPP_BUSINESS_MANAGE]
    },
    PLAN_BILLING: {
      URL: PLAN_BILLING,
      KEY: 'planBilling',
      URL_GROUP: [PLAN_BILLING]
    },
    USER_PROFILE_DOCUMENT: {
      URL: USER_PROFILE_DOCUMENT,
      KEY: 'uploadDocument',
      URL_GROUP: [USER_PROFILE_DOCUMENT]
    }
  }
  useEffect(() => {
    dispatch(changeCurrent([]));
    Object.values(SIDEBAR_ROUTE).filter(obj => {
      obj.URL_GROUP.map((obj2) => {
      })
    })
    if(![USER_PROFILE,USER_PROFILE_DOCUMENT,CHANGE_PASSWORD].includes(props.history.location.pathname) && userData.isDocVerificationRequired != "approved"){
      props.history.push(USER_PROFILE_DOCUMENT);
    }
  }, [props.history.location.pathname])

  function handleClick(e) {
    //dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }

  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const onMouseEnter = event => {      
    if(window.outerWidth > 1024){
      if (collapsed && openDrawer === false) {
        dispatch(toggleOpenDrawer());
      }
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };

  const sidebarCreditChange = () => {
    let finalCredit = 0;
    if (pusherData && pusherData.type === "credit") {
      finalCredit = parseFloat(pusherData.data.totalCredits)
    } else {
      if (!(!numberData || !numberData.data || !numberData.data.length) && user_billing && user_billing.data && user_billing.data.data) {
        finalCredit = parseFloat(user_billing.data.data.availableCredits - user_billing.data.data.usedCredits)
      } else {
        if (userCredits && userCredits.credits) {
          finalCredit = userCredits.credits
        } else {
          if (userData && userData.stripeId && userData.plan && userData.plan.planDisplayName && userData.plan.planDisplayName !== 'free') {
            if (!userData.parentId || (userData.parentId && userData.totalCredits && userData.userModules.indexOf('billing') >= 0)) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
              setSidebarCreditRedirect(true);
            } else if (userData && userData.parentId && userData.userModules && userData.userModules.indexOf('billing') == -1) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
            }
          }
        }
      }
    }
    let userDataLocal = JSON.parse(localStorage.getItem('userData'));
    userDataLocal.totalCredits = finalCredit;
    localStorage.setItem("userData", JSON.stringify(userDataLocal));
    let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
    finalCredit = formatter.format(finalCredit.toFixed(2).toString());
    setSidebarCredit(finalCredit);
  }
  const checkCredits = () =>{
    props.getCredits().then(({payload})=>{
      if(payload.success){
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
      let finalCredit= payload.data ? payload.data.totalCredits ? formatter.format( payload.data.totalCredits.toFixed(2).toString()):sidebarCredit : sidebarCredit;
      let finalCr= payload.data ? payload.data.totalCredits ? payload.data.totalCredits:sidebarCredit : sidebarCredit;
      let userData = JSON.parse(localStorage.getItem('userData'));
      userData.totalCredits = finalCr;
      localStorage.setItem("userData", JSON.stringify(userData));
      setSidebarCredit(finalCredit);  
      }
    });
  }
  const warningMsg = msg => { message.destroy(); message.warning(msg) };
  const userIsBlocked = () => {
    userData.isBlocked && !userData.amountDue && warningMsg(userData.blockReason);
    userData.isBlocked && userData.amountDue && warningMsg(userData.blockReason + " Please update your payment method.");

  };
  const tabTitleChange = () => {
    if (current != []) {
      current.splice(0, current.length)
      current.push("calltracking")
    }

  }
  function changeTabTitle(currentKey) {
    // const keyName = currentKey[0];
    const keyName = window.location.pathname;
    if (keyName.match('livechat')) {
      return "Whatsapp Business Livechat"
    } else if (keyName.match("history")) {
      return "Whatsapp Business History"
    } else if (keyName.match("contacts")) {
      return "Whatsapp Business Contacts"
    }
    else if (keyName.match("campaigns")) {
      return "Whatsapp Business Campaign"
    }else if (keyName.match("manage")) {
      return "Whatsapp Business Manage"
    }
    else if (currentKey[0] == undefined) {
      return "Whatsapp Business"
    }
    else {
      return keyName[0].toUpperCase() + keyName.slice(1);
    }
  }
  
  return (
    <div>
      
      <SidebarWrapper>
        {/* <PusherConnection userData={userData} /> */}
        <Helmet>
          <title>{changeTabTitle(current)} | {window.env.REACT_APP_PAGETITLE}</title>
        </Helmet>
        <CHTour isOpen={isTourOpen} />
        <AddNumberModal />
        <PlanUpgradePopup />
        <Addonpopup />
        <ResumeAccountPopup />

        <Sider
          trigger={null}
          collapsible={false}
          collapsed={isCollapsed}
          width={240}
          className={`isomorphicSidebar  ${userData && userData.isProfileRemaining ? "sidebarDisabled" : ''}`}
          // onMouseEnter={onMouseEnter}
          // onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={isCollapsed} isBlocked={userData.isBlocked} />
          <Scrollbars className={`sidebarScrollbars scrolltabheight ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`} 
            style={ isCollapsed ? {height: height - 75} : {height: height - 94}}
          >
            <Menu
              onClick={handleClick}
              theme="dark"
              className="isoDashboardMenu WhatApps_Sider_outer_div"
              mode={mode}
              openKeys={isCollapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={onOpenChange}
            >
              <Menu.Item key={SIDEBAR_ROUTE.WHATSAPP_BUSINESS_MAIN.KEY} className={window.location.pathname == '/whatsappbusiness' ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.WHATSAPP_BUSINESS_MAIN.URL} >
                    <span className="isoMenuHolder svgremove">
                      <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"/></svg>
                      <span className="nav-text">Dashboard</span>
                    </span>
                </Link>
              </Menu.Item>
              
              {/* <Menu.Item key={SIDEBAR_ROUTE.WHATSAPP_BUSINESS_LIVECHAT.KEY} className={window.location.pathname.match('/livechat') ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.WHATSAPP_BUSINESS_LIVECHAT.URL}>
                  <span className="isoMenuHolder">
                    <i className="liveChatIcon"></i>  
                    <span className="nav-text">Live Chat</span>
                    </span>
                    </Link>
                    </Menu.Item>
              <Menu.Item key={SIDEBAR_ROUTE.WHATSAPP_BUSINESS_HISTORY.KEY} data-tour="tour-activity" className={window.location.pathname.match('/history') ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.WHATSAPP_BUSINESS_HISTORY.URL}>
                  <span className="isoMenuHolder">
                  <i className="material-icons updateIcon"></i> 
                  <span className="nav-text">History</span>
                  </span>
                </Link>
              </Menu.Item> 
              <Menu.Item key={SIDEBAR_ROUTE.WHATSAPP_BUSINESS_CONTACTS.KEY} data-tour="tour-activity" className={window.location.pathname.match('/contacts')  ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.WHATSAPP_BUSINESS_CONTACTS.URL}>
                  <span className="isoMenuHolder">
                      <i className="material-icons contactsIcon"></i> 
                    <span className="nav-text">Contacts</span>
                  </span>
                </Link>
              </Menu.Item>              
              <Menu.Item key={SIDEBAR_ROUTE.WHATSAPP_BUSINESS_CAMPAIGNS.KEY} data-tour="tour-activity" className={window.location.pathname.match('/campaigns') ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.WHATSAPP_BUSINESS_CAMPAIGNS.URL}>
                  <span className="isoMenuHolder">
                    <span class="material-icons campaignIcon"></span>
                    <span className="nav-text">Campaigns</span>
                  </span>
                </Link>
              </Menu.Item>   
              <Menu.Item key={SIDEBAR_ROUTE.WHATSAPP_BUSINESS_MANAGE.KEY} data-tour="tour-activity" className={window.location.pathname.match('/manage') ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.WHATSAPP_BUSINESS_MANAGE.URL}>
                  <span className="isoMenuHolder">
                    <span class="material-icons settingicon"></span>
                    <span className="nav-text">Manage</span>
                  </span>
                </Link>
              </Menu.Item>            */}
            </Menu>
          </Scrollbars>
          {showCallingCreditSection &&
          <div className="sidebarCreditDiv credibtntab chflex chflexcenter" style={{marginTop:"-10px"}}>
            {sidebarCreditRedirect  || !userData.isFreeTrialUser ?
              (
                <>
                  {isCollapsed ? "" :
                  <>
                  <Link to={SIDEBAR_ROUTE.PLAN_BILLING.URL}>
                    <span className="sidebarCreditSpan" onClick={() => tabTitleChange()}>
                      <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                        <InfoCircleFilled className='creditsInfoIcon' /> 
                      </Tooltip>
                      <span className="currentCreditValue"> {(currentCredit > 0) ? sidebarCredit : '$0.00'}</span>
                    </span>
                  </Link>
                   <Tooltip title="Refresh">
                      <div className="refreshwrapper  chflex chflexcenter justcenter" onClick={()=> checkCredits()}><div className="refreshbtn"></div></div>
                   </Tooltip>
                   </>
                  }
                </>
              )
              
            :
              <>
                <span className="sidebarCreditSpan">
                  <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                    <InfoCircleFilled className='creditsInfoIcon' /> 
                  </Tooltip>
                  <span className="currentCreditValue"> {(currentCredit > 0) ? sidebarCredit : '$0.00'}</span>
                </span>
                <Tooltip title="Refresh">
                   <div className="refreshwrapper  chflex chflexcenter justcenter" onClick={()=> checkCredits()}><div className="refreshbtn"></div></div>
                </Tooltip>
              </>
            }
            </div>}
        </Sider>
      </SidebarWrapper>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    numbers:state.number.get_number,
    user_billing: state.number.user_billing,
    //get_plan: state.planReducer.get_plan,
    pusherData: state.dashboard.userData,
  }
}
export default connect(mapStateToProps,{getCredits})(withRouter(SidebarCt));