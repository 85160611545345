import didGroups from './actions';
import { bindActionCreators } from 'redux';
const initState = {
  get_didGroups: [],
  common_number_list: [],
  add_did_group: [],
  edit_did_group: [],
  update_did_group: [],
  loading: true,
}
export default function didGroupsReducer(state = initState, action) {
  switch (action.type) {
    case didGroups.GET_DID_GROUPS:
      return {
        ...state,
        get_didGroups: action.payload.data,
        loading: false
      };
    case didGroups.ADD_DID_GROUP:
      return {
        ...state,
        add_did_group: action.payload,
        loading: false
      };
    case didGroups.EDIT_DID_GROUP:
      return {
        ...state,
        edit_did_group: action.payload.data,
        loading: false
      };
    case didGroups.UPDATE_DID_GROUP:
      let finalList;
      if (action.payload.success && state.get_didGroups.length !== 0) {
        finalList = state.get_didGroups;
        let data = {
          _id: action.payload.data._id,
          name: action.payload.data.name,
          numberList: action.payload.data.numberList,
        };
        finalList.splice(action.staticPayload.updateIndex, 1, data);
      } else {
        finalList = action.payload
      }
      return {
        ...state,
        update_did_group: action.payload,
        loading: false
      };
      case didGroups.DELETE_DID_GROUP:
        if (action.payload.success) {
          let get_didGroups = state.get_didGroups;
          get_didGroups.splice(action.staticPayload, 1);
          return {
            ...state,
            get_didGroups: get_didGroups
          };
        }
    case didGroups.COMMON_NUMBER_LIST:
      return {
        ...state,
        common_number_list: action.payload.data,
      };
    default:
      return state;
  }
}

