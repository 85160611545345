import React, { useState, useEffect } from 'react';
import Modals from '@iso/components/Feedback/Modal';
import addonwrapper from './addOnpopup.style';
import {  Button ,Row,Col,Card,Checkbox } from 'antd';
import modalActions from '@iso/redux/modal/actions';
import planSetting from '@iso/redux/plan/actions';
import users from '../../redux/users/actions';
import message from '@iso/components/Feedback/Message';
import {useDispatch, useSelector} from "react-redux";
import OurCompliances from '../OurCompliances/ourCompliances'; 
import authantication from "@iso/redux/authentication/action";
import _ from "lodash";
import Currency from "../../constants";

const { updateAddons } = planSetting;
const { closeModal,openModal } = modalActions;
const { changeUserData } = authantication;
const {
    addUserEstimate
} = users;
const Modal = addonwrapper(Modals);
const successMsg = msg => { message.destroy(); message.success(msg) };
const errorMsg = msg => { message.destroy(); message.error(msg) };
let disableAddAddonBtn = false;
const Addonpopup = () => {
    const [purchaseDisable, setPurchaseDisable] = useState(false);
    const [addons, setAddons] = useState([]);
    const [userAddonFeatures, setUserAddonFeatures] = useState([]);
    const [modalPropsData, setModalPropsData] = useState();
    const [description, setDescription] = useState('');
    const [addonConfirmationPopUp,  setAddonConfirmationPopUp] = useState(false);
    const [freeTrialAddOnPopup,  setFreeTrialAddOnPopup] = useState(false);
    const [prorateAmount,  setProrateAmount] = useState(0);
    const [planAmount, setPlanAmount] = useState(0);
    const dispatch = useDispatch();
    const { modalVisibility, modalType, modalProps } = useSelector(state => state.modal);
    const chargbeeData = useSelector(state => state.number.chargbee_data);

    const userData = JSON.parse(localStorage.getItem('userData'));
    if(!userData){
        window.location.href ="/login"
    }else{
        userData.selectedCurrency = userData.selectedCurrency ? userData.selectedCurrency : "USD"
        userData.currencySign = userData.currencySign ? userData.currencySign : "$"
    }

    useEffect(() => {
        /* if(modalVisibility && window.env.REACT_APP_IS_DIALERHQ){
            message.destroy();
            return message.info(`To purchase addon please contact ${window.env.REACT_APP_SUPPORT_MAIL} .`);
        } */
        if(modalProps.addons){
            let addonArr = [];
            modalProps.addons.forEach(function(item,i){
                if(item.addonName.startsWith(modalProps.addonFeature)){
                    modalProps.addons.splice(i, 1);
                    modalProps.addons.unshift(item);
                    setDescription(item.description)
                    setPurchaseDisable(false)
                }
            });
            modalProps.addons.forEach(function(item,i){
                if(item.addonName.startsWith(modalProps.addonFeature)){
                    item.newAddonSelected =  true;
                    item.removeAddon = false;
                    item.isChecked = true;
                }else{
                    item.isChecked = false;
                    item.newAddonSelected =  false;
                }
                let userPurchasedAddon = _.map(modalProps.userAddonFeatures, '_id')
                if(modalProps.addonFeature == "callTracking" || modalProps.addonFeature == "numberMasking"){
                     userPurchasedAddon = modalProps.userAddonFeatures
                }
                if(userPurchasedAddon.indexOf(item._id) >= 0){
                    item.purchased = true;

                }
                console.log("userData.selectedCurrency",userData.selectedCurrency)
                item.amount = userData && userData.selectedCurrency != "USD" ? item.amountCurrency[userData.selectedCurrency] : item.amount
                addonArr.push((item))
            });
            setAddons(addonArr)
            setModalPropsData(modalProps)
        }
    }, [modalProps])

    const handleCancel = () => {
        dispatch(closeModal());
        modalPropsData.addonUpgradeCallBack(false)
    };
    const handleSelected = (e,data) => {
        e.preventDefault();
        if(document.querySelector(".addoncheckedborder")){
            document.querySelector(".addoncheckedborder").classList.remove("addoncheckedborder")
        }
        e.currentTarget.closest(".addoncard").classList.add("addoncheckedborder")
        document.querySelector(".addondescbox").classList.remove("showaddondesc")
        setDescription(data.description)
        let addonArr = [];
        _.forEach(modalProps.addons, function (addon) {
            if (addon._id === data._id) {
                addon.newAddonSelected = !addon.newAddonSelected ? true : false;
                addon.removeAddon = addon.newAddonSelected ? true : false;
                addon.isChecked = !addon.isChecked;
            }
            addonArr.push(addon);
        });

        setAddons(addonArr)
        setPurchaseDisable(addonArr.filter((data)=> data.isChecked).length == 0)
    }
    //     var att = e.currentTarget.getAttribute("data-attribute")
    //     var ele = document.getElementById(att);
    //    if(ele.classList.contains("showaddondesc")){
    //        ele.classList.remove("showaddondesc")
    //        if(document.querySelector(".addoncheckedborder")){
    //         document.querySelector(".addoncheckedborder").classList.remove("addoncheckedborder")
    //        }           
    //        e.currentTarget.closest(".addoncard").classList.add("addoncheckedborder")
    //    }else{
    //     ele.classList.add("showaddondesc")
    //     e.currentTarget.closest(".addoncard").classList.remove("addoncheckedborder")
    //    }

    const updateAddonFunc = () => {
        const newAddonData = _.filter(addons, function (o) { return o.newAddonSelected == true; });
        if(chargbeeData.data && chargbeeData.data.success){
            if(chargbeeData.data.data){
                let chargebeeDt =  chargbeeData.data.data.find((dt)=>dt.id == userData.stripeId);
                if(chargebeeDt && chargebeeDt.status == "paused"){
                    dispatch(closeModal());
                    return dispatch(openModal({modalType: 'resumeAccount', modalProps: { 'resumeReason':"addOn"},currentModalProps: { 'resumeReason':"addOn"},oldModalProps:modalProps}));
                }
              }
        }
        dispatch(addUserEstimate({
            newAddonData: newAddonData,
             type:"addon"})).then(({ payload }) => {
            if(payload.success){
                dispatch(closeModal());
                setProrateAmount(payload.data.prorateValue)
                setPlanAmount(payload.data.planAmount)
                if(userData.isFreeTrialUser){
                    setFreeTrialAddOnPopup(true)
                } else if(payload.data.prorateValue <= 0){
                    updateAddonFuncConfirm();
                }else{
                    setAddonConfirmationPopUp(true);
                }
            }else{
                message.destroy();
                message.error(payload.error ? payload.error.error : "Something Went wrong")
                dispatch(closeModal());
                modalPropsData.addonUpgradeCallBack(false)
                return false;
            }
        })
    }
    const updateAddonFuncConfirm = () => {
        disableAddAddonBtn = true;
        if(addons && addons.length > 0 && addons.find(add=>add.addonName.toLowerCase().includes("whatsapp"))){
            setAddonConfirmationPopUp(false)
        }
        dispatch(updateAddons(userData._id, addons)).then(({ payload }) => {
            console.log("addon pop eitimate==========",payload)
            dispatch(closeModal());
            if (payload.success && payload.data) {
                let userData = JSON.parse(localStorage.getItem('userData'));
                userData.userAddonFeatures = payload.data.userAddonFeature;
                localStorage.setItem("userData", JSON.stringify(userData));   
                dispatch(changeUserData({data: { userAddonFeatures : payload.data.userAddonFeature }, "type":"changeAuth"}))
                var newAddonData = _.find(addons, function (o) { return o.addonPurchase == true; });
                if(modalPropsData.addonFeature == "usA2PMessagingService" && !payload.data.userAddonFeature.find((element)=>element.addonName.startsWith("usA2PMessagingService"))){
                    disableAddAddonBtn = false 
                    errorMsg( "US A2P messaging service addon not added");
                    modalPropsData.addonUpgradeCallBack(false)
                    setFreeTrialAddOnPopup(false)
                    return false
                }else{
                    disableAddAddonBtn = false 
                    var successMsgData = !newAddonData ? 'Addons added successfully.' : 'Addons updated successfully.';
                }
                setAddonConfirmationPopUp(false)
                setFreeTrialAddOnPopup(false)
                if(userData.isFreeTrialUser){
                    dispatch(changeUserData({data: { isFreeTrialUser : false }, "type":"freeTrialUser"}))
                }
                //dispatch(changeUserData({plans: payload.data.user.plan}));
                successMsg(successMsgData);
                if(modalPropsData.addonUpgradeCallBack){
                    modalPropsData.addonUpgradeCallBack(modalPropsData.addonFeature)
                }else{
                    window.location.reload();
                }
                // window.location.reload();
              //  userAddonFeatures = payload.data.userAddon;
            } else {
                disableAddAddonBtn = false;
                if(modalPropsData.addonUpgradeCallBack)modalPropsData.addonUpgradeCallBack(false)
                errorMsg(payload.error && payload.error.error ? payload.error.error : "Something Went wrong");
            }
        })
    }
    const cancelConformationPopup = (data) =>{
        if(data == "Add-ons"){
            setAddonConfirmationPopUp(false)    
        }else if(data == "Warning"){
            setFreeTrialAddOnPopup(false)
        }
        modalPropsData.addonUpgradeCallBack(false)
    }
    const wpAddon = addons && addons.length && addons.map((data, index) =>!data.addonName.toLowerCase().includes("whatsapp"))
    return (
        <Row>
        <Modal
        title="Choose Addons"
        visible={modalVisibility && modalType == "addon"/*  && !window.env.REACT_APP_IS_DIALERHQ */}
        onCancel={handleCancel}
        width="850px"
        footer={[
            <div>
            <div className="hippaPciCol">
                {/* <OurCompliances />                 */}
                <Button className={`purchasenowbtn ${purchaseDisable || (modalProps.billingAccess && !modalProps.billingAccess['update']) ? 'disablePurchaseButton' : ''}`} disabled={purchaseDisable || (modalProps.billingAccess && !modalProps.billingAccess['update'])} onClick={()=>updateAddonFunc()}>Purchase Now</Button>
            </div>
          </div>
        ]}
        >
             <div className="addonwrapper">
                <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col  span={11} className="addoncol">

                        {addons && addons.length && addons.map((data, index) =>
                            ((!userData.plan.isOfficePhoneSystem || (userData.plan.isOfficePhoneSystem && !data.hideInOfficePhoneSystem)) && !data.addonName.toLowerCase().includes("customcaller")) && ((modalProps.addonFeature && modalProps.addonFeature == "whatsapp" && data.addonName.toLowerCase().includes("whatsapp")) || !data.addonName.toLowerCase().includes("whatsapp"))
                            &&
                            <Card className={`addoncard ${data.purchased ? 'addondisabledcard' : ''}`}>
                            <Checkbox className="addoncheckbox" disabled={data.purchased} checked={data.isChecked || data.purchased} onClick={(e) => handleSelected(e, data)} >
                                <div className="addondetail">
                                    <h3 className={`addontitle ${data.purchased ? 'disableaddon' : ''}`}>{data.originalName}</h3>
                                </div>
                                <div className="addonprice">
                                    <p className={`pricetext ${data.purchased ? 'disableaddon' : ''}`}>{userData.currencySign}{data.amount}</p>
                                    <p className={`perusertext ${data.purchased ? 'disableaddon' : ''}`}>{data.isRecurring && data.addonType == 'number' ? 'Number/Month': !data.isBenefitsUserWise && data.isRecurring && data.addonType == 'user' ? 'Account/Month'  : data.isBenefitsUserWise && data.isRecurring && data.addonType == 'user' ? 'User/Month' :  data.addonType == 'perrequest' ? "Per Number" : 'Per User'}</p>
                                </div>
                                <div className="addonrightarrow">
                                    <i class={`arrow right ${data.purchased ? 'disableaddonarrow' : ''}`}></i>
                                </div>
                            </Checkbox>
                        </Card>
                            )}
                           {/* <Card  className="addoncard addondisabledcard">
                            <Checkbox className="addoncheckbox" disabled  onClick={(e) => handleSelected(e)}>
                                <div className="addondetail">
                                    <h3 className="addontitle disableaddon">Voicemail Transcription</h3>                                
                                </div>
                                <div className="addonprice">
                                    <p className="pricetext disableaddon">$10</p>
                                    <p className="perusertext disableaddon">Number/Month</p>
                                </div>
                                <div className="addonrightarrow">
                                    <i class="arrow right disableaddonarrow"></i>
                                </div>
                            </Checkbox>
                        </Card>*/}
                    </Col>
                    <Col span={12}>
                        <div class="addondescbox" id="voicemail" >
                            <div className="addondesctext">Description</div> 
                            {description}
                        </div>                                                                      
                    </Col>                    
                </Row>                
             </div>
        </Modal>
        <Modal
                visible={addonConfirmationPopUp}
                title="Add-ons"
                cancelText="No"
                okText="Yes"
                onOk={() => updateAddonFuncConfirm()}
                okButtonProps={{ disabled:  disableAddAddonBtn  }}
                onCancel={()=>cancelConformationPopup("Add-ons")}
                className="exportYesNO"
                centered
            >
                <p>You will be charged {Currency[userData.selectedCurrency].sign}{prorateAmount} as per prorated charges. please confirm to process the payment.
                </p>
            </Modal>
            <Modal
              title="Warning"
              visible={freeTrialAddOnPopup}
              onOk={() => updateAddonFuncConfirm()}
              okText={<span className="addpopupbtn">Upgrade Now</span>}
              // confirmLoading={this.state.confirmLoading}
              onCancel={() => cancelConformationPopup("Warning")}
              className="exportYesNO addYesNo free-warning-center warningbtn"
              centered
            >
              <p>This option is not currently available in the account. If you still wish to try this feature, your account will be upgraded to the <b className="toCapitalize">{userData.plan.planDisplayName}</b> and will be charged immediately.</p>
            </Modal>
        </Row>
    )
}
export default  Addonpopup;