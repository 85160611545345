const countries = {
    AF:{
        shortName : "",
        name : "Afghanistan",
        countryCode : "93",
        value:0
    },
    AL:{
        shortName : "",
        name : "Albania",
        countryCode : "355",
        value:0
    },
    DZ:{
        shortName : "",
        name : "Algeria",
        countryCode : "213",
        value:0
    },
    AS:{
        shortName : "",
        name : "American Samoa",
        countryCode : "1684",
        value:0
    },
    AD:{
        shortName : "",
        name : "Andorra",
        countryCode : "376",
        value:0
    },
    AO:{
        shortName : "",
        name : "Angola",
        countryCode : "244",
        value:0
    },
    AI:{
        shortName : "",
        name : "Anguilla",
        countryCode : "1264"
    },
    AQ:{
        shortName : "",
        name : "Antarctica",
        countryCode : "0",
        value:0
    },

    AG:{
        shortName : "",
        name : "Antigua And Barbuda",
        countryCode : "1268",
        value:0
    },
    AR:{
        name : "Argentina",
        shortName : "",
        countryCode : "54",
        value:0
    },
    AM:{
        shortName : "",
        name : "Armenia",
        countryCode : "374",
        value:0
    },
    AW:{
        shortName : "",
        name : "Aruba",
        countryCode : "297",
        value:0
    },
    AU:{
               
        name : "Australia",
        
        countryCode : "61",
        value:0
    },
    AT:{
        name : "Austria",
        countryCode : 43,
        value:0      
},

AZ:{
    shortName : "",
    name : "Azerbaijan",
    countryCode : "994",
    value:0
},

BS:{
    shortName : "",
    name : "Bahamas The",
    countryCode : "1242",
    value:0
},

BH:{
               
    name : "Bahrain",
    countryCode : 973.0,
    shortName : "",
    value:0
},
BD:{
    shortName : "",
    name : "Bangladesh",
    countryCode : "880",
    value:0
},

BB:{
    shortName : "",
    name : "Barbados",
    countryCode : "1246",
    value:0
},
BY:{
    shortName : "",
    name : "Belarus",
    countryCode : "375",
        value:0
},
BE:{
    name : "Belgium",
    shortName : "",
    countryCode : "32",
        value:0
},
BZ:{
    shortName : "",
    name : "Belize",
    countryCode : "501",
        value:0
},
BJ:{
    shortName : "",
    name : "Benin",
    countryCode : "229",
        value:0
},
BM:{
    shortName : "",
    name : "Bermuda",
    countryCode : "1441",
        value:0
},
BT:{
    shortName : "",
    name : "Bhutan",
    countryCode : "975",
        value:0
},
BO:{
    shortName : "",
    name : "Bolivia",
    countryCode : "591",
        value:0
},
BA:{
    shortName : "",
    name : "Bosnia and Herzegovina",
    countryCode : "387",
        value:0
},
BW:{
    shortName : "",
    name : "Botswana",
    countryCode : "267",
        value:0
},
BV:{
    shortName : "",
    name : "Bouvet Island",
    countryCode : "0",
        value:0
},
BR:{
               
    name : "Brazil",
    shortName : "",
    countryCode : "55",
        value:0
},
IO:{
    shortName : "",
    name : "British Indian Ocean Territory",
    countryCode : "246",
        value:0
},
BN:{
    shortName : "",
    name : "Brunei",
    countryCode : "673",
        value:0
},
BG:{
               
    name : "Bulgaria",
    shortName : "",
    countryCode : "359",
        value:0
},
BF:{
    shortName : "",
    name : "Burkina Faso",
    countryCode : "226",
        value:0
},
BI:{
    shortName : "",
    name : "Burundi",
    countryCode : "257",
        value:0
},
KH:{
    shortName : "",
    name : "Cambodia",
    countryCode : "855",
        value:0
},
CM:{
    shortName : "",
    name : "Cameroon",
    countryCode : "237",
        value:0
},
CA:{
    name : "Canada",
    shortName : "",
    countryCode : "1",
        value:0
},
CV:{
    shortName : "",
    name : "Cape Verde",
    countryCode : "238",
        value:0
},
KY:{
    shortName : "",
    name : "Cayman Islands",
    countryCode : "1345",
        value:0
},
CF:{
    shortName : "",
    name : "Central African Republic",
    countryCode : "236",
        value:0
},
TD:{
    shortName : "",
    name : "Chad",
    countryCode : "235",
        value:0
},
CL:{
    name : "Chile",
    shortName : "",
    countryCode : "56",
    value:0
},
CN:{
    name : "China",
    countryCode : 86.0,
    value:0
    
},
CX:{
    shortName : "",
    name : "Christmas Island",
    countryCode : "61",
    value:0
},
CC:{
    shortName : "",
    name : "Cocos (Keeling) Islands",
    countryCode : "672",
    value:0
},
CO:{

    name : "Colombia",
    countryCode : 57.0,
    value:0
    
    
},
                         
KM:{
    shortName : "",
    name : "Comoros",
    countryCode : "269",
    value:0
},

CD:{
    shortName : "",
    name : "Congo The Democratic Republic Of The",
     countryCode : "242",
    value:0
},
CG:{
    shortName : "",
    name : "Congo",
    countryCode : "242",
    value:0
},
CK:{
    shortName : "",
    name : "Cook Islands",
    countryCode : "682",
    value:0
},
CR:{
    shortName : "",
    name : "Costa Rica",
    countryCode : "506",
    value:0
},
CI:{
    shortName : "",
    name : "Cote D Ivoire (Ivory Coast)",
    countryCode : "225",
    value:0
},
HR:{
               
    name : "Croatia",
    countryCode : 385.0,
    shortName : "",
    value:0
},
CU:{
    shortName : "",
    name : "Cuba",
    countryCode : "53",
    value:0
},
CY:{
    name : "Cyprus",
    shortName : "",
    countryCode : "357",
    value:0
},
CZ:{

    name : "Czech Republic",
    shortName : "",
    countryCode : "429",
    value:0
},
DK:{
    name : "Denmark",
    shortName : "",
    countryCode : "45",
    value:0
},
DJ:{
    shortName : "",
    name : "Djibouti",
    countryCode : "253",
    value:0
},
DM:{
    shortName : "",
    name : "Dominica",
    countryCode : "1767",
    value:0
},
DO:{
               
    name : "Dominican Republic",
    countryCode : "1",
    value:0
},
TP:{
    shortName : "",
    name : "East Timor",
    countryCode : "670",
    value:0
},
EC:{
    shortName : "",
    name : "Ecuador",
    countryCode : "593",
    value:0
},
EG:{
    shortName : "",
    name : "Egypt",
    countryCode : "20",
    value:0
},
SV:{
    name : "El Salvador",
    shortName : "",
    countryCode : "503",
    value:0
},
GQ:{
    shortName : "",
    name : "Equatorial Guinea",
    countryCode : "240",
    value:0
},
ER:{
    shortName : "",
    name : "Eritrea",
    countryCode : "291",
    value:0
},
EE:{
    name : "Estonia",
    shortName : "",
    countryCode : "372",
    value:0
},
ET:{
    shortName : "",
    name : "Ethiopia",
    countryCode : "251",
    value:0
},
XA:{
    shortName : "",
    name : "External Territories of Australia",
    countryCode : "61",
    value:0
},
FK:{
    shortName : "",
    name : "Falkland Islands",
    countryCode : "500",
    value:0
},
FO:{
    shortName : "",
    name : "Faroe Islands",
    countryCode : "298",
    value:0
},
FJ:{
    shortName : "",
    name : "Fiji Islands",
    countryCode : "679",
    value:0
},
FI:{
               
    name : "Finland",
    shortName : "",
    countryCode : "358",
    value:0
},
FR:{
    name : "France",
    shortName : "",
    countryCode : "33",
    value:0
},
GF:{
    shortName : "",
    name : "French Guiana",
    countryCode : "594",
    value:0
},
PF:{
    shortName : "",
    name : "French Polynesia",
    countryCode : "689",
    value:0
},
TF:{
    shortName : "",
    name : "French Southern Territories",
    countryCode : "0",
    value:0
},
GA:{
    shortName : "",
    name : "Gabon",
    countryCode : "241",
    value:0
},
GM:{
    shortName : "",
    name : "Gambia The",
    countryCode : "220",
    value:0
},
GE:{
               
    name : "Georgia",
    countryCode : 995.0,
    shortName : "",
    value:0
},
DE:{
    name : "Germany",
    countryCode : 49,
    value:0
    
    
},
GH:{
    shortName : "",
    name : "Ghana",
    countryCode : "233",
    value:0
},

/* 122 */
GI:{
    shortName : "",
    name : "Gibraltar",
    countryCode : "350",
    value:0
},
GR:{
    name : "Greece",
    countryCode : 30,
    value:0
                              
                              
},
GL:{
    shortName : "",
    name : "Greenland",
    countryCode : "299",
    value:0
},

/* 124 */
GD:{
    shortName : "",
    name : "Grenada",
    countryCode : "1473",
    value:0
},

/* 125 */
GP:{
    shortName : "",
    name : "Guadeloupe",
    countryCode : "590",
    value:0
},

/* 126 */
GU:{
    shortName : "",
    name : "Guam",
    countryCode : "1671",
    value:0
},

/* 127 */
GT:{
    shortName : "",
    name : "Guatemala",
    countryCode : "502",
    value:0
},

/* 128 */
XU:{
    shortName : "",
    name : "Guernsey and Alderney",
    countryCode : "44",
    value:0
},

GW:{
    shortName : "",
    name : "Guinea-Bissau",
    countryCode : "245",
    value:0
},

GN:{
    shortName : "",
    name : "Guinea",
    countryCode : "224",
    value:0
},

GY:{

    shortName : "",
    name : "Guyana",
    countryCode : "592",
    value:0
},

HT:{
    shortName : "",
    name : "Haiti",
    countryCode : "509",
    value:0
},

HM:{
    shortName : "",
    name : "Heard and McDonald Islands",
    countryCode : "0",
    value:0
},

HN:{
    shortName : "",
    name : "Honduras",
    countryCode : "504",
    value:0
},
HK:{
    name : "Hong Kong",
    shortName : "",
    countryCode : "852",
    value:0
},
HU:{
               
    name : "Hungary",
    shortName : "",
    countryCode : "36",
    value:0
},
IS:{
    shortName : "",
    name : "Iceland",
    countryCode : "354",
    value:0
},
IN:{
               
    name : "India",
    shortName : "",
    countryCode : "91",
    value:0
},
ID:{
               
    name : "Indonesia",
    shortName : "",
    countryCode : "62",
    value:0
},
IR:{
    shortName : "",
    name : "Iran",
    countryCode : "98",
    value:0
},
IQ:{
    shortName : "",
    name : "Iraq",
    countryCode : "964",
    value:0
},
IE:{
               
    name : "Ireland",
    shortName : "",
    countryCode : "353",
    value:0
},
IL:{
               
    name : "Israel",
    shortName : "",
    countryCode : "972",
    value:0
},
IT:    {
               
    name : "Italy",
    shortName : "",
    countryCode : "39",
    value:0
},
JM:{
  
    shortName : "",
    name : "Jamaica",
    countryCode : "1876",
    value:0
},
JP:{
    name : "Japan",
    shortName : "",
    countryCode : "81",
    value:0
},
XJ:{

    shortName : "",
    name : "Jersey",
    countryCode : "44",
    value:0
},
JO:{
    shortName : "",
    name : "Jordan",
    countryCode : "962",
    value:0
},
KZ:{
    shortName : "",
    name : "Kazakhstan",
    countryCode : "7",
    value:0
},

/* 142 */
KE:{
    shortName : "",
    name : "Kenya",
    countryCode : "254",
    value:0
},

/* 143 */
KI:{
    shortName : "",
    name : "Kiribati",
    countryCode : "686",
    value:0
},

/* 144 */
KP:{
    shortName : "",
    name : "Korea North",
    countryCode : "850",
    value:0
},

/* 145 */
KW:{
    shortName : "",
    name : "Kuwait",
    countryCode : "965",
    value:0
},

/* 146 */
KG:{
    shortName : "",
    name : "Kyrgyzstan",
    countryCode : "996",
    value:0
},

/* 147 */
LA:{
    shortName : "",
    name : "Laos",
    countryCode : "856",
    value:0
},

LV:{
    name : "Latvia",
    shortName : "",
    countryCode : "371",
    value:0
},

/* 148 */
LB:{

    shortName : "",
    name : "Lebanon",
    countryCode : "961",
    value:0
},

/* 149 */
LS:{

    shortName : "",
    name : "Lesotho",
    countryCode : "266",
    value:0
},

/* 150 */
LR:{

    shortName : "",
    name : "Liberia",
    countryCode : "231",
    value:0
},

/* 151 */
LY:{

    shortName : "",
    name : "Libya",
    countryCode : "218",
    value:0
},

/* 152 */
LI:{

    shortName : "",
    name : "Liechtenstein",
    countryCode : "423",
    value:0
},
LT:{
               
    name : "Lithuania",
    shortName : "",
    countryCode : "370",
    value:0
},
LU:{
    name : "Luxembourg",
    shortName : "",
    countryCode : "352",
    value:0
},
MO:{

    shortName : "",
    name : "Macau S.A.R.",
    countryCode : "853",
    value:0
},

/* 154 */
MK:{

    shortName : "",
    name : "Macedonia",
    countryCode : "389",
    value:0
},

/* 155 */
MG:{

    shortName : "",
    name : "Madagascar",
    countryCode : "261",
    value:0
},

/* 156 */
MW:{

    shortName : "",
    name : "Malawi",
    countryCode : "265",
    value:0
},

/* 157 */
MV:{

    shortName : "",
    name : "Maldives",
    countryCode : "960",
    value:0
},

/* 158 */
ML:{

    shortName : "",
    name : "Mali",
    countryCode : "223",
    value:0
},

MT:{
    name : "Malta",
    shortName : "",
    countryCode : "356",
    value:0
},

/* 159 */
XM:{

    shortName : "",
    name : "Man (Isle of)",
    countryCode : "44",
    value:0
},

/* 160 */
MH:{

    shortName : "",
    name : "Marshall Islands",
    countryCode : "692",
    value:0
},

/* 161 */
MQ:{

    shortName : "",
    name : "Martinique",
    countryCode : "596",
    value:0
},

/* 162 */
MR:{

    shortName : "",
    name : "Mauritania",
    countryCode : "222",
    value:0
},

/* 163 */
MU:{

    shortName : "",
    name : "Mauritius",
    countryCode : "230",
    value:0
},

/* 164 */
YT:{

    shortName : "",
    name : "Mayotte",
    countryCode : "269",
    value:0
},

/* 165 */
FM:{

    shortName : "",
    name : "Micronesia",
    countryCode : "691",
    value:0
},

/* 166 */
MD:{
    shortName : "",
    name : "Moldova",
    countryCode : "373",
    value:0
},

/* 167 */
MC:{

    shortName : "",
    name : "Monaco",
    countryCode : "377",
    value:0
},

/* 168 */
MN:{

    shortName : "",
    name : "Mongolia",
    countryCode : "976",
    value:0
},

/* 169 */
MS:{

    shortName : "",
    name : "Montserrat",
    countryCode : "1664",
    value:0
},

/* 170 */
MA:{

    shortName : "",
    name : "Morocco",
    countryCode : "212",
    value:0
},

/* 171 */
MZ:{

    shortName : "",
    name : "Mozambique",
    countryCode : "258",
    value:0
},

/* 172 */
MM:{

    shortName : "",
    name : "Myanmar",
    countryCode : "95",
    value:0
},

MY:{
               
    name : "Malaysia",
    countryCode : 60.0,
    value:0
},

MX:{
    name : "Mexico",
    shortName : "",
    countryCode : "52",
    value:0
},
NA:{

    shortName : "",
    name : "Namibia",
    countryCode : "264",
    value:0
},

/* 174 */
NR:{

    shortName : "",
    name : "Nauru",
    countryCode : "674",
    value:0
},

/* 175 */
NP:{

    shortName : "",
    name : "Nepal",
    countryCode : "977",
    value:0
},

AN:{

    shortName : "",
    name : "Netherlands Antilles",
    countryCode : "599",
    value:0
},
NL:{
    name : "Netherlands",
    shortName : "",
    countryCode : "31",
    value:0
},
NC:{

    shortName : "",
    name : "New Caledonia",
    countryCode : "687",
    value:0
},
NZ:{
    name : "New Zealand",
    shortName : "",
    countryCode : "64",
    value:0
},
NI:{

    shortName : "",
    name : "Nicaragua",
    countryCode : "505",
    value:0
},
NE:{

    shortName : "",
    name : "Niger",
    countryCode : "227",
    value:0
},

/* 180 */
NG:{

    shortName : "",
    name : "Nigeria",
    countryCode : "234",
    value:0
},

/* 181 */
NU:{

    shortName : "",
    name : "Niue",
    countryCode : "683",
    value:0
},

/* 182 */
NF:{

    shortName : "",
    name : "Norfolk Island",
    countryCode : "672",
    value:0
},

/* 183 */
MP:{

    shortName : "",
    name : "Northern Mariana Islands",
    countryCode : "1670",
    value:0
},

NO:{
               
    name : "Norway",
    shortName : "",
    countryCode : "47",
    value:0
},
OM:{

    shortName : "",
    name : "Oman",
    countryCode : "968",
    value:0
},

/* 185 */
PK:{

    shortName : "",
    name : "Pakistan",
    countryCode : "92",
    value:0
},

/* 186 */
PW:{

    shortName : "",
    name : "Palau",
    countryCode : "680",
    value:0
},

/* 187 */
PS:{

    shortName : "",
    name : "Palestinian Territory Occupied",
    countryCode : "970",
    value:0
},
PA:{
    name : "Panama",
    countryCode : 507.0,
    shortName : "",
    value:0
},
PG:{

    shortName : "",
    name : "Papua new Guinea",
    countryCode : "675",
    value:0
},
PY:{

    shortName : "",
    name : "Paraguay",
    countryCode : "595",
    value:0
},
PE:{
    name : "Peru",
    shortName : "",
    countryCode : "51",
    value:0
},
PH:{
    name : "Philippines",
    shortName : "",
    countryCode : "63",
    value:0
},
PN:{

    shortName : "",
    name : "Pitcairn Island",
    countryCode : "0",
    value:0
},
PR:{
               
    name : "Puerto Rico",
    countryCode : "1",
    value:0
},

PL:{

    name : "Poland",
    countryCode : "48",
    value:0
},

PT:{
    name : "Portugal",
    shortName : "",
    countryCode : "351",
    value:0
},

QA:{

    shortName : "",
    name : "Qatar",
    countryCode : "974",
    value:0
},

/* 192 */
RE:{

    shortName : "",
    name : "Reunion",
    countryCode : "262",
    value:0
},

/* 193 */
RW:{

    shortName : "",
    name : "Rwanda",
    countryCode : "250",
    value:0
},
                          
RO:{
    name : "Romania",
    countryCode : 40,
    value:0
    
},
RU:{
    name : "Russia",
    shortName : "",
    countryCode : "7",
    value:0
},
SH:{

    shortName : "",
    name : "Saint Helena",
    countryCode : "290",
    value:0
},

/* 195 */
KN:{

    shortName : "",
    name : "Saint Kitts And Nevis",
    countryCode : "1869",
    value:0
},

/* 196 */
LC:{

    shortName : "",
    name : "Saint Lucia",
    countryCode : "1758",
    value:0
},

/* 197 */
PM:{

    shortName : "",
    name : "Saint Pierre and Miquelon",
    countryCode : "508",
    value:0
},

/* 198 */
VC:{

    shortName : "",
    name : "Saint Vincent And The Grenadines",
    countryCode : "1784",
    value:0
},

/* 199 */
WS:{

    shortName : "",
    name : "Samoa",
    countryCode : "684",
    value:0
},

/* 200 */
SM:{

    shortName : "",
    name : "San Marino",
    countryCode : "378",
    value:0
},

/* 201 */
ST:{

    shortName : "",
    name : "Sao Tome and Principe",
    countryCode : "239",
    value:0
},

/* 202 */
SA:{

    shortName : "",
    name : "Saudi Arabia",
    countryCode : "966",
    value:0
},

/* 203 */
SN:{

    shortName : "",
    name : "Senegal",
    countryCode : "221",
    value:0
},

/* 204 */
RS:{

    shortName : "",
    name : "Serbia",
    countryCode : "381",
    value:0
},

/* 205 */
SC:{

    shortName : "",
    name : "Seychelles",
    countryCode : "248",
    value:0
},

/* 206 */
SL:{

    shortName : "",
    name : "Sierra Leone",
    countryCode : "232",
    value:0
},
SG:{
               
    name : "Singapore",
    shortName : "",
    countryCode : "65",
    value:0
},


SI:{

    name : "Slovenia",
    countryCode : 386.0,
    shortName : "",
    value:0
},

SK:        {
    name : "Slovakia",
    shortName : "",
    countryCode : "421",
    value:0
},
XG:{

    shortName : "",
    name : "Smaller Territories of the UK",
    countryCode : "44",
    value:0
},
SB:{

    shortName : "",
    name : "Solomon Islands",
    countryCode : "677",
    value:0
},

/* 209 */
SO:{

    shortName : "",
    name : "Somalia",
    countryCode : "252",
    value:0
},
ZA:{
    name : "South Africa",
    shortName : "",
    countryCode : "27",
    value:0
},
GS:{

    shortName : "",
    name : "South Georgia",
    countryCode : "0",
    value:0
},

KR:{
               
    name : "South Korea",
    countryCode : 82.0,
    shortName : "",
    value:0
},
/* 211 */
SS:{

    shortName : "",
    name : "South Sudan",
    countryCode : "211",
    value:0
},

ES:{
 
    name : "Spain",
    countryCode : 34,
    value:0
    
},
LK:{

    shortName : "",
    name : "Sri Lanka",
    countryCode : "94",
    value:0
},

/* 213 */
SD:{

    shortName : "",
    name : "Sudan",
    countryCode : "249",
    value:0
},

/* 214 */
SR:{

    shortName : "",
    name : "Suriname",
    countryCode : "597",
    value:0
},

/* 215 */
SJ:{

    shortName : "",
    name : "Svalbard And Jan Mayen Islands",
    countryCode : "47",
    value:0
},

/* 216 */
SZ:{

    shortName : "",
    name : "Swaziland",
    countryCode : "268",
    value:0
},
SE:{
    name : "Sweden",
    shortName : "",
    countryCode : "46",
    value:0
},

CH:{
    name : "Switzerland",
    shortName : "",
    countryCode : "41",
    value:0
},

SY:{

    shortName : "",
    name : "Syria",
    countryCode : "963",
    value:0
},
TW:{

    shortName : "",
    name : "Taiwan",
    countryCode : "886",
    value:0
},

/* 219 */
TJ:{

    shortName : "",
    name : "Tajikistan",
    countryCode : "992",
    value:0
},

/* 220 */
TZ:{
    shortName : "",
    name : "Tanzania",
    countryCode : "255",
    value:0
},

/* 221 */
TH:{

    shortName : "",
    name : "Thailand",
    countryCode : "66",
    value:0
},

/* 222 */
TG:{

    shortName : "",
    name : "Togo",
    countryCode : "228",
    value:0
},

/* 223 */
TK:{

    shortName : "",
    name : "Tokelau",
    countryCode : "690",
    value:0
},

/* 224 */
TO:{

    shortName : "",
    name : "Tonga",
    countryCode : "676",
    value:0
},

/* 225 */
TT:{

    shortName : "",
    name : "Trinidad And Tobago",
    countryCode : "1868",
    value:0
},

/* 226 */
TN:{

    shortName : "",
    name : "Tunisia",
    countryCode : "216",
    value:0
},

TR:{
               
    name : "Turkey",
    countryCode : "90",
    value:0
},

/* 227 */
TM:{

    shortName : "",
    name : "Turkmenistan",
    countryCode : "7370",
    value:0
},

/* 228 */
TC:{

    shortName : "",
    name : "Turks And Caicos Islands",
    countryCode : "1649",
    value:0
},

/* 229 */
TV:{

    shortName : "",
    name : "Tuvalu",
    countryCode : "688",
    value:0
},

/* 230 */
UG:{

    shortName : "",
    name : "Uganda",
    countryCode : "256",
    value:0
},

/* 231 */
UA:{

    shortName : "",
    name : "Ukraine",
    countryCode : "380",
    value:0
},

/* 232 */
AE:{

    shortName : "",
    name : "United Arab Emirates",
    countryCode : "971",
    value:0
},
GB:{
    name : "United Kingdom",
    shortName : "",
    countryCode : "44",
    value:0
},
UM:{

    shortName : "",
    name : "United States Minor Outlying Islands",
    countryCode : "1",
    value:0
},
US:{
    name : "United States",
    shortName : "",
    countryCode : "1",
    value:0
},
UY:{

    shortName : "",
    name : "Uruguay",
    countryCode : "598",
    value:0
},

/* 235 */
UZ:{

    shortName : "",
    name : "Uzbekistan",
    countryCode : "998",
    value:0
},

/* 236 */
VU:{

    shortName : "",
    name : "Vanuatu",
    countryCode : "678",
    value:0
},

/* 237 */
VA:{

    shortName : "",
    name : "Vatican City State (Holy See)",
    countryCode : "39",
    value:0
},

/* 238 */
VE:{

    shortName : "",
    name : "Venezuela",
    countryCode : "58",
    value:0
},

/* 239 */
VN:{

    shortName : "",
    name : "Vietnam",
    countryCode : "84",
    value:0
},

/* 240 */
VG:{

    shortName : "",
    name : "Virgin Islands (British)",
    countryCode : "1284",
    value:0
},

/* 241 */
VI:{

    shortName : "",
    name : "Virgin Islands (US)",
    countryCode : "1340",
    value:0
},

/* 242 */
WF:{

    shortName : "",
    name : "Wallis And Futuna Islands",
    countryCode : "681",
    value:0
},

/* 243 */
EH:{

    shortName : "",
    name : "Western Sahara",
    countryCode : "212",
    value:0
},

/* 244 */
YE:{

    shortName : "",
    name : "Yemen",
    countryCode : "967",
    value:0
},

/* 245 */
YU:{

    shortName : "",
    name : "Yugoslavia",
    countryCode : "38",
    value:0
},

/* 246 */
ZM:{

    shortName : "",
    name : "Zambia",
    countryCode : "260",
    value:0
},

/* 247 */
ZW:{

    shortName : "",
    name : "Zimbabwe",
    value:0,
    countryCode : "263"
},
                     

}                 
                          
export default countries;