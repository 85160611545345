import { parse as tldts } from 'tldts';
const commonEnv = {
    ...process.env,
    "REACT_APP_DOMAIN": tldts(window.location.hostname).domain,
    "REACT_APP_PAGETITLE": "CallHippo",
    "REACT_APP_LOGO": "https://ch-badges.s3.ca-central-1.amazonaws.com/callhippo_logo.png",
    "REACT_APP_LOGO_ALT": "CallHippo",
    "REACT_APP_FAVICON": "https://d1x9dsge91xf6g.cloudfront.net/webapp/images/callhippo-favicon.png",
    "REACT_APP_WEBSITE_URL": "https://callhippo.com/",
    "REACT_APP_MS_CLARITY": "grf1wvowre",
}

const whiteLabelEnvLive = {};

whiteLabelEnvLive["localhost"] = {
    ...commonEnv,
    "REACT_APP_IS_CALLHIPPO": true,
};

whiteLabelEnvLive["web.callhippo.com"] = {
    ...commonEnv,
    "REACT_APP_IS_CALLHIPPO": true,
    "REACT_APP_IS_CRISPCHAT_ENABLED": true,
    "REACT_APP_IS_CALLMEWIDGET_ENABLED": true,
};

whiteLabelEnvLive["web.waaotalk.com"] = {
    ...commonEnv,
    "REACT_APP_PAGETITLE": "Waaotalk",
    "REACT_APP_IS_WHITE_LABEL": true,
    "REACT_APP_WEBSITE_URL": "https://www.waaotalk.com/",
    "REACT_APP_BASE_URL": "https://web.waaotalk.com/",
    "REACT_APP_DIALER_URL": "https://dialer.waaotalk.com/",
    "REACT_APP_REPORTS_URL": "https://web.waaotalk.com",
    "REACT_APP_BASE_URL_V2": "https://web.waaotalk.com/",
    "REACT_APP_BASE_URL_V3": "https://web.waaotalk.com/",
    "REACT_APP_FRONT_URL": "https://web.waaotalk.com/",
    "REACT_APP_PHONE_URL": "https://dialer.waaotalk.com/",
    "REACT_APP_SERVER_URL": "https://web.waaotalk.com/",
    "PUBLIC_URL": "https://web.waaotalk.com/",
    "REACT_APP_CALL_URL": "https://dialer.waaotalk.com/",
    "REACT_APP_WHATSAPP_URL": "https://whatsapp.waaotalk.com",
    "REACT_APP_SUPPORT_MAIL": "support@waaotalk.com",
    "REACT_APP_LOGO": "https://callhippo-dev-copy.s3.amazonaws.com/cropped-WhatsApp-Image-2021-03-06-at-6.31.23-AM.jpeg",
    "REACT_APP_LOGO_ALT": "Waaotalk",
    "REACT_APP_FAVICON": "https://callhippo-dev-copy.s3.amazonaws.com/222.jpeg",
    "REACT_APP_REDIRECT_URL": "https://web.waaotalk.com/",
};

whiteLabelEnvLive["web.buysecondnumber.com"] = {
    ...commonEnv,
    "REACT_APP_IS_BUYSECONDNUMBER": true,
    "REACT_APP_IS_WHITE_LABEL": true,
    "REACT_APP_PAGETITLE": "2nd Number",
    "PUBLIC_URL": "https://web.buysecondnumber.com/",
    "REACT_APP_WEBSITE_URL": "https://buysecondnumber.com/",
    "REACT_APP_BASE_URL": "https://web.buysecondnumber.com/",
    "REACT_APP_BASE_URL_V2": "https://web.buysecondnumber.com/",
    "REACT_APP_BASE_URL_V3": "https://web.buysecondnumber.com/",
    "REACT_APP_REPORTS_URL": "https://web.buysecondnumber.com",
    "REACT_APP_FRONT_URL": "https://web.buysecondnumber.com/",
    "REACT_APP_SERVER_URL": "https://web.buysecondnumber.com/",
    "REACT_APP_REDIRECT_URL": "https://web.buysecondnumber.com/",
    "REACT_APP_DIALER_URL": "https://dialer.buysecondnumber.com/",
    "REACT_APP_PHONE_URL": "https://dialer.buysecondnumber.com/",
    "REACT_APP_CALL_URL": "https://dialer-api.buysecondnumber.com/",
    "REACT_APP_SUPPORT_MAIL": "support@buysecondnumber.com",
    "REACT_APP_CHARGEBEE_SITE": "dialerhq",
    "REACT_APP_LOGO": "https://d1x9dsge91xf6g.cloudfront.net/webapp/images/buysecondnumber-logo.png",
    "REACT_APP_FAVICON": "https://d1x9dsge91xf6g.cloudfront.net/webapp/images/buysecondnumber-favicon.png",
    "REACT_APP_LOGO_ALT": "2nd Number",
}

whiteLabelEnvLive["web.dialerhq.com"] = {
    ...commonEnv,
    "REACT_APP_IS_DIALERHQ": true,
    "REACT_APP_IS_CRISPCHAT_ENABLED": true,
    "REACT_APP_PAGETITLE": "DialerHQ",
    "PUBLIC_URL": "https://web.dialerhq.com/",
    "REACT_APP_WEBSITE_URL": "https://dialerhq.com/",
    "REACT_APP_BASE_URL": "https://web.dialerhq.com/",
    "REACT_APP_BASE_URL_V2": "https://web.dialerhq.com/",
    "REACT_APP_BASE_URL_V3": "https://web.dialerhq.com/",
    "REACT_APP_REPORTS_URL": "https://web.dialerhq.com",
    "REACT_APP_FRONT_URL": "https://web.dialerhq.com/",
    "REACT_APP_SERVER_URL": "https://web.dialerhq.com/",
    "REACT_APP_REDIRECT_URL": "https://web.dialerhq.com/",
    "REACT_APP_DIALER_URL": "https://phone.dialerhq.com/",
    "REACT_APP_PHONE_URL": "https://phone.dialerhq.com/",
    "REACT_APP_CALL_URL": "https://phone-api.dialerhq.com/",
    "REACT_APP_SUPPORT_MAIL": "support@dialerhq.com",
    "REACT_APP_CHARGEBEE_SITE": "dialerhq",
    "REACT_APP_LOGO": "https://d1x9dsge91xf6g.cloudfront.net/webapp/images/dialerhq-logo.png",
    "REACT_APP_FAVICON": "https://d1x9dsge91xf6g.cloudfront.net/webapp/images/dialerhq-favicon.png",
    "REACT_APP_LOGO_ALT": "DialerHQ",
    "REACT_APP_CRISP_WEBSITE_ID": "2e6c4089-3b56-498e-835a-418e10f21189",
    "REACT_APP_MS_CLARITY": "i0ca9faq67",
}

export default whiteLabelEnvLive;