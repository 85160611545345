import React, { useState } from 'react';
import { Form, Input, Button, Select, Radio, Checkbox, Row, Col } from 'antd';
import Modals from '@iso/components/Feedback/Modal';
import Callhippoinfopopupwrapper from './Callhippoinfopopup.style';
import authentication from '@iso/redux/authentication/action';
import { useDispatch, useSelector } from 'react-redux';
import integrationList from "../../containers/Integrations/integrationsList";

const integrationL=integrationList; 
const imageUrl = process.env.REACT_APP_IMAGE_URL;


const { updateOnboardingDetails } = authentication;

const Modal = Callhippoinfopopupwrapper(Modals);

const Callhippoinfopopup = (props) => {

  const { getFieldDecorator } = props.form;

  const isOnBoardingProcessPending = useSelector(state => state.authentication.isOnBoardingProcessPending);
  const userData = useSelector(state => state.authentication.userData.data);
  const chlogo = window.env.REACT_APP_LOGO
  // const chlogo = imageUrl + "oasis.png";
  const dispatch = useDispatch();

  const [primaryPurpose, setPrimaryPurpose] = useState('');
  const [otherPurpose, setOtherPurpose] = useState('');
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [otherHear, setOtherHear] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [teamSize, setTeamSize] = useState('');
  const [preferredSoftware, setPreferredSoftware] = useState('');
  const [otherSoftware, setOtherSoftware] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        setIsDisabled(true);
        let filterSoftware=preferredSoftware.split(",").filter((data)=>data!="Others").join(",");
        let obj = {
          primaryPurpose: primaryPurpose == 'Other' ? otherPurpose : primaryPurpose,
          bestJobRole: jobRole,
          teamSize: teamSize,
          preferredSoftware: filterSoftware.concat(","+otherSoftware),
          howDidYouHear: howDidYouHear == 'Other'? otherHear: howDidYouHear
        }
        dispatch(updateOnboardingDetails(userData._id, obj)).then((res) => { setIsDisabled(false) });
      }
    });
  };

  const onChangePrimaryPurpose = (e) => {
    setPrimaryPurpose(e)
  }
  const onChangeOtherPurpose = (e) => {
    setOtherPurpose(e.target.value)
  }
  const onChangeHowDidYouHear = (e) => {
    setHowDidYouHear(e)
  }
  const onChangeOtherHear = (e) => {
    setOtherHear(e.target.value)
  }
  const onChangeSoftware = (e) => {
    setPreferredSoftware(e.toString())
  }
  const onChangeOtherSoftware = (e) => {
    setOtherSoftware(e.target.value)
  }
  const onChangeJobRole = (e) => {
    setJobRole(e)
  }
  const onChangeTeamSize = (e) => {
    setTeamSize(e.target.value)
  }
  const onSelectSoftware = (e) => {
    setPreferredSoftware(e.target.value)
  }

  const [formLayout, setFormLayout] = useState('vertical');
  const { Option } = Select;
  const formItemLayout =
    formLayout === 'vertical'
      ? {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      }
      : null;

  return (
    <Modal
      title={[<div className="chinfotitlewrapper"><img src={chlogo} className="chinfologowidth" /><br />We would like to know you better!</div>]}
      visible={isOnBoardingProcessPending}
      width="500px"
      className="pricingpopup"
      footer={null}
    >
      <div className="infopopupwrapper">
        <Form onSubmit={handleSubmit}
          {...formItemLayout}
        >
          <Form.Item name="optionn" label={`The Primary purpose of using ${window.env.REACT_APP_PAGETITLE}?`} rules={[{ required: true, message: 'Please input your username!' }]}>
            {getFieldDecorator('primaryPurpose', {
              rules: [
                {
                  required: true,
                  message: 'Required'
                }
              ]
            })(<Select placeholder="Select option" onChange={onChangePrimaryPurpose}>
              <Option key="Sales" value="Sales">Sales</Option>
              <Option key="Customer_Support" value="Customer Support">Customer Support</Option>
              <Option key="Call_Center" value="Call Center">Call Center</Option>
              <Option key="Office_Phone_System" value="Office Phone System">Office Phone System</Option>
              <Option key="both" value="Both Sales & Customer Support">Both Sales & Customer Support</Option>
              <Option key="Other" value="Other">Other</Option>
            </Select>)}
          </Form.Item>
          {primaryPurpose == 'Other' && 
          <Form.Item  {...formItemLayout} name="otherPurpose" label="" >
          {getFieldDecorator('otherPurpose', {
              rules: [
                {
                  required: true,
                  message: 'Required'
                }
              ]
            })(<Input placeholder="" onChange={onChangeOtherPurpose} />)}
          </Form.Item>}
          <Form.Item label={`Which ${window.env.REACT_APP_PAGETITLE} products are you interested in?`}>
            {getFieldDecorator('products', {
              rules: [
                {
                  required: true,
                  message: 'Required'
                }
              ]
              // initialValue: preferredSoftware,
            })( 
           <Select placeholder="Select option" onChange={onChangeJobRole}>
            <Option key="Business_Phone_System" value="Business Phone System">Business Phone System</Option>
            <Option key="Call_Tracking" value="Call Tracking">Call Tracking</Option>
            <Option key="Speech_Analytics" value="Speech Analytics">Speech Analytics</Option>
            <Option key="Voice_Broadcasting" value="Voice Broadcasting">Voice Broadcasting</Option>
            <Option key="Whatsapp_Business" value="Whatsapp Business">Whatsapp Business</Option>
          </Select> )}
          </Form.Item>
          <Form.Item label="Select Team size">
            {getFieldDecorator('teamSize', {
              rules: [
                {
                  required: true,
                  message: 'Required'
                }
              ]
              // initialValue: preferredSoftware,
            })(<Radio.Group buttonStyle="solid" className="teamsizewrapper" onChange={onChangeTeamSize}>
              <Radio.Button value="1-3">01-03</Radio.Button>
              <Radio.Button value="03-25">03-25</Radio.Button>
              <Radio.Button value="26-200">26-200</Radio.Button>
              <Radio.Button value="200+">200+</Radio.Button>
            </Radio.Group>)}
          </Form.Item>
           
          <Form.Item name="optionn" label={`Any preferred software you want ${window.env.REACT_APP_PAGETITLE} to integrate with?`} rules={[{ required: true, message: 'Please input your username!' }]}>
           <Select placeholder="Select option" onChange={onChangeSoftware} mode="multiple">
              {Object.keys(integrationL).map((data,key)=><Option key={integrationL[data].displayName} value={integrationL[data].displayName}>
                 {integrationL[data].displayName}
                </Option>)}
              <Option key="Others" value="Others">Others</Option>
            </Select>
          </Form.Item>
          {preferredSoftware.includes('Others') && 
          <Form.Item  {...formItemLayout} name="otherSoftware" label="" >
          {getFieldDecorator('otherSoftware', {
              rules: [
                {
                  required: true,
                  message: 'Required'
                }
              ]
            })(<Input placeholder="" onChange={onChangeOtherSoftware} />)}
          </Form.Item>}  
          {/* CAL7747:start */}
          <Form.Item name="optionn" label="How did you hear about us?" rules={[{ required: false, message: 'Please let us know!' }]}>
            {getFieldDecorator('howDidYouHear', {
              rules: [
                {
                  required: false,
                  message: 'Not Required'
                }
              ]
            })(<Select placeholder="Select option" onChange={onChangeHowDidYouHear}>
              <Option key="Search_Engine" value="Search Engine">Google / Search Engine</Option>
              <Option key="Software_Marketplace" value="Software Marketplace">Software Marketplace</Option>
              <Option key="Social_Media" value="Social Media">Social Media</Option>
              <Option key="Through_a_Friend" value="Through a Friend">Through a Friend</Option>
              <Option key="Other" value="Other">Other (please specify)</Option>
            </Select>)}
          </Form.Item>
          {howDidYouHear == 'Other' && 
          <Form.Item  {...formItemLayout} name="otherHear" label="" >
          {getFieldDecorator('otherHear', {
              rules: [
                {
                  required: true,
                  message: 'Required'
                }
              ]
            })(<Input placeholder="" onChange={onChangeOtherHear} />)}
          </Form.Item>}
          {/* CAL7747:end */}
          <Form.Item className="text-center">
            <Button htmlType="submit" className="infopopupbtn" disabled={isDisabled}>Submit</Button>
          </Form.Item>
         

        </Form>
      </div>
    </Modal>
  )
}

const WrappedFormWIthSubmissionButton = Form.create()(Callhippoinfopopup);
export default WrappedFormWIthSubmissionButton;