import axiosApi, {errorResponseStatus} from '../api';
import {ClearUserData} from "../api";
import Axios from 'axios';

export const GET_POWER_DIALER_LIST = 'GET_POWER_DIALER_LIST';
export const START_STOP_CAMPAIGN = 'START_STOP_CAMPAIGN';
export const DOWNLOAD_CAMPAIGN = 'DOWNLOAD_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const GET_USER_TEAM_LIST = 'GET_USER_TEAM_LIST';
export const ADD_CAMPAIGNS = 'ADD_CAMPAIGNS';
export const UPDATE_CAMPAIGNS = 'UPDATE_CAMPAIGNS';
export const GET_ACTVITYFEED_POWER_DIALER_LIST = 'GET_ACTVITYFEED_POWER_DIALER_LIST';
export const GET_CONTACTS = 'GET_CONTACTS';
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS';
export const DELETE_CONTACT = 'DELETE_CONTACT';


const userData = JSON.parse(localStorage.getItem('userData'));

export const powerDialer = (history,postData={}) => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  var pathName = history.location ? history.location.pathname : 'powerDialer'
  let typeName = pathName.substring(pathName.lastIndexOf("/")+1);
  typeName = typeName?typeName:"powerDialer"
  let url = window.env.REACT_APP_BASE_URL + (typeName == "powerDialer" ? "powerDialerGet" : typeName)+'/' + user_id+'?current='+ (postData.current ?postData.current:1)+'&type='+typeName;
  // let url = window.env.REACT_APP_BASE_URL + typeName+'/' + user_id+'?current='+ (postData.current ?postData.current:1)+'&type='+typeName;
  let data ;
  if(postData.searchText){
    // url = url + '&search='+postData.searchText;
    data = {
      search : postData.searchText
    }
  }else
  {
    data ={}
  }
  return axiosApi(`${url}`, "post",data, dispatch, `${GET_POWER_DIALER_LIST}`).then(function(res){
   if (res && !res.payload.success) {  
     return dispatch({
       type: "SET_CUSTOM_MESSAGE",
       payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res; 
});

};

export const startStopPowerDialer = postData => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let campaignId = postData._id;
  let action = postData.action;
  let url = window.env.REACT_APP_BASE_URL + 'powerDialer/' + action + '/' + user_id;
  return axiosApi(`${url}`, "put", { "userId": user_id, "campaignId": campaignId }, dispatch, `${START_STOP_CAMPAIGN}`)
  // .then((res) => {
   
  //   if (res.payload.success) {
  //     return dispatch({
  //       type: "SET_CUSTOM_MESSAGE",
  //       payload: { message: `Campaign ${action=='stop' ? "paused":"started"} successfully.`, type: "success" }
  //     });
  //   } else {
  //     let error = res.payload.error.error;
  //     return dispatch({
  //       type: "SET_CUSTOM_MESSAGE",
  //       payload: { message: error, type: "error" }
  //     });
  //   }
  // });

};

export const downloadPowerDialerCampaign = campaignId => dispatch => {
  let url = window.env.REACT_APP_BASE_URL + 'powerDialer/download/' + campaignId;
  axiosApi(`${url}`, "post", {}, dispatch, `${DOWNLOAD_CAMPAIGN}`).then(function(res){
    if (!res.payload.success) {  
      return dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: res.payload.error.error, type: "error" }
       });
     }
    
 });
};

export const deleteCampaign = campaignId => dispatch => {
  let url = window.env.REACT_APP_BASE_URL + 'powerDialer/' + campaignId;
  let del = axiosApi(`${url}`, "delete", {}, dispatch, `${DELETE_CAMPAIGN}`).then((res) => {
    powerDialer();
    return dispatch({
      type: "SET_CUSTOM_MESSAGE",
      payload: { message: "Successfully deleted.", type: "success" }
    });
  });
};

export const addCampaign = postData => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let url = window.env.REACT_APP_BASE_URL + 'powerDialer/' + user_id;
  let method  = 'post';
  let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  let authToken = userData?userData.authToken:null;
  let billingtoken = userData?userData.billingToken:null;
  // axiosApi(`${url}`, "post", postData, dispatch, `${ADD_CAMPAIGNS}`)
  Axios({
    method:'post',
    url:url,
    data:postData,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      // content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
      id: user_id,
      authtoken: authToken,
      billingtoken: billingtoken,
    },
  })
    .then((result) => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Campaign created successfully.", type: "success" }
      });
      return dispatch({
        type: `${ADD_CAMPAIGNS}`,
        payload: result.data,
      });
    })
    .catch((error) => {
      if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      return dispatch({
        type: ADD_CAMPAIGNS,
        payload: error.response ? error.response.data : ""
      });
    })
}

export const updateCampaign = postData => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  ///let pid = postData.pid;
  let url = window.env.REACT_APP_BASE_URL + 'powerDialer/update/' + user_id;
  let method  = 'post';
  let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  let authToken = userData?userData.authToken:null;
  let billingtoken = userData?userData.billingToken:null;
  // axiosApi(`${url}`, "post", postData, dispatch, `${ADD_CAMPAIGNS}`)
  Axios({
    method:'post',
    url:url,
    data:postData,
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
      // content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
      id: user_id,
      authtoken: authToken,
      billingtoken: billingtoken,
    },
  })
    .then((result) => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Campaign is updated.", type: "success" }
      });
      return dispatch({
        type: `${UPDATE_CAMPAIGNS}`,
        payload: result.data,
      });
    })
    .catch((error) => {
      if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      return dispatch({
        type: UPDATE_CAMPAIGNS,
        payload: error.response ? error.response.data : ""
      });
    })
}

export const getUsersTeamList = (dashboardFilter = false) => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let url = window.env.REACT_APP_BASE_URL + 'getUsersTeamList/' + user_id+'?powerDialerReq=true'+(dashboardFilter ? '&dashboardFilter=true' : '');
  axiosApi(`${url}`, "get", {}, dispatch, `${GET_USER_TEAM_LIST}`)
}
export const getActvityFeedPowerDialer = (history,postData={}) => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let typeName = "getActivityfeedPowerDialer"
  let url = window.env.REACT_APP_BASE_URL + typeName+'/' + user_id+'?current='+ (postData.current ?postData.current:1)+'&type='+'powerDialer';

  if(postData.searchText){
    url = url + '&search='+postData.searchText;
  }
  return axiosApi(`${url}`, "get", {}, dispatch, `${GET_ACTVITYFEED_POWER_DIALER_LIST}`).then(function(res){
   if (res && !res.payload.success) {  
     return dispatch({
       type: "SET_CUSTOM_MESSAGE",
       payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res; 
});

};

export const getContacts = (history,postData={}) => dispatch => {
  let url = window.env.REACT_APP_BASE_URL + 'powerDialer/getContacts/'+postData.campaignMasterId+'?current='+ (postData.current ?postData.current:1);
  if(postData.searchText){
    url = url + '&search='+postData.searchText;
  }
  axiosApi(`${url}`, "get", postData, dispatch, `${GET_CONTACTS}`)
}

export const updateContact = ( campaignNumberId, postData ) => dispatch => {
    let url = window.env.REACT_APP_BASE_URL + 'powerDialer/updateContact/' + campaignNumberId;
    axiosApi(`${url}`, "post", postData, dispatch, `${UPDATE_CONTACTS}`).then(function(res){
      if (!res.payload.success) {  
        return dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: res.payload.error.error, type: "error" }
        });
      }
      
  });
};

export const deleteContact = campaignNumberIds => dispatch => {
  let url = window.env.REACT_APP_BASE_URL + 'powerDialer/delete/Contact';
  let del = axiosApi(`${url}`, "delete", { campaignNumberIds }, dispatch, `${DELETE_CONTACT}`);
};