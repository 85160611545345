import { GET_CALLSCRIPT_LIST,DELETE_CALLSCRIPT } from './actions';
const initState = {
  get_callscript_list: [],
  delete_callscript:{}
}

export default function callscriptReducer(state = initState, action) {
  switch (action.type) {
    case GET_CALLSCRIPT_LIST:
      return {
        ...state,
        get_callscript_list: action.payload,
      };
    case DELETE_CALLSCRIPT:
      return {
        ...state,
        delete_callscript:action.payload
      }
    default:
      return state;
  }
};