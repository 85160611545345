import Axios from 'axios';
import axiosApi, {errorResponseStatus} from '../api';
import {ClearUserData} from "../api";

const userData = JSON.parse(localStorage.getItem('userData'));
const dashboardAction ={
    DASHBOARD_DATA:"DASHBOARD_DATA",
    USER_PLAN:'USER_PLAN',
    CHECK_AVL_MODULE:"CHECK_AVL_MODULE",
    GET_DASHBOARD:"GET_DASHBOARD",
    MARK_TOUR_VISITED:"MARK_TOUR_VISITED",
    TRIGGER_TOUR:"TRIGGER_TOUR",
    SEND_CH_COACH_EMAIL:"SEND_CH_COACH_EMAIL",
    TRIGGER_ADD_NUMBER_POPUP:"TRIGGER_ADD_NUMBER_POPUP",
    UPDATE_COMMON_USER_DATA:"UPDATE_COMMON_USER_DATA",
    SEND_CALL_TRACKING_EMAIL:"SEND_CALL_TRACKING_EMAIL",
    PUSHER_DATA:"PUSHER_DATA",
    CHART_DATA:"CHART_DATA",
    COMMON_DATA:"COMMON_DATA",
    GET_CREDIT:"GET_CREDIT",
    dashboard:  (id,startDate,endDate) =>{
      const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch , getstate) => {
          Axios.get(window.env.REACT_APP_BASE_URL + 'api/v1/dashboard?startDate='+startDate+'&endDate='+endDate,
           {
               headers:{
                 'Accept' : 'application/json',
                 'Content-Type' : 'application/json',
                 'id' :userId ,
                 'authtoken':authToken,
                 'billingtoken' :bilingtoken,
               }
             }
           ).then(postdata => {
               dispatch({
                   type:dashboardAction.DASHBOARD_DATA,
                   postdata,
                 })
           }).catch((error)=>{
             if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
                 return ClearUserData(error.response);
             }
             const postdata = error.response;
               dispatch({
                   type:dashboardAction.DASHBOARD_DATA,
                   postdata,
                 })
           });      
        }
       },
       getChartData:  (id,startDate,endDate) =>{
        const userData = JSON.parse(localStorage.getItem('userData')),
              authToken = userData == null ? '' : userData.authToken,
              userId = userData == null ? '' : userData._id,
              bilingtoken = userData == null ? '' : userData.billingToken;
          return (dispatch , getstate) => {
            Axios.get(window.env.REACT_APP_BASE_URL + 'api/v1/chartData?startDate='+startDate+'&endDate='+endDate,
             {
                 headers:{
                   'Accept' : 'application/json',
                   'Content-Type' : 'application/json',
                   'id' :userId ,
                   'authtoken':authToken,
                   'billingtoken' :bilingtoken,
                 }
               }
             ).then(postdata => {
                 dispatch({
                     type:dashboardAction.CHART_DATA,
                     postdata,
                   })
             }).catch((error)=>{
               if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
                   return ClearUserData(error.response);
               }
               const postdata = error.response;
                 dispatch({
                     type:dashboardAction.CHART_DATA,
                     postdata,
                   })
             });
          }
        },
       userPlan: postdata =>{
        const userData = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '' ,
              authToken = userData == null ? '' : userData.authToken,
              userId = userData == null ? '' : userData._id,
              bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch , getstate) => {
           Axios.post(window.env.REACT_APP_BASE_URL + 'billing/userPlan',postdata,
           {
               headers:{
                 'Accept' : 'application/json',
                 'Content-Type' : 'application/json',
                 'id' :userId ,
                 'authtoken':authToken,
                 'billingtoken' :bilingtoken,
               }
             }
           ).then(postdata => {
               dispatch({
                   type:dashboardAction.USER_PLAN,
                   postdata,
                 })
           }).catch((error)=>{
             if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
                  return ClearUserData(error.response);
             }
             const postdata = error.response;
               dispatch({
                   type:dashboardAction.USER_PLAN,
                   postdata,
                 })
           });      
        }
       },
       checkAvailableModule: postdata =>{
        const userData = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '' ,
              authToken = userData == null ? '' : userData.authToken,
              userId = userData == null ? '' : userData._id,
              bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch , getstate) => {
           Axios.get(window.env.REACT_APP_BASE_URL + 'user/checkAvailableModule',
           {
               headers:{
                 'Accept' : 'application/json',
                 'Content-Type' : 'application/json',
                 'id' :userId ,
                 'authtoken':authToken,
                 'billingtoken' :bilingtoken,
               }
             }
           ).then(postdata => {
               dispatch({
                   type:dashboardAction.CHECK_AVL_MODULE,
                   postdata,
                 })
           }).catch((error)=>{
            if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
                return ClearUserData(error.response);
            }
             const postdata = error.response;
               dispatch({
                   type:dashboardAction.CHECK_AVL_MODULE,
                   postdata,
                 })
           });      
        }
       },
       getDashboard: (id,startDate,endDate) =>{
        const userData = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '' ,
              authToken = userData == null ? '' : userData.authToken,
              userId = userData == null ? '' : userData._id,
              bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch , getstate) => {
           Axios.get(window.env.REACT_APP_BASE_URL + 'api/v1/dashboard?startDate='+startDate+'&endDate='+endDate,
           {
               headers:{
                 'Accept' : 'application/json',
                 'Content-Type' : 'application/json',
                 'id' :userId ,
                 'authtoken':authToken,
                 'billingtoken' :bilingtoken,
               }
             }
           ).then(postdata => {
               dispatch({
                   type:dashboardAction.GET_DASHBOARD,
                   postdata,
                 })
           }).catch((error)=>{
            if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
                return ClearUserData(error.response);
            }
             const postdata = error.response;
               dispatch({
                   type:dashboardAction.GET_DASHBOARD,
                   postdata,
                 })
           });      
        }
       },

      liveCall:() => {
        const userData = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '' ,
authToken = userData == null ? '' : userData.authToken,
userId = userData == null ? '' : userData._id,
bilingtoken = userData == null ? '' : userData.billingToken;
        return Axios.get(`${window.env.REACT_APP_BASE_URL}liveCall/${userId}`,{
        headers:{
          'Accept' : 'application/json',
          'Content-Type' : 'application/json',
          'id' :userId ,
          'authtoken':authToken,
          'billingtoken' :bilingtoken,
        }
      })
    },

    liveCallSection:() => {
      const userData = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '' ,
base_url = window.env.REACT_APP_BASE_URL,
authToken = userData == null ? '' : userData.authToken,
userId = userData == null ? '' : userData._id,
bilingtoken = userData == null ? '' : userData.billingToken;
      return Axios.get(`${base_url}liveCallSection/${userId}`,{
      headers:{
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'id' :userId ,
        'authtoken':authToken,
        'billingtoken' :bilingtoken,
      }
    })
  },

    
      liveCallAction:(data) => {
        const userData = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '' ,
authToken = userData == null ? '' : userData.authToken,
userId = userData == null ? '' : userData._id,
bilingtoken = userData == null ? '' : userData.billingToken;
        return Axios.post(`${window.env.REACT_APP_BASE_URL}api/v1/callAction/plivo`,data,{
        headers:{
          'Accept' : 'application/json',
          'Content-Type' : 'application/json',
          'id' :userId ,
          'authtoken':authToken,
          'billingtoken' :bilingtoken,
        }
      })
    },
    clearDashboard:() => dispatch => {
      dispatch({
        type:"CLEAR_DASHBOARD"
      })
    },
    markTourVisited: postData => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}user/${postData.userId}/markTourVisited`, "get", {}, dispatch, `${dashboardAction.MARK_TOUR_VISITED}`),
    updateDashboardBanner: postData => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}user/${postData.userId}/updateBanner`, "get", {}, dispatch, `${dashboardAction.UPDATE_DASHBOARD_BANNER}`),
    getCredits: () => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}user/credits`, "get", {}, dispatch, `${dashboardAction.GET_CREDIT}`),

    triggerTour: postData => dispatch => {dispatch({type: dashboardAction.TRIGGER_TOUR, payload: postData})},
    
    sendCHCoachEmail : postData => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}callhippocoach`,"post",postData,dispatch,`${dashboardAction.SEND_CH_COACH_EMAIL}`),
    sendCallTrackingEmail : postData => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}callhippocalltracking`,"post",postData,dispatch,`${dashboardAction.SEND_CALL_TRACKING_EMAIL}`),
    triggerAddNumberPopup: postData => dispatch => {dispatch({type: dashboardAction.TRIGGER_ADD_NUMBER_POPUP, payload: postData})},

    changeUserData: postData => dispatch => {dispatch({type: dashboardAction.UPDATE_COMMON_USER_DATA, payload: postData})},

    changeUserSetting: postData => dispatch => {
            dispatch({type: dashboardAction.PUSHER_DATA, payload: postData})
        },
    
    getCampaignList:(search) => {
          const userData = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '' ,
          authToken = userData == null ? '' : userData.authToken,
          userId = userData == null ? '' : userData._id,
          bilingtoken = userData == null ? '' : userData.billingToken;
          return Axios.get(`${window.env.REACT_APP_BASE_URL}getCampaignList/${userId}?search=${search}`,{
          headers:{
            'Accept' : 'application/json',
            'Content-Type' : 'application/json',
            'id' :userId ,
            'authtoken':authToken,
            'billingtoken' :bilingtoken,
          }
        })
    },

    getCommonData: (postData={}) => dispatch => {
        console.log("getCommonData")
        return axiosApi(`${window.env.REACT_APP_BASE_URL}commondata/`,"get",{},dispatch,`${dashboardAction.COMMON_DATA}`)
    },


}
export default dashboardAction;