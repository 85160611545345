
import importContactActions from './actions';
const initState = {  
  import_contact: [],
  }

  export default function importContactReducer(state = initState, action) {
    switch (action.type) {
      case importContactActions.IMPORT_CONTACT:
        return {
          ...state,
          import_contact: action.payload,
        };
      default:
        return state;
    }
  }