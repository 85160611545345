import React from "react";
import {connect} from "react-redux";
import {
               Route,
               Redirect
             } from 'react-router-dom';
function PrivateRoute({ children,auth, ...rest }) {
               return (
                 <Route
                   {...rest}
                   render={({ location }) =>
                     //isLoggedIn ? (
                     auth.isAuthenticated ? (
                       children
                     ) : (
                       <Redirect
                         to={{
                           pathname: '/',
                           state: { from: location },
                         }}
                       />
                     )
                   }
                 />
               );
             }

const mapStateToProps = (state) => {
  return {
         auth:state.authentication
  }
}
export default connect(mapStateToProps)(PrivateRoute)