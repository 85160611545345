import React from 'react';
import { Link } from 'react-router-dom';
//import chlogo from '../../assets/images/chlogo.svg';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const chlogo = imageUrl+'chlogo.svg';
const { DASHBOARD, ACTIVITYFEED } = APP_PRIVATE_ROUTE;
export default ({ collapsed, isBlocked }) => {
  let HOME = window.env.REACT_APP_REPORTS_APP ? ACTIVITYFEED : DASHBOARD;
  return (
    <div className="isoLogoWrapper chflex chflexcenter">
      {collapsed ? (
        <div>
          <h3>
            <Link to={!isBlocked && HOME}>
              <img className="chlogocollapse" src={window.env.REACT_APP_FAVICON} alt="chlogo" />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to={!isBlocked && HOME}>
            <img className="chlogot" src={window.env.REACT_APP_LOGO} alt="chlogo" />
          </Link>
        </h3>
      )}
    </div>
  );
};
