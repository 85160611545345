import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const isoModal = ComponentName => WithDirection(styled(ComponentName)`
.subscriptionwrapper .ant-row:after {
    display: block;  
    padding: 0 10px;     
}
.subscriptionwrapper{
    padding: 0 10px; 
}
.plancard .ant-card-head {
    border-bottom: 0px;
  }
  
  .plancard .ant-card-head-title {
    font-weight: 700;
    font-size: 22px;
    padding: 16px 0px 0;
    padding-bottom: 5px;
    text-align: center;
    text-transform: capitalize;
  }
  
  .plancard .ant-card-body {
    padding-top: 0px;
  }
  
.plantext {
    color: rgb(0, 0, 0);
    font-size: 30px;
    text-align: center;
    font-weight: 700;
  }
  
  .planmonth {
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .upgradeNowBtn {
    background-color: #e27849;
    border-color: #e27849;
    color: #fff;
    font-size: 14px;
    height:34px;
  }
  
  .planbtncenter {
    text-align: center;
    margin-bottom: 14px;
  }
  
  .plancard .ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #e27849;
    border-color: #e27849;
    color: #fff;
  }
  
  .planlist li {
    font-weight: 600;
    font-size: 14px;
    margin: 3px 0px;
    list-style-type: none;
    padding-left: 50px;
    position: relative;    
    padding: 5px 0 5px 20px;
  }
  
  .planlist li:before {
    content: "";
    height: 10px;
    width: 10px;
    background: rgb(226, 120, 73);
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 11px;
  }
  
  .planinfoicon {
    margin-left: 5px;
  }
  .everythingbox {
    background: rgb(255, 250, 234);
    padding: 12px 0px 12px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 180px;
       
  }
  .everythingempty{
    background: rgb(255, 255, 255);
  }
  .planDisabled {
    pointer-events: none;
  }
  .planDisabled .upgradeNowBtn, .planDisabled .upgradeNowBtn:hover, .planDisabled .upgradeNowBtn:focus{
    background-color: #e5e5e5;
    border-color: #e5e5e5;
    color: #000;
  }
  
  .planDisabled .everythingbox{
    background-color: #f9f9f9;
  }
  .planDisabled .planlist li {
    color: #b3b3b3;
  }
  .planDisabled .ant-card-head-title{
    color: #b3b3b3;
  }
  .planDisabled .plantext{
    color: #b3b3b3;
  }
  .planDisabled .planmonth{
    color: #b3b3b3;
  }
  .planDisabled .planlist li:before {
    background-color: #e5e5e5;
  }
  .planribbon {
    position: relative;
    overflow: hidden;
  }
  .planribbon:after {
    font-weight: 800;
    width: 175px;
    padding: 7px;
    color: rgb(255, 255, 255);
    content: "Popular";
    position: absolute;
    text-align: center;
    background: rgb(226, 120, 73);
    transform: rotate(45deg);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 20px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    top: 22px;
    left: 225px;
    font-size: 18px;
  }
  .planribbon_four_card:after {
    top: 18px;
    left: 132px;
  }
  .yourcurrentplan:before {
    content: "Your Current Plan";
    position: absolute;
    top: -22px;    
    border-radius: 5px 5px 0px 0px;
    background: rgb(226, 120, 73);
    left: 65px;
    color: #fff;
    padding: 3px 13px;
    font-size:11px;
    }
    .monthlyswitch{
        border: 1px solid #f1f1f1;
        border-radius: 5px;        
        padding-bottom: 20px;
        margin: 0 15px;        
        display: flex;
    flex-direction: column;
    flex-flow: wrap;
    }
    .subscriptionswitch{
        background: rgb(227, 121, 73);      
        margin: 0px 8px;
    }
    .subswitchwrapper{
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .monthlytext{
        font-size: 16px;
        font-weight: 600;
    }
    .annuallytext{
        font-size: 16px;
        font-weight: 600;
        color: rgb(227, 121, 73);
    }
    .plancard{
        height: 100%;
    }
    .subinfo{
        font-size: 16px;
        margin-left: 5px;
    }
    .sublist{
        display:flex;
        align-items:center;
    }
    .subtitle{
        margin-bottom: 7px;
    }
    .hippacenter {
      text-align: center;
    }
    .staterText {
      text-align: center;
      font-size: 13px;
      font-style: italic;
      padding:10px 0px 3px 10px;
      font-weight:600;
    }
    .padSpace {
      padding:23px;
    }
    .mgtop30 {
      margin-top:30px
    }
    .emptyText {
      padding:20px 0px 15px 10px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      .subscriptionwrapper {    
        overflow-x: auto;
    }
    .subscriptionwrapper::-webkit-scrollbar {
      height:6px;
      background-color: #F5F5F5;
    }
    .subscriptionwrapper::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
      box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
      background-color:  #F5F5F5;
    }
    }
`);

export default isoModal;