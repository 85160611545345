import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '@iso/lib/helpers/style_utils';
import WithDirection from '@iso/lib/helpers/rtl';
import refreshImg from '../../assets/images/icon/ch/refresh.svg';
import LeaderBoard from '../../assets/images/icon/ch/LeaderBoard.svg';
import LeaderBoardwhite from '../../assets/images/icon/ch/LeaderBoardwhite.svg';
import targetImage from "../../assets/images/icon/ch/track_changes_black.svg";
import targetWhiteImg from "../../assets/images/icon/ch/track_changes_white.svg";
import smslogblack from '../../assets/images/icon/ch/smslogblack.svg';
import smslogGray from '../../assets/images/icon/ch/smslogGray.svg';
import callscriptGray from '../../assets/images/icon/ch/callscriptGray.svg';
import needhelpGrayIcon from '../../assets/images/icon/ch/help_gray.svg';
import smslogwhite from '../../assets/images/icon/ch/smslogwhite.svg';
import calllogblack from '../../assets/images/icon/ch/calllogblack.svg';
import calllogwhite from '../../assets/images/icon/ch/calllogwhite.svg';
import Numbernewicon from '../../assets/images/icon/ch/NumbersNew.svg';
import Numbernewiconwhite from '../../assets/images/icon/ch/NumbersNewWhite.svg';
import didGroup from '../../assets/images/icon/ch/didGroup.svg';
import didGroupWhite from '../../assets/images/icon/ch/didGroupWhite.svg';
import ControllHippoIcon from '../../assets/images/icon/ch/controllhippo_samll_logo.svg';

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const SidebarPowerDialerImg = imageUrl+'icon/ch/powerdialer.svg'
const SidebarPowerDialerImgWhite = imageUrl+'icon/ch/powerdialer.svg'
const callscriptImg = imageUrl+'icon/ch/callscript.svg';
const callscriptwhite = imageUrl+'icon/ch/callscriptwhite.svg';
const numberImg = imageUrl+'icon/ch/Numbers.svg';
const numberwhite = imageUrl+'icon/ch/Numberwhite.svg';
const userImg = imageUrl+'icon/ch/Users.svg';
const userwhite = imageUrl+'icon/ch/Userwhite.svg';
const teamImg = imageUrl+ 'icon/ch/Team.svg';
const teamwhite = imageUrl+'icon/ch/Teamwhite.svg';
const integrationImg = imageUrl+'icon/ch/Integrations.svg';
const integrationwhite = imageUrl+'icon/ch/Integrationwhite.svg';
const settingImg = imageUrl+'icon/ch/settings_black_Icon.svg';
const settingwhite = imageUrl+'icon/ch/settings_white_icon.svg';
const callreportImg = imageUrl+'icon/ch/CallReport.svg';
const callreportwhite = imageUrl+'CallReport-white-icon_new.svg';
const callplannerImg = imageUrl+'icon/ch/CallPlanner.svg';
const callplannerwhite = imageUrl+'icon/ch/CallPlannerwhite.svg';
const planicon = imageUrl+'icon/ch/planbilling.svg';
const planiconwhite = imageUrl+'icon/ch/planiconwhite.svg';
const campaignImg = imageUrl + 'icon/ch/campaign_management-grey.svg';
const campaignWhite = imageUrl + 'icon/ch/campaign_management-white.svg';
const needhelpIcon = imageUrl + 'icon/ch/help_black.svg';
const CallsstatsIconBlack = imageUrl + "assessment_black.svg";
const CallsstatsIconWhite = imageUrl + "assessment_white.svg";
const activityFeedBlack = imageUrl + "graphic_eq_black.svg";
const activityFeedWhite = imageUrl + "graphic_eq_white.svg";
const userPerfomenceblack = imageUrl + "people_alt_black_24dp.svg";
const userPerfomencewhite = imageUrl + "people_alt_black_24dpwhite.svg";
const liveChatIcon = imageUrl + "icon/ch/question_answer_black_icon.svg";
const liveChatWhiteIcon = imageUrl + "icon/ch/question_answer_white_icon.svg";
const campaignIcon = imageUrl + "icon/ch/campaign_black_icon.svg";
const campaignWhiteIcon = imageUrl + "icon/ch/campaign_white_icon.svg";
const contactsIcon = imageUrl + "icon/ch/contacts_black_icon.svg";
const contactsWhiteIcon = imageUrl + "icon/ch/contacts_white_icon.svg";
const updateIcon = imageUrl + "icon/ch/update_black_icon.svg";
const updateWhiteIcon = imageUrl + "icon/ch/update_white_icon.svg";
const addWhiteIcon = imageUrl + "icon/ch/add_white_icon1234.svg";
const CallStatusReport = imageUrl + "icon/ch/Call_Status_report_black_icon.svg";
const CallStatusReportWhite = imageUrl + "icon/ch/Call_Status_report_white_icons.svg";
const AbandonRateReport = imageUrl + "icon/ch/Abandon_Rate_black_icon.svg";
const AbandonRateReportWhite = imageUrl + "icon/ch/Abandon_Rate_white_icons.svg";
const AvailabilityReport = imageUrl + "icon/ch/Availibility_Report__black_icon.svg";
const AvailabilityReportWhite = imageUrl + "icon/ch/Availibility_Report_white_icons.svg";
const CallReportNew = imageUrl + "icon/ch/call_report_black_icon.svg";
const CallReportNewWhite = imageUrl + "icon/ch/call_report_white_icons.svg";


const SidebarWrapper = styled.div`
  background: #ffffff;
  border-right: 1px solid #ddd;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);


  .sidebarDisabled{
    cursor: not-allowed;

    .ant-layout-sider-children{
      pointer-events: none;
    }

    .sidebarDisabledOpacity{
      opacity: 0.5
    }
  }

  .isomorphicSidebar {
    z-index: 1000;
    background-color: white;
    width: 280px;
    flex: 0 0 280px;   
      
    .sidebarScrollbars{
      border-right: 1px solid #dddddd;
    }

    .sidebarCreditDiv{
      width: 100%;
      text-align: center;
      // min-height: 100px;

      @media only screen and (max-width: 767px) {
        min-width: 128px;
      }

      .sidebarCreditSpan{
        padding: 0.5rem 0rem;
        border-radius: 6px;
        font-size: 12px;
        color:black;
        margin-right:4px;
      }
    }
    // Parvati TODO CSS Upgrade Now Btn
  .sidebarUpgradeSpan{
    background: #e27646;
    color: #ffffff;
    padding: 0.2rem 1rem;
    border-radius: 20px;
    font-size: 13px;
    cursor : Pointer;
    margin-right: 18px;
  }
    .scrollarea {
      height: calc(100vh - 70px);
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 70px;
      background: white;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.14), 0 1px 1px -2px rgba(0,0,0,.2), 0 1px 4px 0 rgba(0,0,0,.12);
      clip-path: inset(-5px 0px -5px -5px);
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 21px;
          font-weight: 300;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: ${palette('grayscale', 6)};
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      padding: 3px 0;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 5px -10px;
        
        .nav-icon{
          margin-right: 12px;
          fill: #464646;
        }

        &:hover{
          background-color: #34495e;
          
          .nav-text{
            color: #ffffff;
          }
          .nav-icon{
            fill: #ffffff;
          }
          .sidebarPowerDialerImgWhite {
            display: block;
          }
          .sidebarPowerDialerImg {
            display: none;
          }
        }
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;

        i {
          font-size: 19px;
          color: #333;
          margin-right: 12px;
          width: 18px;
          ${transition()};
        }
        .sidebarPowerDialerImgWhite {
          background-image: url(${SidebarPowerDialerImgWhite});
          height: 18px;
          margin-right: 16px;
          width: 18px;
          background-repeat: no-repeat;
          background-size: contain
          display:none;
        }
        .sidebarPowerDialerImg {
          background-image: url(${SidebarPowerDialerImg});
          height: 18px;
          margin-right: 16px;
          width: 18px;
          background-repeat: no-repeat;
          background-size: contain
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 13px;
        color: #464646;
        font-weight: 500;
        letter-spacing: .5px;
        ${transition()};
      }

      .ch-nav-call-notificaion{
        background: #19313e;
        color: #fff;
        border-radius: 40px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        margin-left: 10px;
        font-size: 13px;
        padding-right: 2px;
        padding-top: 1px;
      }

      .ant-menu-item-selected {
        background-color: rgba(0, 0, 0, 0.1);
        .anticon {
          color: #fff;
        }

        .nav-text, .nav-icon {
          color: #333;
        }
      }

      > li {
        &:hover {
          i,
          .nav-text {
            color: #333;
          }
        }
      }

    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette('secondary', 5)};
    }

    .ant-menu-submenu-inline{
      padding-left: 0px !important;
    },
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 96%;
        display: flex;
        align-items: center;
        padding: 0 24px;
        padding-left: 23px !important;
        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
          right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }

          ${'' /* &:after {
            content: '\f123';
            font-family: 'Ionicons' !important;
            font-size: 16px;
            color: inherit;
            left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
            right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
            ${transition()};
          } */};
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: #ffffff;
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${props =>
            props['data-rtl'] === 'rtl' ? '0px ' : '0px '};
          padding-right: ${props =>
            props['data-rtl'] === 'rtl' ? '74px !important' : '0px !important'};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            a {
              color: #ffffff !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
    .hippo-coach-icon{
      height:18px;
      margin-right: 16px;
    }
    .isoDashboardMenu > li:hover .voiceBroadcastIcon{
      color:#ffffff !important;
    }
    .callscripticon{
          background-image: url(${callscriptGray});
          height: 18px;
          margin-right: 12px;
          width: 18px;
          background-repeat: no-repeat;
          background-size: contain
    }
    .callScriptsMenu:hover .callscripticon{
      background-image: url(${callscriptwhite});     
    }
    .didgroupicon,.numbericon_new,.numbericonmain,.usericon_submenu,.activityfeed,.userperfomnce,
    .usericon,.Calls_statsIcon,.teamicon,.campaignicon,.integrationicon,.settingicon,
    .planicon,.callplannericon,.leaderboardicon,.call_status_report,.abandon_rate_report,.availability_report,
    .call_report_new_icon,.contollhippo_icon,.call_report_icon,.activitityFeedicon,.targeticon,.need_help_icon,
    .liveChatIcon,.campaignIcon,.contactsIcon,.updateIcon,.calllog,.smslog{
      height: 18px;
      margin-right: 16px;
      width: 18px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .numbericonmain{
      background-image: url(${numberImg});
    }
    .numbericon_new{
      background-image: url(${Numbernewicon});
    }
    .didgroupicon{
      background-image: url(${didGroup});
    }
    .isoDashboardMenu .whiteicfixtest .ant-menu-submenu-title:hover .numbericonmain{
      background-image: url(${numberwhite});     
    }
    .numbermenue:hover .numbericon_new{
      background-image: url(${Numbernewiconwhite});
    }
    .didgroupmenue:hover .didgroupicon{
      background-image: url(${didGroupWhite});
    }
    .Calls_statsIcon {
      background-image: url(${CallsstatsIconBlack});
    }
   
    .isoDashboardMenu .callstatswhite .ant-menu-submenu-title:hover .Calls_statsIcon{
      background-image: url(${CallsstatsIconWhite});     
    } 
    .usericon {
      background-image: url(${userImg});
    }
    .userperfomnce {
      background-image: url(${userPerfomenceblack});
    }
    
    .activityfeed {  
      background-image: url(${activityFeedBlack});        
    }
    .isoDashboardMenu .whiteicfixtest .ant-menu-submenu-title:hover .activityfeed{
      background-image: url(${activityFeedWhite});     
      } 
    .isomorphicSidebar .isoDashboardMenu .user-menu-active:hover .usericon{
      background-image: url(${userwhite});     
    } 
    .usericon_submenu {
      background-image: url(${userImg});
    }
    .usermenu:hover .usericon_submenu {
      background-image: url(${userwhite});  
    } 

   .isoDashboardMenu .whiteicfixtest .ant-menu-submenu-title:hover .userperfomnce{
    background-image: url(${userPerfomencewhite});     
    } 
    
    .isoDashboardMenu > li:hover .numbericon{
      background-image: url(${numberwhite});     
    }
    .isoDashboardMenu > .activityfeedmenu:hover .activityfeed {
      background-image: url(${activityFeedWhite});     
    } 
    .teamicon{
      background-image: url(${teamImg});
    }   
    .campaignicon {
      background-image: url(${campaignImg});
    }
    .campaignManagementMenu:hover .campaignicon{
      background-image: url(${campaignWhite});     
    }
    .integrationicon{
      background-image: url(${integrationImg});
    } 
    .isoDashboardMenu > li:hover .integrationicon{
      background-image: url(${integrationwhite});     
    } 
    .settingicon{
      background-image: url(${settingImg});     
    }
    .isoDashboardMenu > li:hover .settingicon{
      background-image: url(${settingwhite});     
    } 
    .planicon {
      background-image: url(${planicon});
    }
    .isoDashboardMenu > li:hover .planicon{
      background-image: url(${planiconwhite});     
    }
    .callplannericon{
      background-image: url(${callplannerImg}); 
    } 
    .callReminderMenu:hover .callplannericon{
      background-image: url(${callplannerwhite});     
    }
    .leaderboardicon{
      background-image: url(${LeaderBoard});  
    }   
    .calldetailicon{
      margin-right: 8px;
      color:#464646;
      font-size: 19px;
    }   
    .isoDashboardMenu > li:hover .calldetailicon {
      color:#fff;
    }
    .campaignicon{
      color:#333;
      margin-right:11px;
    }
    .campaignManagementMenu:hover .campaignicon{
      color:#fff;     
    }
    .call_status_report {
      background-image: url(${CallStatusReport});
    }
    .status_report:hover .call_status_report {
      background-image: url(${CallStatusReportWhite});  
    } 
    .abandon_rate_report {
      background-image: url(${AbandonRateReport});
    }
    .abandon_report:hover .abandon_rate_report {
      background-image: url(${AbandonRateReportWhite});  
    } 
    .availability_report {
      background-image: url(${AvailabilityReport});
    }
    .avail_report:hover .availability_report {
      background-image: url(${AvailabilityReportWhite});  
    } 
    .call_report_new_icon {
      background-image: url(${CallReportNew});
    }
    .call_report_main_icon:hover .call_report_new_icon {
      background-image: url(${CallReportNewWhite});  
    } 
    .contollhippo_icon {
      background-image: url(${ControllHippoIcon});
    }
    .ctrlh_new_flag{
      background-color: #ffff35;
      color: #000;
      border-radius: 5px;
      height: 14px;
      width: 28px;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4px;
    }
  @media (min-width: 768px) and (max-width: 1024px) {
    .credibtntab {
      display:none;
    }
    .scrolltabheight{
      height:954px !important;
    }
  }
  }
  .need__help_div {
    align-items: center;
    display: flex;
    padding-left: 24px;
    margin-bottom: 20px;
    cursor:pointer;
  }
  .need__help_div .help__Icon {
    margin-right: 13px;
    font-size: 19px;
    color: #333;
  }
  .need__help_div .help-text {
    font-size: 13px;
    font-weight:500;
    color: #464646;
  }
  .needhelpcenter{
    justify-content:center;
    padding-left:0;
  }
  .helpcollapsedmargin{
    margin-right:0;
  }
  .isomorphicSidebar .sidebarremoveborder{
    border-right:unset;
  }
  /*refresh button beside credit div*/
  .addBtnSideBar {
    background-image: url(${addWhiteIcon});
    height: 12px;      
    width: 12px;
    background-repeat: no-repeat;        
    display:inline-block;
    // transform: translate(-3px,1px);
    background-size: 16px;
    background-position: center;
  }
    .refreshbtn {
      background-image: url(${refreshImg});
      height: 12px;      
      width: 12px;
      background-repeat: no-repeat;        
      display:inline-block;
      background-size: 14px;
      background-position: center;
    }
    .refreshwrapper {
      border-radius: 50px;
      background-color: #e27646;
      color:#fff;
      padding:4px;
      cursor:pointer;
    }
    .sidebarCreditSpan {
      .currentCreditValue{
        font-weight:600;
      }
      .creditsInfoIcon{
        margin:0 6px 0px 2px;
      }
    }
    .addCreditsBtnMain{
      border-radius: 36px !important;
      font-size: 14px !important;
      padding: 1px 8px !important;
      font-weight:400;
      margin-right: 4px;
    }
    .addCreditsBtnName{
      font-size: 12px !important;
      margin-left: 2px !important;
    }
    .justcenter{
      justify-content: center;
    }
    .mrgtopbtm0{
      margin:0px !important;
    }
    .creditbtnwrapper {
      display:flex;
      align-items:center;
      justify-content:center;
    }
    .upgradenowbtn {
      margin-bottom: 10px;
    }
    .needhelpbottom {
        margin-bottom:15px;
        margin-top: 10px;
      }
      .emptybtnupgrade {
        height: 25px;
        width: 25px;
        display: inline-block;
        margin-left: 10px;
      }
    }
    .numberssubmenu .ant-menu-submenu-title:hover {
      background-color: rgb(52, 73, 94);
    }
    .numberssubmenu .ant-menu-submenu-title:hover .nav-text {
      color: rgb(255, 255, 255) !important;
    }
    .ant-menu-inline-collapsed .pad_numbersZero {
      padding: 0px !important;
      margin-top: 0px;
    }
    .numbersmenu:hover .numbericon {
      background-image: url(${numberwhite});  
   } 
   .teammenu:hover .teamicon {
    background-image: url(${teamwhite});  
 } 
    .usersubmenu .ant-menu-submenu-title:hover {
      background-color: rgb(52, 73, 94);
    }
    .usersubmenu .ant-menu-submenu-title:hover .nav-text {
      color: rgb(255, 255, 255) !important;
    }
    .isomorphicSidebar .ant-menu-submenu-inline > .ant-menu-submenu-title {        
      padding-right: 0;
    }
    // .isomorphicSidebar .usermenuleft.ant-menu-submenu-inline > .ant-menu-submenu-title {
    //   padding-left:0 !important;
    // }
    // .isomorphicSidebar .numbersmenuleft.ant-menu-submenu-inline > .ant-menu-submenu-title {
    //     padding-left:0 !important;
    //   }
    .isomorphicSidebar  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
      color:white !important;
      background: white !important;
    }
    .isomorphicSidebar  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
      color:white !important;
      background: white !important;
    }
    .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before{
      background:black;
    }
    .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
      background:black;
    }
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
      background:black;
      transform: rotate(-45deg) translateX(3px) !important;
    }
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
      background:black;        
    }
    .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-inline .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item{
      padding-left: 45px !important;
    }
    .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-inline .ant-menu-item-group .ant-menu-item-group-title {
      display:none;
    }
    .isomorphicSidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
      background:black;
     
    }
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
      background:black;
    }
     .leaderboardmenu:hover .leaderboardicon {
      background-image: url(${LeaderBoardwhite});     
    }      
    .teammenu:hover .teamicon {
       background-image: url(${teamwhite});  
    } 
    .call_report_icon {
      background-image: url(${callreportImg});
    }
    .isoDashboardMenu .whiteicfixtest .ant-menu-submenu-title:hover .call_report_icon {
      background-image: url(${callreportwhite});     
      }
    .activitityFeedicon {
      background-image: url(${activityFeedBlack});  
    }
    .activity_feed:hover .activitityFeedicon {
      background-image: url(${activityFeedWhite});     
    }      
    .targeticon {
      background-image: url(${targetImage});
    }
    .targetmenu:hover .targeticon {
      background-image: url(${targetWhiteImg});  
    } 
    .ant-menu-submenu-selected .pl10 {
      padding-left: 10px !important;
    }
    .need_help_icon {
      background-image: url(${needhelpGrayIcon}); 
    }
    .pad24 {
      padding: 0 24px;
    }
    .ant-menu-inline-collapsed .pad_userZero {
      padding: 0px !important;
      margin-top: 0px;
    }
    .ant-menu-inline-collapsed .pad24 {
      padding: 0px !important;
    }
    .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
      transform: translateY(-2px);
  }
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    transform: rotate(43deg) translateX(3px) !important;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-42deg) translateX(-3px);
}
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: unset !important;
  transform: translateY(1px);
}
.liveChatIcon {
  background-image: url(${liveChatIcon});
}
.isoDashboardMenu > li:hover .liveChatIcon {
  background-image: url(${liveChatWhiteIcon});     
} 
.campaignIcon {
  background-image: url(${campaignIcon});    
}
.isoDashboardMenu > li:hover .campaignIcon {
  background-image: url(${campaignWhiteIcon});     
} 
.contactsIcon {
  background-image: url(${contactsIcon});   
}
.isoDashboardMenu > li:hover .contactsIcon {
  background-image: url(${contactsWhiteIcon});     
} 
.updateIcon {
  background-image: url(${updateIcon});     
}
.smslog {
  background-image: url(${smslogGray});
}
.isomorphicSidebar  .smsmenu:hover .smslog {
  background-image: url(${smslogwhite});     
} 
.calllog {
  background-image: url(${calllogblack});
}
.isomorphicSidebar .callmenu:hover .calllog {
  background-image: url(${calllogwhite});     
} 
.isomorphicSidebar .isoDashboardMenu .callmenu:hover {
  background-color: #34495e;
}
.isomorphicSidebar .isoDashboardMenu .smsmenu:hover {
  background-color: #34495e;
}
.isoDashboardMenu > li:hover .updateIcon {
  background-image: url(${updateWhiteIcon});     
} 
.callTrack_Sider_outer_div .ant-menu-item {
  margin: 5px 0px !important;
}
.WhatApps_Sider_outer_div .ant-menu-item {
  margin: 5px 0px !important;
}
.Broadcast_Sider_outer_div .ant-menu-item {
  margin: 5px 0px !important;
}
`;

export default WithDirection(SidebarWrapper);
