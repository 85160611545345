import modalActions from './actions';

const initialState = {
  modalVisibility: false,
  modalType: '',
  modalProps: {},
  oldModalProps:{},
  currentModalProps:{}
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case modalActions.SHOW_MODAL:
      return {
        modalVisibility: true,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
        oldModalProps:action.payload.oldModalProps,
        currentModalProps:action.payload.currentModalProps
      };
    case modalActions.HIDE_MODAL:
      return {...initialState,currentModalProps:state.modalProps,oldModalProps:state.oldModalProps};
    default:
      return state;
  }
}
