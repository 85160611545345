import {
  FETCH_LIVECALLS_REQUEST,
  FETCH_LIVECALLS_SUCCESS,
  FETCH_LIVECALLS_FAILURE,
  LIVE_CALL_UPDATED,
  LIVE_CALL_CREATED,
  LIVE_CALL_REMOVED,
  AGENT_ADDED,
  AGENT_REMOVED,
  POST_CALL_ACTION_REQUEST,
  POST_CALL_ACTION_SUCCESS,
  POST_CALL_ACTION_FAILED,
} from './liveCallTypes';
import {findIndex, filter} from 'lodash';

const initialState = {
  loading: false,
  totalInboundCalls: 0,
  totalOutboundCalls: 0,
  averageHandleTime: 0,
  totalAvailableAgents: 0,
  totalLiveCalls: 0,
  liveCalls: [],
  agents: [],
  parentUserPlan: {},
  error: '',
};

const reducer = (state = initialState, action) => {
  let index, liveCallsArr, agentsArr, removeLiveCall;
  switch (action.type) {
    case FETCH_LIVECALLS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LIVECALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        error: '',
      };
    case FETCH_LIVECALLS_FAILURE:
      return {
        ...state,
        ...initialState,
        error: action.payload,
      };
    case LIVE_CALL_CREATED:
      liveCallsArr = [action.payload].concat(state.liveCalls);
      agentsArr = state.agents;
      liveCallsArr.forEach(liveCall => {
        index = findIndex(agentsArr, {"_id": liveCall.user ? liveCall.user._id : ''});
        if (index >= 0) {
          agentsArr[index]['onCall'] = true;
        }
      })
      return {
        ...state,
        totalLiveCalls: state.totalLiveCalls + 1,
        totalInboundCalls: action.payload.callType === 'Incoming' ? state.totalInboundCalls + 1 : state.totalInboundCalls,
        totalOutboundCalls: action.payload.callType === 'Outgoing' ? state.totalOutboundCalls + 1 : state.totalOutboundCalls,
        liveCalls:liveCallsArr,
        agents: agentsArr
      };

    case LIVE_CALL_UPDATED:
      liveCallsArr = state.liveCalls;
      index = findIndex(liveCallsArr, {"CallUUID": action.payload.CallUUID});
      if (index >= 0) {
        liveCallsArr.splice(index, 1, action.payload);
      }
      agentsArr = state.agents;
      liveCallsArr.forEach(liveCall => {
        index = findIndex(agentsArr, {"_id": liveCall.user ? liveCall.user._id : ''});
        if (index >= 0) {
          agentsArr[index]['onCall'] = true;
        }
      })
      return {
        ...state,
        liveCalls: liveCallsArr,
        agents: agentsArr

      };
    case LIVE_CALL_REMOVED:
      liveCallsArr = state.liveCalls;
      index = findIndex(liveCallsArr, {"CallUUID": action.payload.CallUUID});
      if (index >= 0){
        removeLiveCall = liveCallsArr[index];
        liveCallsArr.splice(index, 1);
      }
      agentsArr = state.agents; 
      if (removeLiveCall) {
        let agentIndex = findIndex(agentsArr, {"_id": removeLiveCall.user ? removeLiveCall.user._id : ''});
        if (agentIndex >= 0) {
          agentsArr[agentIndex]['onCall'] = false;
        }
      }
      return {
        ...state,
        totalLiveCalls: state.totalLiveCalls - 1,
        liveCalls: liveCallsArr
      };

    case AGENT_ADDED:
      agentsArr = state.agents;
      index = findIndex(agentsArr, {"_id": action.payload.user_id});
      if (index >= 0) {
        agentsArr.splice(index, 1, {
          ...agentsArr[index],
          available: true
        });
      }
      return {
        ...state,
        totalAvailableAgents: filter(agentsArr, user => user.available).length,
        agents: agentsArr
      };


    case AGENT_REMOVED:
      agentsArr = state.agents;
      index = findIndex(agentsArr, {"_id": action.payload.user_id});
      if (index >= 0){
        agentsArr.splice(index, 1, {
          ...agentsArr[index],
          available: false
        });
      }
      return {
        ...state,
        totalAvailableAgents: filter(agentsArr, user => user.available).length,
        agents: agentsArr
      };
    
    case POST_CALL_ACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    
    case POST_CALL_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    
    case POST_CALL_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
