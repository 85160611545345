import React from 'react';
import "./ErrorBoundary.css";

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const chlogo = imageUrl+"chlogo.svg";
const userData = JSON.parse(localStorage.getItem('userData'));
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
      return { hasError: true ,error};
  }

  refreshPage = (e) => {
    console.log("Clicked");
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    setTimeout(function(){
      window.location.reload(true);
    },1000)
  }

  render() {
    const {error, hasError} = this.state;
    if (hasError) {
      if(error && error.name==='ChunkLoadError'){
        // window.location.reload();
        this.refreshPage();
      }
      return (<div id="notfound">
          <div className="notfound">
              <img src={window.env.REACT_APP_LOGO} width="300px" />
              <h1>oops!</h1>
              {error.name === 'ChunkLoadError' ?
              <h2>
                This application has been updated, please refresh your browser to see the latest content.
              </h2>:
              <h2>Something went wrong</h2>
              }
              <a onClick={(e) => this.refreshPage(e)}>Please reload</a>
          </div>
      </div>);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
