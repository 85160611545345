import Pusher from "pusher-js";
import React, {Fragment,memo } from "react";
import dashboardActions from '@iso/redux/dashboard/actions';
import { useDispatch } from 'react-redux';

const { changeUserData, changeUserSetting } = dashboardActions;


const PusherConnection=(props)=> {
    console.log("this is props" , props)
    // console.log(props,"PusherConnection............")
    let userData = props.userData
    const dispatch = useDispatch();
           
    // React.useEffect(() => {
    //     const parentId = userData.parentId || userData._id;
    //     const pp = dispatch(pusherConnection(parentId));
        // console.log(pp,"ppppppppppppppppppppppppppppppp")
    //   }, []);
    if(userData){
        console.log("this is userdata" , userData)
        let self = this;
        let parentId = userData.parentId !=undefined ? userData.parentId : userData._id;
        const pusherDta = localStorage.getItem('socket_id');
        const pusherList = JSON.parse(localStorage.getItem('pusher'));
        const pusher = global.pusher;
        // const pusher =  new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        //     cluster: 'ap2',
        //     forceTLS: true,
        //     authEndpoint: authUrl
        // });

        pusher.connection.bind('error', function (err) {
            if (err.error.data.code === 4004) {
                // console.log("Pusher over limit !!")
                
            }
        });
        pusher.connection.bind('state_change', function (states) {
            // console.log("pusher state_change")
        });
        pusher.connection.bind('connected', function(res){
            console.log("pusheConnection")
            localStorage.setItem('socket_id',res.socket_id)
        });

        pusher.connection.bind('disconnected', function(err){
            localStorage.removeItem('socket_id')
            console.log("pusher disconnected")
        });
        if(props.pathname.includes("/dashboard")){
            let channelName = 'private-' + parentId;
            if (!(pusher.channel(channelName))) {
            let channel = pusher.subscribe(channelName);
            channel.bind('common-update', function (data) {
                let userData = JSON.parse(localStorage.getItem('userData'));
                if (data.key == 'credit-sidebar') {
                    userData.totalCredits = data.credit;
                    // dispatch(changeUserSetting({ data: { totalCredits: data.credit }, "type":"credit" }));
                }else if (data.key == 'amountDue') {
                    userData.amountDue = data.amountDue;
                    dispatch(changeUserSetting({ data: { amountDue: data.amountDue }, "type":"amountDue" }));
                }else if (data.key == 'userActive') {
                    userData.userActive = data.userActive
                    // dispatch(changeUserSetting({ data: { userActive: data.userActive }, "type":"userActive" }));
                    // ;
                    dispatch(changeUserData({ data: { userActive: data.userActive }, "type":"userActive" }));
                    ;
                }
                localStorage.setItem("userData", JSON.stringify(userData));
                //dispatch(changeUserData({ totalCredits: data.credit }));
                //dispatch(changeUserPlan({ availableCredits: data.credit }));
            });
            // console.log("this is datatttttt" , )
            channel.bind('livecall-created', function(data) {
                //return self.liveCallCreated(data);                
                dispatch(changeUserSetting({ data: data, "type":"livecall-created" }));
            });
            channel.bind('livecall-updated', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"livecall-updated" }));
            });
            channel.bind('powerDialer-agent-answer', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"powerDialer-agent-answer" }));
            });
            channel.bind('livecall-removed', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"livecall-removed" }));
            });
            channel.bind('livecall-completed', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"livecall-completed" }));
            });
            channel.bind('member_added', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"member_added" }));
            });
            channel.bind('member_removed', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"member_removed" }));
            });
            channel.bind('powerDialerCalls-update', function(data) {
                dispatch(changeUserSetting({ data: data, "type":"powerDialerCalls-update" }));
            });
            // channel.bind('livecall-bulkCreated', function(data) {
            //     console.log("this is bulk" , data)
            //     dispatch(changeUserSetting({ data: data, "type":"livecall-bulkCreated" }));
            // });
            
            // Comment code Appcues
            // let userData = JSON.parse(localStorage.getItem('userData'));
            // dispatch(changeUserSetting({ data: { amountDue: userData.amountDue,totalCredits: userData.totalCredits }, "type":"plan" }));
        // if (window.Appcues) {
        //     window.Appcues.identify(userData.email,{
        //         name: userData.fullName,
        //         email: userData.email,
        //         credit:userData.totalCredits,
        //         plan:userData.plan.planDisplayName,
        //         sub_users:userData.users,
        //         numbers:userData.numbers
        //     });
        // }

           
        }
    }else{
        global.pusher && global.pusher.disconnect();
        console.log("Pusher Disconnect",pusher)
    }
    }
    return (<Fragment></Fragment>)       
}
export default memo(PusherConnection)