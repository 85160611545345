import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const Customcalleridpopupstyle = styled.div`
.customcalleridwrapper .ant-row:after {
        display: block;
    }
.registerrequest{
    margin-bottom: 20px;
    font-size: 15px;
    text-align: center;
    margin-top: 13px;
}
.customcallercancel{
    margin-left: 20px;
}
.customcalleridbtn{
    text-align:center;
}
.addnumberbtn {
    background: #e27849;
    border-color: #e27849;
    color: #fff;
}
.addnumberbtn.ant-btn:hover,.addnumberbtn.ant-btn:focus,.addnumberbtn.ant-btn-active {
    background-color: #d46332;
    border-color: #e27849;
    color: #fff;
}
`
export default WithDirection(Customcalleridpopupstyle);