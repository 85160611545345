const initialState = {
               pusher: {}
             };
             
export default function pusherReducer(state = initialState, action) {
  switch (action.type) {
    case "PUSHER_CONNECTION":
      // console.log(action.payload,"action.payload")
      return {
        pusher: {
          ...action.payload,
        }
      };
    case "PUSHER_DISCONNECTION":
      return {
        pusher: null
      };
    default:
      return state;
  }
}
             