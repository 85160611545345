import Axios from 'axios';
import cookie from 'react-cookies';
import { APP_PUBLIC_ROUTE } from '@iso/components/root/routeConstants';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import axiosApi, { ClearUserData, allCookieClear, errorResponseStatus } from "../api";
import dashboardAction from "../dashboard/actions";

const { LANDING, LOGIN_IN, AUTH_PROFILE } = APP_PUBLIC_ROUTE;
const { USER_PROFILE, DASHBOARD, PLAN_BILLING, ACTIVITYFEED } = APP_PRIVATE_ROUTE;
const userData = JSON.parse(localStorage.getItem('userData'));

const setLocalStorage = userData => {
  var expires=new Date();
  expires.setDate(expires.getDate() + 31);
  let domain = window.env.REACT_APP_DOMAIN;
  cookie.save('authToken', userData.authToken, { path: '/', domain:domain,expires });
  cookie.save('billingToken', userData.billingToken, { path: '/', domain:domain  });
  cookie.save('user_id', userData._id, { path: '/', domain:domain });
  if (userData.isReportsApp) {
    cookie.save('chReports', userData._id, { path: '/', domain:domain });
    }
    
  cookie.save('noChatbot', !userData.isChatBox, { path: '/', domain:"."+domain,expires });
  localStorage.setItem('userData', JSON.stringify(userData));
  localStorage.setItem('authToken', userData.authToken);
  localStorage.setItem('billingToken', userData.billingToken);
  localStorage.setItem('user_id', userData._id);


  window.mixpanel.identify(userData.email);
  // Comment code Appcues
  // if (window.Appcues) {
  //   window.Appcues.identify(userData.email, {
  //     name: userData.fullName,
  //     email: userData.email,
  //     credit: userData.totalCredits,
  //     plan: userData.plan.planDisplayName,
  //     sub_users: userData.users,
  //     numbers: userData.numbers
  //   });
  //   window.Appcues.track("Appcuse");
  // }

  let obj = {
    "$name": userData.fullName,
    "$email": userData.email,
    "plan": userData.plan.planDisplayName,
    "available credit": userData.totalCredits,
    "sub_users": userData.users,
    "numbers": userData.numbers,
    "parent_id":userData.parentEmail?userData.parentEmail:userData.email
  }
  if (userData.parentId) {
    delete obj.sub_users
    delete obj.numbers;
  }
  window.mixpanel.people.set(obj);
}

const removeLocalStorage = () => {
  allCookieClear();
  localStorage.removeItem('userData');
  localStorage.removeItem('authToken');
  localStorage.removeItem('billingToken');
  localStorage.removeItem('user_id');
}

const loginUser = (postdata, history, dispatch) => {
  setLocalStorage(postdata.data.data);
  dispatch({
    type: authantication.AUTHENTICATION,
    postdata: postdata.data,
  })
  dispatch({
    type: dashboardAction.PUSHER_DATA,
    payload: { "data": postdata.data, type: "login" },
  })
  let redirectTo = window.env.REACT_APP_REPORTS_APP ? ACTIVITYFEED : postdata.data.data.isProfileRemaining ? AUTH_PROFILE : DASHBOARD;
  let referrer = cookie.load('referrer');
  if (postdata.data.data.isBlocked) {
    redirectTo = PLAN_BILLING;
  } else if (referrer) {
    redirectTo = referrer;
    cookie.remove('referrer');
  }
  history.push(redirectTo)

}
const authantication = {
  SIGN_UP: 'SIGN_UP',
  AUTHENTICATION: "AUTHENTICATION",
  SIGN_IN: 'SIGN_IN',
  LOG_OUT: 'LOG_OUT',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  GOOGLE_SIGN_IN: 'GOOGLE_SIGN_IN',
  GOOGLE_DIRECT_LOGIN: 'GOOGLE_DIRECT_LOGIN',
  MAGIC_LINK: 'MAGIC_LINK',
  RESET_PASSWORD: 'RESET_PASSWORD',
  ATTEMPT_FAIL: 'ATTEMPT_FAIL',
  MAGIC_LINK_AUTH: 'MAGIC_LINK_AUTH',
  SUB_USER_INVITE: 'SUB_USER_INVITE',
  CONFIRM_SIGN_UP_AUTH: 'CONFIRM_SIGN_UP_AUTH',
  UPDATE_COMMON_USER_DATA: 'UPDATE_COMMON_USER_DATA',
  TWO_FACTOR_AUTH_REDIRECT: "TWO_FACTOR_AUTH_REDIRECT",
  TWO_FACTOR_AUTH_FAILURE: "TWO_FACTOR_AUTH_FAILURE",
  ACCOUNT_NOT_VERIFY: "ACCOUNT_NOT_VERIFY",
  RESET_OTP_ERROR: "RESET_OTP_ERROR",
  BLOCK_ACCOUNT: "BLOCK_ACCOUNT",
  UPDATE_ONBOARDING_DETAILS: "UPDATE_ONBOARDING_DETAILS",
  RESEND_EMAIL_VERIFICATION: "RESEND_EMAIL_VERIFICATION",
  INVALID_LOGIN: "INVALID_LOGIN",
  RESEND_VERIFICATION: "RESEND_VERIFICATION",
  signUp: postdata => {
    return Axios.post(window.env.REACT_APP_BASE_URL + 'signup', postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }
    )
  },
  doLogin: (postdata, history) => {
    return (dispatch, getstate) => {
      let requestHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
      postdata.normalDirectLoginToken && (requestHeaders.normaldirectlogin = true)
      Axios.post(window.env.REACT_APP_BASE_URL + 'login', postdata,
        {
          headers: requestHeaders
        }
      ).then(postdata => {
        console.log("postdata.data.data.selectedCurrency", postdata.data.data.selectedCurrency)
        postdata.currency = postdata.data && postdata.data.data ? postdata.data.data.selectedCurrency : "USD"
        postdata.currency1 = postdata.data && postdata.data.data ? postdata.data.data.selectedCurrency : "USD"
        console.log("postdatapostdata", postdata)
        if (postdata.data.data.is2faEnabled) {
          dispatch({
            type: authantication.TWO_FACTOR_AUTH_REDIRECT,
            postdata: postdata
          })
        } else {
          console.log(postdata, "postdata")
          loginUser(postdata, history, dispatch);
        }
      }).catch((error) => {
        dispatch({
          type: authantication.AUTHENTICATION,
          postdata: error && error.response && error.response.data ? error.response.data : undefined,
        })
        dispatch({
          type: authantication.INVALID_LOGIN,
          postdata: error && error.response && error.response.data ? error.response.data : undefined,
        })
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.error && error.response.data.verified) {
          dispatch({
            type: authantication.ACCOUNT_NOT_VERIFY,
            postdata: true
          })
        }
        else {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error && error.response && error.response.data && error.response.data.error && error.response.data.error.error ? error.response.data.error.error : undefined, type: "errorLong" }
          })
        }
        postdata.normalDirectLoginToken && (history.push(LOGIN_IN))
      });
    }
  },

  twoFactorAuthLogin: (postdata, history) => {
    return (dispatch, getstate) => {
      Axios.post(window.env.REACT_APP_BASE_URL + '2falogin', postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      ).then(postdata => {
        loginUser(postdata, history, dispatch);
      }).catch((error) => {
        dispatch({
          type: authantication.TWO_FACTOR_AUTH_FAILURE,
          postdata: error.response.data,
        })
        if (error.response.data.blocked) {
          dispatch({
            type: "BLOCK_ACCOUNT",
            payload: { message: error.response.data.error.error, type: "error" }
          });
          dispatch({
            type: authantication.AUTHENTICATION,
            postdata: {}
          })
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error.error, type: "errorLong" }
          })
        } else {
          dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error.error, type: "error" }
          })
        }
      });
    }
  },

  resend2faCode: postdata => {
    return Axios.post(window.env.REACT_APP_BASE_URL + 'resend2facode', postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
  },

  logout: (history) => {
    return (dispatch, getstate) => {
      Axios.post(window.env.REACT_APP_BASE_URL + 'logout', {},
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authtoken': localStorage.getItem('authToken'),
            'billingtoken': localStorage.getItem('billingToken'),
            'id': localStorage.getItem('user_id'),
          }
        }
      ).then(postdata => {
        removeLocalStorage();
        dispatch({
          type: authantication.AUTHENTICATION,
          postdata: {},
        })
        dispatch({
          type: "CLEAR_CALL_PLANNER"
        })
      }).catch((error) => {
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: 'Please check your network connection and try again.', type: "error" }
        })
        ClearUserData(error.response);
      });
    }
  },
  forgotPassword: postdata => {
    return Axios.post(window.env.REACT_APP_BASE_URL + 'forgotpassword', postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'chauthkey': 'nb2IWsNX2hvMpJOcEJJVd06roVFqHmx8TsyN'
        }
      }
    )
  },
  resetpassword: postdata => {
    return Axios.post(window.env.REACT_APP_BASE_URL + 'reset/' + postdata.hash, postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
  },
  resetTokenCheck: postdata => {
    return Axios.post(window.env.REACT_APP_BASE_URL + 'resetTokenCheck/' + postdata.hash, postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
  },
  resendVerification: (postdata) => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}resendVerification`, "post", postdata, dispatch, `${authantication.RESEND_VERIFICATION}`),
  attemptFail: postdata => {
    return Axios.post(window.env.REACT_APP_BASE_URL + 'attemptfail/' + postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
  },
  subUserInvite: (postdata, history) => dispatch => {
    Axios.post(window.env.REACT_APP_BASE_URL + 'user/register/' + postdata.hash, postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    ).then(postdata => {

      if (localStorage.getItem('userData') != undefined && localStorage.getItem('userData') != null) {
        removeLocalStorage()
        window.location.href = "/login"
      } else {
        setLocalStorage(postdata.data.data);
        let isProfileRemaining = postdata.data.data.isProfileRemaining ? USER_PROFILE : DASHBOARD;
        dispatch({
          type: authantication.AUTHENTICATION,
          postdata: postdata.data,
        })
        history.push(isProfileRemaining)
      }


    }).catch((error) => {
      // if (error.response && error.response.status === 401) {
      //   return ClearUserData(error.response.data);
      // }
      dispatch({
        type: authantication.AUTHENTICATION,
        postdata: error.response.data,
      })
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: error.response.data.error.error, type: "error" }
      })
      history.push(LOGIN_IN)
    });
  },
  magiclinkauth: (postdata, history) => {
    return (dispatch, getstate) => {
      Axios.post(window.env.REACT_APP_BASE_URL + 'doLogintoken', postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      ).then(postdata => {
        setLocalStorage(postdata.data.data);
        dispatch({
          type: authantication.AUTHENTICATION,
          postdata: postdata.data,
        })
        let isProfileRemaining = postdata.data.data.isProfileRemaining ? AUTH_PROFILE : DASHBOARD;
        history.push(isProfileRemaining)
      }).catch((error) => {
        const postdata = error.response;
        dispatch({
          type: authantication.AUTHENTICATION,
          postdata: error.response.data,
        })
        history.push(LANDING)
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response.data.error.error, type: "error" }
        });

      });
    }
  },
  confirmSignUpAuth: (postdata, history, newFlowSignup) => {
    return (dispatch, getstate) => {
      let hashToken = postdata.hash;
      return Axios.post(window.env.REACT_APP_BASE_URL + 'confirmaccount/' + postdata.hash, postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      ).then(postdata => {
          let regUrl = '/register/' + hashToken;
          let returnData = {
            type: authantication.AUTHENTICATION,
            postdata:postdata.data,
          }
          if(postdata && postdata.data && postdata.data.data && !postdata.data.data.unBounceUser){
            setLocalStorage(postdata.data.data);
            dispatch(returnData)
          }
          let isProfileRemaining = (postdata.data && postdata.data.data && postdata.data.data.unBounceUser) ? regUrl : ((postdata.data.data.isProfileRemaining) ? AUTH_PROFILE : DASHBOARD);
          if(history && !newFlowSignup){
            history.push({
             pathname: isProfileRemaining,
             search: '',
             state: { detail: postdata.data}
           })
          }
         returnData = {
           type: returnData.type,
           payload: returnData.postdata
         }
        return returnData
      }).catch((error) => {
        if (error && error.response && error.response.data) {
          let returnData = {
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: error.response.data.error.error, type: "errorLong" }
          }
          dispatch(returnData)
          history.push(LANDING);
          return returnData
        }
      });
    }
  },
  googlesignin: postdata => {

    return (dispatch, getstate) => 
      Axios.post(window.env.REACT_APP_BASE_URL + 'googlesignin', postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      ).then(postdata => {
        if (!postdata.data.success && postdata.data.error) {
          return dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: postdata.data.error, type: "error" }
          })
        }
        //console.log(!postdata.data.success && postdata.data.error);
        if (postdata) {
          dispatch({
            type: authantication.AUTHENTICATION,
            postdata: postdata.data,
          })
          return {payload: postdata.data}
        }
      }).catch((error) => {
        dispatch({
          type: authantication.AUTHENTICATION,
          postdata: error.response ? error.response.data : "Something went wrong",
        })
      });
    
  },
  directlogin: (postdata, history) => {

    return (dispatch, getstate) => {
      Axios.post(window.env.REACT_APP_BASE_URL + (postdata.signInType == 'sso' ? 'ssoLogin' : 'directlogin'), postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      ).then(postdata => {
        setLocalStorage(postdata.data.data);
        if (postdata) {
          dispatch({
            type: authantication.AUTHENTICATION,
            postdata: postdata.data,
          })
        }
        let isProfileRemaining = postdata.data.data.isProfileRemaining ? AUTH_PROFILE : window.env.REACT_APP_REPORTS_APP ? ACTIVITYFEED : DASHBOARD;
        let referrer = cookie.load('referrer');
        if (referrer) {
          isProfileRemaining = referrer;
          cookie.remove('referrer');
        }
        history.push(isProfileRemaining)
      }).catch((error) => {
        dispatch({
          type: authantication.AUTHENTICATION,
          postdata: error.response.data,
        })
        dispatch({
          type: authantication.INVALID_LOGIN,
          postdata: error && error.response && error.response.data ? error.response.data : undefined,
        })
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response.data.error && error.response.data.error.error, type: "errorLong" }
        })
      });
    }
  },
  getMagicLink: postdata => {

    return Axios.post(window.env.REACT_APP_BASE_URL + 'getMagicLink', postdata,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'chauthkey': 'nb2IWsNX2hvMpJOcEJJVd06roVFqHmx8TsyN'
        }
      }
    )
  },
  changeAuth: (obj) => dispatch => {
    return dispatch({
      type: authantication.AUTHENTICATION,
      postdata: { success: true, data: obj }
    })
  },
  changeUserData: postData => dispatch => { dispatch({ type: authantication.UPDATE_COMMON_USER_DATA, payload: postData }) },
  resetOtpError: postData => dispatch => { dispatch({ type: authantication.RESET_OTP_ERROR, payload: postData }) },
  updateOnboardingDetails: (id, postData) => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}user/onboarding/${id}`, "post", postData, dispatch, `${authantication.UPDATE_ONBOARDING_DETAILS}`),
  updateUserSignupInfo: (id, postData) => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}updateUserSignupInfo`, "post", postData, dispatch, `${authantication.UPDATE_USERSIGNUP_INFO}`),
  resendEmailVerification: (postData) => dispatch => axiosApi(`${window.env.REACT_APP_BASE_URL}resendWithoutVerification`, "post", postData, dispatch, "SET_CUSTOM_MESSAGE"),
  resendEmailVeification: (postdata) => {
    return (dispatch, getstate) => {
      Axios.post(window.env.REACT_APP_BASE_URL + 'resendWithoutVerification', postdata,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      ).then(postdata => {
        if (postdata) {
          dispatch({
            type: authantication.RESEND_EMAIL_VERIFICATION,
            postdata: postdata.data,
          })
        }
        let message = postdata.data.data;
        console.log(message, "message")
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message, type: "success" }
        })
        return postdata
      }).catch((error) => {
        dispatch({
          type: authantication.RESEND_EMAIL_VERIFICATION,
          postdata: error.response.data,
        })
        dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: error.response.data.error, type: "errorLong" }
        })
      });
    }
  }
}
export default authantication;

