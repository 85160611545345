import React, { lazy, Suspense ,useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Link,useLocation ,matchPath,Redirect} from "react-router-dom";
import appActions from '@iso/redux/app/actions';
import { useDispatch } from 'react-redux';
import { DashboardContainer, DashboardGlobalStyles } from './containers/Dashboard/Dashboard.styles';
import { Layout } from 'antd';
import message from '@iso/components/Feedback/Message';
import cookie from 'react-cookies';
import Sidebar from './containers/Sidebar/Sidebar.js';
import BroadcastSidebar from "./containers/Sidebar/broadcastSiderbar";
import Topbar from './containers/Topbar/Topbar';
import siteConfig from './config/site.config';
import useWindowSize from '@iso/lib/hooks/useWindowSize';
import { PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { PUBLIC_ROUTE } from '@iso/components/root/routeConstants';
import { APP_PRIVATE_ROUTE, APP_PUBLIC_ROUTE } from '@iso/components/root/routeConstants';
import { Switch, Route } from 'react-router';
import Loader from '@iso/components/utility/loader';
import moment from 'moment';
import SidebarCt from './containers/Sidebar/Sidebar_ct';
import SidebarCoach from './containers/Sidebar/Sidebar_coach';
import ChangeResendEmail from './components/ChangeResendEmail';
import OurCompliances from '../src/components/OurCompliances/ourCompliances';
import PusherConnection from './pusher';
import { pusherDisConnection, pusherConnection } from "./redux/puhser/action";
import SidebarWhatsapp from './containers/Sidebar/Siderbar_whatsapp';

const { PLAN, PLAN_BILLING } = APP_PRIVATE_ROUTE;
const { AUTH_PROFILE } = APP_PUBLIC_ROUTE;

let privateRoutesMenu = [
  {
    path: PRIVATE_ROUTE.NUMBER,
    component: lazy(() => import('@iso/containers/AddNumber')),
  },
  {
    path: PRIVATE_ROUTE.ADD_NUMBER,
    component: lazy(() => import('@iso/containers/AddNumber/addNumber')),
  },
  {
    path: PRIVATE_ROUTE.NUMBER_SETTING,
    component: lazy(() => import('@iso/containers/AddNumber/numberSettings')),
  },
  {
    path: PRIVATE_ROUTE.NUMBER_BY_ID,
    component: lazy(() => import('@iso/containers/AddNumber/numberSettings')),
  },
  {
    path: PRIVATE_ROUTE.NUMBER_CUSTOMER_AVAILABILITY,
    component: lazy(() => import('@iso/containers/AddNumber/Calendar/Calendar.js')),
  },
  {
  // ADD_DID_GROUPs
    path: PRIVATE_ROUTE.DID_GROUPS,
    component: lazy(() => import('@iso/containers/didGroups/didgroups.js')),
  },
  {
    path: PRIVATE_ROUTE.ADD_DID_GROUP,
    component: lazy(() => import('@iso/containers/didGroups/addDidgroup.js')),
  },
  {
    path: PRIVATE_ROUTE.DID_GROUP_BY_ID,
    component: lazy(() => import('@iso/containers/didGroups/editDidgroup.js')),
  },
  {
    path: PRIVATE_ROUTE.USERS,
    component: lazy(() => import('@iso/containers/Users')),
  },
  {
    path: PRIVATE_ROUTE.USER_BY_ID,
    component: lazy(() => import('@iso/containers/Users/userSettings')),
  },
  {
    path: PRIVATE_ROUTE.USER_CUSTOMER_AVIBLITY,
    component: lazy(() => import('@iso/containers/Users/Calendar/Calendar.js')),
  },
  {
    path: PRIVATE_ROUTE.INVITE_USER,
    component: lazy(() => import('@iso/containers/Users/inviteUser')),
  },
  {
    path: PRIVATE_ROUTE.PLAN,
    component: lazy(() => import('@iso/containers/PlanSetting/PlanSetting')),
  },
  {
    path: PRIVATE_ROUTE.CALL_LOG_HISTROY,
    component: lazy(() => import('@iso/containers/CallReport/userCallLogHistory')),
  },
  {
    path: PRIVATE_ROUTE.USER_PROFILE,
    component: lazy(() => import('@iso/containers/UserProfile/userProfile')),
  },
  {
    path: PRIVATE_ROUTE.USER_PROFILE_DOCUMENT,
    component: lazy(() => import('@iso/containers/UserProfile/uploadDocument')),
  },
  // {
  //   path: PRIVATE_ROUTE.CHANGE_EMAIL,
  //   component: lazy(() => import('@iso/containers/UserProfile/changeEmail')),
  // },
  {
    path: PRIVATE_ROUTE.CHANGE_PASSWORD,
    component: lazy(() => import('@iso/containers/ChangePassword/changePassword')),
  },

  {
    path: PRIVATE_ROUTE.IMPORT_CONTACT,
    component: lazy(() => import('@iso/containers/ImportContact/importContact')),
  },
  
  {
    path: PRIVATE_ROUTE.CALL_REPORT_DASHBOARD,
    component: lazy(() => import('@iso/containers/CallReport/callReportDashboard')),
  },
  {
    path: PRIVATE_ROUTE.ABANDON_RATE_REPORT,
    component: lazy(() => import('@iso/containers/CallReport/callReports')),
  },
  {
    path: PRIVATE_ROUTE.AVAILABILITY_REPORT,
    component: lazy(() => import('@iso/containers/CallReport/callReports')),
  },
  {
    path: PRIVATE_ROUTE.USER_STATUS_REPORT,
    component: lazy(() => import('@iso/containers/CallReport/callReports')),
  },
  {
    path: PRIVATE_ROUTE.CALL_STATUS_REPORT,
    component: lazy(() => import('@iso/containers/CallReport/callReports')),
  },
  {
    path: PRIVATE_ROUTE.OUTGOING_CALL_REPORT,
    component: lazy(() => import('@iso/containers/CallReport/callReports')),
  },
  {
    path: PRIVATE_ROUTE.CALL_REPORT,
    component: lazy(() => import('@iso/containers/CallReport/callReports')),
  },
  {
    path: PRIVATE_ROUTE.CALL_PLANNER,
    component: lazy(() => import('@iso/containers/callPlanner')),
  },
  {
    path: PRIVATE_ROUTE.TEAMS,
    component: lazy(() => import('@iso/containers/Teams/Teams')),
  },
  {
    path: PRIVATE_ROUTE.ADD_TEAMS,
    component: lazy(() => import('@iso/containers/Teams/AddTeam')),
  },
  {
    path: PRIVATE_ROUTE.TEAM_BY_ID,
    component: lazy(() => import('@iso/containers/Teams/EditTeam')),
  },
  {
    path: PRIVATE_ROUTE.CAMPAIGNMANAGEMENT,
    component: lazy(() => import('@iso/containers/CampaignManagement/CampaignManagement')),
  },
  {
    path: PRIVATE_ROUTE.ADD_CAMPAIGNMANAGEMENT,
    component: lazy(() => import('@iso/containers/CampaignManagement/AddCampaignManagement')),
  },
  {
    path: PRIVATE_ROUTE.CAMPAIGNMANAGEMENT_BY_ID,
    component: lazy(() => import('@iso/containers/CampaignManagement/EditCampaignManagement')),
  },
  {
    path: PRIVATE_ROUTE.EDIT_CAMPAIGNS,
    component: lazy(() => import('@iso/containers/powerDialer/addCampaigns')),
  },
  {
    path: PRIVATE_ROUTE.INTEGRATION,
    component: lazy(() => import('@iso/containers/Integrations/integrations')),
  },
  {
    path: PRIVATE_ROUTE.POWER_DIALER,
    component: lazy(() => import('@iso/containers/powerDialer')),
  },
  {
    path: PRIVATE_ROUTE.ADD_CAMPAIGNS,
    component: lazy(() => import('@iso/containers/powerDialer/addCampaigns')),
  },
  {
    path: PRIVATE_ROUTE.UPDATE_CAMPAIGNS,
    component: lazy(() => import('@iso/containers/powerDialer/updateCampaigns')),
  },
   {
    path: PRIVATE_ROUTE.DASHBOARD,
    component: lazy(() => import('@iso/containers/Dashboard/index.js')),
  },
  {
    path: PRIVATE_ROUTE.MONITER_USER,
    component: lazy(() => import('@iso/containers/Dashboard/MoniterUser.js')),
  },
  {
    path: PRIVATE_ROUTE.DUMMY_CALL_LOG,
    component: lazy(() => import('@iso/containers/ActivityFeed/CallLog/dummyCallLogs'))
  },
  {
    path: PRIVATE_ROUTE.DUMMY_SMS_LOG,
    component: lazy(() => import('@iso/containers/ActivityFeed/SmsLog/dummySmsLogs'))
  },
  {
    path: PRIVATE_ROUTE.DUMMY_BROADCAST_ACTIVITY_FEED,
    component: lazy(() => import('@iso/containers/ActivityFeed/CallLog/dummyCallLogs'))
  },
  // {
  //   path: PRIVATE_ROUTE.COACH,
  //   component: lazy(() => import('@iso/containers/Coach/index.js'))
  // },
  {
    path: PRIVATE_ROUTE.CALL_TRACKING,
    component: lazy(() => import('@iso/containers/CallTrack/index.js'))
  },
  {
    path: PRIVATE_ROUTE.BROADCAST_CALL,
    component: lazy(() => import('@iso/containers/powerDialer')),
  },
  {
    path: PRIVATE_ROUTE.CALL_SCRIPT,
    component: lazy(() => import('@iso/containers/CallScript/index.js'))
  },
  {
    path: PRIVATE_ROUTE.ADD_CALL_SCRIPT,
    component: lazy(() => import('@iso/containers/CallScript/addScript.js'))
  },
  {
    path: PRIVATE_ROUTE.EDIT_CALL_SCRIPT,
    component: lazy(() => import('@iso/containers/CallScript/addScript.js'))
  },
  {
    path:PRIVATE_ROUTE.BROADCAST,
    component: lazy(() => import('@iso/containers/broadcast/dashboard/index.js'))
  },
  {
    path:PRIVATE_ROUTE.ADD_CAMPAIGNS_BROADCAST,
    component: lazy(() => import('@iso/containers/powerDialer/addCampaigns.js')),
    exact:true
  },
  {
    path:PRIVATE_ROUTE.EDIT_CAMPAIGNS_BROADCAST,
    component: lazy(() => import('@iso/containers/powerDialer/addCampaigns.js')),
    exact:true
  },
  {
    path: PRIVATE_ROUTE.BROADCAST_ACTIVITY_FEED,
    component: lazy(() => import('@iso/containers/ActivityFeed/activityFeed')),
    exact:true
  },
  {
    path: PRIVATE_ROUTE.REPORT,
    component: lazy(() => import('@iso/containers/Report/index.js'))
  },
  {
    path: PRIVATE_ROUTE.INTEGRATIONCALLTRACK,
    component: lazy(() => import('@iso/containers/IntegrationCallTrack/index.js'))
  },
  {
    path: PRIVATE_ROUTE.ACTIVITYFEEDCALLTRACK,
    component: lazy(() => import('@iso/containers/ActivityFeedCallTrack/index.js'))
  },
  {
    path: PRIVATE_ROUTE.CALLDETAIL,
    component: lazy(() => import('@iso/containers/CallDetail/index.js'))
  },
  {
    path: PRIVATE_ROUTE.NUMBERCALLTRACK,
    component: lazy(() => import('@iso/containers/NumberCallTrack/index.js'))
  },
	{
    path: PRIVATE_ROUTE.ADD_NUMBERCALLTRACK,
    component: lazy(() => import('@iso/containers/NumberCallTrack/AddNumberCallTrack.js'))
  },
  {
    path: PRIVATE_ROUTE.NUMBER_SETTINGCALLTRACK,
    component: lazy(() => import('@iso/containers/NumberCallTrack/NumberSettingCallTrack.js'))
  },
  {
    path: PRIVATE_ROUTE.CAMPAIGNCALLTRACK,
    component: lazy(() => import('@iso/containers/CampaignCallTrack/index.js'))
  },
  {
    path: PRIVATE_ROUTE.PLAN_BILLING,
    component: lazy(() => import('@iso/containers/PlanBilling/PlanBilling'))
  },
  {
    path: PRIVATE_ROUTE.LEADERBOARD,
    component: lazy(() => import('@iso/containers/LeaderBoard/index'))
  },
  {
    path: PRIVATE_ROUTE.TARGET_VS_ACHIEVEMENT,
    component: lazy(() => import('@iso/containers/TargetVsAchievement/index.js'))
  },
  {
    path: PRIVATE_ROUTE.ADD_TARGET,
    component: lazy(() => import('@iso/containers/TargetVsAchievement/addTarget.js'))
  },
  {
    path: PRIVATE_ROUTE.EDIT_TARGET,
    component: lazy(() => import('@iso/containers/TargetVsAchievement/editTarget.js'))
  },
  {
    path: PRIVATE_ROUTE.INTEGRATION_INSTALL,
    component: lazy(() => import('@iso/containers/Integrations/install.js')),
  },
  {
    path:PRIVATE_ROUTE.CALL_RECORDING_PAGE,
    component: lazy(() => import('@iso/containers/Pages/CallRecording/Index.js'))
  },
  {
    path:PRIVATE_ROUTE.CALL_SUMMARY,
    component: lazy(() => import('@iso/containers/Coach/index.js'))
  },
  {
    path:PRIVATE_ROUTE.COACHCALLANALYSIS,
    component: lazy(() => import('@iso/containers/CoachCallAnalysis/index.js'))
  },
  {
   path:PRIVATE_ROUTE.EVALUATIONCOACHING,
   component: lazy(() => import('@iso/containers/CoachEvaluationCoaching/index.js'))
  },
  {
    path:PRIVATE_ROUTE.MANAGETHEMES,
    component: lazy(() => import('@iso/containers/CoachManageThemes/index.js'))
  },
  {
    path:PRIVATE_ROUTE.WHATSAPP_BUSINESS_MAIN,
    component: lazy(() => import('@iso/containers/WhatsappBusiness/index.js'))
  },
  {
    path:PRIVATE_ROUTE.WHATSAPP_BUSINESS_LIVECHAT,
    component: lazy(() => import('@iso/containers/WhatsappBusiness/liveChat.js'))
  },
  {
    path:PRIVATE_ROUTE.WHATSAPP_BUSINESS_HISTORY,
    component: lazy(() => import('@iso/containers/WhatsappBusiness/history.js'))
  },
  {
    path:PRIVATE_ROUTE.WHATSAPP_BUSINESS_CONTACTS,
    component: lazy(() => import('@iso/containers/WhatsappBusiness/contacts.js'))
  },
  {
    path:PRIVATE_ROUTE.WHATSAPP_BUSINESS_CAMPAIGNS,
    component: lazy(() => import('@iso/containers/WhatsappBusiness/wpcampaign.js'))
  },
  {
    path:PRIVATE_ROUTE.WHATSAPP_BUSINESS_MANAGE,
    component: lazy(() => import('@iso/containers/WhatsappBusiness/appSetting.js'))
  },
  {
    path: PRIVATE_ROUTE.CALL_LOG,
    component: lazy(() => import('@iso/containers/ActivityFeed/activityFeed.js')),
  },
  {
    path: PRIVATE_ROUTE.SMS_LOG,
    component: lazy(() => import('@iso/containers/ActivityFeed/activityFeed.js')),
  },
  {
    path: PRIVATE_ROUTE.SMS_VERIFICATION,
    component: lazy(() => import('@iso/containers/AddNumber/smsVerification.js')),
  },
  ];

const { Content, Footer } = Layout;
const { toggleAll, pageLoad } = appActions;
const styles = {
  layout: { flexDirection: 'row', overflowX: 'hidden' },
  content: {
    padding: '70px 0 0',
    flexShrink: '0',
    background: '#f1f3f6',
    position: 'relative',
    height: '100vh',
    overflow: 'auto'
  },
  footer: {
    background: '#ffffff',
    textAlign: 'center',
    borderTop: '1px solid #ededed',
  },
  stickyChangeEmail:{
    position: 'sticky',
    zIndex: '100',
    top:'0px'
  }
};

export default function Home(props) {
  const dispatch = useDispatch();
  const appHeight = useSelector(state => state.App.height);
  const PageLoaded = useSelector(state => state.App.PageLoaded);
  const location = useLocation();
  const { width, height } = useWindowSize();
  const [creditBtn, setCreditBtn] = useState(true);
  const [creditBtn5, setCreditBtn5] = useState(true);
  const [creditBtn1, setCreditBtn1] = useState(true);
  const [amountDueBtn, setAmountDueBtn] = useState(true);
  const [url, setUrl] = useState('');

  const [showCallingCreditSection, setShowCallingCreditSection] = useState(false);
  const { isAuthenticated, userData } = useSelector(state => state.authentication);
  const { userCredits } = useSelector(state => state.dashboard);
  const { get_plan } = useSelector(state => state.planReducer);
  const { pusher } = useSelector(state => state.pushserReducer);

  // console.log(useSelector(state => state),"useSelector(state => state.planReducer")
  // const availableCredits = get_plan.length !=0 && get_plan.data.availableCredits.availableCredits.toFixed(2);


  let history = useHistory();
  if (!PageLoaded && isAuthenticated) {
    if (userData.data.isBlocked) {
      dispatch(pageLoad());

      const warningMsg = msg => { message.destroy(); message.warning(msg) };
      !userData.data.amountDue && warningMsg(userData.data.blockReason);
      // userData.data.amountDue && warningMsg(userData.data.blockReason + " Please update your payment method.");
      let search = window.location.search;
      const params = new URLSearchParams(search);
      const redirectUrl = params.get('r');
      if(!redirectUrl){
        history.push(PLAN_BILLING);
       
      }
    }
    if (userData.data.isProfileRemaining || /* userData.data.isPhoneVerificationPending || */ userData.data.isOnBoardingProcessPending) {
      dispatch(pageLoad());
      history.push(AUTH_PROFILE);
    }
  }
  React.useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);

  React.useEffect(() =>{
    if( userData.data.plan.isAccessEditable && userData.data.isDisableCredit && !(userData.data.roleData.priority == 1 || userData.data.roleData.priority == 1.5)){
      setShowCallingCreditSection(!userData.data.isDisableCredit)
    }else{
      setShowCallingCreditSection(true)
    }
  }, [userData])
  
  React.useEffect(() => {
    if (window.env.REACT_APP_REPORTS_APP == 'true' && userData.data.isReportsApp) {
      privateRoutesMenu = [
        {
          path: PRIVATE_ROUTE.CALL_REPORT,
          component: lazy(() => import('@iso/containers/CallReport/callReports')),
        },
        {
          path:PRIVATE_ROUTE.CALL_RECORDING_PAGE,
          component: lazy(() => import('@iso/containers/Pages/CallRecording/Index.js'))
        }
      ]
    }
  }, []);

  React.useEffect(() => {
    // console.log(location.pathname.includes("/dashboard"),">>>>>>>>>>>>>>>>>>>>>>")
    if(location.pathname.includes("/dashboard") || location.pathname.includes("/powerDialer")|| location.pathname.includes("/moniteruser")){
      const parentId = userData.data.parentId || userData.data._id;
      dispatch(pusherConnection(parentId,userData.data))
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if(location.pathname == "/activityFeed"){
      history.push('/calllog');
    }
  }, [location.pathname]);
  
  const updateCookies = () => {
    setCreditBtn(false)
    setCreditBtn1(false)
    setCreditBtn5(false)
    cookie.save('creditDisplayMessage',false,{path:"/",domain:window.env.REACT_APP_DOMAIN});
  }
  const updateCookies5 = () => {
    setCreditBtn5(false)
    setCreditBtn(false)
    setCreditBtn1(false)
    cookie.save('creditDisplayMessage5',false,{path:"/",domain:window.env.REACT_APP_DOMAIN});
  }
  const updateCookies1 = () => {
    setCreditBtn1(false)
    setCreditBtn(false)
    setCreditBtn5(false)
    cookie.save('creditDisplayMessage1',false,{path:"/",domain:window.env.REACT_APP_DOMAIN});
  }

  const updateCookiesAmountDue = () => {
    setAmountDueBtn(false)
    cookie.save('amountDueDisplayMessage',true,{path:"/",domain:window.env.REACT_APP_DOMAIN});
  }

  const lowCreditPopup=()=>{
    let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });

    let isPopupShow = !userCredits.amountDue || (userCredits.amountDue && (!amountDueBtn || cookie.load("amountDueDisplayMessage")));
    if(showCallingCreditSection){
    if(userData.data && !userData.data.isBlocked &&  !userData.data.isFreeTrialUser && userCredits && isPopupShow &&  userData.data.plan && !userData.data.plan.isOfficePhoneSystem ){
      if(userData.data && userData.data.stripeId && ( userData.data.totalCredits.toFixed(2) <= 1 ) && creditBtn1 && !cookie.load("creditDisplayMessage1")){
        return(<div id="g_reminder_alert_box">
        <div className="addcredit_ctntn">
        <span className="g_reminder_name">{userData.data && userData.data.fullName},</span><span>You may miss an important call or get disconnected | You just have&nbsp;</span><span>{userData.data && userData.data.totalCredits > 0 && userData.data.totalCredits.toFixed(2)  ? formatter.format(userData.data.totalCredits.toFixed(2)):0}</span>&nbsp;<span>Credits left</span><Link to="/planBilling">Add Credits Now</Link><i className="material-icons g_reminder_remove_icon_style" id="g_reminder_alert_close" onClick={() => updateCookies1()}>clear</i>
        </div>
      </div>)
      }if(userData.data && userData.data.stripeId && (userData.data.totalCredits.toFixed(2) <= 5) && creditBtn5 && !cookie.load("creditDisplayMessage5")){
        return( <div id="g_reminder_alert_box">
        <div className="addcredit_ctntn">
        <span className="g_reminder_name">{userData.data && userData.data.fullName},</span><span>You may miss an important call or get disconnected | You just have&nbsp;</span><span>{userData.data && userData.data.totalCredits > 0 && userData.data.totalCredits.toFixed(2)  ? formatter.format(userData.data.totalCredits.toFixed(2)):0}</span>&nbsp;<span>Credits left</span><Link to="/planBilling">Add Credits Now</Link><i className="material-icons g_reminder_remove_icon_style" id="g_reminder_alert_close" onClick={() => updateCookies5()}>clear</i>
        </div>
      </div>)
      }
      else if(userData.data && userData.data.stripeId &&  userData.data.totalCredits.toFixed(2) <= 10  && creditBtn && !cookie.load("creditDisplayMessage")){
        return( <div id="g_reminder_alert_box">
        <div className="addcredit_ctntn">
        <span className="g_reminder_name">{userData.data && userData.data.fullName},</span><span>You may miss an important call or get disconnected | You just have&nbsp;</span><span>{userData.data && userData.data.totalCredits > 0 && userData.data.totalCredits.toFixed(2) ? formatter.format(userData.data.totalCredits.toFixed(2)):0}</span>&nbsp;<span>Credits left</span><Link to="/planBilling">Add Credits Now</Link><i className="material-icons g_reminder_remove_icon_style" id="g_reminder_alert_close" onClick={() => updateCookies()}>clear</i>
        </div>
      </div>)
      }
      } 
  }
  }

  const amountDuePopup=()=>{
    if( window.top.document.querySelector(".crisp-client")){
      window.top.document.querySelector(".crisp-client").style.display = cookie.load("noChatbot") =='true' ? 'none' :'block'
    }
    // console.log("amountDuePopup called",userCredits, cookie.load("amountDueDisplayMessage"),amountDueBtn)
    if(!userData.data.isBlocked && userCredits && userCredits.amountDue && !userData.data.isFreeTrialUser && amountDueBtn && !cookie.load("amountDueDisplayMessage")){
      // console.log("amountDuePopup called here")
      return(<div id="g_reminder_alert_box" className="amount-due">
        <div className="addcredit_ctntn amountdue-text">
          <span className="ng-binding">{userData.data && userData.data.fullName},</span><span>{userData.data && userData.data.blockReason}</span>&nbsp;<span></span><Link to={PLAN_BILLING}>Click here</Link><i className="material-icons g_reminder_remove_icon_style" id="g_reminder_alert_close" onClick={() => updateCookiesAmountDue()}>clear</i>
        </div>
      </div>)
    }
  }

  const freeTrailPopup=()=>{
    if(userData.data && userData.data.isFreeTrialUser && userData.data.numbers > 0){
      let setUserData = JSON.parse(localStorage.getItem('userData'));
      let expireDate = setUserData && setUserData.expiredFreeTrailDate ? setUserData.expiredFreeTrailDate : moment();
      return(<div id="g_reminder_alert_box" className="amount-due">
        <div className="addcredit_ctntn amountdue-text">
          <span className="toCapitalize"></span><span>Your trial ends in {moment(expireDate).diff(moment(), 'days')} days</span>&nbsp;<span></span><i className="material-icons g_reminder_remove_icon_style" id="g_reminder_alert_close" onClick={() => updateCookies()}>clear</i>
        </div>
      </div>)
    }
  }
  // const withTracker = () => {
    // if(window.Appcues) {
    //   window.Appcues.page();
    // }
  // };
  const passUrl = (url) => {
    setUrl(url)
   
  }
  const NotFound = () =>  {
    if(isAuthenticated){
      history.push('/dashboard')
    }else{
      history.push('/login')
    }
    return (
      <>
      <h1>404 Not Found </h1>
        </>
    )
  }
  

  return (
    <DashboardContainer>
      {/* {console.log(pusher,"pusherrrrrrrrrrrrrrrrrrrr")} */}
        {isAuthenticated == true && pusher && Object.keys(pusher).length > 0  &&<PusherConnection pathname={location.pathname} userData ={userData.data} pusher={pusher}/>}
      <DashboardGlobalStyles />
      <Layout style={{ height: height }}>
        <Topbar passUrl={passUrl}/>
        <Layout style={styles.layout}>
        {location.pathname.includes("/whatsappbusiness") &&
        <SidebarWhatsapp />
         }
        {location.pathname.includes("/coach") &&
        <SidebarCoach />
         }
          {location.pathname.includes("/calltracking") || location.pathname.includes("/calldetail") ? 
          <SidebarCt />
          :
          (location.pathname.includes("/broadcast")) ? <BroadcastSidebar /> : (!location.pathname.includes("/install") && !location.pathname.includes("/coach") && !location.pathname.includes("/whatsappbusiness") ? <Sidebar /> : ""
          )}
          <Layout
            className="isoContentMainLayout"
            style={{
              height: appHeight,
            }}
          >           
            <Content className="isomorphicContent" style={styles.content}>
              {/* Start Change and Resend Email Parvati*/}
              <div style={styles.stickyChangeEmail}>
              <ChangeResendEmail />
              </div>
             {/* End Change and Resend Email Parvati */}
              <Suspense fallback={<Loader />}>
                <Switch>
                  {privateRoutesMenu.map((route, idx) => (
                    <Route exact={true} key={idx} path={`${route.path}`}>
                      <route.component/>
                    </Route>
                  ))}
                  <Route path="*"exact={true} component={NotFound}/>
                </Switch>
              </Suspense>

            </Content>
        {/* <Footer className='d__block footer_bottom_sticky'>
              <p className='text-center bottom_text'>
                <a href="https://www.callhippo.com" target='_blank'>
                Powered by CallHippo</a>
              </p> */}
          {/* {siteConfig.footerText} */}
          {/* </Footer> */}
        </Layout>
        </Layout>
        {lowCreditPopup()}
        {amountDuePopup()}
      </Layout>
    </DashboardContainer>
  );
}
