import React from 'react';
import styled from 'styled-components';
import { Row, Col} from 'antd'
import Hippa from '../../assets/images/hippaCompliant.png';
import pci from '../../assets/images/pciCompliant.png';

const OurCompliancesStyle = styled.div`
.our__compliances__logoImg {
    max-width: 90px;
    margin-left: 10px;
}
.position__Initial {
    position: initial;
}
`;

export default function OurCompliances() {
    return (
        <OurCompliancesStyle>
            <Row>
                <Col className="gutter-row mgtop15 mgbtm10 position__Initial" span={24}>
                    <div className="text-center chflex chflexcenter">
                        <h3>Our Compliances:</h3>
                        <img src={Hippa} alt="HIPAA" className="our__compliances__logoImg"/>
                        <img src={pci} alt="PCI" className="our__compliances__logoImg"/>
                    </div>
                </Col>
                
            </Row>
        </OurCompliancesStyle>
    )
}