import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import whiteLabelEnvLive from "./config/whiteLabelEnvLive";
import whiteLabelEnvTest from "./config/whiteLabelEnvTest";

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

//Very important to run app
window.env = process.env.REACT_APP_ENVIRONMENT == "LIVE" ?
    whiteLabelEnvLive[isLocalhost ? 'localhost' : window.location.hostname] || whiteLabelEnvLive['web.callhippo.com'] :
    whiteLabelEnvTest[isLocalhost ? 'localhost' : window.location.hostname] || whiteLabelEnvTest['staging-app.callhippo.com'];

    
if(window.env.REACT_APP_ENVIRONMENT !== "LOCAL" && window.env.REACT_APP_IS_CRISPCHAT_ENABLED){
    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }
    const getCrispCookie = function (name) { var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)')); if (match) return match[2]; }
    getCrispCookie('noChatbot') && JSON.parse(getCrispCookie('noChatbot')) && (window.top.document.head.insertAdjacentHTML("beforeend", '<style type="text/css">.crisp-client { display: none; }</style>'))
    var crisp_keys = [];
    window.$crisp = []; window.CRISP_WEBSITE_ID = window.env.REACT_APP_CRISP_WEBSITE_ID || "ce269983-bd92-4062-917b-2a29aa89b96b"; (function () { let d = document; let s = d.createElement("script"); s.src = "https://client.crisp.chat/l.js"; s.async = 1; d.getElementsByTagName("head")[0].appendChild(s); })();
    let stateCheck = setInterval(() => {
        var match = document.cookie.match(new RegExp('(^| )crispemail=([^;]+)'));
        if (match) {
            window.$crisp.push(["set", "user:email", match[2]]);
            var chatcsmagent = document.cookie.match(new RegExp('(^| )chatcsmagent=([^;]+)'));
            if (chatcsmagent) {
                window.$crisp.push(["set", "session:data", [[["csm", chatcsmagent[2]]]]]);
            }
            clearInterval(stateCheck);
        }
        ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"].forEach(function (source) {
            if (getUrlVars()[source]) {
                crisp_keys.push([source, getUrlVars()[source]]);
            }
        });
        if (crisp_keys.length > 0) {
            window.$crisp.push(["set", "session:data", [crisp_keys]]);
        }
    }, 100);
}

if(window.env.REACT_APP_ENVIRONMENT !== "LOCAL"){
    if(window.env.REACT_APP_IS_CALLMEWIDGET_ENABLED){
        var e = document.createElement("div");
        e.id = "callhippo-widget-container";
        document.getElementsByTagName("body")[0].appendChild(e);
        window.USERID = "58dcf6c3f329f14c016168a8";
        var e = document.createElement("script");
        e.type = "text/javascript"
        e.async = !0
        e.src = "https://d1x9dsge91xf6g.cloudfront.net/callhippo/files/callmeback.min.js";
        var t = document.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(e, t);        
    }
    
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-P58KL6S');

    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", window.env.REACT_APP_MS_CLARITY);
}

document.getElementById("favicon").href = window.env.REACT_APP_FAVICON
document.getElementById("app-page-title").text = window.env.REACT_APP_PAGETITLE

ReactDOM.render(<Fragment> <Provider store={store}><App /></Provider></Fragment>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
