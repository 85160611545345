import styled from 'styled-components';

const AddNumPopup = ComponentName => styled(ComponentName)`
    display: flex;
    .ant-modal-content {
        float: left;
        width: 100%;
    }
    .ant-modal-body {
        float: left;
        width: 100%;
    }
    .ant-modal-footer {
        float: left;
        width: 100%;
    }
    .mcenter{
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .adresspopuprct .ant-modal-content {
        float: left;
        width: 100%;
    }
    .adresspopuprct .ant-modal-body {
        float: left;
        width: 100%;
    }
    .adresspopuprct .ant-modal-footer {
        float: left;
        width: 100%;
        text-align: center;
        padding: 15px 0px;
    }
    .adresspopuprct {
        width: 800px;
        display: flex;
    }
    .stepwizard-step p {
        margin-top: 10px
    }

    .stepwizard {
        position: relative;
        width: 100%;
        padding-top: 0;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .stepwizard-row:after {
        left: 25%;
        top: 17px;
        position: absolute;
        content: " ";
        width: 50%;
        border: .5px dotted #8b8b8b;
        border-width: 1.2px
    }
    .stpcheck {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        margin-bottom: 0
    }

    .stepwizard-step {
        float: left;
        text-align: center;
        position: relative;
        z-index: 1;
        width: 187px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .stepwizard-btn-circle-one {
        border: 1.5px dotted #8b8b8b;
        border-width: 1.9px
    }

    .stepwizard-btn-circle-two {
        border: 1.5px dotted #8b8b8b;
        border-width: 1.9px
    }
    .ml-0 {
        margin-left: 0px !important;
    }
    .stepwizard-btn-circle-two span {
        font-size: 17px;
        color: #8b8b8b;
        font-weight: 500
    }

    .stepwizard-btn-circle-three {
        border: 1.5px dotted #c1c1c1;
        border-width: 1.9px
    }

    .stepwizard-btn-circle-three span {
        color: #c1c1c1;
        font-weight: 500;
        font-size: 17px
    }
    .emptybox_feature {
        padding:10px !important;
    }
    .number-container.brdorange{
        height:100%;
    }
    .step-check-icon {
        width: unset;
        font-weight: 600;
        font-size: 20px!important;
        margin-top: 4px
    }

    .stepwizard-btn-circle-one,.stepwizard-btn-circle-two,.stepwizard-btn-circle-three {
        width: 35px;
        height: 35px;
        background-color: #fff;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        border-radius: 38px;
        color: #3c8103;
        display: flex;
        justify-content: center;
        align-items: center
    }

    .stepwizard-btn-circle-one span i {
        color: #8b8b8b
    }
    .numplan_title_offerchange {
        font-size: 22px;
        padding-top: 25px;
        margin-bottom: 40px;
        width: 100%;
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .number_purchse_an_offer {
        position: absolute;
        top: -40px;
        left: 156px;
        min-width: 149px;
    }
    .num_purchse_span {
        padding: 5px 0;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        background-color: #19313f;
        border-radius: 3px;
        color: #fff;
        font-size: 11.5px;
        float: left;
        width: 138px;
    }
    .num_purchse_span::before {
        content: '';
        position: absolute;
        top: 24px;
        right: 214px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #19313f;
        right: inherit;
        left: 65px;
        transform: rotate(270deg);
        margin-top: -6px;
        border-top: 6px solid transparent;
        border-right: 6px solid #19313f;
        border-bottom: 6px solid transparent;
        border-left: inherit;
    }
    .number-container {
        flex: 1;
        border: 1px solid #dbdbdb;
        border-radius: 7px;
        text-align: center;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    }
    .num-annuactive {
        border: 1px solid #e27646;
    }
    .num-annu-priceactive {
        border-top: 1px solid #e27646;
    }
    .numplanrch {
        width: 273px;
        margin-left: auto;
    }
    .numplanrch2 {
        width: 273px;
        margin-right: auto;
    }
    .plandigit {
        display: inline-block!important;
        position: relative!important;
        top: -13px!important;
        margin: 0 auto!important;
        padding: 0 10px!important;
        background-color: #fff!important;
        color: #6b6b6b!important;
        font-size: 12px!important;
        letter-spacing: .8px!important;
        text-transform: uppercase!important;
        box-sizing: border-box!important;
        border: 1px solid #e3e3e3;
        background-color: #f5f5f5!important;
        border-radius: 4px;
    }
    .num_background {
        background-color: #676363!important;
        color:
        #fff!important;
    }

    .plan_title_silver {
        background-color: #e37546!important;
        color: #fff!important;
        font-weight: 600;
        border: 1px solid #e37546;
    }
    .planstat {
        color: #6b6b6b;
        font-size: 56px;
        font-weight: 100;
        line-height: 70px;
        position: relative;
    }
    .plan_price_position {
        position: relative;
    }
    .plan_type_label {
        font-size: 10px;
        font-weight: 400;
    }
    .actrctprice {
        border-top: 1px solid #e27646;
    }
    .rdiochrct .ant-radio-inner {
        border-color: #e27646;
    }
    .rdiochrct .ant-radio-inner::after {
        background-color: #e27646;
    }
    .addnumboxborder {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }
    .bgwhite {
        background: #fff;
    }
    .planprice {
        padding: 12px 0;
        border-top: 1px solid #dbdbdb;
        color: #6b6b6b;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: .8px;
        text-transform: uppercase;
    }
    .lbpadleft {
        margin-bottom: 0!important;
        padding-left: 30px!important;
    }
    .labellight {
        font-weight: 400;
    }
    .plan_currency_AN {
        font-size: 16px;
        vertical-align: 41px;
        position: absolute;
        right: 100%;
        top: -16px;
        font-weight: 400;
    }
    .lnhnormal .ant-form-item-control {
        line-height: normal;
    }
    .paybtnrct {
        padding: 0 4rem;
        margin-bottom:5px;
    }
    .duration_center {
        display: flex;
        justify-content: center;
    }
    .duration_monthly {
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        height: 30px;
        width: 110px;
        align-items: center;
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .duration_annually {
        border: 1px solid #e37546;
        border-radius: 3px;
        height: 30px;
        width: 110px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .priceplanttitle {
        font-size: 22px;
        float: left;
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .pldateslrct {
        margin-bottom: 2px !important;
        padding-left: 12px;
    }
    .pldateslrct .ant-radio-wrapper span {
        font-size: 13px;
        font-weight: 500;
    }
    .number_purchse_an_offer_secpage {
        position: absolute;
        top: 8px;
        min-width: 150px;
        right: 135px;
    }
    .num_purchse_span_sec {
        padding: 5px 11px;
        text-align: center;
        background-color: #19313f;
        border-radius: 3px;
        color: #fff;
        margin-left: -100px;
        font-size: 11.5px;
    }
    .num_purchse_span_sec::before {
        content: '';
        position: absolute;
        top: 4px;
        right: 134px;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-right: 7px solid #19313f;
        border-bottom: 7px solid transparent;
    }
    .planslbox {
        width: 278px;
        margin: 0 auto;
    }
    .mlauto{
        margin-left: auto !important;
    }
    .planslbox2 {
        width: 225px;
        margin-right: auto;
    }
    .slrctplan {
        width: 155px;
        background-color: #fff;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.04), 0 1px 5px 0 transparent, 0 3px 1px -2px rgba(0,0,0,.2);
        color: #e37546;
        line-height: 32px;
        font-weight: 500;
        border: 1px solid #e37546;
        height: 36px;
    }
    .slrctplan:hover {
        background-color: green;
        border: 1px solid green;
        color: white;
    }
    .plan_check_icon {
        float: left;
        margin-right: 5px;
        margin-left: 5px;
        font-size: 18px;
    }
    .plan_selected i {
        line-height: 33px;
    }
    .plan_selected, .plan_selected:active, .plan_selected:focus {
        border: 1px solid green;
        background-color: green;
        color: #fff;
        margin-left: 10px;
    }
    .Trial_selected{
        margin-right: 10px;
    }
    .Trial_selected i {
        line-height: 33px;
    }
    .Trial_selected, .Trial_selected:active, .Trial_selected:focus {
        border: 1px solid #1890ff;
        background-color: #1890ff;
        color: #fff;
        width: 155px;
        line-height: 32px;
        font-weight: 500;
        height: 36px;
    }
    .whoplan {
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .whoplan i {
        font-size: 18px;
        color: darkgrey;
        cursor:pointer;
    }
    /*verifacationpopup start*/
    .verificationpopup .ant-modal-body{
    float:left;
    width:100%;
    }
    .veficationpopupaddressform{
    float:left;
    width:100%;
    }
    .verificationpopup .verificationpopup-container{
    padding-bottom:10px;
    }
    .verificationpopup .verificationpopup-fileupload{
        display:flex;
        align-items: center;
        background-color:#f5f5f5;
        border-color:#d9d9d9;
        color:#000;
        margin-top:12px;
    }
    .verificationpopup .verificationpopup-icon{
    margin-right:5px;
    }
    .verificationpopup .ant-modal-footer{
    text-align:center!important;
    }
    .verificationpopup .ant-upload-list-item{
    margin-top:15px!important;
    }
    .verificationpopup .ant-modal-title{
    text-align:center;
    }

    /* credit popup new css */

    .more_credit_check_icon {
        display: flex;
        justify-content: center;
    }
    .credit_icon_border {
        height: 80px;
        width: 80px;
        border: 3.5px solid #1c8e1c;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 999px;
    }
    .more_credit_check_icon .credit_icon_border .material-icons {
        font-size: 50px;
        color: #1c8e1c;
    }
    .modal_more_credit_congrets {
        text-align: center ;
    }
    .modal_more_credit_congrets .modal_credit_text2 {
        font-size: 38px;
    }
    .modal_more_credit_congrets hr {
        width: 40%;
        border: none;
        border-bottom: 1px solid #9e9e9e3d;
        margin: 0 auto;
    }
    .modal_more_credit_congrets .credit_model_text_span {
        font-size: 13px;
    }
    .modal_more_credit_congrets .credit_model_text_span:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        color: #676363;
        font-weight: 400;
    }
    .modal_more_credit_congrets .modal_credit_text3 {
        font-size: 30px;
        margin-top: 10px;
        line-height: 1.2;
    }
    .modal_more_credit_congrets .credit_model_text_span:last-of-type {
        color: #676363;
        font-weight: 400;
    }
    .modal_more_credit_dd_btn {
        text-align : center;
        margin-top: 35px;
    }
    .modal_more_credit_dd_btn .credit_model_select_option {
        border: none;
        box-shadow: 0 0 3px #8e8e8e80;
        padding: 8px 20px;
        border-radius: 4px;
        font-weight: 600;
        cursor: pointer;
    }
    .modal_more_credit_dd_btn .credit_btn-new:first-of-type,
    .modal_more_credit_dd_btn .credit_btn-new:first-of-type:hover,
    .modal_more_credit_dd_btn .credit_btn-new:first-of-type:focus,
    .modal_more_credit_dd_btn .credit_btn-new:first-of-type:active {
        border: none;
        background-color:#e27646;
        color: #fff;
        padding: 8px 25px;
        border-radius: 4px;
        text-transform: uppercase;
        letter-spacing: .5px;
        margin-left: 15px;
        box-shadow: 0 0 3px #8e8e8e80;
        cursor: pointer;
    }

    .modal_more_credit_dd_btn .credit_btn-new:last-of-type {
        border: none;
        background-color: #9e9e9e;
        color: #fff;
        padding: 8px 25px;
        text-transform: uppercase;
        letter-spacing: .5px;
        border-radius: 4px;
        margin-left: 15px;
        box-shadow: 0 0 3px #8e8e8e80;
        cursor: pointer;
    }
     .modal_more_credit_whycall a {
        color: #000;
        text-decoration: underline;
    }
    .linkcss a {
        color:#1890ff !important;
    }
    .modal_more_credit_whycall {
        margin-top: 40px;
    }

    .addCreditNewPopup .ant-modal-footer {
        display: none;
    }
    .credit_model_text_span .material-icons {
        font-size: 18px;
        margin-left: 5px;
    }
    .modal_more_credit_call_icon {
        margin-top: 15px;
    }

    .planSelect__duration .ant-radio-wrapper{
        border: 1px solid rgb(227, 227, 227);
        background-color: whitesmoke;
        border-radius: 3px;
        height: 30px;
        width: 120px;
        margin: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .planSelect__duration .ant-radio-wrapper-checked{
        border: 1px solid #e37546;
        background-color: #fff;
    }
    .planSelect__duration .ant-radio-inner{
        border: 2px solid #c1c1c1 !important;
        width: 10px;
        height: 10px;
    }
    .planSelect__duration .ant-radio-checked::after{
        border: 2px solid #e37546;
        width: 10px;
        height: 10px;
    }
    .planSelect__duration .ant-radio-inner::after{
        border: 2px solid #c1c1c1;
    }
    .planSelect__duration .ant-radio-checked .ant-radio-inner::after{
        background: #e37546;
        border-color: #e37546;
        top: -3px;
        left: -3px;
        height: 12px;
        width: 12px;
    }
    .planSelect__duration .ant-radio-wrapper:hover .ant-radio, .planSelect__duration .ant-radio:hover .ant-radio-inner, .planSelect__duration .ant-radio-input:focus + .planSelect__duration .ant-radio-inner{
        border-color: #c1c1c1;
    }
    .numberPopup{
        .number_edit_modal_note{
           list-style-type: disc;
           padding-left: 16px;
           margin-top: 10px;
           margin-bottom: 10px;
        }
    }
    .systemDocUploadButton{
        .verificationpopup-fileupload{
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 5px;
        }
        .verificationpopup-icon{
            margin-right:10px;        
        }
        .required:after {
            content:" *";
            color: red;
         }
     }
     /*seat purchased dropdown*/
    

     .seatpurchasedBuyNowRow{        
        align-items:center;
        display:-webkit-flex;
        justify-content:space-between;
        margin-top:17px
     }
     .ant-modal-footer{
         position:relative;
     } 
     .seatpurchasedlabel{
         color:#000;
         font-weight:500;
     }
     .seatpurchasedtooltip{
        font-size: 18px;
        vertical-align: middle;
        color: darkgray;
        margin-right: 5px;
        cursor: pointer;
     }
     .seatdropdown .ant-select-dropdown-menu-item{
         text-align:left;
     }
     .ofcCountryErr{
         color :red;
         font-weight:bold;
         margin-top:15px;
     }
     .plancontainer:after{
        content: "";
        clear: both;
        display: block;
     }
     .plancontainer:before{
        content: "";
        clear: both;
        display: block;
     }
     .totalseatinput{
        border: 1px solid #ddd;
        width: 40px;
     }
     /*currency dropdown*/
     .mothcost_dropdown{
        top: 3px;
        left: 335px;
    }
    .currecydrop_monthcost:before{
        content: '';
        top: 4px;
        left: -48px;
        transform: rotate(0deg);
        right:unset;
    } 
    .currencydropbtm{
        margin-bottom: 35px;
    }
    .w-100{
        width:100%
    }
    .unlimited_calling_div .ant-form-item-children{
        justify-content:center;
    }
    .currencydropdownbtn{
        color:#000;
    }
    .currencydropdownbtn .ant-select-selection:hover{
        color:#000;
        border: 1px solid #dedede;
    }
    .currencydropdownwrapper{
        position: absolute;
        right: 22px;
    }
    .currencydropdownwrapper label{
        color:#000;
        font-size: 15px;
        margin-right: 5px;
    }
    .currencydropdownbtn .ant-select-selection:active{
        box-shadow: 0 0 0 2px rgb(130 136 142 / 8%);
        border: 1px solid #dedede;
    }
    .currencydropdownbtn .ant-select-focused .ant-select-selection, .ant-select-selection:focus{
        border: 1px solid #dedede;
    }
    .currencydropdownbtn .ant-select-selection--single{
        height:30px;
    }
    .currencydropdownbtn .ant-select-focused .ant-select-selection, .ant-select-selection:focus{
        box-shadow: 0 0 0 2px rgb(130 136 142 / 8%);
    }
     .addnumberbtn {
        background: #3369E7;
        border-color: #3369E7;
        color: #fff;
    }
    .addnumberbtn.ant-btn:hover,.addnumberbtn.ant-btn:focus,.addnumberbtn.ant-btn-active {
        background-color: #3369E7;
        border-color: #3369E7;
        color: #fff;
    }
    .uploadbtn {
        background: #e27849;
        border-color: #e27849;
        color: #fff;
    }
    .uploadbtn.ant-btn:hover,.uploadbtn.ant-btn:focus,.uploadbtn.ant-btn-active {
        background-color: #d46332 !important;
        border-color: #e27849 !important;
        color: #fff;
    }
    .hippaImg {
        width: 70px;
        height: 22px;
        margin-right: 10px;
    }
    
    .pciImg {
        width: 70px;
        height: 28px;
    }
    .d-flex{
        /* align-items: center; */
        /* margin: auto; */
        margin-top: 30px;
    }
     .hippaPciCol {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      /*reserve seat */
      .seatpurchaseddropdownbtn {
        position: absolute;
        left: 27px;
        top: 5px;
      }
      .purchasebtnend {
          text-align: center;
          margin-top:10px;        
      }
      .comparePlanLink{
        color: #19313f;
        border-bottom: 1px solid black;
        font-weight: 500;
      }
      .switchwrapper {
        display: flex;        
    } 
    .tabBorderTop {
        border-top:1px solid #e8e8e8;
    }
    .opsplanBox {
        width:247px !important;
    }
    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab:hover {
        color:#e27646;
    }
    .ant-tabs-ink-bar {
        background-color:#e27646;
    }
    .checkoutBtn,
    .checkoutBtn:hover,
    .checkoutBtn:active,
    .checkoutBtn:focus {
        background-color:#e27646;
        color:#fff;
        border:1px solid #e27646;
    }
    .numberPlanTooltip {
    position: absolute;
    right: 7px;
    top: -15px;
    font-size: 18px;
    color:darkgray
    }
    .scheme-list {
        padding-left: 25px;
    }
    .scheme-list-details::before {
        border-radius: 50%;
        top: 0px;
        content: "✓";
        color: green;
        display: block;
        position: absolute;
        left: -11px;
    }
    .scheme-list .scheme-list-details {
        font-weight: 400 !important;
        color:#333;
        font-size: 14px;
        margin: 4px 0px;
        list-style-type: none;
        padding-left: 4px;
        position: relative;
        text-align: left;
        text-transform: none;
        letter-spacing: normal;
    }
    .upperText {
    font-size: 10px;
    text-transform: none;
    font-weight: 400;
    font-style: italic;
    }
    .mgtop20 {
        margin-top:20px;
    }
    .mgleft25 {
        margin-left:25px;
    }
    .scheme-list .scheme-list-details .material-icons {
        font-size:14px;
    }
    .tab-text-title {
        // border-bottom: 1px solid #eee;
        margin-bottom: 0 !important;
        padding-bottom: 10px !important;
    }
    .serviceText {
        color:#000;
        font-size:15px;
    }
    .ant-form-item-children {
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .infopopopen {
        font-size: 20px;
        color: darkgray;
    .scheme-list {
        padding-left: 15px;
    }
    .scheme-list-details {
        font-weight: 500;
        font-size: 15px;
        margin: 8px 0px;
        list-style-type: none;
        padding-left: 4px;
        position: relative;
    }    
    .scheme-title-box {
        background: rgb(255,255,255);
        padding: 0px 0px 5px 10px;
        border-radius: 5px;
        font-style: italic;
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        color: #333;
        text-transform: initial;
    }
    .addNoTabHide .ant-tabs-top-bar{
        display: none;
    }
     @media (min-width: 768px) and (max-width: 1024px) {
        .monthcosttab{
           left:341px !important;
        }
        .switchwrapper {
            display: flex;
            margin-left: 34px;
        } 
        .seatpurchaseddropdownbtn {
            left: 12px; 
        }
        .currencydropdownwrapper {
            right:7px;
        }
     }
     
`;

export default AddNumPopup;