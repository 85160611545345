import styled from 'styled-components';

const AddNumberModal = ComponentName => styled(ComponentName)`
width: 700px !important;
.ant-modal-title{
  text-align:center;
  font-size:22.5px;
  font-weight: 700;
  margin: 1.46rem 0 1.168rem;
}
.ant-modal-header{
  border-bottom:none;
}
.ant-modal-footer{
  border-top:none;
}

.addNumberModalWrapper{
  display:flex;
  justify-content: center;
  align-items: center;
}
.callcountries {
  text-align: center;
  padding: 0 18px;
  border-right: 2px solid #e27646;
  width:31.25%;
}
.callcountries:last-child {
  border-right: 0;
}
.addNumberModalWrapper img {
  width: 60px;
}
.callcountryheading {
  margin-top: 10px;
  color: #444;
  font-size: 17px;
}
.manymoretext{
  margin: 1.14rem 0 .912rem;
}
.manymoretext a{
  text-align:center;
  font-weight:700;
  color: #e27646;
  font-size:18px;
  text-decoration: underline;
}
.endtourtextwrapper{
  text-align:center;
}
.endtourbtnwrapper{
  text-align:center;
  margin-top:10px;
}
.etaddnumber{
    color: #fff!important;
    background-color: #e27646!important;
    border: #e27646!important;
    text-align:center;
    height: 36px;
    line-height: 36px;
    padding: 0 2rem;
    text-transform: uppercase;
    font-size:14px;
    border-radius:4px;
    cursor:pointer;
}
.etaddnumber:hover{
    background-color: #f56e31!important;
    border: #f56e31!important;
    color:#fff !important;
}
`;

export default AddNumberModal;