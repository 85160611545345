import React, { useState, useEffect } from 'react';
import upgradewrapper from './upgradeOptionpopup.style';
import {  Button,Row,Col  } from 'antd';
import Modals from '@iso/components/Feedback/Modal';
import {useDispatch, useSelector} from "react-redux";
import modalActions from '@iso/redux/modal/actions';
import numberAction from "@iso/redux/number/actions";
import message from '@iso/components/Feedback/Message';
const Modal = upgradewrapper(Modals);
const { closeModal } = modalActions;
const {sendDetailsForVoxbone} = numberAction;


const Upgradeoptionpopup = () => {
    const dispatch = useDispatch();
    const { modalVisibility, modalType, modalProps } = useSelector(state => state.modal);

    const userData = JSON.parse(localStorage.getItem('userData'));
    if(!userData){
        window.location.href ="/login"
    }

    useEffect(() => {
    }, [modalProps])


    const handleCancel = () => {
        dispatch(closeModal());
    };

    const sendRequest = () => {
        dispatch(sendDetailsForVoxbone({type : 'addcampaign',featureName:modalProps.feature,onRequest:true}));
        dispatch(closeModal());
        message.destroy();
        message.success("Your Request has been sent successfully. We will notify you upon activation via email.")
    };

    return (
        <Modal
        title={modalProps.feature}
        visible={modalVisibility && modalType == "onRequest"}
        onCancel={handleCancel}
        width="500px"
        centered
        footer={[
          <Button className="purchasenowbtn" onClick={sendRequest}>Send Request</Button>
        ]}
        >
             <div className="upgradewrapper">
                <Row>
                    <Col span={24}>
                            <h3 className="registerrequest"> {modalProps.feature == "Power Dialer" ? "Sky Rocket your agent's performance by 3x via Power Dialer. If an agent is making 200 calls a day, with power dialer it can be 750 a day at least.":"Just convey your Offer, Fire Sale or any announcement details and reach out to people without having any involvement of an agent."} </h3>
                    </Col>                   
                </Row>                
             </div>
        </Modal>
    )
}
export default  Upgradeoptionpopup;