import { message } from 'antd';
import React, { Component } from 'react';

class CustomToast extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show
        }
        }
          info = (message1) => {
            message.info(message1);
          };
          success = (message2) => {
            message.success(message2)
          }
          error = (message3) => {
            message.error(message3)
          }
        render(){
            return(
            <div >
            {this.info(this.props.message1)}
            {this.success(this.props.message2)}
            {this.error(this.props.message3)}
            </div>
            );
        }
}

export default CustomToast;