import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Layout,Tooltip } from 'antd';
import message from '@iso/components/Feedback/Message';
import Scrollbars from '@iso/components/utility/customScrollBar';
import Menu from '@iso/components/uielements/menu';
import appActions from '@iso/redux/app/actions';
import Logo from '@iso/components/utility/logo';
import SidebarWrapper from './Sidebar.styles';
import { Link, withRouter } from 'react-router-dom';
import CHTour from './CHTour';
import { APP_PRIVATE_ROUTE, PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { Helmet } from "react-helmet";
import AddNumberModal from './AddNumberModal.js';
import PlanUpgradePopup from "@iso/components/Popups/PlanUpgrade/PlanUpgradePopup";
import Addonpopup from "../../components/Popups/addOnpopup";
import dashboardAction from "../../redux/dashboard/actions";
import ResumeAccountPopup from "../../components/Popups/resumeAccountPopup";
import {InfoCircleFilled} from '@ant-design/icons';


const { NUMBERCALLTRACK,CALL_TRACKING,INTEGRATIONCALLTRACK,ACTIVITYFEEDCALLTRACK,CALLDETAIL,CAMPAIGNCALLTRACK, PLAN_BILLING, USER_PROFILE, USER_PROFILE_DOCUMENT, CHANGE_PASSWORD} = APP_PRIVATE_ROUTE;

const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
const {getCredits} = dashboardAction ;

function SidebarCt(props) {
  const dispatch = useDispatch();
  const {
    view,
    openKeys,
    collapsed,
    openDrawer,
    current,
    height,
  } = useSelector(state => state.App);

  const userData = useSelector(state => state.authentication.userData.data);
  const numberData = useSelector(state => state.number.get_number);
  const user_billing = useSelector(state => state.number.user_billing);
  const get_plan = useSelector(state=>state.planReducer.get_plan);
  const isTourOpen = useSelector(state => state.authentication.isTourOpen);
  const pusherData = useSelector(state => state.dashboard.pusher_data);
  const userCredits = useSelector(state => state.dashboard.userCredits);
  var currentCredit = userCredits && userCredits.credits ? userCredits.credits : 0;
  // Pusher connecttion
  const [showCallingCreditSection, setShowCallingCreditSection] = useState(false);
  const [sidebarCredit, setSidebarCredit] = useState(0);
  const [sidebarCreditRedirect, setSidebarCreditRedirect] = useState(false);

  //This useEffect will call's every time when this component loaded
  useEffect(() => { 
    if (!userData.isTourVisited && !userData.isProfileRemaining) props.history.push(CALL_TRACKING) ;
    sidebarCreditChange();
  }, []);
  
  //Change credit when push event call
  useEffect(() => { if(pusherData && pusherData.type === "credit") sidebarCreditChange();}, [pusherData]);
  useEffect(() =>{
    if(userData.plan.isAccessEditable && userData.isDisableCredit && !(userData.roleData.priority == 1 || userData.roleData.priority == 1.5)){
      setShowCallingCreditSection(!userData.isDisableCredit)
    }else{
      setShowCallingCreditSection(true)
    }
  }, [userData])
  const customizedTheme = useSelector(
    state => state.ThemeSwitcher.sidebarTheme
  );

  const SIDEBAR_ROUTE = {
    CALL_TRACKING: {
      URL: CALL_TRACKING,
      KEY: 'calltracking',
      URL_GROUP: [CALL_TRACKING]
    },
    INTEGRATIONCALLTRACK: {
      URL: INTEGRATIONCALLTRACK,
      KEY: 'integration',
      URL_GROUP: [INTEGRATIONCALLTRACK]
    }, 
    ACTIVITYFEEDCALLTRACK: {
      URL: ACTIVITYFEEDCALLTRACK,
      KEY: 'activity',
      URL_GROUP: [ACTIVITYFEEDCALLTRACK]
    },
    CALLDETAIL: {
      URL: CALLDETAIL,
      KEY: 'calldetail',
      URL_GROUP: [ACTIVITYFEEDCALLTRACK]
    },
    NUMBERCALLTRACK : {
      URL: NUMBERCALLTRACK,
      KEY: 'numbercalltrack',
      URL_GROUP: [NUMBERCALLTRACK]
    },
    CAMPAIGNCALLTRACK : {
      URL: CAMPAIGNCALLTRACK,
      KEY: 'campaigncalltrack',
      URL_GROUP: [CAMPAIGNCALLTRACK]
    },
    PLAN_BILLING: {
      URL: PLAN_BILLING,
      KEY: 'planBilling',
      URL_GROUP: [PLAN_BILLING]
    },
    USER_PROFILE_DOCUMENT: {
      URL: USER_PROFILE_DOCUMENT,
      KEY: 'uploadDocument',
      URL_GROUP: [USER_PROFILE_DOCUMENT]
    }
  }

  console.log("")

  useEffect(() => {
    dispatch(changeCurrent([]));
    Object.values(SIDEBAR_ROUTE).filter(obj => {
      obj.URL_GROUP.map((obj2) => {
        // if (props.history.location.pathname.toLowerCase().includes(obj2.toLowerCase())) {
        //   dispatch(changeCurrent([obj.KEY]));
        // }
      })
    })
    if(![USER_PROFILE,USER_PROFILE_DOCUMENT,CHANGE_PASSWORD].includes(props.history.location.pathname) && userData.isDocVerificationRequired != "approved"){
      props.history.push(USER_PROFILE_DOCUMENT);
    }
  }, [props.history.location.pathname])

  function handleClick(e) {
    //dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }

  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };
  const checkCredits = () =>{
    props.getCredits().then(({payload})=>{
      if(payload.success){
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
      let finalCredit= payload.data ? payload.data.totalCredits ? formatter.format( payload.data.totalCredits.toFixed(2).toString()):sidebarCredit : sidebarCredit;
      let finalCr= payload.data ? payload.data.totalCredits ? payload.data.totalCredits:sidebarCredit : sidebarCredit;
      let userData = JSON.parse(localStorage.getItem('userData'));
      userData.totalCredits = finalCr;
      localStorage.setItem("userData", JSON.stringify(userData));
      setSidebarCredit(finalCredit);  
      }
    });
  }
  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const onMouseEnter = event => {      
    if(window.outerWidth > 1024){
      if (collapsed && openDrawer === false) {
        dispatch(toggleOpenDrawer());
      }
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };

  const sidebarCreditChange = () => {
    let finalCredit = 0;
    if (pusherData && pusherData.type === "credit") {
      finalCredit = parseFloat(pusherData.data.totalCredits)
    } else {
      if (!(!numberData || !numberData.data || !numberData.data.length) && user_billing && user_billing.data && user_billing.data.data) {
        finalCredit = parseFloat(user_billing.data.data.availableCredits - user_billing.data.data.usedCredits)
      } else {
        if (userCredits && userCredits.credits) {
          finalCredit = userCredits.credits
        } else {
          if (userData && userData.stripeId && userData.plan && userData.plan.planDisplayName && userData.plan.planDisplayName !== 'free') {
            if (!userData.parentId || (userData.parentId && userData.totalCredits && userData.userModules.indexOf('billing') >= 0)) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
              setSidebarCreditRedirect(true);
            } else if (userData && userData.parentId && userData.userModules && userData.userModules.indexOf('billing') == -1) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
            }
          }
        }
      }
    }
    let userDataLocal = JSON.parse(localStorage.getItem('userData'));
    userDataLocal.totalCredits = finalCredit;
    localStorage.setItem("userData", JSON.stringify(userDataLocal));
    let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
    finalCredit = formatter.format(finalCredit.toFixed(2).toString());
    setSidebarCredit(finalCredit);
  }
  
  const warningMsg = msg => { message.destroy(); message.warning(msg) };
  const userIsBlocked = () => {
    userData.isBlocked && !userData.amountDue && warningMsg(userData.blockReason);
    //userData.isBlocked && userData.amountDue && warningMsg(userData.blockReason + " Please update your payment method.");

  };
  const tabTitleChange = () => {
    if (current != []) {
      current.splice(0, current.length)
      current.push("calltracking")
    }

  }
  function changeTabTitle(currentKey) {
    const keyName = currentKey[0];
    if (currentKey[0] == "activityfeed") {
      return "activity"
    } else if (currentKey[0] == "voiceBroadcast") {
      return "Voice Broadcast"
    }
    else if (currentKey[0] == undefined) {
      return "calltracking"
    }else {
      return keyName[0].toUpperCase() + keyName.slice(1);
    }
  }
  
  return (
    <div>
      
      <SidebarWrapper>
        {/* <PusherConnection userData={userData} /> */}
        <Helmet>
          <title>{changeTabTitle(current)} | {window.env.REACT_APP_PAGETITLE}</title>
        </Helmet>
        <CHTour isOpen={isTourOpen} />
        <AddNumberModal />
        <PlanUpgradePopup />
        <Addonpopup />
        <ResumeAccountPopup />

        <Sider
          trigger={null}
          collapsible={false}
          collapsed={isCollapsed}
          width={240}
          className={`isomorphicSidebar  ${userData && userData.isProfileRemaining ? "sidebarDisabled" : ''}`}
          // onMouseEnter={onMouseEnter}
          // onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={isCollapsed} isBlocked={userData.isBlocked} />
          <Scrollbars className={`sidebarScrollbars scrolltabheight ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`} 
            style={ isCollapsed ? {height: height - 75} : {height: height - 94}}
          >
            <Menu
              onClick={handleClick}
              theme="dark"
              className="isoDashboardMenu callTrack_Sider_outer_div"
              mode={mode}
              openKeys={isCollapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={onOpenChange}
            >
              <Menu.Item key={SIDEBAR_ROUTE.CALL_TRACKING.KEY} className={window.location.pathname == '/calltracking' ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.CALL_TRACKING.URL} >
                    <span className="isoMenuHolder svgremove">
                      {/* <i className="callreporticon"></i> */}
                      <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"/></svg>
                      <span className="nav-text">Dashboard</span>
                    </span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key={SIDEBAR_ROUTE.INTEGRATIONCALLTRACK.KEY} className={window.location.pathname.indexOf('/integration') > -1 ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.INTEGRATIONCALLTRACK.URL}>
                  <span className="isoMenuHolder">
                    <i className="integrationicon"></i>  
                    <span className="nav-text">Integrations</span>
                    </span>
                    </Link>
                    </Menu.Item>
              <Menu.Item key={SIDEBAR_ROUTE.ACTIVITYFEEDCALLTRACK.KEY} data-tour="tour-activity" className={window.location.pathname.indexOf('/activity') > -1 || window.location.pathname.indexOf('/calldetail') > -1 ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.ACTIVITYFEEDCALLTRACK.URL}>
                  <span className="isoMenuHolder svgremove">
                    <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18h2V6H7v12zm4 4h2V2h-2v20zm-8-8h2v-4H3v4zm12 4h2V6h-2v12zm4-8v4h2v-4h-2z"/></svg>
                    <span className="nav-text">Activity Feed</span>
                  </span>
                </Link>
              </Menu.Item> 
              {/* <Menu.Item key={SIDEBAR_ROUTE.NUMBERCALLTRACK.KEY} data-tour="tour-activity" className={window.location.pathname == '/numbercalltrack' || window.location.pathname == '/number_settingcalltrack' ||  window.location.pathname == '/addnumbercalltrack' ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.NUMBERCALLTRACK.URL}>
                  <span className="isoMenuHolder">
                      <i className="numbericon"></i> 
                    <span className="nav-text">Numbers</span>
                  </span>
                </Link>
              </Menu.Item>              
              <Menu.Item key={SIDEBAR_ROUTE.CAMPAIGNCALLTRACK.KEY} data-tour="tour-activity" className={window.location.pathname == '/campaigncalltrack' ? "ant-menu-item-selected" : ''}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.CAMPAIGNCALLTRACK.URL}>
                  <span className="isoMenuHolder">
                    <span class="material-icons campaignicon">campaign</span>
                    <span className="nav-text">Campaigns</span>
                  </span>
                </Link>
              </Menu.Item>              */}
            </Menu>
          </Scrollbars>
          {showCallingCreditSection && 
          <div className="sidebarCreditDiv credibtntab chflex chflexcenter" style={{marginTop:"-10px"}}>
            {sidebarCreditRedirect  || !userData.isFreeTrialUser ?
              (
                <>
                  {isCollapsed ? "" :
                  <>
                  <Link to={SIDEBAR_ROUTE.PLAN_BILLING.URL}>
                    <span className="sidebarCreditSpan" onClick={() => tabTitleChange()}>
                       Credits
                      <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                        <InfoCircleFilled className='creditsInfoIcon' /> 
                      </Tooltip>
                      <span className="currentCreditValue">{(currentCredit > 0) ? sidebarCredit : '$0.00'} </span>
                    </span>
                  </Link>
                  <Tooltip title="Refresh">
                   <div className="refreshwrapper chflex chflexcenter justcenter" onClick={()=> checkCredits()}><div className="refreshbtn"></div></div>
                  </Tooltip>
                  </>
                  }
                </>
              )
              
            :
              <>
                <span className="sidebarCreditSpan"> 
                  Credits
                  <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                    <InfoCircleFilled className='creditsInfoIcon' /> 
                  </Tooltip>
                  <span className="currentCreditValue">{(currentCredit > 0) ? sidebarCredit : '$0.00'}</span>
                </span>
                <Tooltip title="Refresh">
                   <div className="refreshwrapper chflex chflexcenter justcenter" onClick={()=> checkCredits()}><div className="refreshbtn"></div></div>
                </Tooltip>
              </>
            }
            </div>}
        </Sider>
      </SidebarWrapper>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    numbers:state.number.get_number,
    user_billing: state.number.user_billing,
    //get_plan: state.planReducer.get_plan,
    pusherData: state.dashboard.userData,
  }
}
export default connect(mapStateToProps,{getCredits})(withRouter(SidebarCt));