import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const isoModal = ComponentName => WithDirection(styled(ComponentName)`
    .ant-modal-close{
        display:none;
    }
    .confirmemail .ant-row:after {
        display: block;
    }
    .confirmmailicon{
        width:140px;
    }
    .confirmemailcenter{
        text-align:center;
    }
    .confirmemailtext{
        margin-bottom:15px;
        color:#000;
    }
    .emailtext{
        color:#000;
        font-weight:500;
        margin-bottom: 15px;
    }
    .emailcolor{
        color:#000;
    }
    .resendemailbtn{
        margin-bottom: 15px;
        background:#e27647;
        border-color:#e27647;
        color:#fff;
        width:100%;
        height:40px;
    }
    .resendemailbtn.ant-btn:hover, .resendemailbtn.ant-btn:focus{       
        background:#e27647;
        border-color:#e27647;
        color:#fff;
    }
    .resendemailbtn.ant-btn:active{       
        background:#e27647;
        border-color:#e27647;
        color:#fff;
    }
    .changemailbtn{
        color:#000;
        background:#f2f3f5;
        border-color:#f2f3f5;
        width:100%;
        height:40px;
    }
    .changemailbtn.ant-btn:hover, .changemailbtn.ant-btn:focus{        
        color:#000;
        background:#f2f3f5;
        border-color:#f2f3f5;
    }
    .changemailbtn.ant-btn:active{ 
        color:#000;
        background:#f2f3f5;
        border-color:#f2f3f5;
    }
`);

export default isoModal;