import Pusher from "pusher-js";

const userData = JSON.parse(localStorage.getItem('userData'));
export const pusherConnection = ( parentId , userData)=> {
  const pusher = new Pusher(window.env.REACT_APP_PUSHER_KEY, {
    cluster: 'ap2',
    forceTLS: true,
    authEndpoint: window.env.REACT_APP_BASE_URL+ "pusher/auth/" + parentId
});

global.pusher =pusher

pusher.connection.bind( 'error', function( err ) {
  if( err.error.data.code === 4004 ) {
    console.log('>>> detected limit error');
  }
});

pusher.connection.bind('connected', function(res){
  console.log("Pusher connected");
});

  return {
    type: "PUSHER_CONNECTION",
    payload: pusher
  };
  };
             
export const pusherDisConnection = (pusher) => {
  pusher && pusher.disconnect();
  return {
    type: "PUSHER_DISCONNECTION",
    payload: {pusher:{}}

  };
};
