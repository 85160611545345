import React from "react";
import { connect } from "react-redux";
import { message } from 'antd';
import {clearMessage} from "./redux/customMessage/action";
message.config({
  maxCount:1
})
class ToastMessage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      toastMessage: "",
      type:""
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if(this.props.customMessage.message !== this.state.toastMessage){
                   this.setState({
                                  toastMessage:this.props.customMessage.message,
                                  type:this.props.customMessage.type?this.props.customMessage.type:"info"
                   })
    }
  }
  render() {
    {
    if(this.state.toastMessage){

      if(this.state.type==="info"){
        return (
          <div>
            {message.info(this.state.toastMessage,2,() => {
      this.props.clearMessage()
      message.destroy();
    })}
          </div>
        )
      }

      if(this.state.type==="error"){
        return (
          <div>
            {message.error(this.state.toastMessage,3,() => {
      this.props.clearMessage()
      message.destroy();
    })}
          </div>
        )
      }

      if(this.state.type === "errorLong"){
        return (
          <div>
            {message.error(this.state.toastMessage,10,() => {
      this.props.clearMessage()
      message.destroy();
    })}
          </div>
        )
      }

      if(this.state.type==="success"){
        return (
          <div>
            {message.success(this.state.toastMessage,2,() => {
      this.props.clearMessage()
      message.destroy();
    })}
          </div>
        )
      }
      if(this.state.type==="infoLong"){
        return (
          <div>
            {message.info(this.state.toastMessage,5,() => {
      this.props.clearMessage()
      message.destroy();
    })}
          </div>
        )
      }
      
      if(this.state.type==="warning"){
        return (
          <div>
            {message.warning(this.state.toastMessage,2,() => {
      this.props.clearMessage()
      message.destroy();
    })}
          </div>
        )
      }

    }else{
      return (<div></div>)
    }
  };
  }
}
const mapStateToProps = (state) => {
  return {
    customMessage: state.customMessage,
  };
};
export default connect(mapStateToProps,{clearMessage})(ToastMessage);
