import axios from 'axios';
import axiosApi, { ClearUserData,errorResponseStatus } from '../api';
import moment from "moment";

const userData = JSON.parse(localStorage.getItem('userData'));

const getLogs = {

    GET_CALLLOGS: 'GET_CALLLOGS',   
    GET_CALLLOGS_CSV: 'GET_CALLLOGS_CSV',
    GET_SMSLOGS: 'GET_SMSLOGS',
    GET_SMSLOGS_CSV: 'GET_SMSLOGS_CSV',
    GET_CALLRECORD_CSV: 'GET_CALLRECORD_CSV',
    GET_FILTER_COUNT : 'GET_FILTER_COUNT',

    GET_FEEDBACK: 'GET_FEEDBACK',
    ADD_FEEDBACK: 'ADD_FEEDBACK',

    GET_CALLTRANSCRIPT: 'GET_CALLTRANSCRIPT',
    DELETE_CALLLOGS: 'DELETE_CALLLOGS',
    GET_TAGS: 'GET_TAGS',
    GET_TRACKING_CALLLOGS: 'GET_TRACKING_CALLLOGS',
    GET_TRACKING_LOG: 'GET_TRACKING_LOG', 
    GET_TRACKING_REPORT: 'GET_TRACKING_REPORT', 
    EXPORT_TRACKING_REPORT: 'EXPORT_TRACKING_REPORT', 
    GET_TRACKING_LOGS_CSV: 'GET_TRACKING_LOGS_CSV',
    GET_LEADERBOARD_LIST: 'GET_LEADERBOARD_LIST',
    UPDATE_CALLLOGS_DATA: 'UPDATE_CALLLOGS_DATA',
    GET_CALLLOGS_DOWNLOAD_COLUMN : 'GET_CALLLOGS_DOWNLOAD_COLUMN',
    UPDATE_CALLLOGS_DOWNLOAD_COLUMN : 'UPDATE_CALLLOGS_DOWNLOAD_COLUMN',

    //getCallLogs: callLogList,

    //This function is used to Get CallLogs
    getRecording: async (callsid = '') => {
        try {
            const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
            return await axios.get(`${window.env.REACT_APP_BASE_URL}api/v1/recording/${callsid}`,{
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'id': userId,
                    'authtoken': authToken,
                    'billingtoken': bilingtoken
                }
            })
        }catch(err){

            console.log("======>err",err);
            if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                return ClearUserData(err.response);
            }
            let message = err.response;
            return message;

        }
        

        
    },
    getCallLogs: (logQueryString,filter) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            return axios.post(window.env.REACT_APP_BASE_URL + 'getCallLog' , logQueryString,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                console.log("executed===>",postData)
                if(postData.data.data.dayDiff){
                    if(postData.data.data.dayDiff > 90){
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:postData.data.data.message,type:"infoLong"}
                        })
                        // dispatch({
                        //     type:"CHANGE_QUERY",
                        //     payload:{
                        //         skip:0,
                        //         limit:50,
                        //         startDate: moment().subtract(1,'month').toDate(),
                        //         endDate: new Date()
                        //     }
                        // })

                    }
                }else{
                    dispatch({
                        type: getLogs.GET_CALLLOGS,
                        postData,
                        filter
                    });
                }
                return postData;
            }).catch(err => {
                console.log("executedFailed===>",err)
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                let message = err.response;
                return message
             })
        }
    },

    //This function is used to Get CallLogs CSV
    getCallLogsCsv: (userDetail, logQueryString,filterData) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;

        return (dispatch, getstate) => {
            axios.post(window.env.REACT_APP_BASE_URL + 'getCallLog' , logQueryString,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                if(!postData.data.data.url){
                    dispatch({
                        type:"SET_CUSTOM_MESSAGE",
                        payload:{message:'Your request is submitted successfully. We will send you the report on your registered email ID. ',type:"infoLong"}
                    });
                }
                else{
                    dispatch({
                        type:"SET_CUSTOM_MESSAGE",
                        payload:{message:'Your report is downloaded successfully!',type:"success"}
                    });
                    dispatch({
                        type: getLogs.GET_CALLLOGS_CSV,
                        postData
                    });
                }
                dispatch({
                    type:"SELECTED_CALLSID",
                    payload:'none'
                })
            }).catch(err => { 
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                dispatch({
                    type:"SET_CUSTOM_MESSAGE",
                    payload:{message:err && err.response && err.response.data && err.response.data.error && err.response.data.error.error,type:"error"}
                });
                dispatch({
                    type:"SELECTED_CALLSID",
                    payload:'none'
                })
                dispatch({
                    type: getLogs.GET_CALLLOGS_CSV,
                    postData:err.response
                });
            })
        }
    },
    deleteCallLog: data => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;

        return (dispatch, getstate) => {
            axios.get(window.env.REACT_APP_BASE_URL + 'deletecalllog'+data,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                dispatch({
                    type: getLogs.DELETE_CALLLOGS,
                    postData
                });
                dispatch({
                    type:"SELECTED_CALLSID",
                    payload:'none'
                })
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                dispatch({
                    type:"SELECTED_CALLSID",
                    payload:'none'
                })

                dispatch({
                    type:"SET_CUSTOM_MESSAGE",
                    payload:{message:err.response.data.error.error,type:"error"}
                });
                dispatch({
                    type: getLogs.GET_CALLLOGS_CSV,
                    postData:err.response
                });
            })
        }
    },

    getCallLogsRecordings: (userDetail,data) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;

        return (dispatch, getstate) => {
            axios.get(window.env.REACT_APP_BASE_URL + 'getCallLog/recordings'+data,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                if(!postData.data.data.url){
                    dispatch({
                        type:"SET_CUSTOM_MESSAGE",
                        payload:{message:"Your request is submitted successfully. We will send you recording on your registered email ID.",type:"infoLong"}
                    });
                }else{
                    dispatch({
                        type:"SET_CUSTOM_MESSAGE",
                        payload:{message:'Your report is downloaded successfully!',type:"success"}
                    });
                    dispatch({
                        type: getLogs.GET_CALLRECORD_CSV,
                        postData
                    });
                }
                dispatch({
                    type:"SELECTED_CALLSID",
                    payload:'none'
                })
            }).catch(err => {
                dispatch({
                    type:"SELECTED_CALLSID",
                    payload:'none'
                })
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                dispatch({
                    type:"SET_CUSTOM_MESSAGE",
                    payload:{message:  err.response.data.error.error,type:"error"}
                });
            })
        }
    },

    getLeaderBoardList: (query,history) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;

        return (dispatch, getstate) => {
            return axios.get(window.env.REACT_APP_BASE_URL + 'leaderBoardList' + query,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
                ).then(postData => {
                    dispatch({
                      type: getLogs.GET_LEADERBOARD_LIST,
                      postData,
                    })
                    return postData;
                }).catch((error) => {
                if(error.response && error.response.status!=428 && errorResponseStatus.indexOf(error.response.status) >= 0) {
                    return ClearUserData(error.response);
                }
                if(error.response.status!=428){
                    dispatch({
                        type:"SET_CUSTOM_MESSAGE",
                        payload:{message:error.response && error.response.data?error.response.data.error.error:"something went wrong", type:"error"}
                    });
                    history.push("/dashboard");
                }
                });
        }
    },

    getSmsLogs: (logQueryString,filter) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
           return axios.get(window.env.REACT_APP_BASE_URL + 'getSmsLog' + logQueryString,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                dispatch({
                    type: getLogs.GET_SMSLOGS,
                    postData,
                    filter
                });
                return postData;
            }).catch(err => {
               if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                   return ClearUserData(err.response);
               }
            })
        }
    },

    //This function is used to Get CallLogs CSV
    getSmsLogsCsv: (userDetail,logQueryString) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            axios.get(window.env.REACT_APP_BASE_URL + 'getSmsLog' + logQueryString,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                if(!postData.data.data.url){
                    dispatch({
                        type:"SET_CUSTOM_MESSAGE",
                        payload:{message:"Your request is submitted successfully. We will send you the report on your registered email ID.",type:"infoLong"}
                    });
                }else{
                    dispatch({
                        type:"SET_CUSTOM_MESSAGE",
                        payload:{message:'Your report is downloaded successfully!',type:"success"}
                    });
                    dispatch({
                        type: getLogs.GET_SMSLOGS_CSV,
                        postData
                    });
                }
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                dispatch({
                    type:"SET_CUSTOM_MESSAGE",
                    payload:{message:err.response.data.error.error,type:"error"}
                });
                dispatch({
                    type: getLogs.GET_SMSLOGS_CSV,
                    postData:err.response
                });
            })
        }
    },

     // This function is used for get feedbacks
     getFeedback: (logId) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            return axios.get(window.env.REACT_APP_BASE_URL + `feedBacks/getFeedbacks/${logId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'id': userId,
                    'authtoken': authToken,
                    'billingtoken': bilingtoken,
                  }
            }
            ).then((postData) => {
                dispatch({
                    type: getLogs.GET_FEEDBACK,
                    postData
                });
                return postData;
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                   return err;
            })
        }
    },

    // This function is used to add Feedback
    addFeedback: data => {
        const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            axios.post(window.env.REACT_APP_BASE_URL + 'feedBacks/addFeedback', data,
              {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'id': userId,
                  'authtoken': authToken,
                  'billingtoken': bilingtoken,
                }
              }
            ).then(postdata => {
                console.log("postData",postdata)
              dispatch({
                type: getLogs.ADD_FEEDBACK,
                postdata,
              })
            }).catch((err) => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
              const postdata = err.response;
              dispatch({
                type: getLogs.ADD_FEEDBACK,
                postdata,
              })
            });
        }
    },

    // This function is used for get transcript
    getCallTranscript: data => {
        const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            axios.post(window.env.REACT_APP_BASE_URL + 'getCallTranscript' , data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'id': userId,
                    'authtoken': authToken,
                    'billingtoken': bilingtoken,
                  }
            }
            ).then(postData => {
                console.log("getCallTranscript postData",postData)
                dispatch({
                    type: getLogs.GET_CALLTRANSCRIPT,
                    postData
                })
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
             })   
        }
    },
    onChangeFromNumber: value => dispatch => {
        dispatch({
            type:"CHANGE_FROM_NUMBER",
            payload:value
        });
        // this.setState(prevState => ({
        //     getLogJson: {
        //         ...prevState.getLogJson,
        //         fromNumber: value
        //     },
        // }));
    },

    onChangeToNumber: value => dispatch => {
        dispatch({
            type:"CHANGe_TO_NUMBER",
            payload:value
        });
        // this.setState(prevState => ({
        //     getLogJson: {
        //         ...prevState.getLogJson,
        //         toNumber: value
        //     },
        // }));
    },

    onStartDateChange:date => dispatch => {
        dispatch({
            type:"CHANGE_START_DATE",
            payload:date._d
        })
        // this.setState(prevState => ({
        //     getLogJson: {
        //         ...prevState.getLogJson,
        //         startDate: date._d
        //     },
        // }));
    },
    onEndDateChange:date => dispatch => {
        dispatch({
            type:"CHANGE_END_DATE",
            payload:date._d
        })
        // this.setState(prevState => ({
        //     getLogJson: {
        //         ...prevState.getLogJson,
        //         endDate: date._d
        //     },
        // }));
    },
    onCallTypeChange:e => dispatch => {
        dispatch({
            type:"CHANGE_CALL_TYPE",
            payload:e.target.value
        })
        // this.setState(prevState => ({
        //     getLogJson: {
        //         ...prevState.getLogJson,
        //         callType: e.target.value
        //     },
        // }));
    },
    userCheckList:userCheckList => (dispatch,state) => {
        console.log("----->state",state());
        let users = state().usersReducer.get_users;
        let checkList = [];
        if(userCheckList.includes("check-all")){
            users.map((m) => {
                checkList.push(m._id);
            })
        }else if(userCheckList.includes("uncheck-all")){
            checkList = [];
        }else{
            checkList = userCheckList
        }
        dispatch({
            type:"CHANGE_USERS",
            payload:checkList
        })
    },
    callStatusCheckList:callStatusCheckList => dispatch => {
        dispatch({
            type:"CHANGE_STATUS_CHECKLIST",
            payload:callStatusCheckList
        })
        // let checkList = callStatusCheckList
        // if(callStatusCheckList.includes("check-all")){
        //     checkList = callStatusOp;
        // }else if(callStatusCheckList.includes("uncheck-all")){
        //     checkList = []
        // }
        // this.setState(prevState => ({
        //     getLogJson: {
        //         ...prevState.getLogJson,
        //         callBackStatus: checkList
        //     },
        // }));
    },
    onChangePowerDialer:(e) => dispatch => {
        console.log("changepowerdialer",e.target.checked);
        dispatch({
            type:"CHANGE_POWER_DIALER",
            payload:e.target.checked
        })
        // console.log(e.target.checked);
        // let callType = e.target.checked?false:true
        // this.setState(prevState => ({
        //     showCallType:callType,
        //     getLogJson: {
        //         ...prevState.getLogJson,
        //         powerDialer:e.target.checked
        //     },
        // }))
    },
    changeQuery:(query) => dispatch => {
        dispatch({
            type:"CHANGE_QUERY",
            payload:query
        })
    },
    changeSelectedCallSids:(query) => dispatch => {
        console.log("SELECTED_CALLSID",query);
        dispatch({
            type:"SELECTED_CALLSID",
            payload:query
        })
    },
    getTags: () => {
        const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            return axios.get(window.env.REACT_APP_BASE_URL + `tags/${userId}/gettags`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'id': userId,
                    // 'authtoken': authToken,
                    // 'billingtoken': bilingtoken,
                  }
            }
            ).then((postData) => {
                dispatch({
                    type: getLogs.GET_TAGS,
                    postData
                });
                return postData;
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                   return err;
            })
        }
    },
    //Get all tracking call logs
    getTrackingCallLog: (logQueryString,filter) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            return axios.get(window.env.REACT_APP_BASE_URL + 'tracking/callLog' + logQueryString,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                if(postData.data.data.dayDiff){
                    if(postData.data.data.dayDiff > 90){
                        dispatch({
                            type:"SET_CUSTOM_MESSAGE",
                            payload:{message:postData.data.data.message,type:"infoLong"}
                        })
                        dispatch({
                            type:"CHANGE_QUERY",
                            payload:{
                                skip:0,
                                limit:50,
                                startDate: moment().subtract(1,'month').toDate(),
                                endDate: new Date()
                            }
                        })

                    }
                }else{
                    dispatch({
                        type: getLogs.GET_TRACKING_CALLLOGS,
                        postData,
                        filter
                    });
                }
                return postData;
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                let message = err.response;
                return message
             })
        }
    },

    updateTrackingLog: (data,callSid) => dispatch => {
        dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
        return axiosApi(`${window.env.REACT_APP_BASE_URL}tracking/detail/`+ callSid, "put", data, dispatch, "SET_CUSTOM_MESSAGE");
    },
    //Download tracking logs
    getTrackingLogsCsv: logQueryString => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;

        return (dispatch, getstate) => {
            axios.get(window.env.REACT_APP_BASE_URL + 'tracking/callLog' + logQueryString,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                dispatch({
                    type:"SET_CUSTOM_MESSAGE",
                    payload:{message:'Your request is submitted successfully. We will send you the report on your registered email ID.',type:"infoLong"}
                });
            }).catch(err => { 
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                dispatch({
                    type:"SET_CUSTOM_MESSAGE",
                    payload:{message: err.response && err.response.data && err.response.data.error && err.response.data.error.error ? err.response.data.error.error : 'Something went wrong!',type:"error"}
                });
                dispatch({
                    type: getLogs.GET_TRACKING_LOGS_CSV,
                    postData:err.response
                });
            })
        }
    },
    //Get single call log tracking data
    getTrackingLog: (logId) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
           return axios.get(window.env.REACT_APP_BASE_URL + 'tracking/detail/' + logId,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                dispatch({
                    type: getLogs.GET_TRACKING_LOG,
                    postData
                });
                return postData;
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                   return err;
            })
        }
    }
    ,
     //Get traking report data
     getTrackingReport: (startDate, endDate) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
           return axios.get(window.env.REACT_APP_BASE_URL + 'tracking/report?startDate='+startDate+'&endDate='+endDate,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                dispatch({
                    type: getLogs.GET_TRACKING_REPORT,
                    postData
                });
                return postData;
            }).catch(err => {
               if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                   return ClearUserData(err.response);
               }
            })
        }
    },
    //Export tracking report
    exportTrackingReport: (startDate, endDate) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        // axiosApi(`${window.env.REACT_APP_BASE_URL}tracking/export?startDate=${startDate}&endDate=${endDate}`,"get",{},dispatch,`${getLogs.EXPORT_TRACKING_REPORT}`);
        return (dispatch, getstate) => {
           return axios.get(window.env.REACT_APP_BASE_URL + 'tracking/export?startDate='+startDate+'&endDate='+endDate,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'id': userId,
                        'authtoken': authToken,
                        'billingtoken': bilingtoken
                    }
                }
            ).then(postData => {
                dispatch({
                    type: getLogs.EXPORT_TRACKING_REPORT,
                    postData
                });
                return postData;
            }).catch(err => {
               if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                   return ClearUserData(err.response);
               }
            })
        }
    },
    updateCallLogsData: postData => dispatch => {dispatch({type: getLogs.UPDATE_CALLLOGS_DATA, payload: postData})},
    // get call logs column
    getCallLogsDownloadColumn: () => {
        const userData = JSON.parse(localStorage.getItem('userData')),
            authToken = userData == null ? '' : userData.authToken,
            userId = userData == null ? '' : userData._id,
            bilingtoken = userData == null ? '' : userData.billingToken;
        return (dispatch, getstate) => {
            return axios.get(window.env.REACT_APP_BASE_URL + `user/callActivity/download/column`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'id': userId,
                    'authtoken': authToken,
                    'billingtoken': bilingtoken
                }
            }
            ).then((postData) => {
                console.log("getCallLogsDownloadColumn ----1111111111",postData.data,postData.data && postData.data.data ? postData.data.data : [])
                dispatch({
                    type: getLogs.GET_CALLLOGS_DOWNLOAD_COLUMN,
                    postData
                });
                return postData;
            }).catch(err => {
                if(err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
                    return ClearUserData(err.response);
                }
                   return err;
            })
        }
    },
    updateCallLogsDownloadColumn: (callLogReportColumns) => dispatch => {
        console.log("updateCallLogsDownloadColumn 111", callLogReportColumns)
       return axiosApi(window.env.REACT_APP_BASE_URL + `user/callActivity/download/column`, "put", { callLogReportColumns }, dispatch, `${getLogs.UPDATE_CALLLOGS_DOWNLOAD_COLUMN}`)
    }
}
export default getLogs;