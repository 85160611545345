import React, { Component } from 'react';
import { Modal, Button,Row,Col,Card  } from 'antd';
import Customcalleridpopupstyle from '../AddNumber/customcalleridPopup.style';

export default class BroadcastPopup extends Component{
    constructor(props) {
        super(props);                  
    }
    
    render() {        
        
        return(
            
            <Modal  visible={this.props.broadcast}  onCancel={this.props.handleCancelvoicebroadcastPopup}
            footer={null}
            centered
            >
                <Customcalleridpopupstyle>
                <div className="customcalleridwrapper">
                    <Row>
                        <Col span={24}>
                            <h3 className="registerrequest">Do you want to register a request for Broadcast?</h3>
                        </Col>
                        <Col span={24} className="customcalleridbtn">                                                        
                                <Button type="primary" onClick={this.props.handleRequestSend}>Yes, Please proceed</Button>
                                <Button className="customcallercancel" onClick={this.props.handleCancelvoicebroadcastPopup}>Cancel</Button>
                        </Col>
                    </Row>
                </div>
                </Customcalleridpopupstyle>
            </Modal>
        
        )
    }
}