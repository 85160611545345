import { store } from './store';
import authantication from "@iso/redux/authentication/action";

export default () =>
  new Promise(() => {
    if(localStorage.userData){
      let data = JSON.parse(localStorage.userData);
      store.dispatch({
          type: authantication.AUTHENTICATION,
          postdata:{success:true,data}
      })
    }
    //store.dispatch(authActions.checkAuthorization());
  });
