const initialState = {
  message: "",
  type:"info"
};

export default function customMessage(state = initialState, action) {
  switch (action.type) {
    case "SET_CUSTOM_MESSAGE":
      return {
        ...state,
        message: action.payload.message,
        type:action.payload.type?action.payload.type:state.type  
      };
    case "CLEAR_CUSTOM_MESSAGE":
      return {
        ...state,
        message: "",
      };
    default:
      return state;
  }
}
