const initState = {
dashboard:{},

}
export default function broadcastReducer(state = initState, action){
    switch(action.type){
      case "BROADCAST_DASHBOARD":
      return {
        ...state,
        dashboard: action.postdata,
      };
      
      case "CLEAR_DASHBOARD":
       return {
        ...state,
        dashboard:{}
       }
      case "MARK_TOUR_VISITED":
        return {
          ...state,
               mark_visited: action.postdata,
        };
      default:
        return state;
    }
}