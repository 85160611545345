import React, { Component } from 'react';
import { Modal} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import planSetting from '@iso/redux/plan/actions';
import numberAction from "@iso/redux/number/actions";
import {setMessage} from "@iso/redux/customMessage/action";
import dashboardActions from '@iso/redux/dashboard/actions';
import modalActions from '@iso/redux/modal/actions';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import Currency from "../../constants";

const {checkAccountInChargebee} = numberAction;
const { resumeAccount,getDueAmount} = planSetting;
const { changeUserData } = dashboardActions;
const { closeModal ,openModal} = modalActions;
const {  ADD_NUMBER ,DASHBOARD,INVITE_USER,PLAN_BILLING,USERS,NUMBER} = APP_PRIVATE_ROUTE;

class ResumeAccountPopup extends Component{
    constructor(props) {
        super(props);   
        this.state={
          amountDue:0,
          currencySign: this.props.userData.data.selectedCurrency ? Currency[this.props.userData.data.selectedCurrency].sign : "$",
        }               
    }

    componentDidMount() {
      if(this.props.userData.data.stripeId){
        this.props.checkAccountInChargebee(this.props.userData.data._id);

      //   this.props.getDueAmount(this.props.userData.data._id,"pending").then(({ payload }) => {
      //     if (payload.data && payload.success) {
      //       if(payload.data.length > 0){
      //         let amountDue = 0;
      //         payload.data.map((inv)=>
      //           {
      //             if(inv.amountDue){
      //               amountDue = amountDue + inv.amountDue
      //             } 
      //           })
      //         this.setState({amountDue}) 
      //       }
      //     }
      // });
      }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.chargbee_data !== this.props.chargbee_data) {
        if (this.props.chargbee_data.data && this.props.chargbee_data.data.success) {
          if(this.props.chargbee_data.data.data && this.props.userData.data.stripeId){
            this.setState({ amountDue: this.props.chargbee_data.data.data.find((dt)=>dt.id ==  this.props.userData.data.stripeId) ? this.props.chargbee_data.data.data.find((dt)=>dt.id ==  this.props.userData.data.stripeId).total_dues / 100 : 0  });
          }
        }
      }
      // if (prevProps.get_amountdue !== this.props.get_amountdue) {
      //   if (this.props.get_amountdue.data && this.props.get_amountdue.success) {
      //     if(this.props.get_amountdue.data.length > 0){
      //       let amountDue = 0;
      //       this.props.get_amountdue.data.map((inv)=>
      //         {
      //           if(inv.amountDue){
      //             amountDue = amountDue + inv.amountDue
      //           } 
      //         })
      //       this.setState({amountDue}) 
      //     }
      //   }
      // }
      if (prevProps.resume_account !== this.props.resume_account) {
        if (this.props.resume_account && this.props.resume_account.success) {
           await this.props.checkAccountInChargebee(this.props.userData.data._id);
           this.props.setMessage({message:'Your account has been Resumed Successfully.',type:"success"});
           this.props.changeUserData({ data: {acoountPauseReason:''}, "type": "accountPause" });
           switch(this.props.modalData && this.props.modalData.currentModalProps.resumeReason ? this.props.modalData.currentModalProps.resumeReason  : "dashboard"){
            case "addNumber":
              this.props.history.push(ADD_NUMBER);
              break;
             case "inviteUser":
              this.props.history.push(INVITE_USER);
              break;
             case "upgradeDowngradePlan":
              this.props.history.push(DASHBOARD);
              break;
             case "addOn":
              this.props.openModal({modalType: 'addon',modalProps:this.props.modalData.oldModalProps});
              break;
             case "userRole":
              this.props.history.push(USERS);
              break;
             case "planUpgradePopup":
              this.props.openModal({modalType: 'planUpgrade',modalProps:this.props.modalData.oldModalProps});
              break;
             case "callerId":
              this.props.history.push(this.props.modalData.currentModalProps.pathDirect);
              break;
             case "dashboard":
              this.props.history.push(DASHBOARD);
              break;
             default:
              this.props.history.push(DASHBOARD);
              break;
           }
        }
      }
    }

    handleActiveOk = ()=> {
      this.props.closeModal()
      this.props.resumeAccount({reason:this.props.modalData && this.props.modalData.modalProps ? this.props.modalData.modalProps.resumeReason : "Resume Account"});
    }
    
    render() {        
        const { modalVisibility, modalType, modalProps } = this.props.modalData;
        return(
            
          <Modal
          title="Activate Account"
          visible={modalVisibility && modalType == "resumeAccount"}
          onOk={this.handleActiveOk}
          onCancel={()=>this.props.closeModal()}
          okText="Yes"
          okButtonProps= {{type : 'default'}}
          cancelText="No"
          className="exportYesNO"
          centered
          >
          {this.state.amountDue ? <><p>On Account Activation you will be charged pending invoice of {this.state.currencySign}{this.state.amountDue} </p><br></br></>:''}  
          <p>Do you want to activate your account?</p>
        </Modal>
        
        )
    }
}

const mapStateToProps = state => ({
  resume_account:state.planReducer.resume_account,
  get_amountdue:state.planReducer.get_amountdue,
  userData:state.authentication.userData,
  chargbee_data:state.number.chargbee_data,
  modalData: state.modal
});

export default connect(mapStateToProps, {checkAccountInChargebee, resumeAccount,setMessage,changeUserData,closeModal,getDueAmount,openModal})(withRouter(ResumeAccountPopup));