import whiteLabelEnvLive from "./whiteLabelEnvLive";

const whiteLabelEnvTest = {};

whiteLabelEnvTest["localhost"] = {
    ...whiteLabelEnvLive["localhost"],
};

whiteLabelEnvTest["staging-app.callhippo.com"] = {
    ...whiteLabelEnvLive["web.callhippo.com"],
};

whiteLabelEnvTest["staging-app.waaotalk.com"] = {
    ...whiteLabelEnvLive["web.waaotalk.com"],
    "REACT_APP_BASE_URL": "https://staging-app.waaotalk.com/",
    "REACT_APP_DIALER_URL": "https://staging-phone.waaotalk.com/",
    "REACT_APP_REPORTS_URL": "https://staging-app.waaotalk.com",
    "REACT_APP_BASE_URL_V2": "https://staging-app.waaotalk.com/",
    "REACT_APP_BASE_URL_V3": "https://staging-app.waaotalk.com/",
    "REACT_APP_FRONT_URL": "https://staging-app.waaotalk.com/",
    "REACT_APP_PHONE_URL": "https://staging-phone.waaotalk.com/",
    "REACT_APP_SERVER_URL": "https://staging-app.waaotalk.com/",
    "PUBLIC_URL": "https://staging-app.waaotalk.com/",
    "REACT_APP_REDIRECT_URL": "https://staging-app.waaotalk.com/",
    "REACT_APP_CALL_URL": "https://staging-phone.waaotalk.com/",
};

whiteLabelEnvTest["staging-app.buysecondnumber.com"] = {
    ...whiteLabelEnvLive["web.buysecondnumber.com"],
    "REACT_APP_BASE_URL": "https://staging-app.buysecondnumber.com/",
    "REACT_APP_DIALER_URL": "https://staging-phone.buysecondnumber.com/",
    "REACT_APP_REPORTS_URL": "https://staging-app.buysecondnumber.com",
    "REACT_APP_BASE_URL_V2": "https://staging-app.buysecondnumber.com/",
    "REACT_APP_BASE_URL_V3": "https://staging-app.buysecondnumber.com/",
    "REACT_APP_FRONT_URL": "https://staging-app.buysecondnumber.com/",
    "REACT_APP_PHONE_URL": "https://staging-phone.buysecondnumber.com/",
    "REACT_APP_SERVER_URL": "https://staging-app.buysecondnumber.com/",
    "PUBLIC_URL": "https://staging-app.buysecondnumber.com/",
    "REACT_APP_REDIRECT_URL": "https://staging-app.buysecondnumber.com/",
    "REACT_APP_CALL_URL": "https://staging-phone.buysecondnumber.com/",
    "REACT_APP_CHARGEBEE_SITE": "dialerhq-test",
}

whiteLabelEnvTest["staging-app.dialerhq.com"] = {
    ...whiteLabelEnvLive["web.dialerhq.com"],
    "REACT_APP_BASE_URL": "https://staging-app.dialerhq.com/",
    "REACT_APP_DIALER_URL": "https://staging-phone.dialerhq.com/",
    "REACT_APP_REPORTS_URL": "https://staging-app.dialerhq.com",
    "REACT_APP_BASE_URL_V2": "https://staging-app.dialerhq.com/",
    "REACT_APP_BASE_URL_V3": "https://staging-app.dialerhq.com/",
    "REACT_APP_FRONT_URL": "https://staging-app.dialerhq.com/",
    "REACT_APP_PHONE_URL": "https://staging-phone.dialerhq.com/",
    "REACT_APP_SERVER_URL": "https://staging-app.dialerhq.com/",
    "PUBLIC_URL": "https://staging-app.dialerhq.com/",
    "REACT_APP_REDIRECT_URL": "https://staging-app.dialerhq.com/",
    "REACT_APP_CALL_URL": "https://staging-phone.dialerhq.com/",
    "REACT_APP_CHARGEBEE_SITE": "dialerhq-test",
}

export default whiteLabelEnvTest;