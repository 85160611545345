import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Layout, Popover, Tooltip } from 'antd';
import message from '@iso/components/Feedback/Message';
import Scrollbars from '@iso/components/utility/customScrollBar';
import Menu from '@iso/components/uielements/menu';
import appActions from '@iso/redux/app/actions';
import Logo from '@iso/components/utility/logo';
import SidebarWrapper from './Sidebar.styles';
import { Link, withRouter } from 'react-router-dom';
// import Popover from '@iso/components/uielements/popover';
import styled from 'styled-components';
import CHTour from './CHTour';
import { APP_PRIVATE_ROUTE, PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { Helmet } from "react-helmet";
import AddNumberModal from './AddNumberModal.js';
import PlanUpgradePopup from "@iso/components/Popups/PlanUpgrade/PlanUpgradePopup";
import Callhippoinfopopup from "@iso/components/Popups/Callhippoinfopopup";
import Addonpopup from "../../components/Popups/addOnpopup";
import Enterpriseplanpopup from "../../components/Popups/enterprisePlanPopup";
import Upgradeoptionpopup from "../../components/Popups/upgradeOptionpopup";
import Confirmemailpopup from "../../components/Popups/confirmEmailpopup";
import ResumeAccountPopup from "../../components/Popups/resumeAccountPopup";
import dashboardAction from "../../redux/dashboard/actions";
import AddNoPopUp from '../AddNumber/addNoPopUp';
import numberAction from '../../redux/number/actions';
import planSetting from '@iso/redux/plan/actions';
import {setMessage} from "@iso/redux/customMessage/action";
import modalActions from '@iso/redux/modal/actions';
import authantication from "@iso/redux/authentication/action";
import {InfoCircleFilled} from '@ant-design/icons';
import {
  Action,
  checkAccessAllowedForModule,
  ModuleName,
  Message,
  defaultModuleAccess
} from "@iso/lib/helpers/moduleAccess";
const { SubMenu } = Menu;
const {getCredits} = dashboardAction ;
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const callbackPhone = imageUrl+"callback_Phone.svg";
const helpDoc = imageUrl+"help_doc.svg";
const { openModal } = modalActions;
const { changeUserData } = authantication;
const PopoverTextWrapper = styled.div`
.popover__outer .popover_Text {
  font-size: 13px !important;
  font-weight: 400 !important;
  color:#000;
}
.popover__outer {
  padding:5px 5px 5px 5px;
  margin:-5px -16px -4px;
}
.popover__outer a {
  color:#333 !important;
}
.padTopzero {
  padding-top:0px;
}
.callbackPhoneIcon {
  background-image: url(${callbackPhone});
  height: 18px;
  margin-right: 10px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain; 
}
.helpDocIcon {
  background-image: url(${helpDoc});
  height: 18px;
  margin-right: 10px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain; 
}
.popover__contentText {
  font-size:13px;
  color:#000;
  padding:9px 10px 10px 12px;
}
.helpDoc:hover{
  background:#f1f1f1;
}
`;

const { CALL_SCRIPT,DASHBOARD, NUMBER, ADD_NUMBER, NUMBER_SETTING, NUMBER_BY_ID, DID_GROUPS, ADD_DID_GROUP,DID_GROUP_BY_ID,USERS, USER_BY_ID, USER_CUSTOMER_AVIBLITY, INVITE_USER, TEAMS, ADD_TEAMS, TEAM_BY_ID, ACTIVITYFEED, INTEGRATION, POWER_DIALER, ADD_CAMPAIGNS, PLAN, PLAN_BILLING, CALL_REPORT, ABANDON_RATE_REPORT, AVAILABILITY_REPORT, CALL_STATUS_REPORT,OUTGOING_CALL_REPORT, CALL_PLANNER, EDIT_CAMPAIGNS,BROADCAST_CALL,ADD_CAMPAIGNS_BROADCAST,EDIT_CAMPAIGNS_BROADCAST, COACH,CallTrack,LEADERBOARD, TARGET_VS_ACHIEVEMENT, CAMPAIGNMANAGEMENT, CAMPAIGNMANAGEMENT_BY_ID, ADD_CAMPAIGNMANAGEMENT,CALL_LOG,SMS_LOG,DUMMY_CALL_LOG,DUMMY_SMS_LOG ,USER_STATUS_REPORT} = APP_PRIVATE_ROUTE;
const { ADD_CALL_SCRIPT, EDIT_CALL_SCRIPT, ADD_TARGET,EDIT_TARGET, USER_PROFILE, USER_PROFILE_DOCUMENT, CHANGE_PASSWORD } = PRIVATE_ROUTE

const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

const {
   user,getUserProfile,checkAccountInChargebee
} = numberAction;
const { getPlan } = planSetting;

function Sidebar(props) {
  const dispatch = useDispatch();
  const {
    view,
    openKeys,
    collapsed,
    openDrawer,
    current,
    height,
  } = useSelector(state => state.App);

  const { modalVisibility, modalType, modalProps } = useSelector(state => state.modal);
  const userData = useSelector(state => state.authentication.userData.data);
  const numberData = useSelector(state => state.number.get_number);
  const user_billing = useSelector(state => state.number.user_billing);
  const get_plan = useSelector(state=>state.planReducer.get_plan);
  const isTourOpen = useSelector(state => state.authentication.isTourOpen);
  const pusherData = useSelector(state => state.dashboard.pusher_data);
  const userCredits = useSelector(state => state.dashboard.userCredits);
  const user_data = useSelector(state => state.number.user_data);
  const get_users = useSelector(state => state.number.get_users);
  var currentCredit = userCredits && userCredits.credits ? userCredits.credits : 0;
  // Pusher connecttion
  const [sidebarCredit, setSidebarCredit] = useState(0);
  
  const [sidebarCreditRedirect, setSidebarCreditRedirect] = useState(false);
  const [showCallingCreditSection, setShowCallingCreditSection] = useState(false);
  const [addPopUpVisible, setPopupVisibility] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState(true);
  const [upgradePlanShow, setUpgradePlanShow] = useState(false);
  const [user_details, setUserDetails] = useState('');
  const [creditModuleAccess] = useState(checkAccessAllowedForModule(userData.roleData, ModuleName.CREDIT,userData));
  //This useEffect will call's every time when this component loaded
  useEffect(() => { 
    if (!userData.isTourVisited && !userData.isProfileRemaining && /* !userData.isPhoneVerificationPending && */ !userData.isOnBoardingProcessPending) props.history.push(DASHBOARD) ;
    if(isTourOpen && userData && userData.parentId)  window.mixpanel.track("New sub user login",{distinct_id: userData.email});
    sidebarCreditChange();
    props.user({ userId: userData._id, planId: userData.plan._id, authToken: userData.authToken, billingtoken: userData.billingToken });
    props.getUserProfile();
    props.getPlan({ uid: userData._id })
    props.checkAccountInChargebee(userData._id).then(({postdata})=>{
      if(postdata.data && !postdata.data.chargebeeAccountExist){
        setUpgradePlan(false)
      }
      setUpgradePlanShow(true)
    }); 
  }, []);

  useEffect(() => {
    setPopupVisibility(modalType == "addNoPopUp" ? true : false)
  }, [modalProps])
  
  useEffect(() => {
    try {         
      const timeoutId = setTimeout(() => {
        if(window.clarity){
          let clarityEmail = userData.email || "no_email"
          window.clarity("set", "email", clarityEmail);
          console.log("===> ms clarity email tag event called from common:", clarityEmail)
        }
      }, 3000);
      
      return () => clearTimeout(timeoutId); 
      
    }catch(err) {
      console.log("Sidebar try catch error ======>", err)
    }
  }, [])
  
  //Change credit when push event call
  useEffect(() => { if(pusherData && pusherData.type === "credit") sidebarCreditChange();}, [pusherData]);
  useEffect(() => {  if (props.get_users.success) {    
    dispatch(changeUserData({
      isFreeTrialUser: get_users.data && get_users.data.isFreeTrialUser,
      isBlocked: get_users.data && get_users.data.isBlocked,
      isDocVerificationRequired: get_users.data.isDocVerificationRequired
    }));
    setUserDetails(get_users.data) 
  }}, [get_users]);

  useEffect(() =>{
    if( userData.plan.isAccessEditable && userData.isDisableCredit && !(userData.roleData.priority == 1 || userData.roleData.priority == 1.5)){
      setShowCallingCreditSection(!userData.isDisableCredit)
    }else{
      setShowCallingCreditSection(true)
    }
  }, [userData])
  const customizedTheme = useSelector(
    state => state.ThemeSwitcher.sidebarTheme
  );

  const SIDEBAR_ROUTE = {
    DASHBOARD: {
      URL: DASHBOARD,
      KEY: 'dashboard',
      URL_GROUP: [DASHBOARD]
    },
    NUMBER: {
      URL: NUMBER,
      KEY: 'number',
      URL_GROUP: [
        NUMBER,
        ADD_NUMBER,
        NUMBER_SETTING,
        NUMBER_BY_ID
      ]
    },
    DID_GROUPS: {
      URL: DID_GROUPS,
      KEY: 'didgrops',
      URL_GROUP: [
        DID_GROUPS,
        ADD_DID_GROUP,
        DID_GROUP_BY_ID
      ]
    },
    USERS: {
      URL: USERS,
      KEY: 'user',
      URL_GROUP: [
        USERS,
        USER_BY_ID,
        USER_CUSTOMER_AVIBLITY,
        INVITE_USER
      ]
    },
    TEAMS: {
      URL: TEAMS,
      KEY: 'team',
      URL_GROUP: [
        TEAMS,
        ADD_TEAMS,
        TEAM_BY_ID,
      ]
    },
    ACTIVITYFEED: {
      URL: ACTIVITYFEED,
      KEY: 'activityfeed',
      URL_GROUP: [ACTIVITYFEED]
    },
    INTEGRATION: {
      URL: INTEGRATION,
      KEY: 'integration',
      URL_GROUP: [INTEGRATION]
    },
    POWER_DIALER: {
      URL: POWER_DIALER,
      KEY: 'powerdialer',
      URL_GROUP: [
        POWER_DIALER,
        ADD_CAMPAIGNS,
        EDIT_CAMPAIGNS
      ]
    },
    // Parvati code 26-11-2021
    CAMPAIGNMANAGEMENT: {
      URL: CAMPAIGNMANAGEMENT,
      KEY: 'campaignmanagement',
      URL_GROUP: [
        CAMPAIGNMANAGEMENT,
        ADD_CAMPAIGNMANAGEMENT,
        CAMPAIGNMANAGEMENT_BY_ID,
      ]
    },
    //parvati code end 11-01-2021
    PLAN: {
      URL: PLAN,
      KEY: 'plan',
      URL_GROUP: [PLAN]
    },
    PLAN_BILLING: {
      URL: PLAN_BILLING,
      KEY: 'planBilling',
      URL_GROUP: [PLAN_BILLING]
    },
    CALL_REPORT: {
      URL: CALL_REPORT,
      KEY: 'callreport',
      URL_GROUP: [CALL_REPORT]
    },
    ABANDON_RATE_REPORT: {
      URL: ABANDON_RATE_REPORT,
      KEY: 'abandonRateReport',
      URL_GROUP: [ABANDON_RATE_REPORT]
    },
    AVAILABILITY_REPORT: {
      URL: AVAILABILITY_REPORT,
      KEY: 'availabilityReport',
      URL_GROUP: [AVAILABILITY_REPORT]
    },
    USER_STATUS_REPORT: {
      URL: USER_STATUS_REPORT,
      KEY: 'userStatusReport',
      URL_GROUP: [USER_STATUS_REPORT]
    },
    CALL_STATUS_REPORT: {
      URL: CALL_STATUS_REPORT,
      KEY: 'callStatusReport',
      URL_GROUP: [CALL_STATUS_REPORT]
    },
    OUTGOING_CALL_REPORT: {
      URL: OUTGOING_CALL_REPORT,
      KEY: 'outgoingCallReport',
      URL_GROUP: [OUTGOING_CALL_REPORT]
    },
    CALL_PLANNER: {
      URL: CALL_PLANNER,
      KEY: 'callplanner',
      URL_GROUP: [CALL_PLANNER]
    },
    COACH: {
      URL: COACH,
      KEY: 'coach',
      URL_GROUP: [COACH]
    },
    CallTrack:{
      URL: CallTrack,
      KEY: 'calltracking',
      URL_GROUP: [CallTrack]
    },  
    CALL_SCRIPT:{
      URL:CALL_SCRIPT,
      KEY:'callscript',
      URL_GROUP:[
        CALL_SCRIPT,
        EDIT_CALL_SCRIPT,
        ADD_CALL_SCRIPT
      ]
    },
    LEADERBOARD: {
      URL: LEADERBOARD,
      KEY: 'leaderboard',
      URL_GROUP: [LEADERBOARD]
    },
    TARGET_VS_ACHIEVEMENT: {
      UPL: TARGET_VS_ACHIEVEMENT,
      KEY: 'targetvsachievement',
      URL_GROUP: [
        TARGET_VS_ACHIEVEMENT,
        ADD_TARGET,
        EDIT_TARGET
      ]
    },
    CALL_LOG: {
      URL: CALL_LOG,
      KEY: 'calllog',
      URL_GROUP: [CALL_LOG]
    },
    SMS_LOG: {
      URL: SMS_LOG,
      KEY: 'smslog',
      URL_GROUP: [SMS_LOG]
    },
    DUMMY_CALL_LOG: {
      URL: DUMMY_CALL_LOG,
      KEY: 'dummycalllog',
      URL_GROUP: [DUMMY_CALL_LOG]
    },
    DUMMY_SMS_LOG: {
      URL: DUMMY_SMS_LOG,
      KEY: 'dummysmslog',
      URL_GROUP: [DUMMY_SMS_LOG]
    },
    USER_PROFILE_DOCUMENT: {
      URL: USER_PROFILE_DOCUMENT,
      KEY: 'uploadDocument',
      URL_GROUP: [USER_PROFILE_DOCUMENT]
    }

  }
  
  useEffect(() => {
    dispatch(changeCurrent([]));
    Object.values(SIDEBAR_ROUTE).filter(obj => {
      obj.URL_GROUP.map((obj2) => {
        if (typeof obj2 !== 'undefined' && props.history.location.pathname.toLowerCase().includes(obj2.toLowerCase())) {
          dispatch(changeCurrent([obj.KEY]));
        }
      })
    })
    if(![USER_PROFILE,USER_PROFILE_DOCUMENT,CHANGE_PASSWORD].includes(props.history.location.pathname) && userData.isDocVerificationRequired !== "approved"){
      props.history.push(USER_PROFILE_DOCUMENT);
    }
  }, [props.history.location.pathname])

  function handleClick(e) {
    //dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }

  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';  
  const onMouseEnter = event => {      
    if(window.outerWidth > 1024){  
      if (collapsed && openDrawer === false) {
        dispatch(toggleOpenDrawer());
      }
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };

  const sidebarCreditChange = () => {
    let finalCredit = 0;
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (pusherData && pusherData.type === "credit") {
      finalCredit = parseFloat(pusherData.data.totalCredits)
    } else {
      if (!(!numberData || !numberData.data || !numberData.data.length) && user_billing && user_billing.data && user_billing.data.data) {
        finalCredit = parseFloat(user_billing.data.data.availableCredits - user_billing.data.data.usedCredits)
      } else {
        if (userCredits && userCredits.credits) {
          finalCredit = userCredits.credits
        } else {
           if (userData && userData.stripeId && userData.plan && userData.plan.planDisplayName && userData.plan.planDisplayName !== 'free') {
            if (!userData.parentId || (userData.parentId && userData.totalCredits && userData.userModules.indexOf('billing') >= 0)) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
              setSidebarCreditRedirect(true);
            } else if (userData && userData.parentId && userData.userModules && userData.userModules.indexOf('billing') == -1) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
            }
          }
        }
      }
    }
    let userDataLocal = JSON.parse(localStorage.getItem('userData'));
    if(userDataLocal){
      userDataLocal.totalCredits = finalCredit;
      localStorage.setItem("userData", JSON.stringify(userDataLocal));
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
      finalCredit = finalCredit ? formatter.format(finalCredit.toFixed(2).toString()) : "$0.00";

      setSidebarCredit(finalCredit);
      if(userDataLocal.isFreeTrialUser){
      setUpgradePlan(true);
      }
    }

  }
  
  const warningMsg = msg => { message.destroy(); message.warning(msg) };
  const userIsBlocked = () => {
    userData.isBlocked && !userData.amountDue && warningMsg(userData.blockReason);
    //userData.isBlocked && userData.amountDue && warningMsg(userData.blockReason + " Please update your payment method.");

  };
  const clickCampaignManagement= ()=>{
    if(userData.isBlocked && !userData.amountDue){
      warningMsg(userData.blockReason);
    }else if(userData.isBlocked && userData.amountDue){
     // warningMsg(userData.blockReason + " Please update your payment method.");
    }else if(props.userData && props.userData.data && props.userData.data.roleData && props.userData.data.roleData.priority && props.userData.data.roleData.priority > 2){
      warningMsg("You are not authorized to use this module.");
    }else{
      props.history.push(SIDEBAR_ROUTE.CAMPAIGNMANAGEMENT.URL)
    }
  }

  const onTabClick = tabName => {
    if(!userData.isBlocked && userData.plan.planDisplayName !== 'free' && tabName === 'report' && userData.stripeId && !userData.plan.report){
      if(tabName === 'report'){
        window.mixpanel.track("Clicking on the Reports.",{distinct_id: userData.email,parent_id: userData && userData.parentEmail?userData.parentEmail:userData.email});
      }
      dispatch(openModal({modalType: 'planUpgrade', modalProps: { planFeatureFlag: 'report', planFeatureName: 'Report', planUpgradeCallBack: () => props.history.push(CALL_REPORT) }}));
    }
  };
  const tabTitleChange = () => {
    if (current != []) {
      current.splice(0, current.length)
      current.push("credit")
    }
  }
  const checkCredits = () =>{
    props.getCredits().then(({payload})=>{
      if(payload.success){
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
      let finalCredit= payload.data ? payload.data.totalCredits ? formatter.format( payload.data.totalCredits.toFixed(2).toString()):sidebarCredit : sidebarCredit;
      let finalCr= payload.data ? payload.data.totalCredits ? payload.data.totalCredits:sidebarCredit : sidebarCredit;
      let userData = JSON.parse(localStorage.getItem('userData'));
      userData.totalCredits = finalCr;
      localStorage.setItem("userData", JSON.stringify(userData));
      setSidebarCredit(finalCredit);  
      }
    });
  }
  const planUpgrade = async() => {
    if(userData && typeof userData.userActive !== "undefined" && !userData.userActive){
      return props.setMessage({message:"Your email not verified, please verify to continue.",type:"info"});
    }
    else{
      await setPopupVisibility(true)  
    }   
  }
  const changeStateFromChild = async(obj) => {
      if(addPopUpVisible){
        await setPopupVisibility(obj.addPopUpVisible);
      }
      if(obj.upgradePlan && !upgradePlan){
         setUpgradePlan(obj.upgradePlan)
      }
  }
  const handleclose = () => {
    setPopupVisibility(false)
  }
  function changeTabTitle(currentKey) {
    const keyName = currentKey[0];
    if (currentKey[0] == "settings" || currentKey[0] == "plan" || currentKey[0] == "credit") {
      return "Setting"
    } else if(currentKey[0] == "planBilling" ){
      return "Plan and Billing"
    } else if (currentKey[0] == "coach") {
      return "Coach"
    } else if (currentKey[0] == "callPlanner") {
      return "Call Planner"
    } else if (currentKey[0] == "callreport") {
      return "Call Report"
    } else if (currentKey[0] == "abandonRateReport") {
      return "Abandon Rate Report"
    } else if (currentKey[0] == "availabilityReport") {
      return "Availability Report"
    } else if (currentKey[0] == "userStatusReport") {
      return "User Status Report"
    } else if (currentKey[0] == "callStatusReport") {
      return "Call Status Report"
    } else if (currentKey[0] == "powerdialer") {
      return "PowerDialer"
    }else if (currentKey[0] == "voicebroadcast") {
      return "voicebroadcast"
    // }else if (currentKey[0] == "integration") {
    //   return "Integrations"
    } else if (currentKey[0] == "activityfeed") {
      return "Activity Feed"
    } else if (currentKey[0] == "callplanner") {
      return "Call Planner"
    } else if (currentKey[0] == "team") {
      return "Teams"
    }else if (currentKey[0] == "campaignmanagement") {
      return "Campaign Management"
    }else if (currentKey[0] == "user") {
      return "Users"
    } else if (currentKey[0] == "number") {
      return "Numbers"
    } else if (currentKey[0] == "didgrops") {
      return "DID Groups"
    }else if (currentKey[0] == "voiceBroadcast") {
      return "Voice Broadcast"
    }else if (currentKey[0] == "callscript"){
      return "Call Scripts"
    }else if (currentKey[0] == "leaderboard"){
      return "Leader Board"
    }else if (currentKey[0] == "targetvsachievement"){
      return "Target vs Achievement"
    }else if (currentKey[0] == "addtarget"){
      return "Add Target"
    }else if (currentKey[0] == "uploadDocument"){
      return "Upload Document"
    }else if (currentKey[0] == undefined || "dashboard") {
      return "Dashboard"
    }else {
      return keyName[0].toLowerCase() + keyName.slice(1);
    }
  }
  const TitleContent = (
    <PopoverTextWrapper style={{padding:0}}>
    <div className="popover__outer helpDoc" style={{padding:10}} onClick={()=>{
      window.mixpanel.track("Click on the Help documentation.",{distinct_id: userData.email,parent_id: userData && userData.parentEmail?userData.parentEmail:userData.email});
    }}>
    {/* <a href='https://support.callhippo.com/' target='_blank' className="chflex chaligncenter">
    <i className="helpDocIcon"></i>
    <span className="popover_Text" >Help Documentation</span>
      </a> */}
    </div>

    <div className="popover__outer helpDoc" style={{padding:10}} onClick={()=>{
      window.mixpanel.track("Click on the Request a callback.",{distinct_id: userData.email,parent_id: userData && userData.parentEmail?userData.parentEmail:userData.email});
    }}>
    {/* <a href="https://calendly.com/callhippo/callhippo-product-demo" target='_blank' className="chflex chaligncenter">
    <i className="callbackPhoneIcon"></i>
    <span className="popover_Text">Request a Callback</span>
    </a> */}
    </div>
    </PopoverTextWrapper>
  );
  const content = (
    <PopoverTextWrapper className="needHelpPopover" style={{margin:-12, marginRight:-16,marginLeft:-16}} onClick={()=>{
      window.mixpanel.track("Click on the 24*7 support.",{distinct_id: userData.email,parent_id: userData && userData.parentEmail?userData.parentEmail:userData.email});
    }}>
      <p href="tel:+61871501878" target='_blank' className="popover__contentText helpDoc">Australia<a href="tel:+61871501878"> <br/> +61871501878 </a> <br/> <span style={{fontSize:'10px'}}>24/7 support</span></p>
      <p href="tel:+18198009120" target='_blank' className="popover__contentText helpDoc">Canada <a href="tel:+18198009120"> <br/> +18198009120 </a> <br/> <span style={{fontSize:'10px'}}>24/7 support</span></p>
      <p href="tel:+918068575824" target='_blank' className="popover__contentText helpDoc">India <a href="tel:+918068575824"> <br/> +918068575824 </a> <br/> <span style={{fontSize:'10px'}}>24/7 support</span></p>
      <p href="tel: +441473378282" target='_blank' className="popover__contentText helpDoc">United Kingdom <a href="tel: +441473378282"> <br/> +441473378282 </a> <br/> <span style={{fontSize:'10px'}}>24/7 support</span></p>
      <p href="tel:+17408482535" target='_blank' className="popover__contentText helpDoc">United States <a href="tel:+17408482535"> <br/> +17408482535 </a> <br/> <span style={{fontSize:'10px'}}>24/7 support</span></p>
    </PopoverTextWrapper>
  )
  return (
    <div>
      
      <SidebarWrapper>
        {/* <PusherConnection userData={userData} /> */}
        <Helmet>
          <title>{changeTabTitle(current)} | {window.env.REACT_APP_PAGETITLE}</title>
        </Helmet>
        <CHTour isOpen={isTourOpen} />
        <Callhippoinfopopup />
        <AddNumberModal />
        <Addonpopup />
        <AddNoPopUp visible={addPopUpVisible} newPlan={true} contactName='' addressVerifiedNoteHide='' parent_state='' number='' changeStateFromChild={changeStateFromChild} premium='' close={handleclose} userbilling='' get_avail_number='' user_data={user_data} user_details={user_details} secondNumberPopupOk='' history={props.history} />
        <Enterpriseplanpopup />
        <Upgradeoptionpopup />
        <PlanUpgradePopup />
        <Confirmemailpopup />
        <ResumeAccountPopup />
        <Sider
          trigger={null}
          collapsible={false}
          collapsed={isCollapsed}
          width={240}
          className={`isomorphicSidebar ${userData && userData.isProfileRemaining ? "sidebarDisabled" : ''}`}          
          // onMouseLeave={onMouseLeave}
          // onMouseEnter={onMouseEnter}
          style={styling}
        >
          <Logo collapsed={isCollapsed} isBlocked={userData.isBlocked} />
          <Scrollbars className={`sidebarScrollbars sidebarremoveborder ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`} 
            style={ isCollapsed ? {height: height - 100} : userData.isFreeTrialUser ? {height: height - 180} : {height: height - 139}}
            >
            {window.env.REACT_APP_REPORTS_APP && userData.isReportsApp ? 
              <Menu
              onClick={handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={isCollapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={onOpenChange}
            >
              <Menu.Item key="activityfeed" data-tour="tour-activity">
              <Link onClick={userIsBlocked} to={!userData.isBlocked && (userData.numbers || userData.deletedNumbers || userData.parentId ? "/activityFeed":"/dummyactivityfeed")} >
                <span className="isoMenuHolder">
                  <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18h2V6H7v12zm4 4h2V2h-2v20zm-8-8h2v-4H3v4zm12 4h2V6h-2v12zm4-8v4h2v-4h-2z"/></svg>
                  <span className="nav-text">Activity Feed</span>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={SIDEBAR_ROUTE.CALL_REPORT.KEY}>
            <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.CALL_REPORT.URL}>
              <span className="isoMenuHolder">
                <i className="call_report_icon"></i>
                <span className="nav-text">Reports</span>
              </span>
            </Link>
          </Menu.Item>
          </Menu>

          :
          <Menu
              onClick={handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              // openKeys={isCollapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={onOpenChange}
            >
              <Menu.Item key={SIDEBAR_ROUTE.DASHBOARD.KEY}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.DASHBOARD.URL}>
                  <span className="isoMenuHolder svgremove">                    
                    <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"/></svg>
                    <span className="nav-text">Dashboard</span>
                  </span>
                </Link>
              </Menu.Item>
              <SubMenu
                key="sub1"
                className={window.location.pathname == '/numbers' || window.location.pathname == '/didgrops' ? "numbersmenuleft numberssubmenu pad_numbersZero whiteicfixtest reporticonsidebar" : 'numberssubmenu whiteicfixtest reporticonsidebar'}
                title={
                  <Menu.Item data-tour="tour-number">
                    <span className="isoMenuHolder">
                      <i className="numbericonmain"></i>
                      <span className="nav-text">Number and DID</span>
                    </span>
                  </Menu.Item>
                }
              >
              <Menu.Item key={SIDEBAR_ROUTE.NUMBER.KEY} className="numbermenue">
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.NUMBER.URL}>
                  <span className="isoMenuHolder">
                    <i className="numbericon_new"></i>
                    <span className="nav-text">Numbers</span>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key={SIDEBAR_ROUTE.DID_GROUPS.KEY} className="didgroupmenue">
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.DID_GROUPS.URL}>
                  <span className="isoMenuHolder">
                    <i className="didgroupicon"></i>
                    <span className="nav-text">DID Group</span>
                  </span>
                </Link>
              </Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub2"
                className={window.location.pathname == '/users' || window.location.pathname == '/teams' || window.location.pathname == '/leaderboard' ||  window.location.pathname == '/targetvsachievement' ? "usermenuleft usersubmenu pad_userZero whiteicfixtest reporticonsidebar" : 'usersubmenu whiteicfixtest reporticonsidebar'}
                title={
                  <Menu.Item data-tour="tour-users">
                    <span className="isoMenuHolder">
                      <i className="userperfomnce"></i>                    
                      <span className="nav-text">Users & Performance</span>
                    </span>
                </Menu.Item> 
                }
               >
                <Menu.ItemGroup key="g1">                  
                    <Menu.Item key={SIDEBAR_ROUTE.USERS.KEY} className="usermenu">
                    <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.USERS.URL}>
                        <span className="isoMenuHolder">
                        <i className="usericon_submenu"></i>                    
                        <span className="nav-text">Users</span>
                        </span>
                    </Link>
                    </Menu.Item> 
                    <Menu.Item key={SIDEBAR_ROUTE.TEAMS.KEY} className="teammenu">
                        <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.TEAMS.URL}>
                        <span className="isoMenuHolder">                    
                            <i className="teamicon teamicontab"></i>
                            <span className="nav-text">Teams</span>
                        </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={SIDEBAR_ROUTE.LEADERBOARD.KEY} className="leaderboardmenu">
                        <Link to={SIDEBAR_ROUTE.LEADERBOARD.URL}>
                        <span className="isoMenuHolder">
                            <i className="leaderboardicon leaderboardicontab"></i>                      
                            <span className="nav-text">Leader Board</span>
                        </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={SIDEBAR_ROUTE.TARGET_VS_ACHIEVEMENT.KEY} className="targetmenu">
                        <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.TARGET_VS_ACHIEVEMENT.UPL}>
                        <span className="isoMenuHolder">
                            <i className="targeticon"></i>                      
                            <span className="nav-text">Target vs Achievement</span>
                        </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={SIDEBAR_ROUTE.CALL_PLANNER.KEY} className="callReminderMenu">
                        <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.CALL_PLANNER.URL}>
                        <span className="isoMenuHolder ">                    
                            <i className="callplannericon"></i>
                            <span className="nav-text">Call Reminder</span>
                            {isCollapsed ?  "" :<span className="ch-nav-call-notificaion hidenotification">{userData.countPendingReminder}</span>}
                        </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={SIDEBAR_ROUTE.CALL_SCRIPT.KEY} className="callScriptsMenu">
                        <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.CALL_SCRIPT.URL}>
                        <span className="isoMenuHolder">
                            <i className="callscripticon scripticon"></i>                    
                            <span className="nav-text">Call Scripts</span>
                        </span>
                        </Link>
                    </Menu.Item>           
                    { <Menu.Item key={SIDEBAR_ROUTE.CAMPAIGNMANAGEMENT.KEY} className="campaignManagementMenu">
                        <Link onClick={clickCampaignManagement} >
                        <span className="isoMenuHolder">                    
                        <i className="campaignicon"></i>
                        <span className="nav-text">Campaign Management</span>
                        </span>
                        </Link>
                    </Menu.Item> }
                </Menu.ItemGroup>
             </SubMenu>
              {/* <Menu.Item key={SIDEBAR_ROUTE.INTEGRATION.KEY}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.INTEGRATION.URL}>
                  <span className="isoMenuHolder">
                    <i className="integrationicon"></i>                    
                    <span className="nav-text">Integrations</span>
                  </span>
                </Link>
              </Menu.Item>*/}
              {!userData.isBlocked && (userData.numbers || userData.deletedNumbers || userData.parentId) ?
                <Menu
                  onClick={handleClick}
                  theme="dark"
                  className="isoDashboardMenu"
                  mode={mode}
                  // openKeys={isCollapsed ? [] : openKeys}
                  selectedKeys={current}
                  onOpenChange={onOpenChange}
                >    
                  <Menu.Item key={SIDEBAR_ROUTE.CALL_LOG.KEY} className="callmenu" data-tour="tour-calllog">
                    <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.CALL_LOG.URL}>
                      <span className="isoMenuHolder">
                        <i className="calllog"></i>                    
                        <span className="nav-text">Call Logs / Recordings</span>
                      </span>
                    </Link>
                  </Menu.Item> 
                  <Menu.Item key={SIDEBAR_ROUTE.SMS_LOG.KEY} className="smsmenu">
                    <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.SMS_LOG.URL}>
                      <span className="isoMenuHolder">
                        <i className="smslog"></i>                    
                        <span className="nav-text">SMS Logs</span>
                      </span>
                    </Link>
                  </Menu.Item>   
                </Menu>        
                // <SubMenu
                //   key="activityfeed"
                //   className={window.location.pathname == '/calllog' || window.location.pathname == '/smslog' ? "usermenuleft usersubmenu pad_userZero whiteicfixtest reporticonsidebar" : 'usersubmenu whiteicfixtest reporticonsidebar'}
                //   title={
                //     <Menu.Item className="activitymenu">
                //         <span className="isoMenuHolder svgremove">
                //               <i className="activityfeed"></i> 
                //               <span className="nav-text">Activity Feed</span>
                //           </span>
                //     </Menu.Item>
                //   }>  
                //   <Menu.ItemGroup key="g2"> 
                    
                //   </Menu.ItemGroup>                
                // </SubMenu>
                :
                <Menu
                  onClick={handleClick}
                  theme="dark"
                  className="isoDashboardMenu"
                  mode={mode}
                  // openKeys={isCollapsed ? [] : openKeys}
                  selectedKeys={current}
                  onOpenChange={onOpenChange}
                >
                  <Menu.Item key={SIDEBAR_ROUTE.DUMMY_CALL_LOG.KEY} className="callmenu" data-tour="tour-calllog">
                    <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.DUMMY_CALL_LOG.URL}>
                      <span className="isoMenuHolder">
                        <i className="calllog"></i>                    
                        <span className="nav-text">Call Logs / Recordings</span>
                      </span>
                    </Link>
                  </Menu.Item> 
                  <Menu.Item key={SIDEBAR_ROUTE.DUMMY_SMS_LOG.KEY} className="smsmenu">
                    <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.DUMMY_SMS_LOG.URL}>
                      <span className="isoMenuHolder">
                        <i className="smslog"></i>                    
                        <span className="nav-text">SMS Logs</span>
                      </span>
                    </Link>
                  </Menu.Item> 
                </Menu>
                // <SubMenu
                // key="activityfeed"
                // className={window.location.pathname == '/calllog' || window.location.pathname == '/smslog' ? "usermenuleft usersubmenu pad_userZero whiteicfixtest reporticonsidebar" : 'usersubmenu whiteicfixtest reporticonsidebar'}
                // title={
                //   <Menu.Item>
                //       <span className="isoMenuHolder svgremove">
                //             <i className="activityfeed"></i> 
                //             <span className="nav-text">Activity Feed</span>
                //         </span>
                //   </Menu.Item>
                // }>  
                // <Menu.ItemGroup key="g2"> 
                    
                // </Menu.ItemGroup>                
                // </SubMenu>
                // <Menu.Item key="activityfeed" data-tour="tour-activity">
                // {userData.isReportsApp ? <a onClick={userIsBlocked}  target={ userData.isReportsApp ? '_blank' : ''} href={!userData.isBlocked && (userData.numbers || userData.deletedNumbers || userData.parentId ? ( userData.isReportsApp ? window.env.REACT_APP_REPORTS_URL + ACTIVITYFEED : ACTIVITYFEED) :"/dummyactivityfeed")} >
                //     <span className="isoMenuHolder svgremove">
                //       <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18h2V6H7v12zm4 4h2V2h-2v20zm-8-8h2v-4H3v4zm12 4h2V6h-2v12zm4-8v4h2v-4h-2z"/></svg>
                //       <span className="nav-text">Activity Feed</span>
                //     </span>
                //   </a> : 
                //   <Link onClick={userIsBlocked} target={ userData.isReportsApp ? '_blank' : ''} to={!userData.isBlocked && (userData.numbers || userData.deletedNumbers || userData.parentId ? ( userData.isReportsApp ? window.env.REACT_APP_REPORTS_URL + ACTIVITYFEED : ACTIVITYFEED) :"/dummyactivityfeed")} >
                //     <span className="isoMenuHolder svgremove">
                //       <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18h2V6H7v12zm4 4h2V2h-2v20zm-8-8h2v-4H3v4zm12 4h2V6h-2v12zm4-8v4h2v-4h-2z"/></svg>
                //       <span className="nav-text">Activity Feed</span>
                //     </span>
                //   </Link>}
                // </Menu.Item>
              }
              <SubMenu
                key="subr2"
                className={'usermenuleft usersubmenu pad_userZero whiteicfixtest reporticonsidebar'}
                title={
                  <Menu.Item>
                    <span className="isoMenuHolder">
                      <i className="call_report_icon"></i>
                      <span className="nav-text">Reports</span>
                    </span>
                  </Menu.Item>
                }
              >
                <Menu.ItemGroup key="r1">
                  <Menu.Item key={SIDEBAR_ROUTE.CALL_STATUS_REPORT.KEY} className="status_report">
                    <Link target={userData.isReportsApp ? "_blank" : "_self"} onClick={() => {userIsBlocked(); onTabClick('report')}} to={!userData.isBlocked && (userData.plan.planDisplayName === 'free' || userData.plan.report) && (userData.isReportsApp ? window.env.REACT_APP_REPORTS_URL + SIDEBAR_ROUTE.CALL_STATUS_REPORT.URL : SIDEBAR_ROUTE.CALL_STATUS_REPORT.URL)} >
                        <span className="isoMenuHolder">
                          <i className="call_status_report"></i>
                          <span className="nav-text">Call Status Report</span>
                        </span>
                    </Link>
                  </Menu.Item> 
                  <Menu.Item key={SIDEBAR_ROUTE.ABANDON_RATE_REPORT.KEY} className="abandon_report">
                    <Link target={userData.isReportsApp ? "_blank" : "_self"} onClick={() => {userIsBlocked(); onTabClick('report')}} to={!userData.isBlocked && (userData.plan.planDisplayName === 'free' || userData.plan.report) && (userData.isReportsApp ? window.env.REACT_APP_REPORTS_URL + SIDEBAR_ROUTE.CALL_STATUS_REPORT.URL : SIDEBAR_ROUTE.ABANDON_RATE_REPORT.URL)} >
                      <span className="isoMenuHolder">
                        <i className="abandon_rate_report"></i>
                        <span className="nav-text">Abandon Rate Report</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={SIDEBAR_ROUTE.OUTGOING_CALL_REPORT.KEY} className="status_report">
                    <Link target={userData.isReportsApp ? "_blank" : "_self"} onClick={() => {userIsBlocked(); onTabClick('report')}} to={!userData.isBlocked && (userData.plan.planDisplayName === 'free' || userData.plan.report) && (userData.isReportsApp ? window.env.REACT_APP_REPORTS_URL + SIDEBAR_ROUTE.OUTGOING_CALL_REPORT.URL : SIDEBAR_ROUTE.OUTGOING_CALL_REPORT.URL)} >
                        <span className="isoMenuHolder">
                          <i className="call_status_report"></i>
                          <span className="nav-text">Outgoing Call Report</span>
                        </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={SIDEBAR_ROUTE.USER_STATUS_REPORT.KEY} className="avail_report">
                    <Link target={userData.isReportsApp ? "_blank" : "_self"} onClick={() => {userIsBlocked(); onTabClick('report')}} to={!userData.isBlocked && (userData.plan.planDisplayName === 'free' || userData.plan.report) && (userData.isReportsApp ? window.env.REACT_APP_REPORTS_URL + SIDEBAR_ROUTE.CALL_STATUS_REPORT.URL : SIDEBAR_ROUTE.USER_STATUS_REPORT.URL)} >
                      <span className="isoMenuHolder">
                        <i className="availability_report"></i>
                        <span className="nav-text">User Status Report</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key={SIDEBAR_ROUTE.AVAILABILITY_REPORT.KEY} className="avail_report">
                    <Link target={userData.isReportsApp ? "_blank" : "_self"} onClick={() => {userIsBlocked(); onTabClick('report')}} to={!userData.isBlocked && (userData.plan.planDisplayName === 'free' || userData.plan.report) && (userData.isReportsApp ? window.env.REACT_APP_REPORTS_URL + SIDEBAR_ROUTE.CALL_STATUS_REPORT.URL : SIDEBAR_ROUTE.AVAILABILITY_REPORT.URL)} >
                      <span className="isoMenuHolder">
                        <i className="availability_report"></i>
                        <span className="nav-text">Availability Report</span>
                      </span>
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key={SIDEBAR_ROUTE.CALL_REPORT.KEY} className="call_report_main_icon">
                    <Link target={userData.isReportsApp ? "_blank" : "_self"} onClick={() => {userIsBlocked(); onTabClick('report')}} to={!userData.isBlocked && (userData.plan.planDisplayName === 'free' || userData.plan.report) && (userData.isReportsApp ? window.env.REACT_APP_REPORTS_URL + SIDEBAR_ROUTE.CALL_STATUS_REPORT.URL : SIDEBAR_ROUTE.CALL_REPORT.URL)} >
                      <span className="isoMenuHolder">
                        <i className="call_report_new_icon"></i>
                        <span className="nav-text">Call Report</span>
                      </span>
                    </Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
              
              <Menu.Item key={SIDEBAR_ROUTE.POWER_DIALER.KEY}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.POWER_DIALER.URL}>
                  <span className="isoMenuHolder svgremove">
                    <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2.02c-5.51 0-9.98 4.47-9.98 9.98s4.47 9.98 9.98 9.98 9.98-4.47 9.98-9.98S17.51 2.02 12 2.02zM11.48 20v-6.26H8L13 4v6.26h3.35L11.48 20z"/></svg>
                    <span className="nav-text">Power Dialer</span>
                  </span>
                </Link>
              </Menu.Item>
             { <Menu.Item key={SIDEBAR_ROUTE.PLAN_BILLING.KEY}>
                <Link onClick={userIsBlocked}  to={SIDEBAR_ROUTE.PLAN_BILLING.URL}>
                 <span className="isoMenuHolder">                    
                   <i className="planicon"></i>
                   <span className="nav-text">Plan and Billing</span>
                 </span>
                </Link>
             </Menu.Item> }
              <Menu.Item key={SIDEBAR_ROUTE.PLAN.KEY}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.PLAN.URL}>
                  <span className="isoMenuHolder">
                    <i className="settingicon"></i>                      
                    <span className="nav-text">Settings</span>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key={SIDEBAR_ROUTE.PLAN.KEY}>
                <a href="https://controlhippo.com" className='ctrlh_link' target='_blank'>
                  <span className="isoMenuHolder">
                    <i className="contollhippo_icon"></i>                      
                    <span className="nav-text">Whatsapp Shared Inbox</span>
                    <span className='ctrlh_new_flag'>New</span>
                  </span>
                </a>
              </Menu.Item>
            </Menu>
              
            }
          </Scrollbars>
          <div className="sidebarCreditDiv">
          {/* <Popover content={content} title={TitleContent} trigger="click" placement='right'>         
            <div className={`need__help_div needhelpbottom mrgtopbtm0 ${isCollapsed ? 'needhelpcenter' : ''}`}> 
              {isCollapsed ?
              <Tooltip title="Need Help" placement="right">
                <i className={`need_help_icon${isCollapsed ? 'helpcollapsedmargin' : ''}`}></i>            
              </Tooltip>
              :
                ""
              }
              {isCollapsed ?  "" : <><i className="need_help_icon"></i><span className="help-text">Need Help</span></>}
            </div>
            </Popover>*/}
          {showCallingCreditSection && <>
            {!userData.isFreeTrialUser ?
             (
               <>{ upgradePlanShow && <>
                {isCollapsed ? "" :
                !upgradePlan && props.userData && props.userData.data && !userData.plan.isOfficePhoneSystem && props.userData.data.numbers == 0 && props.userData.data.roleData && props.userData.data.roleData.priority == 1  ? 
                  <div className="upgradenowbtn"> 
                    <span className="sidebarUpgradeSpan" onClick={() => planUpgrade()}>Upgrade here! </span>
                  </div> : "" 
                }
                {isCollapsed ? "" :
                  upgradePlan || ( props.userData && props.userData.data &&(userData.plan.isOfficePhoneSystem || props.userData.data.numbers > 0  || ( props.userData.data.roleData && props.userData.data.roleData.priority != 1) ))  ?
                  <div className="chflex chflexcenter" style={{marginTop:"4px"}}>
                     <Link to={SIDEBAR_ROUTE.PLAN_BILLING.URL}>
                      <div className="sidebarCreditSpan chflex chflexcenter" onClick={() => tabTitleChange()}>
                        Credits
                        <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                          <InfoCircleFilled className='creditsInfoIcon' /> 
                        </Tooltip>
                        <span className="currentCreditValue">{(currentCredit > 0) ? sidebarCredit : '$0.00'}</span> 
                        </div>
                        </Link>
                          <>
                          {creditModuleAccess[Action.UPDATE] &&
                            <div>
                              <Tooltip title="Add Credit">
                                <Link to={SIDEBAR_ROUTE.PLAN_BILLING.URL+"#callingCredit"}>
                                  <div className="refreshwrapper addCreditsBtnMain chflex chflexcenter justcenter" onClick={() => tabTitleChange()}>
                                    <i className='addBtnSideBar'></i> 
                                    <span className='addCreditsBtnName'>Add</span>
                                  </div>
                                </Link>
                              </Tooltip>
                            </div>
                          }
                          </>
                          <Tooltip title="Refresh">
                            <div className="refreshwrapper chflex chflexcenter justcenter" onClick={()=> checkCredits()}>
                              <i className="refreshbtn"></i>
                              </div>
                          </Tooltip>
                  </div> : ""
                }</>}
               </>
             )                           
              :              
              <> 
               { !userData.stripeId ? 
                  <div className="upgradenowbtn"> 
                    <span className="sidebarUpgradeSpan" onClick={() => planUpgrade()}>Upgrade here! </span>
                  </div> :
                 !userData.isFreeTrialUser && <Link to={SIDEBAR_ROUTE.PLAN_BILLING.URL}>
                    <div className="upgradenowbtn"> 
                      <span className="sidebarUpgradeSpan" onClick={() => tabTitleChange()}>Upgrade here! </span>
                    </div>
                  </Link>
                }             
                {userData.stripeId ? 
                <div className='chflex chflexcenter' style={{marginTop:"4px"}}>
                      <div className="sidebarCreditSpan chflex chflexcenter" onClick={() => tabTitleChange()}>Credits
                        <Tooltip title={<div className='sidebarCreditsTooltip'>Credits are common for calling and SMS</div>} open>
                          <InfoCircleFilled className='creditsInfoIcon' /> 
                        </Tooltip>
                        <span className="currentCreditValue">{(currentCredit > 0) ? sidebarCredit : '$0.00'}</span>
                        <Tooltip title="Refresh">
                         <div className="refreshwrapper chflex chflexcenter justcenter" onClick={()=> checkCredits()}>
                          <i className="refreshbtn"></i>
                          </div>
                        </Tooltip>
                        <>
                          { 
                          (props.userData && props.userData.data && props.userData.data.roleData && props.userData.data.roleData.name && props.userData.data.roleData.priority )? 
                            ( props.userData.data.roleData.name == "Owner" || props.userData.data.roleData.name == "Admin" || props.userData.data.roleData.name == "Dashboard User")? 
                            <div>
                              <Tooltip title="Add Credit">
                                <Link to={SIDEBAR_ROUTE.PLAN_BILLING.URL+"#callingCredit"}>
                                  <div className="refreshwrapper addCreditsBtnMain chflex chflexcenter justcenter" onClick={() => tabTitleChange()}>
                                    <i className='addBtnSideBar'></i>
                                    <span className='addCreditsBtnName'>Add</span>
                                  </div>
                                </Link>
                              </Tooltip>
                            </div>:""
                            : ""
                          }
                        </>
                      </div>
                  </div>    
                  :''}    
               </>
              }</>}
            </div>

        </Sider>
      </SidebarWrapper>
    </div>
  );  
}


const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    numbers:state.number.get_number,
    user_billing: state.number.user_billing,
    get_plan: state.planReducer.get_plan,
    pusherData: state.dashboard.userData,
    get_users: state.number.get_users,
    user_data: state.number.user_data,
    userData: state.authentication.userData,
    chargbee_data:state.number.chargbee_data
   }
  }
export default connect(mapStateToProps,{user,getUserProfile,getPlan,checkAccountInChargebee,setMessage,getCredits})(withRouter(Sidebar));
